import React from 'react'

const Terms = () => {
  return (
    <div className="WordSection1" style={{direction:"ltr"}}>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>TERMS AND CONDITIONS</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>Last updated</span></b><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}> <b style={{msoBidiFontWeight: 'normal'}}>February 24, 2023</b></span></p>
        <p className="MsoNormal" style={{lineHeight: '110%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '110%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '115%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '115%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>AGREEMENT TO OUR LEGAL TERMS</span></b></p>
        <p className="MsoNormal" align="center" style={{textAlign: 'center', lineHeight: 'normal', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>We are ArabyAI Technology, doing business as ArabyAI
            ("<b style={{msoBidiFontWeight: 'normal'}}>Company</b>," "<b style={{msoBidiFontWeight: 'normal'}}>we</b>," "<b style={{msoBidiFontWeight: 'normal'}}>us</b>," "<b style={{msoBidiFontWeight: 'normal'}}>our</b>"),
            a company registered in Saudi Arabia at 3184 Imam Saud Bin Abdulaziz Bin
            Mohammed Rd - Al Muruj Dist - RIYADH 12281, Riyadh</span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>, Riyadh 6233</span><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>.</span></p>
        <p className="MsoNormal" align="center" style={{textAlign: 'center', lineHeight: 'normal', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>We operate the website </span><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="http://www.araby.ai/"><span style={{color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>http://www.araby.ai</span></a></span><span lang="EN" style={{color: '#595959'}}> (the "<b style={{msoBidiFontWeight: 'normal'}}>Site</b>"),
            as well as any other related products and services that refer or link to these
            legal terms (the "<b style={{msoBidiFontWeight: 'normal'}}>Legal Terms</b>")
            (collectively, the "<b style={{msoBidiFontWeight: 'normal'}}>Services</b>").</span></p>
        <p className="MsoNormal" style={{lineHeight: 'normal', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>we provide a platform that
            generates different types of content from images to text to coding, etc...
            using AI</span></p>
        <p className="MsoNormal" style={{lineHeight: 'normal', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>You can contact us by phone at +971554181928, email at
            info@araby.ai, or by mail to 3184 Imam Saud Bin Abdulaziz Bin Mohammed Rd - Al
            Muruj Dist - RIYADH 12281, Riyadh, Riyadh 6233, Saudi Arabia.</span></p>
        <p className="MsoNormal" style={{lineHeight: 'normal', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>These Legal Terms constitute a legally binding agreement
            made between you, whether personally or on behalf of an entity ("<b style={{msoBidiFontWeight: 'normal'}}>you</b>"), and ArabyAI Technology,
            concerning your access to and use of the Services. You agree that by accessing
            the Services, you have read, understood, and agreed to be bound by all of these
            Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE
            EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE
            IMMEDIATELY.</span></p>
        <p className="MsoNormal" style={{lineHeight: 'normal', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>We will provide you with prior notice of any scheduled
            changes to the Services you are using. The modified Legal Terms will become
            effective upon posting or notifying you by customercare@araby.ai, as stated in
            the email message. By continuing to use the Services after the effective date
            of any changes, you agree to be bound by the modified terms.</span></p>
        <p className="MsoNormal" align="center" style={{textAlign: 'center', lineHeight: 'normal', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>The Services are intended for users who are at least 18
            years old. Persons under the age of 18 are not permitted to use or register for
            the Services.</span></p>
        <p className="MsoNormal" style={{lineHeight: 'normal', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>We recommend that you print a
            copy of these Legal Terms for your records.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>TABLE OF CONTENTS</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#ourServices"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>1. OUR SERVICES</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#intellectual"><span style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>2. INTELLECTUAL PROPERTY RIGHTS</span></a></span><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#userRepresentation"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>3. USER REPRESENTATIONS</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#userRegistration"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>4. USER REGISTRATION</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#purchaseAndPayment"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>5. PURCHASES AND PAYMENT</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#cancellation"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>6. CANCELLATION</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#prohibitedActivities"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>7. PROHIBITED ACTIVITIES</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#userGenerated"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>8. USER GENERATED CONTRIBUTIONS</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#contributionLicense"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>9. CONTRIBUTION LICENSE</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#socialMedia"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>10. SOCIAL MEDIA</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#thirdParty"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>11. THIRD-PARTY WEBSITES AND CONTENT</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#serviceManagement"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>12. SERVICES MANAGEMENT</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#privacyPolicy"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>13. PRIVACY POLICY</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#copyright"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>14. COPYRIGHT INFRINGEMENTS</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#termAndTermination"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>15. TERM AND TERMINATION</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#modification"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>16. MODIFICATIONS AND INTERRUPTIONS</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#governingLaw"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>17. GOVERNING LAW</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#disputeResolution"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>18. DISPUTE RESOLUTION</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#corrections"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>19. CORRECTIONS</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#declaimer"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>20. DISCLAIMER</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#limitations"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>21. LIMITATIONS OF LIABILITY</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#indemnification"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>22. INDEMNIFICATION</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#userData"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>23. USER DATA</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="electronicCommunications"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>24. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#californiaUser"> <span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>25. CALIFORNIA USERS AND RESIDENTS</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#miscellaneous"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>26. MISCELLANEOUS</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#contactUs"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>27. CONTACT US</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p id='ourServices' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>1. OUR SERVICES</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>The information provided when
            using the Services is not intended for distribution to or use by any person or
            entity in any jurisdiction or country where such distribution or use would be
            contrary to law or regulation or which would subject us to any registration
            requirement within such jurisdiction or country. Accordingly, those persons who
            choose to access the Services from other locations do so on their own
            initiative and are solely responsible for compliance with local laws, if and to
            the extent local laws are applicable.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>The Services are not tailored
            to comply with industry-specific regulations (Health Insurance Portability and
            Accountability Act (HIPAA), Federal Information Security Management Act
            (FISMA), etc.), so if your interactions would be subjected to such laws, you
            may not use the Services. You may not use the Services in a way that would
            violate the Gramm-Leach-Bliley Act (GLBA).</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p id='intellectual' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', color: 'black', msoColorAlt: 'windowtext'}}>2. INTELLECTUAL PROPERTY
              RIGHTS</span></b><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%'}}></span></b></p>
        <p className="MsoNormal" align="center" style={{textAlign: 'center', lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>Our intellectual property</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>We are the owner or the licensee of all intellectual
            property rights in our Services, including all source code, databases,
            functionality, software, website designs, audio, video, text, photographs, and
            graphics in the Services (collectively, the "Content"), as well as
            the trademarks, service marks, and logos contained therein (the
            "Marks").</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>Our Content and Marks are protected by copyright and
            trademark laws (and various other intellectual property rights and unfair
            competition laws) and treaties in the United States and around the world.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>The Content and Marks are provided in or through the
            Services "AS IS" for your personal, non-commercial use or internal
            business purpose only.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>Your use of our Services</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p  className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>Subject to your compliance
            with these Legal Terms, including the "</span><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#prohibitedActivities"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>PROHIBITED ACTIVITIES</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>" section below, we
            grant you a non-exclusive, non-transferable, revocable license to:</span></p>
        <p className="MsoNormal" style={{marginTop: '11.0pt', marginRight: '0cm', marginBottom: '.0001pt', marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l3 level1 lfo2'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>access the Services; and</span></p>
        <p className="MsoNormal" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '11.0pt', marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l3 level1 lfo2'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>download or print a copy of any portion of the Content to which
            you have properly gained access.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>solely for your personal, non-commercial use or internal
            business purpose.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>Except as set out in this section or elsewhere in our
            Legal Terms, no part of the Services and no Content or Marks may be copied,
            reproduced, aggregated, republished, uploaded, posted, publicly displayed,
            encoded, translated, transmitted, distributed, sold, licensed, or otherwise
            exploited for any commercial purpose whatsoever, without our express prior
            written permission.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>If you wish to make any use of the Services, Content, or
            Marks other than as set out in this section or elsewhere in our Legal Terms,
            please address your request to: info@araby.ai. If we ever grant you the
            permission to post, reproduce, or publicly display any part of our Services or
            Content, you must identify us as the owners or licensors of the Services,
            Content, or Marks and ensure that any copyright or proprietary notice appears
            or is visible on posting, reproducing, or displaying our Content.</span></p>
        <p className="MsoNormal" align="center" style={{textAlign: 'center', lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>We reserve all rights not expressly granted to you in and
            to the Services, Content, and Marks.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>Any breach of these Intellectual Property Rights will
            constitute a material breach of our Legal Terms and your right to use our
            Services will terminate immediately.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{color: '#595959'}}>Your
              submissions and contributions</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>Please review this section
            and the "</span><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#prohibitedActivities"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>PROHIBITED ACTIVITIES</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>" section carefully
            prior to using our Services to understand the (a) rights you give us and (b)
            obligations you have when you post or upload any content through the Services.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>Submissions:</span></b><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}> By directly sending us any
            question, comment, suggestion, idea, feedback, or other information about the
            Services ("Submissions"), you agree to assign to us all intellectual
            property rights in such Submission. You agree that we shall own this Submission
            and be entitled to its unrestricted use and dissemination for any lawful
            purpose, commercial or otherwise, without acknowledgment or compensation to
            you.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>Contributions:</span></b><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}> The Services may invite you to
            chat, contribute to, or participate in blogs, message boards, online forums,
            and other functionality during which you may create, submit, post, display,
            transmit, publish, distribute, or broadcast content and materials to us or
            through the Services, including but not limited to text, writings, video,
            audio, photographs, music, graphics, comments, reviews, rating suggestions,
            personal information, or other material ("Contributions"). Any
            Submission that is publicly posted shall also be treated as a Contribution.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>You understand that
            Contributions may be viewable by other users of the Services and possibly
            through third-party websites.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>When you post Contributions, you grant us a license
              (including use of your name, trademarks, and logos): </span></b><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>By posting any Contributions,
            you grant us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive,
            transferable, royalty-free, fully-paid, worldwide right, and license to: use,
            copy, reproduce, distribute, sell, resell, publish, broadcast, retitle, store,
            publicly perform, publicly display, reformat, translate, excerpt (in whole or
            in part), and exploit your Contributions (including, without limitation, your
            image, name, and voice) for any purpose, commercial, advertising, or otherwise,
            to prepare derivative works of, or incorporate into other works, your
            Contributions, and to sublicense the licenses granted in this section. Our use
            and distribution may occur in any media formats and through any media channels.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>This license includes our use
            of your name, company name, and franchise name, as applicable, and any of the
            trademarks, service marks, trade names, logos, and personal and commercial
            images you provide.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>You are responsible for what you post or upload:</span></b><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}> By sending us Submissions
            and/or posting Contributions through any part of the Services or making
            Contributions accessible through the Services by linking your account through
            the Services to any of your social networking accounts, you:</span></p>
        <p className="MsoNormal" style={{marginTop: '11.0pt', marginRight: '0cm', marginBottom: '.0001pt', marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l0 level1 lfo1'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>confirm that you have read and agree with our "</span><span lang="EN"><a href="#prohibitedActivities"><span style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>PROHIBITED ACTIVITIES</span></a></span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>" and will not post,
            send, publish, upload, or transmit through the Services any Submission nor post
            any Contribution that is illegal, harassing, hateful, harmful, defamatory,
            obscene, bullying, abusive, discriminatory, threatening to any person or group,
            sexually explicit, false, inaccurate, deceitful, or misleading;</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l0 level1 lfo1'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>to the extent permissible by applicable law, waive any and all
            moral rights to any such Submission and/or Contribution;</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l0 level1 lfo1'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>warrant that any such Submission and/or Contributions are
            original to you or that you have the necessary rights and licenses to submit
            such Submissions and/or Contributions and that you have full authority to grant
            us the above-mentioned rights in relation to your Submissions and/or
            Contributions; and</span></p>
        <p className="MsoNormal" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '11.0pt', marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l0 level1 lfo1'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>warrant and represent that your Submissions and/or Contributions
            do not constitute confidential information.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>You are solely responsible
            for your Submissions and/or Contributions and you expressly agree to reimburse
            us for any and all losses that we may suffer because of your breach of (a) this
            section, (b) any third party’s intellectual property rights, or (c) applicable
            law.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>We may remove or edit your Content:</span></b><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}> Although we have no
            obligation to monitor any Contributions, we shall have the right to remove or
            edit any Contributions at any time without notice if in our reasonable opinion
            we consider such Contributions harmful or in breach of these Legal Terms. If we
            remove or edit any such Contributions, we may also suspend or disable your
            account and report you to the authorities.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>Copyright infringement</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>We respect the intellectual
            property rights of others. If you believe that any material available on or
            through the Services infringes upon any copyright you own or control, please
            immediately refer to the "</span><span lang="EN" style={{color: 'black', msoColorAlt: 'windowtext'}}><a href="#copyright"><span style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none', textUnderline: 'none'}}>COPYRIGHT INFRINGEMENTS</span></a></span><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>" section below.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p id='userRepresentation' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', color: '#6C7794'}}>3. USER REPRESENTATIONS</span></b></p>
        <p className="MsoNormal" align="center" style={{textAlign: 'center', lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>By using the Services, you represent and warrant that:</span><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}> </span><span lang="EN" style={{fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>(1) all registration information you submit will be true,
            accurate, current, and complete; (2) you will maintain the accuracy of such
            information and promptly update such registration information as necessary;</span><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}> </span><span lang="EN" style={{fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>(3) you have the legal capacity and you agree to comply
            with these Legal Terms;</span><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}> </span><span lang="EN" style={{fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>(4)
            you are not a minor in the jurisdiction in which you reside; (5) you will not
            access the Services through automated or non-human means, whether through a
            bot, script or otherwise; (6) you will not use the Services for any illegal or
            unauthorized purpose; and (7) your use of the Services will not violate any
            applicable law or regulation.</span></p>
        <p className="MsoNormal" align="center" style={{textAlign: 'center', lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>If you provide any information that is untrue,
            inaccurate, not current, or incomplete, we have the right to suspend or
            terminate your account and refuse any and all current or future use of the
            Services (or any portion thereof).</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p id='userRegistration' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', color: '#6C7794'}}></span></b><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>
            4. USER REGISTRATION</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>You may be required to register to use the Services. You
            agree to keep your password confidential and will be responsible for all use of
            your account and password. We reserve the right to remove, reclaim, or change a
            username you select if we determine, in our sole discretion, that such username
            is inappropriate, obscene, or otherwise objectionable.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p  id='purchaseAndPayment' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', color: '#6C7794'}}>5. PURCHASES AND PAYMENT</span></b></p>
        <p className="MsoNormal" align="center" style={{textAlign: 'center', lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>We accept the following forms of payment:</span></p>
        <p className="MsoNormal" style={{lineHeight: 'normal', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{marginLeft: '15.0pt', lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>-<span style={{msoSpacerun: 'yes'}}>&nbsp; </span>Visa</span></p>
        <p className="MsoNormal" style={{marginLeft: '15.0pt', lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>-<span style={{msoSpacerun: 'yes'}}>&nbsp;
            </span>Mastercard</span></p>
        <p className="MsoNormal" style={{background: 'white'}}><span lang="EN" style={{color: '#595959'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>You agree to provide current, complete, and accurate
            purchase and account information for all purchases made via the Services. You
            further agree to promptly update account and payment information, including
            email address, payment method, and payment card expiration date, so that we can
            complete your transactions and contact you as needed. Sales tax will be added
            to the price of purchases as deemed required by us. We may change prices at any
            time. All payments shall be </span><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959'}}>in US dollars.</span></p>
        <p className="MsoNormal" align="center" style={{textAlign: 'center', lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>You agree to pay all charges at the prices then in effect
            for your purchases and any applicable shipping fees, and you authorize us to
            charge your chosen payment provider for any such amounts upon placing your
            order. If your order is subject to recurring charges, then you consent to our
            charging your payment method on a recurring basis without requiring your prior
            approval for each recurring charge, until such time as you cancel the
            applicable order. We reserve the right to correct any errors or mistakes in
            pricing, even if we have already requested or received payment.</span></p>
        <p className="MsoNormal" align="center" style={{textAlign: 'center', lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>We reserve the right to refuse any order placed through
            the Services. We may, in our sole discretion, limit or cancel quantities
            purchased per person, per household, or per order. These restrictions may
            include orders placed by or under the same customer account, the same payment
            method, and/or orders that use the same billing or shipping address. We reserve
            the right to limit or prohibit orders that, in our sole judgment, appear to be
            placed by dealers, resellers, or distributors.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p id="cancellation" className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>6. CANCELLATION</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>You can cancel your
            subscription at any time by logging into your account. Your cancellation will
            take effect at the end of the current paid term.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>If you are unsatisfied with
            our Services, please email us at info@araby.ai or call us at +971554181928.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#0A365A'}}></span></p>
        <p id='prohibitedActivities' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', color: '#6C7794'}}></span></b><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>
             7. PROHIBITED ACTIVITIES</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>You may not access or use the Services for any purpose
            other than that for which we make the Services available. The Services may not
            be used in connection with any commercial endeavors except those that are
            specifically endorsed or approved by us.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>As a user of the Services, you agree not to:</span></p>
        <p className="MsoNormal" style={{marginTop: '11.0pt', marginRight: '0cm', marginBottom: '.0001pt', marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l2 level1 lfo3'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959', background: 'white', msoHighlight: 'white'}}>Systematically retrieve
            data or other content from the Services to create or compile, directly or
            indirectly, a collection, compilation, database, or directory without written
            permission from us.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l2 level1 lfo3'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959', background: 'white', msoHighlight: 'white'}}>Trick, defraud, or mislead
            us and other users, especially in any attempt to learn sensitive account
            information such as user passwords.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l2 level1 lfo3'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959', background: 'white', msoHighlight: 'white'}}>Circumvent, disable, or
            otherwise interfere with security-related features of the Services, including
            features that prevent or restrict the use or copying of any Content or enforce
            limitations on the use of the Services and/or the Content contained therein.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l2 level1 lfo3'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959', background: 'white', msoHighlight: 'white'}}>Disparage, tarnish, or
            otherwise harm, in our opinion, us and/or the Services.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l2 level1 lfo3'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959', background: 'white', msoHighlight: 'white'}}>Use any information
            obtained from the Services in order to harass, abuse, or harm another person.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l2 level1 lfo3'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959', background: 'white', msoHighlight: 'white'}}>Make improper use of our
            support services or submit false reports of abuse or misconduct.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l2 level1 lfo3'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959', background: 'white', msoHighlight: 'white'}}>Use the Services in a
            manner inconsistent with any applicable laws or regulations.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l2 level1 lfo3'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959', background: 'white', msoHighlight: 'white'}}>Engage in unauthorized
            framing of or linking to the Services.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l2 level1 lfo3'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959', background: 'white', msoHighlight: 'white'}}>Upload or transmit (or
            attempt to upload or to transmit) viruses, Trojan horses, or other material,
            including excessive use of capital letters and spamming (continuous posting of
            repetitive text), that interferes with any party’s uninterrupted use and
            enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes
            with the use, features, functions, operation, or maintenance of the Services.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l2 level1 lfo3'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959', background: 'white', msoHighlight: 'white'}}>Engage in any automated use
            of the system, such as using scripts to send comments or messages, or using any
            data mining, robots, or similar data gathering and extraction tools.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l2 level1 lfo3'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959', background: 'white', msoHighlight: 'white'}}>Delete the copyright or
            other proprietary rights notice from any Content.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l2 level1 lfo3'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959', background: 'white', msoHighlight: 'white'}}>Attempt to impersonate
            another user or person or use the username of another user.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l2 level1 lfo3'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959', background: 'white', msoHighlight: 'white'}}>Upload or transmit (or
            attempt to upload or to transmit) any material that acts as a passive or active
            information collection or transmission mechanism, including without limitation,
            clear graphics interchange formats ("gifs"), 1×1 pixels, web bugs,
            cookies, or other similar devices (sometimes referred to as "spyware"
            or "passive collection mechanisms" or "pcms").</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l2 level1 lfo3'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959', background: 'white', msoHighlight: 'white'}}>Interfere with, disrupt, or
            create an undue burden on the Services or the networks or services connected to
            the Services.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l2 level1 lfo3'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959', background: 'white', msoHighlight: 'white'}}>Harass, annoy, intimidate,
            or threaten any of our employees or agents engaged in providing any portion of
            the Services to you.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l2 level1 lfo3'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959', background: 'white', msoHighlight: 'white'}}>Attempt to bypass any
            measures of the Services designed to prevent or restrict access to the
            Services, or any portion of the Services.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l2 level1 lfo3'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959', background: 'white', msoHighlight: 'white'}}>Copy or adapt the Services'
            software, including but not limited to Flash, PHP, HTML, JavaScript, or other
            code.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l2 level1 lfo3'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959', background: 'white', msoHighlight: 'white'}}>Except as permitted by
            applicable law, decipher, decompile, disassemble, or reverse engineer any of
            the software comprising or in any way making up a part of the Services.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l2 level1 lfo3'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959', background: 'white', msoHighlight: 'white'}}>Except as may be the result
            of standard search engine or Internet browser usage, use, launch, develop, or
            distribute any automated system, including without limitation, any spider,
            robot, cheat utility, scraper, or offline reader that accesses the Services, or
            use or launch any unauthorized script or other software.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l2 level1 lfo3'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959', background: 'white', msoHighlight: 'white'}}>Use a buying agent or
            purchasing agent to make purchases on the Services.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l2 level1 lfo3'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959', background: 'white', msoHighlight: 'white'}}>Make any unauthorized use
            of the Services, including collecting usernames and/or email addresses of users
            by electronic or other means for the purpose of sending unsolicited email, or
            creating user accounts by automated means or under false pretenses.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l2 level1 lfo3'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959', background: 'white', msoHighlight: 'white'}}>Use the Services as part of
            any effort to compete with us or otherwise use the Services and/or the Content
            for any revenue-generating endeavor or commercial enterprise.</span></p>
        <p className="MsoNormal" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '11.0pt', marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l2 level1 lfo3'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>use our services to generate
            harmful or abusive content</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p  id='userGenerated' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', color: '#6C7794'}}></span></b><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>
            8. USER GENERATED CONTRIBUTIONS</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>The Services may invite you to chat, contribute to, or
            participate in blogs, message boards, online forums, and other functionality,
            and may provide you with the opportunity to create, submit, post, display,
            transmit, perform, publish, distribute, or broadcast content and materials to
            us or on the Services, including but not limited to text, writings, video,
            audio, photographs, graphics, comments, suggestions, or personal information or
            other material (collectively, "Contributions"). Contributions may be
            viewable by other users of the Services and through third-party websites. As
            such, any Contributions you transmit may be treated as non-confidential and
            non-proprietary. When you create or make available any Contributions, you
            thereby represent and warrant that:</span></p>
        <p className="MsoNormal" style={{marginTop: '11.0pt', marginRight: '0cm', marginBottom: '.0001pt', marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l1 level1 lfo4'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>The creation, distribution, transmission, public display, or
            performance, and the accessing, downloading, or copying of your Contributions
            do not and will not infringe the proprietary rights, including but not limited
            to the copyright, patent, trademark, trade secret, or moral rights of any third
            party.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l1 level1 lfo4'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>You are the creator and owner of or have the necessary licenses,
            rights, consents, releases, and permissions to use and to authorize us, the
            Services, and other users of the Services to use your Contributions in any
            manner contemplated by the Services and these Legal Terms.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l1 level1 lfo4'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>You have the written consent, release, and/or permission of each
            and every identifiable individual person in your Contributions to use the name
            or likeness of each and every such identifiable individual person to enable
            inclusion and use of your Contributions in any manner contemplated by the
            Services and these Legal Terms.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l1 level1 lfo4'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>Your Contributions are not false, inaccurate, or misleading.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l1 level1 lfo4'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>Your Contributions are not unsolicited or unauthorized
            advertising, promotional materials, pyramid schemes, chain letters, spam, mass
            mailings, or other forms of solicitation.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l1 level1 lfo4'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>Your Contributions are not obscene, lewd, lascivious, filthy,
            violent, harassing, libelous, slanderous, or otherwise objectionable (as
            determined by us).</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l1 level1 lfo4'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>Your Contributions do not ridicule, mock, disparage, intimidate,
            or abuse anyone.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l1 level1 lfo4'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>Your Contributions are not used to harass or threaten (in the
            legal sense of those terms) any other person and to promote violence against a
            specific person or class of people.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l1 level1 lfo4'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>Your Contributions do not violate any applicable law,
            regulation, or rule.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l1 level1 lfo4'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>Your Contributions do not violate the privacy or publicity
            rights of any third party.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l1 level1 lfo4'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>Your Contributions do not violate any applicable law concerning
            child pornography, or otherwise intended to protect the health or well-being of
            minors.</span></p>
        <p className="MsoNormal" style={{marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l1 level1 lfo4'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>Your Contributions do not include any offensive comments that
            are connected to race, national origin, gender, sexual preference, or physical
            handicap.</span></p>
        <p className="MsoNormal" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '11.0pt', marginLeft: '36.0pt', textIndent: '-18.0pt', lineHeight: '150%', msoList: 'l1 level1 lfo4'}}>{/*[if !supportLists]*/}<span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}><span style={{msoList: 'Ignore'}}>■<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>{/*[endif]*/}<span dir="LTR" /><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>Your Contributions do not otherwise violate, or link to material
            that violates, any provision of these Legal Terms, or any applicable law or
            regulation.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>Any use of the Services in violation of the foregoing
            violates these Legal Terms and may result in, among other things, termination
            or suspension of your rights to use the Services.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p id='contributionLicense' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', color: '#6C7794'}}></span></b><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>
              9. CONTRIBUTION LICENSE</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>By posting your Contributions to any part of the Services
            or making Contributions accessible to the Services by linking your account from
            the Services to any of your social networking accounts, you automatically
            grant, and you represent and warrant that you have the right to grant, to us an
            unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable,
            royalty-free, fully-paid, worldwide right, and license to host, use, copy,
            reproduce, disclose, sell, resell, publish, broadcast, retitle, archive, store,
            cache, publicly perform, publicly display, reformat, translate, transmit,
            excerpt (in whole or in part), and distribute such Contributions (including,
            without limitation, your image and voice) for any purpose, commercial,
            advertising, or otherwise, and to prepare derivative works of, or incorporate
            into other works, such Contributions, and grant and authorize sublicenses of
            the foregoing. The use and distribution may occur in any media formats and
            through any media channels.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>This license will apply to any form, media, or technology
            now known or hereafter developed, and includes our use of your name, company
            name, and franchise name, as applicable, and any of the trademarks, service
            marks, trade names, logos, and personal and commercial images you provide. You
            waive all moral rights in your Contributions, and you warrant that moral rights
            have not otherwise been asserted in your Contributions.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>We do not assert any ownership over your Contributions.
            You retain full ownership of all of your Contributions and any intellectual
            property rights or other proprietary rights associated with your Contributions.
            We are not liable for any statements or representations in your Contributions
            provided by you in any area on the Services. You are solely responsible for
            your Contributions to the Services and you expressly agree to exonerate us from
            any and all responsibility and to refrain from any legal action against us
            regarding your Contributions.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>We have the right, in our sole and absolute discretion,
            (1) to edit, redact, or otherwise change any Contributions; (2) to
            re-categorize any Contributions to place them in more appropriate locations on
            the Services; and (3) to pre-screen or delete any Contributions at any time and
            for any reason, without notice. We have no obligation to monitor your
            Contributions.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p id='socialMedia' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', color: '#6C7794'}}>10. SOCIAL MEDIA</span></b><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>
            </span></b><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>As part of the functionality of the Services, you may
            link your account with online accounts you have with third-party service
            providers (each such account, a "Third-Party Account") by either: (1)
            providing your Third-Party Account login information through the Services; or
            (2) allowing us to access your Third-Party Account, as is permitted under the
            applicable terms and conditions that govern your use of each Third-Party
            Account. You represent and warrant that you are entitled to disclose your
            Third-Party Account login information to us and/or grant us access to your
            Third-Party Account, without breach by you of any of the terms and conditions
            that govern your use of the applicable Third-Party Account, and without
            obligating us to pay any fees or making us subject to any usage limitations
            imposed by the third-party service provider of the Third-Party Account. By
            granting us access to any Third-Party Accounts, you understand that (1) we may
            access, make available, and store (if applicable) any content that you have
            provided to and stored in your Third-Party Account (the "Social Network
            Content") so that it is available on and through the Services via your
            account, including without limitation any friend lists and (2) we may submit to
            and receive from your Third-Party Account additional information to the extent
            you are notified when you link your account with the Third-Party Account.
            Depending on the Third-Party Accounts you choose and subject to the privacy
            settings that you have set in such Third-Party Accounts, personally
            identifiable information that you post to your Third-Party Accounts may be
            available on and through your account on the Services. Please note that if a
            Third-Party Account or associated service becomes unavailable or our access to
            such Third-Party Account is terminated by the third-party service provider,
            then Social Network Content may no longer be available on and through the
            Services. You will have the ability to disable the connection between your
            account on the Services and your Third-Party Accounts at any time. PLEASE NOTE
            THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH
            YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH
            THIRD-PARTY SERVICE PROVIDERS. We make no effort to review any Social Network
            Content for any purpose, including but not limited to, for accuracy, legality,
            or non-infringement, and we are not responsible for any Social Network Content.
            You acknowledge and agree that we may access your email address book associated
            with a Third-Party Account and your contacts list stored on your mobile device
            or tablet computer solely for purposes of identifying and informing you of
            those contacts who have also registered to use the Services. You can deactivate
            the connection between the Services and your Third-Party Account by contacting
            us using the contact information below or through your account settings (if
            applicable). We will attempt to delete any information stored on our servers
            that was obtained through such Third-Party Account, except the username and
            profile picture that become associated with your account.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p id='thirdParty' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', color: '#6C7794'}}>11. THIRD-PARTY WEBSITES AND
              CONTENT</span></b><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>
            </span></b><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>The Services may contain (or you may be sent via the
            Site) links to other websites ("Third-Party Websites") as well as
            articles, photographs, text, graphics, pictures, designs, music, sound, video,
            information, applications, software, and other content or items belonging to or
            originating from third parties ("Third-Party Content"). Such
            Third-Party Websites and Third-Party Content are not investigated, monitored,
            or checked for accuracy, appropriateness, or completeness by us, and we are not
            responsible for any Third-Party Websites accessed through the Services or any
            Third-Party Content posted on, available through, or installed from the
            Services, including the content, accuracy, offensiveness, opinions,
            reliability, privacy practices, or other policies of or contained in the
            Third-Party Websites or the Third-Party Content. Inclusion of, linking to, or
            permitting the use or installation of any Third-Party Websites or any
            Third-Party Content does not imply approval or endorsement thereof by us. If
            you decide to leave the Services and access the Third-Party Websites or to use
            or install any Third-Party Content, you do so at your own risk, and you should
            be aware these Legal Terms no longer govern. You should review the applicable
            terms and policies, including privacy and data gathering practices, of any
            website to which you navigate from the Services or relating to any applications
            you use or install from the Services. Any purchases you make through
            Third-Party Websites will be through other websites and from other companies,
            and we take no responsibility whatsoever in relation to such purchases which
            are exclusively between you and the applicable third party. You agree and
            acknowledge that we do not endorse the products or services offered on
            Third-Party Websites and you shall hold us blameless from any harm caused by
            your purchase of such products or services. Additionally, you shall hold us
            blameless from any losses sustained by you or harm caused to you relating to or
            resulting in any way from any Third-Party Content or any contact with
            Third-Party Websites.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p id='serviceManagement' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', color: '#6C7794'}}>12. SERVICES MANAGEMENT</span></b><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>
              </span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>We reserve the right, but not
            the obligation, to: (1) monitor the Services for violations of these Legal
            Terms; (2) take appropriate legal action against anyone who, in our sole
            discretion, violates the law or these Legal Terms, including without
            limitation, reporting such user to law enforcement authorities; (3) in our sole
            discretion and without limitation, refuse, restrict access to, limit the
            availability of, or disable (to the extent technologically feasible) any of
            your Contributions or any portion thereof; (4) in our sole discretion and
            without limitation, notice, or liability, to remove from the Services or
            otherwise disable all files and content that are excessive in size or are in
            any way burdensome to our systems; and (5) otherwise manage the Services in a
            manner designed to protect our rights and property and to facilitate the proper
            functioning of the Services.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p id='privacyPolicy' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', color: '#6C7794'}}>13. PRIVACY POLICY</span></b><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>
              </span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>We care about data privacy and security. By using the
            Services, you agree to be bound by our Privacy Policy posted on the Services,
            which is incorporated into these Legal Terms. Please be advised the Services
            are hosted in Saudi Arabia. If you access the Services from any other region of
            the world with laws or other requirements governing personal data collection,
            use, or disclosure that differ from applicable laws in Saudi Arabia, then
            through your continued use of the Services, you are transferring your data to
            Saudi Arabia, and you expressly consent to have your data transferred to and
            processed in Saudi Arabia.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p id='copyright' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', color: '#6C7794'}}>14. COPYRIGHT INFRINGEMENTS</span></b><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>
              </span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>We respect the intellectual property rights of others. If
            you believe that any material available on or through the Services infringes
            upon any copyright you own or control, please immediately notify us using the
            contact information provided below (a "Notification"). A copy of your
            Notification will be sent to the person who posted or stored the material
            addressed in the Notification. Please be advised that pursuant to applicable
            law you may be held liable for damages if you make material misrepresentations
            in a Notification. Thus, if you are not sure that material located on or linked
            to by the Services infringes your copyright, you should consider first
            contacting an attorney.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p id='termAndTermination' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', color: '#6C7794'}}>15. TERM AND TERMINATION</span></b><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>
              </span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>These Legal Terms shall remain in full force and effect
            while you use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL
            TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
            LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN
            IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT
            LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN
            THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR
            USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR
            INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
            DISCRETION.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>If we terminate or suspend your account for any reason,
            you are prohibited from registering and creating a new account under your name,
            a fake or borrowed name, or the name of any third party, even if you may be
            acting on behalf of the third party. In addition to terminating or suspending
            your account, we reserve the right to take appropriate legal action, including
            without limitation pursuing civil, criminal, and injunctive redress.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p id='modification' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', color: '#6C7794'}}>16. MODIFICATIONS AND INTERRUPTIONS</span></b><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>
              </span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>We reserve the right to change, modify, or remove the
            contents of the Services at any time or for any reason at our sole discretion
            without notice. However, we have no obligation to update any information on our
            Services. We will not be liable to you or any third party for any modification,
            price change, suspension, or discontinuance of the Services.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>We cannot guarantee the Services will be available at all
            times. We may experience hardware, software, or other problems or need to
            perform maintenance related to the Services, resulting in interruptions,
            delays, or errors. We reserve the right to change, revise, update, suspend,
            discontinue, or otherwise modify the Services at any time or for any reason
            without notice to you. You agree that we have no liability whatsoever for any
            loss, damage, or inconvenience caused by your inability to access or use the
            Services during any downtime or discontinuance of the Services. Nothing in
            these Legal Terms will be construed to obligate us to maintain and support the
            Services or to supply any corrections, updates, or releases in connection
            therewith.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p id='governingLaw' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', color: '#6C7794'}}>17. GOVERNING LAW</span></b><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>
              </span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>These Legal Terms shall be governed by and defined
            following the laws of Saudi Arabia. ArabyAI Technology and yourself irrevocably
            consent that the courts of Saudi Arabia shall have exclusive jurisdiction to
            resolve any dispute which may arise in connection with these Legal Terms.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p id='disputeResolution' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', color: '#6C7794'}}>18. DISPUTE RESOLUTION</span></b><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>
             </span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>You agree to irrevocably
            submit all disputes related to these Legal Terms or the legal relationship
            established by these Legal Terms to the jurisdiction of the Saudi Arabia
            courts. ArabyAI Technology shall also maintain the right to bring proceedings
            as to the substance of the matter in the courts of the country where you reside
            or, if these Legal Terms are entered into in the course of your trade or
            profession, the state of your principal place of business.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p id='corrections' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', color: '#6C7794'}}>19. CORRECTIONS</span></b><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>
              </span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>There may be information on
            the Services that contains typographical errors, inaccuracies, or omissions,
            including descriptions, pricing, availability, and various other information.
            We reserve the right to correct any errors, inaccuracies, or omissions and to
            change or update the information on the Services at any time, without prior
            notice.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p id='declaimer' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', msoColorAlt: 'windowtext' ,color: '#6C7794'} }>20. DISCLAIMER</span></b><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: 'black', msoColorAlt: 'windowtext'}}> </span></b><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto'}}></span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE
            BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO
            THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR
            IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING,
            WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
            REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR
            THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND
            WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
            INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE,
            OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
            SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR
            ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN,
            (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5)
            ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
            THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN
            ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT
            OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA
            THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY
            FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE
            SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION
            FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR
            IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
            THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A
            PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE
            YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p id='limitations' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', color: '#6C7794'}}>21. LIMITATIONS OF LIABILITY</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR
            AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
            CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING
            LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE
            OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR
            LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE
            ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US
            DURING THE SIX (6) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING. </span><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>CERTAIN US STATE LAWS AND
            INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE
            EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR
            ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY
            HAVE ADDITIONAL RIGHTS.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p id="indemnification" className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', color: '#6C7794'}}>22. INDEMNIFICATION</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>You agree to defend, indemnify, and hold us harmless,
            including our subsidiaries, affiliates, and all of our respective officers,
            agents, partners, and employees, from and against any loss, damage, liability,
            claim, or demand, including reasonable attorneys’ fees and expenses, made by
            any third party due to or arising out of: (1) your Contributions; (2) use of
            the Services; (3) breach of these Legal Terms; (4) any breach of your
            representations and warranties set forth in these Legal Terms; (5) your
            violation of the rights of a third party, including but not limited to intellectual
            property rights; or (6) any overt harmful act toward any other user of the
            Services with whom you connected via the Services. Notwithstanding the
            foregoing, we reserve the right, at your expense, to assume the exclusive
            defense and control of any matter for which you are required to indemnify us,
            and you agree to cooperate, at your expense, with our defense of such claims.
            We will use reasonable efforts to notify you of any such claim, action, or
            proceeding which is subject to this indemnification upon becoming aware of it.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p id='userData' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', color: '#6C7794'}}>23. USER DATA</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>We will maintain certain data that you transmit to the
            Services for the purpose of managing the performance of the Services, as well
            as data relating to your use of the Services. Although we perform regular
            routine backups of data, you are solely responsible for all data that you
            transmit or that relates to any activity you have undertaken using the
            Services. You agree that we shall have no liability to you for any loss or
            corruption of any such data, and you hereby waive any right of action against
            us arising from any such loss or corruption of such data.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p id='electronicCommunications' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', color: '#6C7794'}}>24. ELECTRONIC COMMUNICATIONS, TRANSACTIONS,
              AND SIGNATURES</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>Visiting the Services, sending us emails, and completing
            online forms constitute electronic communications. You consent to receive
            electronic communications, and you agree that all agreements, notices,
            disclosures, and other communications we provide to you electronically, via
            email and on the Services, satisfy any legal requirement that such
            communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC
            SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
            NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR
            VIA THE SERVICES. You hereby waive any rights or requirements under any
            statutes, regulations, rules, ordinances, or other laws in any jurisdiction
            which require an original signature or delivery or retention of non-electronic
            records, or to payments or the granting of credits by any means other than
            electronic means.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p id='californiaUser' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', color: '#6C7794'}}>25. CALIFORNIA USERS AND RESIDENTS</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>If any complaint with us is not satisfactorily resolved,
            you can contact the Complaint Assistance Unit of the Division of Consumer
            Services of the California Department of Consumer Affairs in writing at 1625
            North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone
            at (800) 952-5210 or (916) 445-1254.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p id='miscellaneous' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', color: '#6C7794'}}>26. MISCELLANEOUS</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>These Legal Terms and any policies or operating rules
            posted by us on the Services or in respect to the Services constitute the
            entire agreement and understanding between you and us. Our failure to exercise
            or enforce any right or provision of these Legal Terms shall not operate as a
            waiver of such right or provision. These Legal Terms operate to the fullest extent
            permissible by law. We may assign any or all of our rights and obligations to
            others at any time. We shall not be responsible or liable for any loss, damage,
            delay, or failure to act caused by any cause beyond our reasonable control. If
            any provision or part of a provision of these Legal Terms is determined to be
            unlawful, void, or unenforceable, that provision or part of the provision is
            deemed severable from these Legal Terms and does not affect the validity and
            enforceability of any remaining provisions. There is no joint venture,
            partnership, employment or agency relationship created between you and us as a
            result of these Legal Terms or use of the Services. You agree that these Legal
            Terms will not be construed against us by virtue of having drafted them. You
            hereby waive any and all defenses you may have based on the electronic form of
            these Legal Terms and the lack of signing by the parties hereto to execute
            these Legal Terms.</span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p id='contactUs' className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '14.5pt', lineHeight: '150%', color: '#6C7794'}}>27. CONTACT US</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{color: '#595959'}}>In order to resolve a complaint regarding the Services or
            to receive further information regarding use of the Services, please contact us
            at:</span></p>
        <div style={{display: 'flex', justifyContent: 'space-between', gap: '15px', flexWrap: 'wrap'}}>
        <div style={{display: 'flex', flexDirection: 'column', padding: '10px', width: '45%'}}>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>ArabyAI Technology</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959'}}>3184 Imam Saud Bin Abdulaziz Bin Mohammed Rd -
              Al Muruj Dist - RIYADH 12281</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>Riyadh, Riyadh 6233</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>Saudi Arabia</span></b></p>
        </div>
        {/*----*/}
        <div style={{display: 'flex', flexDirection: 'column', padding: '10px', width: '45%'}}>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}></span></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>MVP Application and Game Design</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', color: '#595959'}}>Muroor Road Madinat Zayed Tower C210, Office 602 </span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '11.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#595959'}}>Abu Dhabi, UAE</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{fontSize: '10.5pt', lineHeight: '150%', fontFamily: 'Roboto', msoFareastFontFamily: 'Roboto', msoBidiFontFamily: 'Roboto', color: '#6C7794'}}>United Arab Emirates</span></b></p>
        {/*----*/}
        </div>
        </div>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{color: '#595959'}}>Phone:
              +971554181928</span></b></p>
        <p className="MsoNormal" style={{lineHeight: '150%', background: 'white'}}><b style={{msoBidiFontWeight: 'normal'}}><span lang="EN" style={{color: '#595959'}}>info@araby.ai</span></b></p>
        <p className="MsoNormal"><span lang="EN"></span></p>
      </div>
  )
}

export default Terms