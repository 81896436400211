import React, { useState, useEffect } from "react";
import AppleIcon from "@mui/icons-material/Apple";
import playS from "../../components/Assets/Img/playstore.png";
import mobile from "../../components/Assets/Img/mobileBanner.png";
import { useTranslation } from "react-i18next";
import backg from "../../components/Assets/Img/bg.png";
import "./style.scss";

const MobileBanner = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "transparent",
        margin: width <= 991 ? null : "20px auto",
        maxWidth: "1250px",
        height: width <= 500 ? "500px" : width <= 991 ? "400px" : "600px",
        overflow: "hidden",
        borderRadius: width <= 991 ? "50px" : "75px",
        bottom: "20px",
      }}
    >
      <div
        style={{
          height: width <= 500 ? "420px" : width <= 991 ? "350px" : "500px",
          borderRadius: width <= 991 ? "50px" : "75px",
          backgroundImage: `url(${backg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "relative",
          direction: lng === "arab" ? "rtl" : "ltr",
          display: "flex",
          justifyContent: width <= 991 ? "center" : "space-between",
          color: "white",
          alignItems: "center",
          top: width <= 991 ? "50px" : "100px",
          margin: "0 25px",
        }}
      >
        <div style={{ margin: width <= 500 ? "0 20px" : "0 50px" }}>
          <h1 style={{ fontWeight: "500", fontSize: width <= 700 ? "1.4rem" : "2rem", color: "white", position: "relative", zIndex: 5 }}>{t("Become More Productive With Our Mobile App Now.")}</h1>
          <h5 style={{ fontWeight: "500", textTransform: "uppercase", padding: width <= 600 ? "20px 0" : "40px 0", fontSize: width <= 700 ? "0.8rem" : "1rem", position: "relative", zIndex: 5 }}>
            {t("Download now and Simplify your life and enjoy a hassle-free way to create and write content.")}
          </h5>
          <div className="mobile_Links" style={{ textAlign: lng === "arab" ? "start" : "end", margin: "auto", justifyContent: "center" }}>
            <a href="https://play.google.com/store/apps/details?id=com.araby.ai" target="_blank" rel="noreferrer">
              <div
                className="mobile_links_container"
                style={{
                  background: "#000000",
                  border: "2px solid #A6A6A6",
                  boxShadow: " 0px 0px 30px rgba(0, 0, 0, 0.35)",
                  minWidth: "200px",
                  direction: lng === "arab" ? "rtl" : "ltr",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "10px",
                  position: "relative",
                  zIndex: 5,
                }}
              >
                <span>
                  <p style={{ color: "white", textAlign: lng === "arab" ? "right" : "left" }}>{t("GET IT ON")}</p>
                  <h6 style={{ color: "white", textAlign: lng === "arab" ? "right" : "left" }}>Google Play</h6>
                </span>
                <img src={playS} alt="playstore" />
              </div>
            </a>
            <a href="https://apps.apple.com/in/app/araby-ai/id6447341656" target="_blank" rel="noreferrer">
              <div
                className="mobile_links_container"
                style={{
                  background: "#000000",
                  border: "2px solid #A6A6A6",
                  boxShadow: " 0px 0px 30px rgba(0, 0, 0, 0.35)",
                  minWidth: "200px",
                  direction: lng === "arab" ? "rtl" : "ltr",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "10px",
                  zIndex: 5,
                  position: "relative",
                }}
              >
                <span>
                  <p style={{ color: "white", textAlign: lng === "arab" ? "right" : "left" }}>{t("DOWNLOAD ON THE")}</p>
                  <h6 style={{ color: "white", textAlign: lng === "arab" ? "right" : "left" }}>App Store</h6>
                </span>
                <AppleIcon sx={{ color: "white" }} fontSize="large" />
              </div>
            </a>
          </div>
        </div>
        {width >= 991 && (
          <img src={mobile} width="44%" alt="images" style={{ position: "relative", zIndex: 10, top: "18px", right: lng === "arab" ? null : "50px", left: lng === "arab" ? "50px" : null }} />
        )}
      </div>
    </div>
  );
};

export default MobileBanner;
