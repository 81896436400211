import React from 'react'
import { Modal } from 'react-bootstrap'
import ContactUs from '../ContactUs/ContactUs'

export const ContactUsModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            centered
            className='contact-modal'
        >
            <Modal.Body>
                <ContactUs />
            </Modal.Body>
        </Modal>
    )
}
