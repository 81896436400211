import React,{useState,useEffect} from 'react'
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import logos from "../../../components/Assets/Img/newLogo.png";
import AppleIcon from '@mui/icons-material/Apple';
import playstore from "../../../components/Assets/Img/playstore.png"
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next"
import "./index.scss"
import { useParams } from 'react-router-dom';

const MobileAppBanner = () => {
  const params = useParams();
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const [getApp, setGetApp] = useState(true);
    const [width, setWidth] = useState(window.innerWidth);
  
    useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, [width]);
  
    useEffect(() => {
      if (localStorage.getItem('app') === 'close') {
        setGetApp(false)
      } else {
        localStorage.setItem('app', 'open')
        setGetApp(true)
      }
    }, [])
  return (
    <div>{width <= 991 && getApp && (isAndroid || isIOS) ? (
        <div className={ lng ==="arab" ? "main_dynamic lng_dir_ar" : "main_dynamic lng_dir"}>
          <div className="dynamic_app container">
            <div className="logo_dynamic">
              <img style={{ width: "35px" }} src={logos} alt="..." width="55px" />
              <label style={{ cursor: "pointer", marginBottom: 0 }}>
                {t("Get the ")}
                <b>{t("APP")}</b>
                {t(" now!")}
              </label>
            </div>
            <div className="get_app"> 
              {isAndroid ? (
                <a href="https://play.google.com/store/apps/details?id=com.araby.ai">
                  <div style={{ border: "0.5px solid white", display: "flex", alignItems: "center", borderRadius: "5px", padding: "3px 10px" }}>
                    <p>{t("Available on")}</p>
                    <AppleIcon sx={{ fontSize: "12px", color: "white", margin: "0 5px" }} />
                    <img src={playstore} alt="..." width="9px" />
                  </div>
                </a>
              ) : isIOS ? (
                <a href="https://apps.apple.com/in/app/araby-ai/id6447341656">
                  <div style={{ border: "0.5px solid white", display: "flex", alignItems: "center", borderRadius: "5px", padding: "3px 10px" }}>
                    <p>{t("Available on")}</p>
                    <AppleIcon sx={{ fontSize: "12px", color: "white", margin: "0 5px" }} />
                    <img src={playstore} alt="..." width="9px" />
                  </div>
                </a>
              ) : (
                <a href="">
                  <div style={{ border: "0.5px solid white", display: "flex", alignItems: "center", borderRadius: "5px", padding: "3px 10px" }}>
                    <p>{t("Available on")}</p>
                    <AppleIcon sx={{ fontSize: "12px", color: "white", margin: "0 5px" }} />
                    <img src={playstore} alt="..." width="9px" />
                  </div>
                </a>
              )}
              <ClearIcon
                sx={{ fontSize: "15px", color: "white",cursor:"pointer" }}
                onClick={() => {
                  localStorage.setItem("app", "close");
                  setGetApp(false);
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
      </div>
  )
}

export default MobileAppBanner