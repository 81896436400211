import React, {useState, useEffect} from "react";

function BannerImg() {
  const [scrolled, setScrolled] = useState(0);
  const display = () => {
    if (window.scrollY >=370) {
      setScrolled(true)  
    } else {
        setScrolled(false)  
    }
}
useEffect(() => {
    window.addEventListener('scroll', display)

}, [scrolled])
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="557"
      height="545.186"
      viewBox="0 0 557 545.186"
      // style={{paddingLeft: '50px'}}
    >
      <defs>
        <filter
          id="Rectangle_14"
          width="359"
          height="158"
          x="0"
          y="0"
          filterUnits="userSpaceOnUse"
        >
          <feOffset></feOffset>
          <feGaussianBlur result="blur" stdDeviation="7.5"></feGaussianBlur>
          <feFlood floodOpacity="0.161"></feFlood>
          <feComposite in2="blur" operator="in"></feComposite>
          <feComposite in="SourceGraphic"></feComposite>
        </filter>
        <clipPath id="clip-path">
          <path
            fill="none"
            d="M0 0H443.342V466.686H0z"
            data-name="Rectangle 1431"
          ></path>
        </clipPath>
        <clipPath id="clip-path-3">
          <path
            fill="none"
            d="M0 0H0.134V0.094H0z"
            data-name="Rectangle 1319"
          ></path>
        </clipPath>
        <clipPath id="clip-path-4">
          <path
            fill="none"
            d="M173.017 222.3a.587.587 0 00.134.094.278.278 0 01-.134-.094"
            data-name="Path 849"
            transform="translate(-173.017 -222.304)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient"
          x1="-2790.756"
          x2="-2774.626"
          y1="0.951"
          y2="0.951"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#9d68c2"></stop>
          <stop offset="1" stopColor="#ccedff"></stop>
        </linearGradient>
        <clipPath id="clip-path-5">
          <path
            fill="none"
            d="M0 0H3.348V2.684H0z"
            data-name="Rectangle 1320"
          ></path>
        </clipPath>
        <clipPath id="clip-path-6">
          <path
            fill="none"
            d="M0 0H1.55V1.744H0z"
            data-name="Rectangle 1321"
          ></path>
        </clipPath>
        <clipPath id="clip-path-7">
          <path
            fill="none"
            d="M0 0H4.993V4.37H0z"
            data-name="Rectangle 1322"
          ></path>
        </clipPath>
        <clipPath id="clip-path-8">
          <path
            fill="none"
            d="M0 0H2.466V2.296H0z"
            data-name="Rectangle 1323"
          ></path>
        </clipPath>
        <clipPath id="clip-path-9">
          <path
            fill="none"
            d="M0 0H2.015V2.987H0z"
            data-name="Rectangle 1324"
          ></path>
        </clipPath>
        <clipPath id="clip-path-10">
          <path
            fill="none"
            d="M0 0H5.06V3.988H0z"
            data-name="Rectangle 1325"
          ></path>
        </clipPath>
        <clipPath id="clip-path-12">
          <path
            fill="none"
            d="M0 0H1.939V1.587H0z"
            data-name="Rectangle 1327"
          ></path>
        </clipPath>
        <clipPath id="clip-path-13">
          <path
            fill="none"
            d="M0 0H1.961V2.168H0z"
            data-name="Rectangle 1328"
          ></path>
        </clipPath>
        <clipPath id="clip-path-14">
          <path
            fill="none"
            d="M0 0H1.763V2.299H0z"
            data-name="Rectangle 1329"
          ></path>
        </clipPath>
        <clipPath id="clip-path-15">
          <path
            fill="none"
            d="M0 0H1.268V1.049H0z"
            data-name="Rectangle 1330"
          ></path>
        </clipPath>
        <clipPath id="clip-path-16">
          <path
            fill="none"
            d="M0 0H2.756V3.491H0z"
            data-name="Rectangle 1331"
          ></path>
        </clipPath>
        <clipPath id="clip-path-17">
          <path
            fill="none"
            d="M0 0H1.697V1.559H0z"
            data-name="Rectangle 1332"
          ></path>
        </clipPath>
        <clipPath id="clip-path-18">
          <path
            fill="none"
            d="M0 0H1.986V1.57H0z"
            data-name="Rectangle 1333"
          ></path>
        </clipPath>
        <clipPath id="clip-path-19">
          <path
            fill="none"
            d="M0 0H2.382V3.622H0z"
            data-name="Rectangle 1334"
          ></path>
        </clipPath>
        <clipPath id="clip-path-21">
          <path
            fill="none"
            d="M0 0H1.13V1.271H0z"
            data-name="Rectangle 1336"
          ></path>
        </clipPath>
        <clipPath id="clip-path-22">
          <path
            fill="none"
            d="M0 0H1.335V1.423H0z"
            data-name="Rectangle 1337"
          ></path>
        </clipPath>
        <clipPath id="clip-path-23">
          <path
            fill="none"
            d="M0 0H176.32V38.098H0z"
            data-name="Rectangle 1339"
          ></path>
        </clipPath>
        <clipPath id="clip-path-24">
          <path
            fill="none"
            d="M228.887 220.7c0 10.52-39.47 19.049-88.16 19.049s-88.16-8.53-88.16-19.049 39.47-19.049 88.16-19.049 88.16 8.528 88.16 19.049"
            data-name="Path 1017"
            transform="translate(-52.567 -201.648)"
          ></path>
        </clipPath>
        <radialGradient
          id="radial-gradient"
          cx="0.5"
          cy="0.5"
          r="2.247"
          gradientTransform="matrix(.223 0 0 -.222 -.501 1.394)"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#be9dea"></stop>
          <stop offset="0.388" stopColor="#e0bff5"></stop>
          <stop offset="1" stopColor="#fff"></stop>
        </radialGradient>
        <clipPath id="clip-path-25">
          <path
            fill="none"
            d="M160.986 72.342L182.6 89.62l2.252-12.046 29.711-2.888 5.4-2.344 6.3-68.516-6.3-2.622z"
            data-name="Path 1018"
            transform="translate(-160.986 -1.204)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-2"
          x1="-3.488"
          x2="-3.465"
          y1="6.543"
          y2="6.543"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#a242ea"></stop>
          <stop offset="1" stopColor="#fe117e"></stop>
        </linearGradient>
        <clipPath id="clip-path-26">
          <path
            fill="none"
            d="M62.245 161.031c-.748 1.056.091 7.579 1.941 16.386 3.4 16.159 10.283 40.055 17.269 52.2 2.3 4 4.613 6.714 6.824 7.474 10.229 3.547 21.633-30.106 24.2-44.675.605-3.392.711-5.745.231-6.349-2.317-2.842-37.426-26.018-47.914-26.016a2.921 2.921 0 00-2.551.978"
            data-name="Path 1019"
            transform="translate(-61.973 -160.053)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-3"
          x1="-1.294"
          x2="-1.27"
          y1="2.01"
          y2="2.01"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#cbc1f7"></stop>
          <stop offset="1" stopColor="#e8dcf7"></stop>
        </linearGradient>
        <clipPath id="clip-path-27">
          <path
            fill="none"
            d="M94.26 188.845a4.507 4.507 0 00-.391 2.594c.409 10.192 12.064 44.347 22.379 55.593v.017a11.311 11.311 0 004.812 3.444 3.885 3.885 0 001.319.189c1.641-.034 3.3-1.677 4.92-4.515 6.807-11.817 13.132-44.312 16.2-66.442.2-1.452.393-2.854.553-4.2 1.228-9.846 1.693-16.886 1.07-17.734a.906.906 0 00-.759-.26c-6.247 0-44.546 24-50.1 31.318"
            data-name="Path 1020"
            transform="translate(-93.856 -157.527)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-4"
          x1="-1.75"
          x2="-1.729"
          y1="1.95"
          y2="1.95"
          xlinkHref="#linear-gradient-3"
        ></linearGradient>
        <clipPath id="clip-path-28">
          <path
            fill="none"
            d="M62.315 161.166c-4.792 6.083 34.28 33.62 50.756 26.427 0 0-35.562-27.653-47.591-27.655a3.794 3.794 0 00-3.165 1.228"
            data-name="Path 1021"
            transform="translate(-61.911 -159.938)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-5"
          x1="-2.33"
          x2="-2.291"
          y1="3.763"
          y2="3.763"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#be9dea"></stop>
          <stop offset="1" stopColor="#9e8cef"></stop>
        </linearGradient>
        <clipPath id="clip-path-29">
          <path
            fill="none"
            d="M93.877 191.3c-1.3 3.1 43.822-4.015 51.242-33.643 0 0-39.012 4.433-51.242 33.643"
            data-name="Path 1022"
            transform="translate(-93.849 -157.652)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-6"
          x1="-3.837"
          x2="-3.796"
          y1="3.697"
          y2="3.697"
          xlinkHref="#linear-gradient-5"
        ></linearGradient>
        <clipPath id="clip-path-31">
          <path
            fill="none"
            d="M0 0H51.039V77.04H0z"
            data-name="Rectangle 1347"
          ></path>
        </clipPath>
        <clipPath id="clip-path-32">
          <path
            fill="none"
            d="M79.335 186.6c-2.192 2.63.517 36.716 8.945 50.489-2.209-.762-4.526-3.478-6.824-7.474-1.373-5.969-3.4-14.829-5.328-23.669-2.46-11.386-8.731-23.065-11.938-28.532-1.855-8.807-2.691-15.33-1.943-16.386 4.561-6.557 47.881 21.87 50.464 25.038.482.6.374 2.957-.231 6.349 0 0-30.952-8.427-33.143-5.814"
            data-name="Path 1023"
            transform="translate(-61.973 -160.053)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-7"
          x1="-2.582"
          x2="-2.54"
          y1="1.65"
          y2="1.65"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <clipPath id="clip-path-33">
          <path
            fill="none"
            d="M0 0H51.536V89.505H0z"
            data-name="Rectangle 1349"
          ></path>
        </clipPath>
        <clipPath id="clip-path-34">
          <path
            fill="none"
            d="M144.047 175.521s-24.681 7.094-30.383 18.462c-5.7 11.351-3.562 38.342 2.585 53.049-10.317-11.246-21.972-45.4-22.381-55.593a4.53 4.53 0 01.391-2.594c5.882-7.751 48.486-34.208 50.855-31.059.625.848.162 7.892-1.068 17.736"
            data-name="Path 1025"
            transform="translate(-93.856 -157.527)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-8"
          x1="-3.906"
          x2="-3.865"
          y1="1.504"
          y2="1.504"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <clipPath id="clip-path-35">
          <path
            fill="none"
            d="M83.993 122.519l-.037.034c0 .017-.017.034-.035.052-.071.084-.143.172-.231.275a.768.768 0 01-.162.208 6.412 6.412 0 01-.175.225c-.108.139-.233.279-.322.416a.9.9 0 01-.125.155 243.022 243.022 0 00-26.871 41.734C40.176 197.367 37.13 225.4 37.13 225.4a47.925 47.925 0 006.859 1.436c6.841.97 17.589 1.245 26.568-4.066l.017-.017c.445-.97 15.148-31.769 24.929-56.233 5.916-14.776 7.734-33.064 7.788-45.714.035-8.6-.767-14.587-1.675-15.07a.642.642 0 00-.3-.067c-2.012 0-8.919 6.5-17.321 16.852"
            data-name="Path 1026"
            transform="translate(-37.13 -105.667)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-9"
          x1="-0.35"
          x2="-0.334"
          y1="2.825"
          y2="2.825"
          xlinkHref="#linear-gradient-3"
        ></linearGradient>
        <clipPath id="clip-path-36">
          <path
            fill="none"
            d="M37.192 170.744c18.032 9.183 33.286-1.532 33.286-1.532s2.138-12.186-9.792-14.589a10.959 10.959 0 00-2.175-.216c-11.121 0-21.32 16.338-21.32 16.338"
            data-name="Path 1027"
            transform="translate(-37.192 -154.406)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-10"
          x1="-2.269"
          x2="-2.208"
          y1="5.584"
          y2="5.584"
          xlinkHref="#linear-gradient-5"
        ></linearGradient>
        <clipPath id="clip-path-38">
          <path
            fill="none"
            d="M0 0H66.163V106.03H0z"
            data-name="Rectangle 1354"
          ></path>
        </clipPath>
        <clipPath id="clip-path-39">
          <path
            fill="none"
            d="M103.293 112.879c-.054 12.648-1.872 30.938-7.788 45.714-9.781 24.465-24.482 55.264-24.93 56.233l-.017.017-26.568 4.066a47.655 47.655 0 01-6.861-1.436s3.048-28.031 18.907-59.78a243.124 243.124 0 0126.871-41.733c-5.006 6.418-25.963 34.361-31.594 58.1-6.416 27.009 19.459 16.611 30.044 0 10.566-16.611 21.614-61.183 21.614-61.183z"
            data-name="Path 1028"
            transform="translate(-37.129 -112.879)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-11"
          x1="-1.213"
          x2="-1.18"
          y1="2.167"
          y2="2.167"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <clipPath id="clip-path-40">
          <path
            fill="none"
            d="M39.219 166.9c-2.8 5.037-4.252 34.048 2.8 58.022 0 0 9.961 2.619 17.429 0s12.652-23.774 13.072-39.69-5.188-26.391-15.354-28a9.5 9.5 0 00-1.481-.111c-6.046 0-13.882 5.136-16.467 9.781"
            data-name="Path 1031"
            transform="translate(-36.906 -157.117)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-12"
          x1="-0.762"
          x2="-0.734"
          y1="2.742"
          y2="2.742"
          xlinkHref="#linear-gradient-3"
        ></linearGradient>
        <clipPath id="clip-path-41">
          <path
            fill="none"
            d="M46.923 222c9.662-1.813 13.164-11.886 15.444-31.63S58.5 160.508 58.5 160.508C37.83 170.937 46.923 222 46.923 222"
            data-name="Path 1032"
            transform="translate(-44.931 -160.508)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-13"
          x1="-3.356"
          x2="-3.276"
          y1="2.112"
          y2="2.112"
          xlinkHref="#linear-gradient-5"
        ></linearGradient>
        <clipPath id="clip-path-43">
          <path
            fill="none"
            d="M0 0H15.463V23.777H0z"
            data-name="Rectangle 1359"
          ></path>
        </clipPath>
        <clipPath id="clip-path-44">
          <path
            fill="none"
            d="M45.193 183.884s5.313 7.539 8.945 8.771a9.4 9.4 0 006.465-.067l-13.417-23.18s-2.432 9.21-1.993 14.476"
            data-name="Path 1033"
            transform="translate(-45.14 -169.408)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-14"
          x1="-6.132"
          x2="-5.996"
          y1="4.54"
          y2="4.54"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#8da4e5"></stop>
          <stop offset="1" stopColor="#755cbb"></stop>
        </linearGradient>
        <clipPath id="clip-path-45">
          <path
            fill="none"
            d="M46.087 178.622c1.453 10.274 6.846 20.651 6.846 20.651 6.435 5.841 11 0 11 0 2.073-30.421-6.1-38.25-6.1-38.25-8.8 3.455-11.744 17.6-11.744 17.6"
            data-name="Path 1034"
            transform="translate(-46.087 -161.023)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-15"
          x1="-1.805"
          x2="-1.76"
          y1="4.821"
          y2="4.821"
          xlinkHref="#linear-gradient-3"
        ></linearGradient>
        <clipPath id="clip-path-47">
          <path
            fill="none"
            d="M46.108 40.991c-5.851 17.7 4.952 52.672 4.952 52.672l36.031-59.487S73.573 22.851 57.026 20.884c0 0-5.067 2.405-10.918 20.107"
            data-name="Path 1036"
            transform="translate(-44.37 -20.884)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-16"
          x1="-1.96"
          x2="-1.914"
          y1="5.809"
          y2="5.809"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#b9acf4"></stop>
          <stop offset="1" stopColor="#e1d1f5"></stop>
        </linearGradient>
        <clipPath id="clip-path-48">
          <path
            fill="none"
            d="M47.257 29.71l15.566 36.46 3.925-3.944c.536-29.143-13.075-41.342-13.075-41.342-3.763 2.842-6.416 8.826-6.416 8.826"
            data-name="Path 1037"
            transform="translate(-47.257 -20.884)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-17"
          x1="-5.101"
          x2="-4.993"
          y1="9.337"
          y2="9.337"
          xlinkHref="#linear-gradient-5"
        ></linearGradient>
        <clipPath id="clip-path-49">
          <path
            fill="none"
            d="M115.893 34.176l36.034 59.487s10.8-34.97 4.95-52.672-10.918-20.107-10.918-20.107c-16.545 1.967-30.065 13.292-30.065 13.292"
            data-name="Path 1038"
            transform="translate(-115.893 -20.884)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-18"
          x1="-5.349"
          x2="-5.302"
          y1="5.809"
          y2="5.809"
          xlinkHref="#linear-gradient-16"
        ></linearGradient>
        <clipPath id="clip-path-50">
          <path
            fill="none"
            d="M137 20.887a55.9 55.9 0 00-13.391 36.391l5.179 3.169 15.079-30.312s-4.866-9.252-6.787-9.254a.511.511 0 00-.08.006"
            data-name="Path 1039"
            transform="translate(-123.607 -20.881)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-19"
          x1="-13.189"
          x2="-13.082"
          y1="10.687"
          y2="10.687"
          xlinkHref="#linear-gradient-5"
        ></linearGradient>
        <clipPath id="clip-path-51">
          <path
            fill="none"
            d="M126.015 56.426l20.461 38.233s15.818-14.957 9.74-39.873-20.762-31.639-20.762-31.639c-13 14.1-9.44 33.278-9.44 33.278"
            data-name="Path 1040"
            transform="translate(-125.569 -23.148)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-20"
          x1="-6.735"
          x2="-6.68"
          y1="5.846"
          y2="5.846"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#45ffcd"></stop>
          <stop offset="1" stopColor="#22e0ff"></stop>
        </linearGradient>
        <clipPath id="clip-path-52">
          <path
            fill="none"
            d="M41.026 54.787C34.949 79.7 50.77 94.659 50.77 94.659l20.459-38.233s3.56-19.18-9.437-33.278c0 0-14.688 6.723-20.766 31.639"
            data-name="Path 1041"
            transform="translate(-39.636 -23.148)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-21"
          x1="-2.033"
          x2="-1.979"
          y1="5.846"
          y2="5.846"
          xlinkHref="#linear-gradient-20"
        ></linearGradient>
        <clipPath id="clip-path-53">
          <path
            fill="none"
            d="M51.445 90.546c-11.6 30.02-12.918 62.635-.873 84.331 18.46 33.221 160.249 36.267 190.416-4.809 10.14-13.809 11.173-35.7 5.151-58.691C234.235 65.941 194.73 16.2 143.3 16.2c-43.692 0-76.641 34.949-91.858 74.347"
            data-name="Path 1042"
            transform="translate(-42.123 -16.199)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-22"
          x1="-0.36"
          x2="-0.351"
          y1="1.957"
          y2="1.957"
          xlinkHref="#linear-gradient-3"
        ></linearGradient>
        <clipPath id="clip-path-55">
          <path
            fill="none"
            d="M132.83 19.387c-61.761 3.6-92.057 60-81 105.818 6.753 27.976 131.379 31.022 161.991 5.669 29.957-24.811-11.629-111.6-76.708-111.61-1.422 0-2.842.04-4.286.124"
            data-name="Path 1044"
            transform="translate(-49.59 -19.263)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-23"
          x1="-0.485"
          x2="-0.474"
          y1="3.112"
          y2="3.112"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#0665bd"></stop>
          <stop offset="1" stopColor="#1a1f77"></stop>
        </linearGradient>
        <clipPath id="clip-path-57">
          <path
            fill="none"
            d="M0 0H207.725V109.81H0z"
            data-name="Rectangle 1373"
          ></path>
        </clipPath>
        <clipPath id="clip-path-58">
          <path
            fill="none"
            d="M240.99 131.152c-30.169 41.075-171.956 38.03-190.418 4.809-12.044-21.7-10.726-54.311.873-84.331a.017.017 0 01.017-.017c-.5 1.765-12.384 43.844-.249 62.358 12.4 18.928 61.477 16.3 114.151 14.119 52.673-2.2 70.1-9.621 78.939-24.051 6.967-11.351-.037-36.405-3.333-46.543l5.168 14.965c6.022 23 4.989 44.883-5.149 58.691"
            data-name="Path 1045"
            transform="translate(-42.123 -51.613)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-24"
          x1="-0.409"
          x2="-0.399"
          y1="2.971"
          y2="2.971"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <clipPath id="clip-path-59">
          <path
            fill="none"
            d="M70.2 94.719c-9.107 1.627-14.9 3.614-15.237 5.881-.551 3.721 3.225 7.338 11.031 10.366h.017c11.887 4.62 33.126 7.837 62.6 7.873 26.622.017 76.338-8.964 73.559-18.239a3.851 3.851 0 00-.839-1.419c-1.619-1.832-5.274-3.339-10.423-4.567-.089-.017-.162-.034-.251-.05-.426-.1-.871-.208-1.334-.313-2.157-.468-4.546-.882-7.112-1.264-12.4-1.824-29.209-2.647-46.547-2.647-24.389 0-49.822 1.627-65.463 4.378"
            data-name="Path 1046"
            transform="translate(-54.911 -90.341)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-25"
          x1="-0.785"
          x2="-0.771"
          y1="9.716"
          y2="9.716"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#8879ea"></stop>
          <stop offset="1" stopColor="#9e8cef"></stop>
        </linearGradient>
        <clipPath id="clip-path-61">
          <path
            fill="none"
            d="M0 0H146.938V20.626H0z"
            data-name="Rectangle 1377"
          ></path>
        </clipPath>
        <clipPath id="clip-path-62">
          <path
            fill="none"
            d="M201.83 98.319a3.3 3.3 0 01-.5.865c-2.886 3.963-14.593 9.065-14.593 9.065s-44.993-17.872-81.47-9.93-39.256 12.648-39.256 12.648h-.017c-7.8-3.029-11.582-6.645-11.031-10.364.339-2.267 6.13-4.257 15.237-5.883 26.765-4.706 82.164-6.125 112.011-1.732a311.14 311.14 0 018.447 1.576c.089.017.16.034.249.05 7.075 1.488 11.262 2.8 10.922 3.7"
            data-name="Path 1047"
            transform="translate(-54.911 -90.341)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-26"
          x1="-0.8"
          x2="-0.785"
          y1="12.463"
          y2="12.463"
          xlinkHref="#linear-gradient-14"
        ></linearGradient>
        <clipPath id="clip-path-63">
          <path
            fill="none"
            d="M61.984 94.719c4.812 6.3 25.552 11.523 59.694 11.575 19.048.017 52.459-5.573 59.428-12.043-12.552-2.714-32.694-3.91-53.614-3.91-24.4 0-49.861 1.629-65.509 4.378"
            data-name="Path 1048"
            transform="translate(-61.984 -90.341)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-27"
          x1="-5.711"
          x2="-5.641"
          y1="2.804"
          y2="2.804"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#5e36cc"></stop>
          <stop offset="1" stopColor="#6c4a96"></stop>
        </linearGradient>
        <clipPath id="clip-path-64">
          <path
            fill="none"
            d="M79.438 119.7a.058.058 0 01-.017.036 72.664 72.664 0 00-5.132 8.771l-.017.036a79.275 79.275 0 00-4.372 10.705c-8.108 24.914-10.46 64.916-4.384 86.545s29.08 31.473 56.238 31.473c31.2 0 52.957-15.727 57.074-39.329 3.67-21.073 2.477-45.764-3.136-68.394a1.337 1.337 0 01-.035-.208 154.112 154.112 0 00-6.7-20.85 55.056 55.056 0 00-9.695-15.832c-8.891-10.087-21.951-16.9-39.023-16.9-16.393 0-30.9 9.466-40.806 23.946"
            data-name="Path 1049"
            transform="translate(-62.098 -95.749)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-28"
          x1="-0.571"
          x2="-0.559"
          y1="1.834"
          y2="1.834"
          xlinkHref="#linear-gradient-3"
        ></linearGradient>
        <clipPath id="clip-path-65">
          <path
            fill="none"
            d="M125.857 147.99s-4.695-26.131-19.612-45.082c0 0 2.661 28.031 19.612 45.082"
            data-name="Path 1050"
            transform="translate(-106.245 -102.908)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-29"
          x1="-11.71"
          x2="-11.6"
          y1="5.561"
          y2="5.561"
          xlinkHref="#linear-gradient-25"
        ></linearGradient>
        <clipPath id="clip-path-66">
          <path
            fill="none"
            d="M108.855 128.4a2.266 2.266 0 00-.8.607l-.017.017c-4.438 5.157 6.789 31.592 19.672 39.692 0 0 46.865-17.475 73.433-68.623 0 0 .322-8.58-3.368-15.866-2.514-5.016-6.932-9.395-14.649-9.932 0 0-17.107 27.546-74.27 54.105"
            data-name="Path 1051"
            transform="translate(-107.048 -74.299)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-30"
          x1="-2.048"
          x2="-2.028"
          y1="2.985"
          y2="2.985"
          xlinkHref="#linear-gradient-3"
        ></linearGradient>
        <clipPath id="clip-path-68">
          <path
            fill="none"
            d="M0 0H49.152V28.937H0z"
            data-name="Rectangle 1384"
          ></path>
        </clipPath>
        <clipPath id="clip-path-69">
          <path
            fill="none"
            d="M49.87 106.951s5.988-6.784 17.736-12.247 22.545-9.743 24.214-14.132-32.223-1.8-32.223-1.8L42.728 85.88s2.644 13.212 7.142 21.071"
            data-name="Path 1052"
            transform="translate(-42.728 -78.013)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-31"
          x1="-1.82"
          x2="-1.777"
          y1="9.903"
          y2="9.903"
          xlinkHref="#linear-gradient-14"
        ></linearGradient>
        <clipPath id="clip-path-70">
          <path
            fill="none"
            d="M143.971 74.531c-4.76 1.23-4.276 31.907 1.8 34.967s16.208-9.178 16.208-9.178S161 74.1 147.181 74.1a12.761 12.761 0 00-3.21.434"
            data-name="Path 1053"
            transform="translate(-140.763 -74.096)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-32"
          x1="-13.297"
          x2="-13.202"
          y1="8.091"
          y2="8.091"
          xlinkHref="#linear-gradient-5"
        ></linearGradient>
        <clipPath id="clip-path-71">
          <path
            fill="none"
            d="M115.164 35.208c-15.755 1.749-16.364 23.583-14.18 27.319 4.5 7.7 34.334.854 36.014-3.717 2.155-5.854-5.382-23.713-19.835-23.713a18.1 18.1 0 00-2 .111"
            data-name="Path 1054"
            transform="translate(-100.064 -35.097)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-33"
          x1="-5.329"
          x2="-5.276"
          y1="11.187"
          y2="11.187"
          xlinkHref="#linear-gradient-20"
        ></linearGradient>
        <clipPath id="clip-path-72">
          <path
            fill="none"
            d="M62.461 58.81c1.682 4.571 31.514 11.418 36.014 3.717 2.183-3.736 1.576-25.571-14.178-27.319a18.1 18.1 0 00-2-.111c-14.457 0-21.99 17.859-19.837 23.713"
            data-name="Path 1055"
            transform="translate(-62.098 -35.097)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-34"
          x1="-3.282"
          x2="-3.229"
          y1="11.187"
          y2="11.187"
          xlinkHref="#linear-gradient-20"
        ></linearGradient>
        <clipPath id="clip-path-75">
          <path
            fill="none"
            d="M0 0H135.812V53.895H0z"
            data-name="Rectangle 1393"
          ></path>
        </clipPath>
        <clipPath id="clip-path-76">
          <path
            fill="none"
            d="M60.132 52.7c-1.554 9.061 19.21 8.668 47.494 7.76 33.986-1.094 68.137 27.319 88.009 6.928 3.275-3.362-29.263-47.483-68.542-46.495S61.483 44.827 60.132 52.7"
            data-name="Path 1059"
            transform="translate(-60.05 -20.873)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-35"
          x1="-0.914"
          x2="-0.899"
          y1="6.243"
          y2="6.243"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0"></stop>
          <stop offset="1" stopColor="#6a9bff"></stop>
        </linearGradient>
        <clipPath id="clip-path-77">
          <path
            fill="none"
            d="M0 0H96.513V17.484H0z"
            data-name="Rectangle 1395"
          ></path>
        </clipPath>
        <clipPath id="clip-path-78">
          <path
            fill="none"
            d="M69.083 76.644c-1.794 6.567 12.356 13.137 47.155 11.481 42.069-2 51-10.557 48.934-12.927-1.243-1.423-13.845 6.284-48.4-1.07-22.815-4.857-45.8-4.393-47.693 2.517"
            data-name="Path 1060"
            transform="translate(-68.932 -70.902)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-36"
          x1="-1.474"
          x2="-1.453"
          y1="15.578"
          y2="15.578"
          xlinkHref="#linear-gradient-35"
        ></linearGradient>
        <clipPath id="clip-path-79">
          <path
            fill="none"
            d="M0 0H1.014V2.976H0z"
            data-name="Rectangle 1397"
          ></path>
        </clipPath>
        <clipPath id="clip-path-80">
          <path
            fill="none"
            d="M134.528 55.561l-1.014-2.976c.195.571.568 1.593 1.014 2.976"
            data-name="Path 1061"
            transform="translate(-133.514 -52.585)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-37"
          x1="-284.681"
          x2="-282.549"
          y1="119.721"
          y2="119.721"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <clipPath id="clip-path-81">
          <path
            fill="none"
            d="M0 0H118.836V128.765H0z"
            data-name="Rectangle 1399"
          ></path>
        </clipPath>
        <clipPath id="clip-path-82">
          <path
            fill="none"
            d="M178.833 200.792c-4.116 23.6-25.874 39.327-57.074 39.327-27.156 0-50.162-9.827-56.238-31.473-6.076-21.627-3.721-61.632 4.379-86.546a78.91 78.91 0 014.366-10.709l.017-.036s-16.43 52.894-3.3 86.581 74.324 32.98 95.029 10.589c19.707-21.3 10.6-71.337 9.675-76.131 5.614 22.632 6.807 47.324 3.136 68.4"
            data-name="Path 1062"
            transform="translate(-62.098 -111.354)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-38"
          x1="-1.017"
          x2="-1"
          y1="1.809"
          y2="1.809"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <clipPath id="clip-path-83">
          <path
            fill="none"
            d="M64.482 173.839c-4.345 29.62 21.158 50.713 54.772 50.713s52.369-19.452 52.818-44.366-16.391-53.954-47.717-56.825q-2.448-.224-4.861-.222c-27.069 0-50.628 20.8-55.012 50.7"
            data-name="Path 1063"
            transform="translate(-63.998 -123.138)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-39"
          x1="-1.197"
          x2="-1.177"
          y1="2.054"
          y2="2.054"
          xlinkHref="#linear-gradient-5"
        ></linearGradient>
        <clipPath id="clip-path-84">
          <path
            fill="none"
            d="M64.616 175.09c-4.343 29.62 21.16 50.711 54.772 50.711s52.369-19.45 52.82-44.366-16.391-53.954-47.719-56.825q-2.448-.223-4.861-.222c-27.069 0-50.628 20.806-55.012 50.7"
            data-name="Path 1064"
            transform="translate(-64.134 -124.387)"
          ></path>
        </clipPath>
        <radialGradient
          id="radial-gradient-2"
          cx="0.531"
          cy="0.845"
          r="0.763"
          gradientTransform="matrix(.929 0 0 -1 -.085 4.055)"
          xlinkHref="#linear-gradient-20"
        ></radialGradient>
        <clipPath id="clip-path-86">
          <path
            fill="none"
            d="M0 0H80.08V31.391H0z"
            data-name="Rectangle 1404"
          ></path>
        </clipPath>
        <clipPath id="clip-path-87">
          <path
            fill="none"
            d="M150.085 112.653c-6.647 10.123-43.234 16.015-64.376 14.14-18.41-1.633-15.615-6.458-15.473-7.063a.058.058 0 00.017-.036c9.909-14.482 24.413-23.946 40.806-23.946 17.07 0 30.132 6.817 39.025 16.9"
            data-name="Path 1065"
            transform="translate(-70.005 -95.749)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-40"
          x1="-1.795"
          x2="-1.769"
          y1="8.075"
          y2="8.075"
          xlinkHref="#linear-gradient-14"
        ></linearGradient>
        <clipPath id="clip-path-88">
          <path
            fill="none"
            d="M0 0H74.806V17.567H0z"
            data-name="Rectangle 1406"
          ></path>
        </clipPath>
        <clipPath id="clip-path-89">
          <path
            fill="none"
            d="M72.869 166.406s7.509 12.7 35.474 12.8 39.332-16.063 39.332-16.063-12.9 8.165-31.349 3.262-36.695-7.659-43.456 0"
            data-name="Path 1068"
            transform="translate(-72.869 -161.641)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-41"
          x1="-2.106"
          x2="-2.077"
          y1="6.336"
          y2="6.336"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#d4e2ff"></stop>
          <stop offset="1"></stop>
        </linearGradient>
        <clipPath id="clip-path-90">
          <path
            fill="none"
            d="M0 0H94.1V92.015H0z"
            data-name="Rectangle 1408"
          ></path>
        </clipPath>
        <clipPath id="clip-path-91">
          <path
            fill="none"
            d="M201.141 98.837c-26.568 51.148-73.433 68.623-73.433 68.623-12.883-8.1-24.11-34.537-19.672-39.692l.017-.019a7.066 7.066 0 01.8-.6s8.365 29.821 32.865 21.547c29.069-9.814 40.962-58.868 40.962-58.868V75.445l15.092 7.527c3.688 7.285 3.368 15.865 3.368 15.865"
            data-name="Path 1071"
            transform="translate(-107.048 -75.445)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-42"
          x1="-2.329"
          x2="-2.307"
          y1="3.351"
          y2="3.351"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <clipPath id="clip-path-92">
          <path
            fill="none"
            d="M154.517 62.236s-12.606 30.816-13.056 44.585c0 0-.225 16.174 9.23 18.8s21.268-14.524 25.658-21.631 11.03-39.125 4.727-46.776a12.493 12.493 0 00-9.885-4.62c-5.6 0-11.854 3.276-16.674 9.646"
            data-name="Path 1072"
            transform="translate(-141.461 -52.589)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-43"
          x1="-3.457"
          x2="-3.431"
          y1="6.41"
          y2="6.41"
          xlinkHref="#linear-gradient-3"
        ></linearGradient>
        <clipPath id="clip-path-93">
          <path
            fill="none"
            d="M154.242 62.239c-9.68 10.272-12.544 39.786-12.544 39.786s10.968-8.1 13.443-20.554 15.764-28.879 15.764-28.879-.084-.006-.242-.006c-1.306 0-7.792.5-16.422 9.653"
            data-name="Path 1073"
            transform="translate(-141.698 -52.586)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-44"
          x1="-10.487"
          x2="-10.413"
          y1="7.207"
          y2="7.207"
          xlinkHref="#linear-gradient-5"
        ></linearGradient>
        <clipPath id="clip-path-95">
          <path
            fill="none"
            d="M0 0H12.418V28.639H0z"
            data-name="Rectangle 1413"
          ></path>
        </clipPath>
        <clipPath id="clip-path-96">
          <path
            fill="none"
            d="M148.872 58.2l8.378 3.071a52.41 52.41 0 00-5.225 11.867c-1.513 5.793-7.194 13.7-7.194 13.7z"
            data-name="Path 1074"
            transform="translate(-144.832 -58.2)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-45"
          x1="-25.21"
          x2="-25.036"
          y1="12.03"
          y2="12.03"
          xlinkHref="#linear-gradient-14"
        ></linearGradient>
        <clipPath id="clip-path-97">
          <path
            fill="none"
            d="M144.046 55.856c-1.351 1.637-4.235 10.927-3.841 22.731s3.27 17.92 3.27 17.92c9.74-2.842 12.766-23.854 12.766-23.854s-1.617-12.973-3.869-15.595a7.164 7.164 0 00-5.313-2.244 3.932 3.932 0 00-3.013 1.041"
            data-name="Path 1075"
            transform="translate(-140.168 -54.815)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-46"
          x1="-6.462"
          x2="-6.413"
          y1="16.233"
          y2="16.233"
          xlinkHref="#linear-gradient-3"
        ></linearGradient>
        <clipPath id="clip-path-98">
          <path
            fill="none"
            d="M147.917 67.481s5.631 18.025 19.19 25.241L168.85 90s-13.8-7.067-20.405-24.681z"
            data-name="Path 1076"
            transform="translate(-147.917 -65.317)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-47"
          x1="-15.274"
          x2="-15.171"
          y1="12.027"
          y2="12.027"
          xlinkHref="#linear-gradient-5"
        ></linearGradient>
        <clipPath id="clip-path-101">
          <path
            fill="none"
            d="M0 0H14.099V47.855H0z"
            data-name="Rectangle 1420"
          ></path>
        </clipPath>
        <clipPath id="clip-path-102">
          <path
            fill="none"
            d="M51.657 87.489a86.277 86.277 0 01.264-22.3C53.643 52.4 57.179 44.145 59.287 45.3s1.779 13.678 3.022 26.016c.968 9.6 2.868 18.609 2.868 18.609s-9.094 8.446-13.521-2.439"
            data-name="Path 1079"
            transform="translate(-51.079 -45.196)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-48"
          x1="-1.562"
          x2="-1.524"
          y1="19.477"
          y2="19.477"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0"></stop>
          <stop offset="1" stopColor="#00e3ff"></stop>
        </linearGradient>
        <clipPath id="clip-path-103">
          <path
            fill="none"
            d="M0 0H90.822V42H0z"
            data-name="Rectangle 1422"
          ></path>
        </clipPath>
        <clipPath id="clip-path-104">
          <path
            fill="none"
            d="M69.557 152.831c5.523 8.557 28.25 4 53.86 6.427s29.979 10.515 35.381 8.876-3.132-39.073-41.4-41.612-49.213 24.178-47.841 26.309"
            data-name="Path 1080"
            transform="translate(-69.445 -126.351)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-49"
          x1="-1.55"
          x2="-1.528"
          y1="4.452"
          y2="4.452"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0"></stop>
          <stop offset="1" stopColor="#d4e2ff"></stop>
        </linearGradient>
        <clipPath id="clip-path-105">
          <path
            fill="none"
            d="M151.072 2.526s-.212 49.41 3.649 73.337l39.4 15.078-6.3-10.719 38.266-4.215s4.051-55.562 5.4-73.482c0 0-13.408-2.525-37.231-2.525a384.422 384.422 0 00-43.182 2.525"
            data-name="Path 1081"
            transform="translate(-151.07 -.001)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-50"
          x1="-3.199"
          x2="-3.177"
          y1="5.482"
          y2="5.482"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#d93de3"></stop>
          <stop offset="1" stopColor="#ff6dba"></stop>
        </linearGradient>
        <clipPath id="clip-path-107">
          <path
            fill="none"
            d="M0 0H19.16V22.145H0z"
            data-name="Rectangle 1426"
          ></path>
        </clipPath>
        <clipPath id="clip-path-108">
          <path
            fill="none"
            d="M144.337 30.86s-4.762-5.73-11.253-4.206-6.921 21.381-3.039 21.873 5.529-10.983 8.5-12.786 8.177-1.1 8.177-1.1z"
            data-name="Path 1085"
            transform="translate(-127.565 -26.396)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-51"
          x1="-13.341"
          x2="-13.236"
          y1="17.272"
          y2="17.272"
          xlinkHref="#linear-gradient-49"
        ></linearGradient>
        <clipPath id="clip-path-109">
          <path
            fill="none"
            d="M0 0H21.117V24.714H0z"
            data-name="Rectangle 1428"
          ></path>
        </clipPath>
        <clipPath id="clip-path-110">
          <path
            fill="none"
            d="M42.531 39.933s2.505-2.758 8.132-1.864 8.871 12.106 11.75 10.981.77-19.293-6.841-24.635c0 0-9.807 8.131-13.041 15.517"
            data-name="Path 1086"
            transform="translate(-42.531 -24.416)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-52"
          x1="-4.039"
          x2="-3.944"
          y1="16.125"
          y2="16.125"
          xlinkHref="#linear-gradient-41"
        ></linearGradient>
        <filter
          id="Rectangle_3"
          width="180"
          height="204"
          x="377"
          y="303"
          filterUnits="userSpaceOnUse"
        >
          <feOffset></feOffset>
          <feGaussianBlur result="blur-2" stdDeviation="7.5"></feGaussianBlur>
          <feFlood floodOpacity="0.161"></feFlood>
          <feComposite in2="blur-2" operator="in"></feComposite>
          <feComposite in="SourceGraphic"></feComposite>
        </filter>
      </defs>
      <g data-name="Component 3 – 1" transform="translate(20.5 22.5)">
        <g data-name="Group 1656" transform="translate(-304 -124.138)">
          <g filter="url(#Rectangle_14)" transform="translate(283.5 101.64)">
            <rect
              width="314"
              height="113"
              fill="#fff"
              data-name="Rectangle 14"
              rx="15"
              transform="translate(22.5 22.5)"
            ></rect>
          </g>
          <text
          style={{direction: 'ltr'}}
            data-name="10X"
            fontFamily="Poppins-SemiBold, Poppins"
            fontSize="52"
            fontWeight="600"
            transform="translate(509 197.138)"
          >
            <tspan x="0" y="-10">
              10X{" "}
            </tspan>
          </text>
          <text
          style={{direction: 'ltr'}}
            fill="#011032"
            data-name="سرعة إخراج المحتوى الرائع"
            fontFamily="Tajawal-Medium, Tajawal"
            fontSize="26"
            fontWeight="500"
            transform="translate(304 139.138)"
          >
            <tspan x="66.68" y="17"></tspan>
            <tspan x="65" y="17">سرعة إخراج</tspan>
            <tspan x="37.066" y="53">
              المحتوى الرائع
            </tspan>
          </text>
        </g>
        <g className={'visible_avatar_inner'} data-name="Group 1653" transform="translate(-526 -105.465)">
          <g data-name="Group 1577" transform="translate(534.014 161.465)">
            
            <g   clipPath="url(#clip-path)" data-name="Group 1576">
              <g data-name="Group 1376">
                <g clipPath="url(#clip-path)" data-name="Group 1375">
                  <g
                    data-name="Group 1315"
                    opacity="0.3"
                    transform="translate(373.99 466.592)"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  >
                    <g data-name="Group 1314">
                      <g clipPath="url(#clip-path-3)" data-name="Group 1313">
                        <g
                          data-name="Group 1312"
                          transform="translate(0 -.001)"
                        >
                          <g
                            clipPath="url(#clip-path-4)"
                            data-name="Group 1311"
                          >
                            <path
                              fill="url(#linear-gradient)"
                              d="M0 0H0.134V0.094H0z"
                              data-name="Rectangle 1318"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <path
                    fill="#e5f6ff"
                    d="M381.812 276.626c-5.689 10.958-12.34 20.105-19.783 26.673a51.273 51.273 0 01-6.781 5.121 42.612 42.612 0 01-6.8 3.495c-33.911 13.611-68.634-17.36-107.426-13.7-32.519 3.073-55.918 20.617-76.66 35.343a204.774 204.774 0 01-21.309 13.811 77.445 77.445 0 01-5.9 2.89 78.798 78.798 0 01-6.744 2.563c0 .017-.019.017-.019.017l-.017-.017c-30.05 10.005-80.806 9.64-103.3-11.892-23.552-22.557-52.15-95.659 12.627-189.817C111.665 46.454 308.3 31.984 360.843 66.6c54.372 35.782 52.187 149.9 20.97 210.029"
                    data-name="Path 850"
                    transform="translate(0 54.704)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M184.787 93.01a48.94 48.94 0 0014.042-17.375 8.36 8.36 0 001.025-3.423c.03-2.317-1.723-4.334-3.742-5.57-4.939-3.02-11.791-2.353-16.4 1.131a17.438 17.438 0 00-6.524 15.053c.225 3.1 1.321 6.309 3.761 8.328s6.424 2.437 8.8.344a1.5 1.5 0 00-2.252-1.06z"
                    data-name="Path 851"
                    transform="translate(201.122 71.123)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M188.55 163.637a48.941 48.941 0 0014.042-17.375 8.36 8.36 0 001.025-3.423c.03-2.317-1.723-4.334-3.742-5.57-4.939-3.02-11.791-2.353-16.4 1.131a17.438 17.438 0 00-6.524 15.053c.225 3.1 1.321 6.309 3.761 8.328s6.424 2.437 8.8.344a1.5 1.5 0 00-2.252-1.06z"
                    data-name="Path 852"
                    transform="translate(205.493 148.734)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M182.4 72.206a9.342 9.342 0 007.741-4.45c-1.621-2.6-4.338-4.752-7.462-4.9s-6.383 2.307-6.273 5.34 4.524 5.176 6.712 3.008z"
                    data-name="Path 853"
                    transform="translate(204.901 69.066)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M187.432 58.3a4.886 4.886 0 004.176.525 4.71 4.71 0 00-.735-1.746 1.693 1.693 0 00-1.705-.674c-.858.241-1.167 1.24-1.34 2.09-.138-.2-.277-.395-.413-.592z"
                    data-name="Path 854"
                    transform="translate(217.697 61.931)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M186.728 61.183a2.156 2.156 0 01-.979.835c-.285-1.066.437-2.122 1.163-2.968.4-.47.988-.978 1.587-.781.415.139.642.567.981.84.681.544 1.939.615 2.034 1.467a1.062 1.062 0 01-.873 1.026 3.213 3.213 0 01-1.476-.1q-1.592-.356-3.18-.714z"
                    data-name="Path 855"
                    transform="translate(215.687 63.984)"
                  ></path>
                  <g
                    data-name="Group 1318"
                    opacity="0.5"
                    transform="translate(439.994 63.156)"
                  >
                    <g data-name="Group 1317">
                      <g clipPath="url(#clip-path-5)" data-name="Group 1316">
                        <path
                          fill="#e5f6ff"
                          d="M206.309 32.235c.895-.189.711-1.656-.13-2.007a3.4 3.4 0 00-2.626.405 3.191 3.191 0 002.8 2.141z"
                          data-name="Path 856"
                          transform="translate(-203.551 -30.09)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <path
                    fill="#e5f6ff"
                    d="M193.676 22.634a.818.818 0 01-.817-.789 1.726 1.726 0 011.178-1.478 1.082 1.082 0 011.28.852 1.135 1.135 0 01-.966 1.211 1.249 1.249 0 01-1.325-.877z"
                    data-name="Path 857"
                    transform="translate(224.019 22.351)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M185.85 51.5a1.266 1.266 0 001.643 1.415 2.1 2.1 0 00-.052-1.362 1.085 1.085 0 00-1.141-.653.7.7 0 00-.441 1.066z"
                    data-name="Path 858"
                    transform="translate(215.741 55.916)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M184.476 62.082a3.684 3.684 0 002.853 1.362q-.117-1.3-.238-2.609a2.329 2.329 0 00-.328-1.177 1.476 1.476 0 00-2.164-.011 1.926 1.926 0 00-.11 2.256z"
                    data-name="Path 859"
                    transform="translate(213.938 65.033)"
                  ></path>
                  <g
                    data-name="Group 1321"
                    opacity="0.5"
                    transform="translate(416.115 56.183)"
                  >
                    <g data-name="Group 1320">
                      <g clipPath="url(#clip-path-6)" data-name="Group 1319">
                        <path
                          fill="#e5f6ff"
                          d="M194.017 27.75c-.006-.294-.015-.588-.022-.886a1.129 1.129 0 00-1.34.321.88.88 0 00.292 1.257.79.79 0 001.107-.59z"
                          data-name="Path 860"
                          transform="translate(-192.504 -26.768)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <path
                    fill="#e5f6ff"
                    d="M188.413 55.451c-.5-.311-1.152.254-1.23.827a16.047 16.047 0 00.14 1.722 2.059 2.059 0 01-.793 1.547 1.048 1.048 0 01-.99.233 1.262 1.262 0 01-.512-.474 6.13 6.13 0 01-1.327-3.117 2.9 2.9 0 011.541-2.844 2.417 2.417 0 012.324.227 2.262 2.262 0 01.927 2.08z"
                    data-name="Path 861"
                    transform="translate(213.375 58.403)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M191.017 60.983a1.466 1.466 0 001.991.008 1.97 1.97 0 00.458-2c-.093-.3-.329-.638-.653-.6a.8.8 0 00-.5.4c-.523.724-1.044 1.452-1.567 2.179z"
                    data-name="Path 862"
                    transform="translate(221.562 64.161)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M199.068 47.179a2.321 2.321 0 01-.984-3.6 6.03 6.03 0 012.717 2.072 2 2 0 01-.789 2.882l-1.9-.733z"
                    data-name="Path 863"
                    transform="translate(229.498 47.884)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M167.894 90.9a.633.633 0 01-.865.256 1.422 1.422 0 01-.612-.772 1.689 1.689 0 01-.164-.785 1.21 1.21 0 011.1-1.016 1.559 1.559 0 011.39.7.946.946 0 01.173.739.543.543 0 01-.592.409z"
                    data-name="Path 864"
                    transform="translate(193.111 97.334)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M189.618 50.615l.951-.025a1.9 1.9 0 00-.21-1.379c-.294-.38-1.053-.4-1.182.061a.943.943 0 00.058.527c.171.548.339 1.1.51 1.643z"
                    data-name="Path 865"
                    transform="translate(219.722 53.765)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M193.028 44.624a5.828 5.828 0 002.136-.884 1.881 1.881 0 00.763-2 1.7 1.7 0 00-.72-.854 2.289 2.289 0 00-3.048.728 2.151 2.151 0 00.923 2.913z"
                    data-name="Path 866"
                    transform="translate(222.913 44.597)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M196.386 42.423q.525-.579 1.048-1.159a2.769 2.769 0 00-.882-.8 1.434 1.434 0 00-.588 1.952z"
                    data-name="Path 867"
                    transform="translate(227.42 44.464)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M205.906 69.924a19.025 19.025 0 002.1-8.6 3.36 3.36 0 00-.22-1.413 2.877 2.877 0 00-2.324-1.413 5.917 5.917 0 00-5.761 3.085 6.9 6.9 0 00.234 6.532 4.86 4.86 0 002.808 2.412 2.793 2.793 0 003.255-1.31.607.607 0 00-1-.017z"
                    data-name="Path 868"
                    transform="translate(231.135 64.239)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M173.627 59.74a4.8 4.8 0 003.666-2.009 4.931 4.931 0 00-1.664-.974 1.712 1.712 0 00-1.783.453c-.536.693-.166 1.673.218 2.456l-.7-.237z"
                    data-name="Path 869"
                    transform="translate(201.381 62.289)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M177.805 56a1.371 1.371 0 00.882-1.906 16.977 16.977 0 01-.365-3.115q-1.686 2.037-3.37 4.076a1.088 1.088 0 01-.309.283c-.439.229-.936-.2-1.431-.241-.724-.059-1.217.7-1.424 1.377-1.476 4.823 4.075.575 6.018-.474"
                    data-name="Path 870"
                    transform="translate(199.258 56.018)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M173.722 64.366a3.738 3.738 0 003.126-.569l-1.8-1.952a2.426 2.426 0 00-.99-.749 1.439 1.439 0 00-1.738 1.251 1.972 1.972 0 001.3 1.87z"
                    data-name="Path 871"
                    transform="translate(200.156 67.083)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M177.822 59.646a1.43 1.43 0 001.6-1.152 1.987 1.987 0 00-.867-1.87c-.264-.189-.657-.321-.893-.1a.769.769 0 00-.158.613l.091 2.657z"
                    data-name="Path 872"
                    transform="translate(206.18 61.986)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M181.911 64.762a5.827 5.827 0 002.136-.884 1.881 1.881 0 00.763-2 1.7 1.7 0 00-.72-.854 2.288 2.288 0 00-3.048.728 2.151 2.151 0 00.923 2.913z"
                    data-name="Path 873"
                    transform="translate(210 66.727)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M197.656 54.061c.123-.758-.646-1.35-1.342-1.715s-1.509-.823-1.569-1.589a.659.659 0 01.208-.571c.432-.342 1 .181 1.5.405a6.1 6.1 0 001.3.243 2.6 2.6 0 012.047 2.106 1.492 1.492 0 01-1.273.951 1.507 1.507 0 01-1.4-.76z"
                    data-name="Path 874"
                    transform="translate(226.208 55.025)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M196.582 45.069c.123-.756-.646-1.347-1.342-1.713s-1.509-.825-1.569-1.589a.656.656 0 01.208-.571c.432-.342 1 .18 1.5.4a5.979 5.979 0 001.3.246 2.6 2.6 0 012.043 2.105 1.492 1.492 0 01-1.273.951 1.511 1.511 0 01-1.4-.76z"
                    data-name="Path 875"
                    transform="translate(224.96 45.146)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M200.091 39.22a4.122 4.122 0 001.133-1.5.686.686 0 00.076-.29.583.583 0 00-.329-.458 1.268 1.268 0 00-1.377.136 1.466 1.466 0 00-.506 1.282.981.981 0 00.339.691.594.594 0 00.741.006.126.126 0 00-.192-.082z"
                    data-name="Path 876"
                    transform="translate(231.246 40.472)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M174.468 74.487a4.8 4.8 0 003.666-2.009 4.965 4.965 0 00-1.664-.974 1.712 1.712 0 00-1.783.453c-.536.693-.166 1.675.218 2.456l-.7-.235z"
                    data-name="Path 877"
                    transform="translate(202.357 78.494)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M169.45 69.517a1.3 1.3 0 002.188.176 2.183 2.183 0 00-.88-1.06 1.1 1.1 0 00-1.31.145.693.693 0 00.3 1.11z"
                    data-name="Path 878"
                    transform="translate(196.635 75.258)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M179.157 63.851a1.3 1.3 0 002.185.176 2.156 2.156 0 00-.878-1.06 1.108 1.108 0 00-1.312.145.694.694 0 00.3 1.11z"
                    data-name="Path 879"
                    transform="translate(207.909 69.032)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M173.485 71.909c-.594.042-.765.875-.476 1.379s.832.825 1.176 1.3a2.023 2.023 0 01.32 1.7 1 1 0 01-.648.762 1.286 1.286 0 01-.7-.082 6.448 6.448 0 01-2.985-1.723 2.851 2.851 0 01-.521-3.174 2.339 2.339 0 014.025-.044z"
                    data-name="Path 880"
                    transform="translate(196.73 77.91)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M196.249 49.4c-.594.046-.765.875-.475 1.381s.832.823 1.176 1.3a2.019 2.019 0 01.32 1.7 1 1 0 01-.648.764 1.306 1.306 0 01-.7-.082 6.447 6.447 0 01-2.985-1.723 2.854 2.854 0 01-.521-3.176 2.341 2.341 0 014.025-.044z"
                    data-name="Path 881"
                    transform="translate(223.173 53.174)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M199.122 51.517c-.592.044-.767.875-.476 1.379s.83.825 1.174 1.3a2.031 2.031 0 01.322 1.7 1.006 1.006 0 01-.651.762 1.29 1.29 0 01-.7-.082 6.448 6.448 0 01-2.991-1.726 2.85 2.85 0 01-.521-3.174 2.339 2.339 0 014.025-.044z"
                    data-name="Path 882"
                    transform="translate(226.506 55.501)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M195.012 55.17c-.594.044-.765.875-.476 1.379s.832.825 1.176 1.3a2.023 2.023 0 01.32 1.7 1 1 0 01-.648.762 1.306 1.306 0 01-.7-.08A6.469 6.469 0 01191.7 58.5a2.851 2.851 0 01-.521-3.174 2.339 2.339 0 014.025-.044z"
                    data-name="Path 883"
                    transform="translate(221.736 59.516)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M175.895 21.755c-.6.042-.765.875-.475 1.379s.83.825 1.174 1.3a2.023 2.023 0 01.32 1.7 1 1 0 01-.646.762 1.3 1.3 0 01-.7-.082 6.447 6.447 0 01-2.985-1.723 2.855 2.855 0 01-.521-3.174 2.339 2.339 0 014.025-.044z"
                    data-name="Path 884"
                    transform="translate(199.528 22.796)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M172.017 66.5l.748-.573a1.986 1.986 0 00-1.02-.982c-.471-.134-1.089.294-.908.737a.981.981 0 00.372.388c.473.34.947.678 1.422 1.018z"
                    data-name="Path 885"
                    transform="translate(198.402 71.339)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M180.448 40.125a5.826 5.826 0 002.136-.884 1.88 1.88 0 00.763-2 1.7 1.7 0 00-.72-.854 2.288 2.288 0 00-3.048.728 2.151 2.151 0 00.923 2.913z"
                    data-name="Path 886"
                    transform="translate(208.301 39.653)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M183.638 69.157a1.6 1.6 0 01-2.663-.376 4.216 4.216 0 01.921-1.545 2.721 2.721 0 011.345 1.436z"
                    data-name="Path 887"
                    transform="translate(210.217 73.885)"
                  ></path>
                  <g
                    data-name="Group 1324"
                    opacity="0.5"
                    transform="translate(394.332 71.721)"
                  >
                    <g data-name="Group 1323">
                      <g clipPath="url(#clip-path-7)" data-name="Group 1322">
                        <path
                          fill="#e5f6ff"
                          d="M186 38.478a3.3 3.3 0 001.1-.722 1.074 1.074 0 00.205-1.207c-.367-.623-1.338-.51-1.956-.9s-.867-1.287-1.587-1.452-1.364.59-1.34 1.3a3.079 3.079 0 00.977 1.855 3.548 3.548 0 001.543 1.125 1.429 1.429 0 001.682-.594z"
                          data-name="Path 888"
                          transform="translate(-182.428 -34.171)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <path
                    fill="#e5f6ff"
                    d="M190.052 58.843a9.521 9.521 0 008.94.94c.307-3.022-.542-6.328-2.951-8.263s-6.537-1.866-8.32.625.432 6.777 3.521 6.313z"
                    data-name="Path 889"
                    transform="translate(217.314 55.124)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M184.968 71.146a.788.788 0 01-.169-1.108 1.563 1.563 0 011.107-.565 1.528 1.528 0 01.748.067 1.036 1.036 0 01.5 1.427 1.176 1.176 0 01-1.517.407 1.2 1.2 0 01-.523-1.473z"
                    data-name="Path 890"
                    transform="translate(214.483 76.324)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M188.615 64.11c-.143-.756-1.07-1.064-1.85-1.186s-1.705-.292-2.023-.995a.648.648 0 010-.607c.29-.46 1-.151 1.556-.1a6.022 6.022 0 001.314-.189 2.668 2.668 0 012.644 1.329 1.45 1.45 0 01-.875 1.306 1.53 1.53 0 01-1.581-.268z"
                    data-name="Path 891"
                    transform="translate(214.505 67.045)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M185.279 51.673c.175-.241.352-.481.53-.722a1.12 1.12 0 00-1.273-.523A.886.886 0 00184 51.6a.8.8 0 001.249.172z"
                    data-name="Path 892"
                    transform="translate(213.638 55.364)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M183.653 60.132a.633.633 0 01-.849-.3 1.372 1.372 0 01-.015-.974 1.706 1.706 0 01.354-.724 1.251 1.251 0 011.507-.174 1.488 1.488 0 01.679 1.371.956.956 0 01-.318.693.551.551 0 01-.724-.017z"
                    data-name="Path 893"
                    transform="translate(212.227 63.503)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M172.093 57.783a9.135 9.135 0 006.13 6.391c2.265-2.093 3.854-5.132 3.333-8.125s-3.683-5.562-6.712-4.811-4.239 5.4-1.591 7.01z"
                    data-name="Path 894"
                    transform="translate(199.553 56.161)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M175.045 66.652c.895-.189.713-1.656-.13-2.007a3.4 3.4 0 00-2.626.405 3.192 3.192 0 002.8 2.141z"
                    data-name="Path 895"
                    transform="translate(200.127 70.886)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M189.536 68.408c.175-.241.352-.481.53-.722a1.12 1.12 0 00-1.273-.523.886.886 0 00-.54 1.175.8.8 0 001.25.172z"
                    data-name="Path 896"
                    transform="translate(218.582 73.754)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M186.007 68.688a21.291 21.291 0 006.314-7.361 3.631 3.631 0 00.489-1.473 3 3 0 00-1.55-2.46 6.528 6.528 0 00-7.125.29 7.576 7.576 0 00-3.024 6.446 5.093 5.093 0 001.524 3.658 3.063 3.063 0 003.811.256.651.651 0 00-.964-.487z"
                    data-name="Path 897"
                    transform="translate(210.366 62.056)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M172.5 46.154a9.342 9.342 0 007.741-4.45c-1.621-2.6-4.338-4.752-7.462-4.9s-6.383 2.307-6.273 5.34 4.524 5.176 6.712 3.008z"
                    data-name="Path 898"
                    transform="translate(193.412 40.438)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M196.641 40a4.8 4.8 0 003.666-2.009 4.931 4.931 0 00-1.664-.974 1.711 1.711 0 00-1.783.453c-.536.693-.166 1.675.218 2.456l-.7-.235z"
                    data-name="Path 899"
                    transform="translate(228.113 40.597)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M200.819 36.257a1.868 1.868 0 00.9-.774 1.875 1.875 0 00-.015-1.131 16.977 16.977 0 01-.365-3.115q-1.686 2.037-3.37 4.076a1.1 1.1 0 01-.309.285c-.439.227-.936-.2-1.431-.243-.724-.059-1.217.7-1.424 1.377-1.476 4.823 4.074.575 6.018-.474"
                    data-name="Path 900"
                    transform="translate(225.991 34.325)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M189.13 62.4q.525-.573 1.051-1.144a2.58 2.58 0 00-2.927-.271 1.444 1.444 0 00.192 2.47c.618.193 1.669.252 1.461.85z"
                    data-name="Path 901"
                    transform="translate(216.812 66.632)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M189.168 42.1a2.15 2.15 0 01-.981.835c-.283-1.066.439-2.122 1.165-2.968.4-.472.988-.978 1.587-.781.417.139.642.567.981.84.681.544 1.939.615 2.034 1.467a1.062 1.062 0 01-.873 1.026 3.243 3.243 0 01-1.476-.1q-1.592-.356-3.18-.714z"
                    data-name="Path 902"
                    transform="translate(218.521 43.009)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M183.4 37.355c.893-.189.709-1.656-.132-2.007a3.4 3.4 0 00-2.626.405 3.191 3.191 0 002.8 2.141z"
                    data-name="Path 903"
                    transform="translate(209.833 38.692)"
                  ></path>
                  <g
                    data-name="Group 1327"
                    opacity="0.5"
                    transform="translate(409.976 65.424)"
                  >
                    <g data-name="Group 1326">
                      <g clipPath="url(#clip-path-8)" data-name="Group 1325">
                        <path
                          fill="#e5f6ff"
                          d="M190.485 33.465a.819.819 0 01-.819-.789 1.727 1.727 0 011.178-1.478 1.08 1.08 0 011.28.852 1.133 1.133 0 01-.966 1.211 1.249 1.249 0 01-1.325-.877z"
                          data-name="Path 904"
                          transform="translate(-189.666 -31.171)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <path
                    fill="#e5f6ff"
                    d="M191.622 35.03a1.3 1.3 0 002.188.176 2.173 2.173 0 00-.88-1.06 1.1 1.1 0 00-1.31.145.693.693 0 00.3 1.11z"
                    data-name="Path 905"
                    transform="translate(222.39 37.361)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M196.736 44.625a3.738 3.738 0 003.126-.569l-1.8-1.95a2.413 2.413 0 00-.99-.751 1.439 1.439 0 00-1.738 1.251 1.973 1.973 0 001.3 1.87z"
                    data-name="Path 906"
                    transform="translate(226.888 45.39)"
                  ></path>
                  <g
                    data-name="Group 1330"
                    opacity="0.5"
                    transform="translate(396.371 63.122)"
                  >
                    <g data-name="Group 1329">
                      <g clipPath="url(#clip-path-9)" data-name="Group 1328">
                        <path
                          fill="#e5f6ff"
                          d="M185.079 33.031a1.675 1.675 0 01-1.55-.884 1.582 1.582 0 01.231-1.729c.283-.319.852-.5 1.131-.172a.93.93 0 01.169.5l.324 2.315c-.141 0-.279 0-.419-.008z"
                          data-name="Path 907"
                          transform="translate(-183.372 -30.074)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 1333"
                    opacity="0.5"
                    transform="translate(389.151 51.437)"
                  >
                    <g data-name="Group 1332">
                      <g clipPath="url(#clip-path-10)" data-name="Group 1331">
                        <path
                          fill="#e5f6ff"
                          d="M182.945 28.495c.123-.758-.646-1.35-1.342-1.715s-1.509-.823-1.569-1.589a.663.663 0 01.208-.571c.432-.342 1 .181 1.5.405a6.1 6.1 0 001.3.243 2.6 2.6 0 012.043 2.105 1.491 1.491 0 01-1.271.951 1.508 1.508 0 01-1.4-.76z"
                          data-name="Path 908"
                          transform="translate(-180.031 -24.507)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 1336"
                    opacity="0.5"
                    transform="translate(425.9 111.333)"
                  >
                    <g data-name="Group 1335">
                      <g clipPath="url(#clip-path-10)" data-name="Group 1334">
                        <path
                          fill="#e5f6ff"
                          d="M199.946 57.032c.123-.758-.646-1.35-1.343-1.715s-1.509-.823-1.569-1.589a.657.657 0 01.208-.571c.432-.342 1 .178 1.5.405a6.1 6.1 0 001.3.243 2.607 2.607 0 012.043 2.105 1.486 1.486 0 01-1.273.949 1.5 1.5 0 01-1.4-.758z"
                          data-name="Path 909"
                          transform="translate(-197.032 -53.044)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 1339"
                    opacity="0.5"
                    transform="translate(397.884 50.453)"
                  >
                    <g data-name="Group 1338">
                      <g clipPath="url(#clip-path-12)" data-name="Group 1337">
                        <path
                          fill="#e5f6ff"
                          d="M185.263 25.452l.746-.512a1.094 1.094 0 00-1.022-.9.9.9 0 00-.91.934.791.791 0 001.12.565z"
                          data-name="Path 910"
                          transform="translate(-184.07 -24.037)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <path
                    fill="#e5f6ff"
                    d="M195.657 37.422c-.595.042-.765.875-.476 1.379s.832.825 1.176 1.3a2.023 2.023 0 01.32 1.7 1 1 0 01-.648.762 1.287 1.287 0 01-.7-.082 6.448 6.448 0 01-2.985-1.723 2.851 2.851 0 01-.521-3.174 2.339 2.339 0 014.025-.044z"
                    data-name="Path 911"
                    transform="translate(222.485 40.013)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M200.836 39.905a1.43 1.43 0 001.6-1.152 1.982 1.982 0 00-.867-1.87c-.262-.189-.657-.321-.893-.1a.77.77 0 00-.158.613l.091 2.657z"
                    data-name="Path 912"
                    transform="translate(232.912 40.293)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M182.95 44.077a2.321 2.321 0 01-.984-3.6 6.03 6.03 0 012.717 2.072 2 2 0 01-.789 2.882l-1.9-.733z"
                    data-name="Path 913"
                    transform="translate(210.775 44.475)"
                  ></path>
                  <g
                    data-name="Group 1342"
                    opacity="0.5"
                    transform="translate(417.133 63.875)"
                  >
                    <g data-name="Group 1341">
                      <g clipPath="url(#clip-path-13)" data-name="Group 1340">
                        <path
                          fill="#e5f6ff"
                          d="M194.189 32.013q.373-.287.748-.573a1.986 1.986 0 00-1.02-.982c-.471-.134-1.089.294-.908.737a.982.982 0 00.372.388q.71.507 1.42 1.018z"
                          data-name="Path 914"
                          transform="translate(-192.976 -30.433)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <path
                    fill="#e5f6ff"
                    d="M179.578 69.228a5.863 5.863 0 002.136-.884 1.881 1.881 0 00.763-2 1.706 1.706 0 00-.72-.854 2.288 2.288 0 00-3.048.728 2.151 2.151 0 00.923 2.913z"
                    data-name="Path 915"
                    transform="translate(207.29 71.634)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M173.48 47.543l1.046-1.159a2.723 2.723 0 00-.882-.8 1.433 1.433 0 00-.588 1.952z"
                    data-name="Path 916"
                    transform="translate(200.81 50.091)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M167.755 83.331a.818.818 0 01-.817-.789 1.727 1.727 0 011.178-1.478 1.081 1.081 0 011.28.852 1.135 1.135 0 01-.966 1.211 1.249 1.249 0 01-1.325-.877z"
                    data-name="Path 917"
                    transform="translate(193.91 89.05)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M179.417 46.289q.525-.579 1.048-1.159a2.77 2.77 0 00-.882-.8 1.434 1.434 0 00-.583 1.951z"
                    data-name="Path 918"
                    transform="translate(207.709 48.713)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M194.734 60.637c.579.128 1-.617.871-1.182a16.474 16.474 0 00-.731-1.576 2.032 2.032 0 01.21-1.717 1.026 1.026 0 01.852-.542 1.294 1.294 0 01.646.277 6.3 6.3 0 012.33 2.5 2.852 2.852 0 01-.463 3.182 2.4 2.4 0 01-2.265.546 2.314 2.314 0 01-1.6-1.654z"
                    data-name="Path 919"
                    transform="translate(226.03 61.12)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M181.227 60.357a3.4 3.4 0 00-1.275.376 1.084 1.084 0 00-.56 1.094c.16.7 1.122.871 1.6 1.421s.439 1.478 1.076 1.843a1.259 1.259 0 001.675-.861 3.03 3.03 0 00-.374-2.053 3.461 3.461 0 00-1.133-1.515 1.44 1.44 0 00-1.788.088z"
                    data-name="Path 920"
                    transform="translate(208.355 66.326)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M192.328 48.612a16.94 16.94 0 00-3.037 7.224 2.9 2.9 0 00-.006 1.257 2.509 2.509 0 001.827 1.543 5.274 5.274 0 005.458-1.927 6.091 6.091 0 00.713-5.726 4.247 4.247 0 00-2.108-2.477 2.487 2.487 0 00-3.024.712.534.534 0 00.871.147z"
                    data-name="Path 921"
                    transform="translate(219.79 53.056)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M198.866 58.321a1.9 1.9 0 001.139.369 1.981 1.981 0 001-.573 17.975 17.975 0 012.6-1.858l-5.318-.812a1.119 1.119 0 01-.408-.12c-.422-.256-.285-.888-.5-1.324-.307-.64-1.23-.678-1.939-.516-5.043 1.154 1.513 3.719 3.417 4.834"
                    data-name="Path 922"
                    transform="translate(224.859 58.688)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M191.569 49.281l.78-.46a1.091 1.091 0 00-.953-.97.91.91 0 00-.977.871.79.79 0 001.076.64z"
                    data-name="Path 923"
                    transform="translate(221.186 52.58)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M196.942 52.766a1.448 1.448 0 001.824.772 2.009 2.009 0 001.239-1.66c.039-.315-.041-.714-.355-.8a.835.835 0 00-.625.172l-2.33 1.4z"
                    data-name="Path 924"
                    transform="translate(228.478 56.109)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M193.493 49.34a4.8 4.8 0 003.618 2.091 4.671 4.671 0 00.041-1.887 1.652 1.652 0 00-1.291-1.276c-.886-.109-1.58.691-2.086 1.4l-.136-.705z"
                    data-name="Path 925"
                    transform="translate(224.757 53.029)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M193.239 46.406A2.231 2.231 0 01192 46c.813-.77 2.114-.688 3.232-.5.623.107 1.364.346 1.485.949.084.42-.186.823-.259 1.245-.149.844.413 1.939-.3 2.443a1.109 1.109 0 01-1.351-.225 3.061 3.061 0 01-.646-1.3q-.47-1.518-.943-3.033z"
                    data-name="Path 926"
                    transform="translate(223.029 49.856)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M194.213 54.632a3.573 3.573 0 002.064 2.351l.845-2.487a2.279 2.279 0 00.179-1.207 1.459 1.459 0 00-1.982-.844 1.955 1.955 0 00-1.022 2.028z"
                    data-name="Path 927"
                    transform="translate(225.594 57.495)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M200.782 49.894c-.333-.479-1.163-.21-1.468.285a16.715 16.715 0 00-.575 1.635 2.132 2.132 0 01-1.36 1.117 1.053 1.053 0 01-1.005-.17 1.224 1.224 0 01-.277-.632 6.075 6.075 0 01.056-3.371 2.994 2.994 0 012.577-2.019 2.377 2.377 0 012.041 1.1 2.229 2.229 0 010 2.267z"
                    data-name="Path 928"
                    transform="translate(227.539 51.361)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M181.119 54.47a5.743 5.743 0 001.85 1.36 2.013 2.013 0 002.166-.351 1.682 1.682 0 00.406-1.033 2.156 2.156 0 10-4.31.021z"
                    data-name="Path 929"
                    transform="translate(210.384 57.409)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M185.992 60.275a4.8 4.8 0 003.619 2.091 4.678 4.678 0 00.041-1.889 1.652 1.652 0 00-1.29-1.274c-.886-.109-1.58.691-2.086 1.4l-.136-.705z"
                    data-name="Path 930"
                    transform="translate(216.044 65.046)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M194.177 46.76c.177-.747-.547-1.389-1.215-1.8s-1.446-.926-1.45-1.694a.662.662 0 01.249-.554c.456-.313.984.246 1.472.506a6.037 6.037 0 001.282.332 2.593 2.593 0 011.885 2.24 1.477 1.477 0 01-2.68.008z"
                    data-name="Path 931"
                    transform="translate(222.456 46.833)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M171.218 62.437c.115-.275.227-.548.342-.821a1.129 1.129 0 00-1.362-.222.877.877 0 00-.244 1.266.8.8 0 001.256-.115z"
                    data-name="Path 932"
                    transform="translate(197.21 67.312)"
                  ></path>
                  <path
                    fill="#e5f6ff"
                    d="M190.894 54.369l.884.344a1.914 1.914 0 00.37-1.348c-.115-.462-.8-.77-1.109-.4a.959.959 0 00-.162.506c-.067.569-.136 1.138-.2 1.7z"
                    data-name="Path 933"
                    transform="translate(221.483 58.023)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M124.668 162.383a34.639 34.639 0 00-14.02-6.441 6.151 6.151 0 00-2.5-.143 4.8 4.8 0 00-3.249 3.258 9.552 9.552 0 003.253 10.415 12.713 12.713 0 0011.294 1.939 8.348 8.348 0 005.134-3.69 4.482 4.482 0 00-1.092-5.732.976.976 0 00-.387 1.614z"
                    data-name="Path 934"
                    transform="translate(121.286 171.12)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M109.947 162.379a6.233 6.233 0 00-4.217-4.326 6.373 6.373 0 00-2.226 5.558c.372 2.038 2.544 3.774 4.6 3.245s2.86-3.7 1.044-4.79z"
                    data-name="Path 935"
                    transform="translate(120.14 173.682)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M99.427 157.595a3.078 3.078 0 00-.272-2.775 3.321 3.321 0 00-1.085.737 1.085 1.085 0 00-.2 1.2c.294.516 1.027.565 1.634.548l-.344.357z"
                    data-name="Path 936"
                    transform="translate(113.555 170.13)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M101.5 158.207a1.484 1.484 0 01.72.506 2.563 2.563 0 01-2.209-.3c-.383-.191-.817-.489-.772-.907.03-.29.29-.5.424-.76.27-.523.13-1.345.7-1.534a.743.743 0 01.834.407 2.017 2.017 0 01.156.97c0 .72-.006 1.44-.009 2.16z"
                    data-name="Path 937"
                    transform="translate(115.272 170.527)"
                  ></path>
                  <g
                    data-name="Group 1345"
                    opacity="0.5"
                    transform="translate(168.304 311.458)"
                  >
                    <g data-name="Group 1344">
                      <g clipPath="url(#clip-path-14)" data-name="Group 1343">
                        <path
                          fill="#fff"
                          d="M79.236 148.669c-.264-.55-1.243-.21-1.355.386a2.177 2.177 0 00.677 1.637 2.105 2.105 0 001.042-2.13z"
                          data-name="Path 938"
                          transform="translate(-77.862 -148.392)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <path
                    fill="#fff"
                    d="M74.714 158.16a.548.548 0 01-.417.646 1.07 1.07 0 01-.832-.183.987.987 0 01-.359-.355.711.711 0 01.391-.955.8.8 0 01.975.441.817.817 0 01-.4.989z"
                    data-name="Path 939"
                    transform="translate(84.839 172.825)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M95 158.821a.846.846 0 00.72-1.274 1.529 1.529 0 00-.923.239.7.7 0 00-.272.831.5.5 0 00.8.124z"
                    data-name="Path 940"
                    transform="translate(109.738 173.126)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M102.365 158.786a2.36 2.36 0 00.5-2.049l-1.751.548a1.663 1.663 0 00-.757.39.945.945 0 00.32 1.4 1.42 1.42 0 001.563-.271z"
                    data-name="Path 941"
                    transform="translate(116.348 172.236)"
                  ></path>
                  <g
                    data-name="Group 1348"
                    opacity="0.5"
                    transform="translate(167.286 329.141)"
                  >
                    <g data-name="Group 1347">
                      <g clipPath="url(#clip-path-15)" data-name="Group 1346">
                        <path
                          fill="#fff"
                          d="M78.01 156.863l-.6.149a.727.727 0 00.422.816.634.634 0 00.817-.378.53.53 0 00-.571-.628z"
                          data-name="Path 942"
                          transform="translate(-77.39 -156.817)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <path
                    fill="#fff"
                    d="M97.433 157.673c-.136.372.348.705.752.67s.761-.275 1.159-.351a1.5 1.5 0 011.18.277.672.672 0 01.309.6.861.861 0 01-.246.4 4.377 4.377 0 01-1.935 1.327 2.089 2.089 0 01-2.181-.565 1.52 1.52 0 01-.2-1.534 1.609 1.609 0 011.286-.913z"
                    data-name="Path 943"
                    transform="translate(111.686 173.175)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M100.677 154.269a.935.935 0 00-.3-1.286 1.45 1.45 0 00-1.44.006c-.195.107-.389.309-.313.512.052.141.21.21.35.267.577.227 1.152.453 1.729.682z"
                    data-name="Path 944"
                    transform="translate(114.545 167.904)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M90.165 151.422a1.658 1.658 0 01-2.319 1.18 3.945 3.945 0 011.007-2.068 1.454 1.454 0 012.092.076l-.214 1.339z"
                    data-name="Path 945"
                    transform="translate(102.04 164.975)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M123.992 165.1a.414.414 0 01.307.518.972.972 0 01-.437.512 1.184 1.184 0 01-.514.225.833.833 0 01-.86-.556 1 1 0 01.27-1 .689.689 0 01.48-.222.366.366 0 01.37.319z"
                    data-name="Path 946"
                    transform="translate(142.228 180.844)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M93.853 156.308l-.162-.611a1.392 1.392 0 00-.912.344c-.216.248-.112.739.22.753a.677.677 0 00.352-.117q.525-.286 1.048-.577z"
                    data-name="Path 947"
                    transform="translate(107.638 171.093)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M89.545 155.876a3.828 3.828 0 00-.927-1.245 1.366 1.366 0 00-1.483-.191 1.15 1.15 0 00-.478.594 1.48 1.48 0 00.96 1.857 1.537 1.537 0 001.855-1.037z"
                    data-name="Path 948"
                    transform="translate(100.568 169.554)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M87.4 153.061l-.951-.5a1.871 1.871 0 00-.417.69 1.045 1.045 0 001.427.084z"
                    data-name="Path 949"
                    transform="translate(99.928 167.645)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M104.889 145.518a14.084 14.084 0 00-6.208-.052 2.439 2.439 0 00-.932.355 1.868 1.868 0 00-.618 1.713 3.936 3.936 0 002.983 3.253 5.076 5.076 0 004.44-1.138 3.22 3.22 0 001.226-2.177 1.834 1.834 0 00-1.388-1.906.391.391 0 00.141.651z"
                    data-name="Path 950"
                    transform="translate(112.812 159.498)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M102.468 166.36a3.188 3.188 0 00-1.93-2.063 3.236 3.236 0 00-.415 1.222 1.106 1.106 0 00.581 1.083c.553.241 1.169-.145 1.647-.512-.017.162-.037.323-.056.487z"
                    data-name="Path 951"
                    transform="translate(116.299 180.544)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M99.78 165.615a1.273 1.273 0 00-.666-.462 1.371 1.371 0 00-.772.18 12.455 12.455 0 01-2.079.705l3.3 1.562a.692.692 0 01.242.157c.223.248 0 .634.05.959.069.476.664.68 1.159.712 3.526.225-.221-2.716-1.234-3.814"
                    data-name="Path 952"
                    transform="translate(111.817 181.474)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M105.5 165.962a2.389 2.389 0 00-.862-1.933l-1.063 1.459a1.6 1.6 0 00-.365.751.978.978 0 001.12.934 1.366 1.366 0 001.085-1.123z"
                    data-name="Path 953"
                    transform="translate(119.885 180.249)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M120.862 169.837c-.2.048-.4.1-.6.149a.728.728 0 00.422.816.635.635 0 00.817-.38.529.529 0 00-.571-.625z"
                    data-name="Path 954"
                    transform="translate(139.673 186.581)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M101.662 162.99a.968.968 0 00-1.031-.856 1.4 1.4 0 00-1.15.842c-.089.2-.119.472.067.592a.568.568 0 00.443.008l1.807-.46z"
                    data-name="Path 955"
                    transform="translate(115.473 178.165)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M104.616 160.226a3.859 3.859 0 00-.927-1.247 1.369 1.369 0 00-1.483-.189 1.151 1.151 0 00-.478.594 1.48 1.48 0 00.96 1.857 1.537 1.537 0 001.855-1.037z"
                    data-name="Path 956"
                    transform="translate(118.074 174.335)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M94.97 151.819c-.538.036-.826.621-.971 1.125s-.337 1.1-.852 1.253a.478.478 0 01-.422-.046c-.3-.227-.028-.674.048-1.033a3.757 3.757 0 00-.028-.879 1.755 1.755 0 011.133-1.637 1.018 1.018 0 01.843.678.97.97 0 01-.309 1.02z"
                    data-name="Path 957"
                    transform="translate(107.548 165.495)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M89.154 153.891c-.538.036-.826.621-.971 1.125s-.337 1.1-.852 1.253a.471.471 0 01-.421-.046c-.3-.227-.028-.674.048-1.033a3.758 3.758 0 00-.028-.88 1.755 1.755 0 011.133-1.637 1.018 1.018 0 01.843.678.971.971 0 01-.309 1.02z"
                    data-name="Path 958"
                    transform="translate(100.793 167.772)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M84.771 151.271a2.915 2.915 0 00-1.2-.5.5.5 0 00-.21-.006.4.4 0 00-.264.283.8.8 0 00.3.867 1.069 1.069 0 00.955.132.694.694 0 00.419-.323.376.376 0 00-.106-.479.08.08 0 00-.028.137z"
                    data-name="Path 959"
                    transform="translate(96.488 165.659)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M112.147 163.573a3.2 3.2 0 00-1.93-2.065 3.247 3.247 0 00-.415 1.224 1.106 1.106 0 00.581 1.083c.553.241 1.169-.145 1.647-.512-.017.162-.037.323-.056.485z"
                    data-name="Path 960"
                    transform="translate(127.542 177.479)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M109.485 167.141a.836.836 0 00-.21-1.44 1.477 1.477 0 00-.592.728.706.706 0 00.3.825c.285.149.72-.048.713-.363z"
                    data-name="Path 961"
                    transform="translate(126.207 182.087)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M104.25 161.542a.835.835 0 00-.21-1.438 1.469 1.469 0 00-.592.728.7.7 0 00.3.823c.285.149.72-.048.715-.363z"
                    data-name="Path 962"
                    transform="translate(120.126 175.936)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M110.59 165.445c.119.376.716.363 1.016.1s.439-.663.711-.955a1.48 1.48 0 011.115-.464.7.7 0 01.62.3.812.812 0 01.05.466 4.15 4.15 0 01-.728 2.187 2.076 2.076 0 01-2.095.816 1.584 1.584 0 01-1.1-1.114 1.531 1.531 0 01.467-1.478z"
                    data-name="Path 963"
                    transform="translate(127.931 180.353)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M92.182 153.71c.119.376.715.363 1.016.1s.439-.663.711-.955a1.479 1.479 0 011.115-.464.7.7 0 01.62.3.812.812 0 01.05.466 4.15 4.15 0 01-.728 2.187 2.076 2.076 0 01-2.095.816 1.584 1.584 0 01-1.1-1.115 1.532 1.532 0 01.467-1.478z"
                    data-name="Path 964"
                    transform="translate(106.548 167.458)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M93.157 151.48c.119.376.713.363 1.016.1s.439-.663.711-.955a1.479 1.479 0 011.116-.466.708.708 0 01.62.3.812.812 0 01.05.466 4.15 4.15 0 01-.729 2.187 2.076 2.076 0 01-2.095.817 1.584 1.584 0 01-1.1-1.115 1.531 1.531 0 01.467-1.478z"
                    data-name="Path 965"
                    transform="translate(107.68 165.007)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M96.207 153.657c.119.376.715.365 1.016.1s.439-.663.711-.955a1.479 1.479 0 011.115-.464.694.694 0 01.62.3.81.81 0 01.05.466 4.157 4.157 0 01-.728 2.187 2.076 2.076 0 01-2.095.816 1.587 1.587 0 01-1.1-1.117 1.528 1.528 0 01.467-1.475z"
                    data-name="Path 966"
                    transform="translate(111.223 167.403)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M76.875 171.423c.119.376.715.363 1.016.1s.439-.663.711-.955a1.479 1.479 0 011.115-.464.7.7 0 01.62.3.812.812 0 01.05.466 4.15 4.15 0 01-.728 2.187 2.078 2.078 0 01-2.1.816 1.585 1.585 0 01-1.1-1.114 1.535 1.535 0 01.465-1.478z"
                    data-name="Path 967"
                    transform="translate(88.768 186.923)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M107.088 165.807l-.506-.395a1.321 1.321 0 00-.519.806c-.019.323.365.659.642.474a.65.65 0 00.21-.3c.16-.357.322-.714.482-1.073z"
                    data-name="Path 968"
                    transform="translate(123.2 181.67)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M88.454 164.921a3.827 3.827 0 00-.927-1.245 1.366 1.366 0 00-1.483-.191 1.148 1.148 0 00-.478.6 1.477 1.477 0 00.96 1.855 1.537 1.537 0 001.854-1.04z"
                    data-name="Path 969"
                    transform="translate(99.301 179.494)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M107.112 157.969a1.029 1.029 0 01.145 1.776 3.036 3.036 0 01-1.2-.361 1.847 1.847 0 01.78-1.085z"
                    data-name="Path 970"
                    transform="translate(123.196 173.59)"
                  ></path>
                  <g
                    data-name="Group 1351"
                    opacity="0.5"
                    transform="translate(181.206 338.364)"
                  >
                    <g data-name="Group 1350">
                      <g clipPath="url(#clip-path-16)" data-name="Group 1349">
                        <path
                          fill="#fff"
                          d="M86.427 161.917a2.24 2.24 0 00-.661-.6.792.792 0 00-.858.05c-.37.329-.147.94-.322 1.4s-.75.754-.754 1.243.61.791 1.092.669a2.176 2.176 0 001.124-.911 2.322 2.322 0 00.536-1.167.928.928 0 00-.661-.995z"
                          data-name="Path 971"
                          transform="translate(-83.829 -161.211)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <path
                    fill="#fff"
                    d="M99.8 159a6 6 0 00-.707-5.917 6.689 6.689 0 00-5.214 3.157c-.96 1.847-.29 4.5 1.686 5.275s4.576-1.3 3.791-3.226z"
                    data-name="Path 972"
                    transform="translate(108.551 168.225)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M108.291 156.667a.571.571 0 01-.733.275 1.038 1.038 0 01-.556-.63.968.968 0 01-.067-.493.742.742 0 01.9-.537.767.767 0 01.51.921.856.856 0 01-.93.558z"
                    data-name="Path 973"
                    transform="translate(124.204 170.611)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M103.241 156.4c-.5.206-.566.852-.532 1.375s.058 1.144-.376 1.457a.481.481 0 01-.415.09c-.359-.117-.255-.625-.3-.991a3.914 3.914 0 00-.328-.819 1.723 1.723 0 01.51-1.91 1.038 1.038 0 011.027.369.968.968 0 01.058 1.062z"
                    data-name="Path 974"
                    transform="translate(117.526 170.968)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M95.233 159.126l-.575-.233a.716.716 0 00-.164.9.638.638 0 00.886.172.515.515 0 00-.071-.833z"
                    data-name="Path 975"
                    transform="translate(109.652 174.606)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M101.158 159.252a.406.406 0 01-.076.594 1.019 1.019 0 01-.664.155 1.26 1.26 0 01-.551-.117.8.8 0 01-.346-.947 1.049 1.049 0 01.837-.647.693.693 0 01.521.1.35.35 0 01.1.47z"
                    data-name="Path 976"
                    transform="translate(115.553 173.932)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M101.69 170.15a6.153 6.153 0 003.45-4.924 6.827 6.827 0 00-6.068-.926c-1.971.789-3.253 3.22-2.278 5.063s4.338 1.92 5.041-.034z"
                    data-name="Path 977"
                    transform="translate(112.047 180.146)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M106.848 164.258c-.264-.55-1.243-.21-1.355.386a2.17 2.17 0 00.677 1.637 2.109 2.109 0 001.042-2.13z"
                    data-name="Path 978"
                    transform="translate(122.515 180.196)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M105.719 153.766l-.575-.233a.719.719 0 00-.164.9.638.638 0 00.886.172.514.514 0 00-.071-.833z"
                    data-name="Path 979"
                    transform="translate(121.833 168.715)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M107.19 159.692a15.017 15.017 0 00-6-2.964 2.661 2.661 0 00-1.081-.092 2.09 2.09 0 00-1.453 1.373 4.14 4.14 0 001.275 4.555 5.5 5.5 0 004.872.98 3.623 3.623 0 002.274-1.538 1.937 1.937 0 00-.4-2.5.422.422 0 00-.188.695z"
                    data-name="Path 980"
                    transform="translate(114.315 172.098)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M94.118 172.893a6.234 6.234 0 00-4.217-4.326 6.373 6.373 0 00-2.226 5.558c.372 2.038 2.544 3.774 4.6 3.245s2.86-3.7 1.044-4.79z"
                    data-name="Path 981"
                    transform="translate(101.754 185.236)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M85.863 154.039a3.188 3.188 0 00-1.93-2.063 3.225 3.225 0 00-.415 1.222 1.106 1.106 0 00.581 1.083c.553.241 1.169-.145 1.647-.512-.017.162-.037.323-.056.485z"
                    data-name="Path 982"
                    transform="translate(97.011 167.005)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M83.175 153.3a1.281 1.281 0 00-.666-.462 1.37 1.37 0 00-.772.181 12.464 12.464 0 01-2.079.705l3.3 1.562a.74.74 0 01.242.155c.223.25 0 .636.05.961.069.476.664.68 1.159.711 3.526.225-.223-2.718-1.234-3.814"
                    data-name="Path 983"
                    transform="translate(92.529 167.935)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M101.872 155.524c-.313-.168-.629-.336-.942-.506a1.637 1.637 0 00.257 1.931c.538.458 1.6.2 1.662-.5.039-.428-.078-1.117.361-1.073z"
                    data-name="Path 984"
                    transform="translate(117.011 170.347)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M88.441 159.466a1.5 1.5 0 01.72.508 2.552 2.552 0 01-2.207-.3c-.385-.189-.819-.487-.774-.907.03-.29.29-.5.424-.758.272-.525.13-1.345.7-1.534a.743.743 0 01.834.407 2.018 2.018 0 01.156.97c0 .72-.007 1.44-.009 2.16z"
                    data-name="Path 985"
                    transform="translate(100.101 171.914)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M86.1 163.128c-.264-.55-1.243-.21-1.355.386a2.169 2.169 0 00.677 1.637 2.109 2.109 0 001.042-2.13z"
                    data-name="Path 986"
                    transform="translate(98.418 178.955)"
                  ></path>
                  <g
                    data-name="Group 1354"
                    opacity="0.5"
                    transform="translate(174.488 331.118)"
                  >
                    <g data-name="Group 1353">
                      <g clipPath="url(#clip-path-17)" data-name="Group 1352">
                        <path
                          fill="#fff"
                          d="M82.4 158.645a.548.548 0 01-.417.646 1.069 1.069 0 01-.832-.183.987.987 0 01-.359-.355.711.711 0 01.391-.955.8.8 0 01.975.441.817.817 0 01-.4.989z"
                          data-name="Path 987"
                          transform="translate(-80.722 -157.759)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <path
                    fill="#fff"
                    d="M83.2 157.609a.836.836 0 00-.21-1.44 1.478 1.478 0 00-.592.728.707.707 0 00.3.825c.285.149.72-.048.713-.363z"
                    data-name="Path 988"
                    transform="translate(95.676 171.612)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M88.9 153.641a2.39 2.39 0 00-.862-1.933l-1.064 1.459a1.6 1.6 0 00-.365.751.977.977 0 001.12.934 1.366 1.366 0 001.085-1.123z"
                    data-name="Path 989"
                    transform="translate(100.597 166.71)"
                  ></path>
                  <g
                    data-name="Group 1357"
                    opacity="0.5"
                    transform="translate(175.017 340.26)"
                  >
                    <g data-name="Group 1356">
                      <g clipPath="url(#clip-path-18)" data-name="Group 1355">
                        <path
                          fill="#fff"
                          d="M82.915 162.316a1.09 1.09 0 01-.372 1.133 1.164 1.164 0 01-1.219.113c-.264-.134-.469-.476-.29-.705a.71.71 0 01.313-.185c.517-.185 1.027-.371 1.539-.558.017.09.037.183.058.273z"
                          data-name="Path 990"
                          transform="translate(-80.968 -162.115)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 1360"
                    opacity="0.5"
                    transform="translate(168.193 344.738)"
                  >
                    <g data-name="Group 1359">
                      <g clipPath="url(#clip-path-19)" data-name="Group 1358">
                        <path
                          fill="#fff"
                          d="M80.193 165.465c-.538.036-.826.621-.971 1.125s-.335 1.1-.852 1.253a.477.477 0 01-.424-.046c-.3-.227-.026-.674.05-1.033a3.757 3.757 0 00-.028-.879 1.755 1.755 0 011.133-1.637 1.018 1.018 0 01.843.678.974.974 0 01-.309 1.02z"
                          data-name="Path 991"
                          transform="translate(-77.81 -164.248)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 1363"
                    opacity="0.5"
                    transform="translate(203.659 311.958)"
                  >
                    <g data-name="Group 1362">
                      <g clipPath="url(#clip-path-19)" data-name="Group 1361">
                        <path
                          fill="#fff"
                          d="M96.6 149.847c-.538.036-.826.621-.971 1.125s-.335 1.1-.852 1.253a.478.478 0 01-.422-.046c-.3-.227-.028-.674.048-1.033a3.756 3.756 0 00-.028-.879 1.755 1.755 0 011.133-1.637 1.018 1.018 0 01.843.678.97.97 0 01-.309 1.02z"
                          data-name="Path 992"
                          transform="translate(-94.218 -148.63)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 1366"
                    opacity="0.5"
                    transform="translate(166.139 341.561)"
                  >
                    <g data-name="Group 1365">
                      <g clipPath="url(#clip-path-21)" data-name="Group 1364">
                        <path
                          fill="#fff"
                          d="M77.8 163.138l-.463-.4a.725.725 0 00-.463.8.629.629 0 00.778.447.517.517 0 00.216-.81z"
                          data-name="Path 993"
                          transform="translate(-76.859 -162.734)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <path
                    fill="#fff"
                    d="M84.309 155.913c.117.378.713.363 1.014.1s.439-.663.711-.955a1.479 1.479 0 011.115-.464.705.705 0 01.62.3.832.832 0 01.052.466 4.171 4.171 0 01-.731 2.187 2.076 2.076 0 01-2.095.817 1.584 1.584 0 01-1.1-1.115 1.531 1.531 0 01.467-1.478z"
                    data-name="Path 994"
                    transform="translate(97.4 169.879)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M85.056 150.67a.968.968 0 00-1.031-.856 1.4 1.4 0 00-1.148.842c-.091.2-.121.472.065.592a.568.568 0 00.443.008l1.807-.46z"
                    data-name="Path 995"
                    transform="translate(96.185 164.627)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M90.538 162.609a1.658 1.658 0 01-2.319 1.179 3.946 3.946 0 011.007-2.067 1.454 1.454 0 012.092.076l-.214 1.339z"
                    data-name="Path 996"
                    transform="translate(102.473 177.268)"
                  ></path>
                  <g
                    data-name="Group 1369"
                    opacity="0.5"
                    transform="translate(172.448 326.985)"
                  >
                    <g data-name="Group 1368">
                      <g clipPath="url(#clip-path-22)" data-name="Group 1367">
                        <path
                          fill="#fff"
                          d="M80.8 156.275l-.506-.395a1.33 1.33 0 00-.519.806c-.02.323.365.659.642.474a.649.649 0 00.21-.3l.484-1.073z"
                          data-name="Path 997"
                          transform="translate(-79.779 -155.79)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <path
                    fill="#fff"
                    d="M107.939 161.1a3.859 3.859 0 00-.927-1.247 1.369 1.369 0 00-1.483-.189 1.151 1.151 0 00-.478.594 1.48 1.48 0 00.96 1.858 1.537 1.537 0 001.855-1.037z"
                    data-name="Path 998"
                    transform="translate(121.934 175.297)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M94.262 167.52c-.318-.168-.633-.336-.951-.5a1.837 1.837 0 00-.415.691 1.04 1.04 0 001.424.084z"
                    data-name="Path 999"
                    transform="translate(107.906 183.534)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M118.994 166.224a.548.548 0 01-.417.646 1.07 1.07 0 01-.832-.183.988.988 0 01-.359-.355.711.711 0 01.391-.955.8.8 0 01.975.441.817.817 0 01-.4.989z"
                    data-name="Path 1000"
                    transform="translate(136.273 181.686)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M92.531 163.761l-.951-.5a1.87 1.87 0 00-.417.691 1.043 1.043 0 001.427.084z"
                    data-name="Path 1001"
                    transform="translate(105.893 179.403)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M99.869 152.685c0-.395-.573-.55-.94-.384a11.591 11.591 0 00-.971.71 1.5 1.5 0 01-1.206.126.7.7 0 01-.5-.468.849.849 0 01.093-.462 4.239 4.239 0 011.362-1.881 2.094 2.094 0 012.248-.183 1.544 1.544 0 01.718 1.381 1.566 1.566 0 01-.893 1.28z"
                    data-name="Path 1002"
                    transform="translate(111.795 164.715)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M101.671 161.618a2.184 2.184 0 00.45.766.788.788 0 00.834.2c.456-.21.426-.856.733-1.245s.945-.508 1.1-.974-.344-.93-.843-.951a2.21 2.21 0 00-1.349.55 2.386 2.386 0 00-.867.961.915.915 0 00.331 1.14z"
                    data-name="Path 1003"
                    transform="translate(118.08 175.174)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M92.452 157.563a12.4 12.4 0 005.4.869 2.192 2.192 0 00.865-.185 1.662 1.662 0 00.778-1.413 3.429 3.429 0 00-2.144-3.232 4.5 4.5 0 00-4.029.4 2.893 2.893 0 00-1.377 1.736 1.6 1.6 0 00.945 1.845.345.345 0 00-.03-.586z"
                    data-name="Path 1004"
                    transform="translate(106.764 168.456)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M97.82 152.095a1.23 1.23 0 00.08-.791 1.335 1.335 0 00-.545-.56 12.041 12.041 0 01-1.664-1.4c.08 1.186.164 2.372.246 3.558a.685.685 0 01-.019.281c-.112.311-.566.319-.834.521-.391.3-.277.9-.061 1.329 1.554 3.083 2.322-1.539 2.8-2.936"
                    data-name="Path 1005"
                    transform="translate(110.147 164.111)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M92.693 155.346l-.432-.434a.734.734 0 00-.521.762.63.63 0 00.746.5.518.518 0 00.274-.793z"
                    data-name="Path 1006"
                    transform="translate(106.556 170.231)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M94.357 151.687a.934.934 0 00.253-1.3 1.424 1.424 0 00-1.325-.548c-.22.021-.482.132-.5.348a.541.541 0 00.214.38q.652.645 1.308 1.291z"
                    data-name="Path 1007"
                    transform="translate(107.783 164.653)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M92.543 154.782a3.1 3.1 0 00.884-2.653 3.444 3.444 0 00-1.3.26 1.105 1.105 0 00-.677 1.026c.058.588.711.915 1.275 1.133l-.463.2z"
                    data-name="Path 1008"
                    transform="translate(106.223 167.173)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M90.656 155.8a1.4 1.4 0 01-.1.858 2.4 2.4 0 01-.828-2.011c-.02-.418.032-.934.426-1.1.274-.12.592 0 .891-.021.6-.032 1.267-.56 1.719-.176a.7.7 0 01.052.907 2.143 2.143 0 01-.789.613q-.969.535-1.937 1.066z"
                    data-name="Path 1009"
                    transform="translate(104.228 168.358)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M95.941 153.2a2.419 2.419 0 001.295-1.687l-1.831-.17a1.7 1.7 0 00-.854.065.943.943 0 00-.277 1.408 1.414 1.414 0 001.543.355z"
                    data-name="Path 1010"
                    transform="translate(109.274 166.278)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M91.916 150.423c-.277.288.032.781.417.9a12.08 12.08 0 001.206.124 1.454 1.454 0 01.971.709.664.664 0 01.037.674.9.9 0 01-.391.275 4.472 4.472 0 01-2.317.472 2.036 2.036 0 01-1.772-1.358 1.529 1.529 0 01.447-1.484 1.648 1.648 0 011.552-.344z"
                    data-name="Path 1011"
                    transform="translate(104.565 165.195)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M97.858 162.44a3.73 3.73 0 00.651-1.4 1.282 1.282 0 00-.566-1.345 1.215 1.215 0 00-.77-.107 1.49 1.49 0 00-1.18 1.734 1.539 1.539 0 001.848 1.047z"
                    data-name="Path 1012"
                    transform="translate(111.464 175.347)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M100.949 158.116a3.1 3.1 0 00.884-2.651 3.441 3.441 0 00-1.3.258 1.108 1.108 0 00-.677 1.026c.058.588.711.915 1.275 1.133l-.46.2z"
                    data-name="Path 1013"
                    transform="translate(115.987 170.839)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M90.644 155.2c-.538 0-.869.563-1.051 1.056s-.415 1.075-.94 1.192a.479.479 0 01-.417-.076c-.283-.248.019-.672.123-1.026a3.727 3.727 0 00.035-.879 1.767 1.767 0 011.249-1.555 1.007 1.007 0 01.791.735.978.978 0 01-.38 1z"
                    data-name="Path 1014"
                    transform="translate(102.357 169.126)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M104.517 166.8l-.614-.1a.713.713 0 00.054.913.64.64 0 00.9-.034.517.517 0 00-.268-.793z"
                    data-name="Path 1015"
                    transform="translate(120.53 183.188)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M96.165 154.861c.035-.208.067-.416.1-.623a1.416 1.416 0 00-.977-.036c-.3.145-.409.636-.106.779a.708.708 0 00.37.027l1.2-.126z"
                    data-name="Path 1016"
                    transform="translate(110.354 169.368)"
                  ></path>
                  <g
                    data-name="Group 1374"
                    opacity="0.5"
                    transform="translate(113.627 423.236)"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  >
                    <g data-name="Group 1373">
                      <g clipPath="url(#clip-path-23)" data-name="Group 1372">
                        <g data-name="Group 1371">
                          <g
                            clipPath="url(#clip-path-24)"
                            data-name="Group 1370"
                          >
                            <path
                              fill="url(#radial-gradient)"
                              d="M0 0H176.32V38.099H0z"
                              data-name="Rectangle 1338"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g data-name="Group 1378" transform="translate(347.984 2.527)">
                <g clipPath="url(#clip-path-25)" data-name="Group 1377">
                  <path
                    fill="url(#linear-gradient-2)"
                    d="M0 0l92.7.2.147 71.556-92.7-.2z"
                    data-name="Path 9768"
                    transform="rotate(-85.94 44.004 47.437)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1380" transform="translate(133.959 335.933)">
                <g clipPath="url(#clip-path-26)" data-name="Group 1379">
                  <path
                    fill="url(#linear-gradient-3)"
                    d="M0 0l86.256.318.221 61.6L.221 61.6z"
                    data-name="Path 9769"
                    transform="rotate(-82.841 39.593 45.752)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1382" transform="translate(202.878 330.631)">
                <g clipPath="url(#clip-path-27)" data-name="Group 1381">
                  <path
                    fill="url(#linear-gradient-4)"
                    d="M0 0l98.776.365L99 63.814.228 63.45z"
                    data-name="Path 9770"
                    transform="rotate(-82.841 46.031 52.611)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1384" transform="translate(133.825 335.692)">
                <g clipPath="url(#clip-path-28)" data-name="Group 1383">
                  <path
                    fill="url(#linear-gradient-5)"
                    d="M0 0H55.548V34.848H0z"
                    data-name="Rectangle 1344"
                    transform="translate(-4.388)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1386" transform="translate(202.863 330.894)">
                <g clipPath="url(#clip-path-29)" data-name="Group 1385">
                  <path
                    fill="url(#linear-gradient-6)"
                    d="M0 0H52.541V36.743H0z"
                    data-name="Rectangle 1345"
                    transform="translate(-1.272)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1398">
                <g clipPath="url(#clip-path)" data-name="Group 1397">
                  <g
                    data-name="Group 1391"
                    opacity="0.3"
                    transform="translate(133.959 335.933)"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  >
                    <g data-name="Group 1390">
                      <g clipPath="url(#clip-path-31)" data-name="Group 1389">
                        <g data-name="Group 1388">
                          <g
                            clipPath="url(#clip-path-32)"
                            data-name="Group 1387"
                          >
                            <path
                              fill="url(#linear-gradient-7)"
                              d="M0 0H51.694V82.619H0z"
                              data-name="Rectangle 1346"
                              transform="translate(-.475 -5.578)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <path
                    fill="#fff"
                    d="M106.09 178.629c-1.842.55-2.27 14.822.8 15.614s3.456-16.888-.8-15.614"
                    data-name="Path 1024"
                    transform="translate(121.554 196.221)"
                  ></path>
                  <g
                    data-name="Group 1396"
                    opacity="0.3"
                    transform="translate(202.878 330.631)"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  >
                    <g data-name="Group 1395">
                      <g clipPath="url(#clip-path-33)" data-name="Group 1394">
                        <g
                          data-name="Group 1393"
                          transform="translate(0 -.001)"
                        >
                          <g
                            clipPath="url(#clip-path-34)"
                            data-name="Group 1392"
                          >
                            <path
                              fill="url(#linear-gradient-8)"
                              d="M0 0H51.925V92.395H0z"
                              data-name="Rectangle 1348"
                              transform="translate(-.042 -2.889)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g data-name="Group 1400" transform="translate(80.259 221.783)">
                <g clipPath="url(#clip-path-35)" data-name="Group 1399">
                  <path
                    fill="url(#linear-gradient-9)"
                    d="M0 0l129.527.478.292 81.354L.292 81.354z"
                    data-name="Path 9771"
                    transform="rotate(-82.841 60.503 69.09)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1402" transform="translate(80.393 324.081)">
                <g clipPath="url(#clip-path-36)" data-name="Group 1401">
                  <path
                    fill="url(#linear-gradient-10)"
                    d="M0 0H35.424V25.52H0z"
                    data-name="Rectangle 1352"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1409">
                <g clipPath="url(#clip-path)" data-name="Group 1408">
                  <g
                    data-name="Group 1407"
                    opacity="0.3"
                    transform="translate(80.258 236.92)"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  >
                    <g data-name="Group 1406">
                      <g clipPath="url(#clip-path-38)" data-name="Group 1405">
                        <g data-name="Group 1404">
                          <g
                            clipPath="url(#clip-path-39)"
                            data-name="Group 1403"
                          >
                            <path
                              fill="url(#linear-gradient-11)"
                              d="M0 0H66.164V106.029H0z"
                              data-name="Rectangle 1353"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <path
                    fill="#fff"
                    d="M54.505 146.495c-2.108-.768 8.778-24.7 12.155-24.916s-10.354 25.571-12.155 24.916"
                    data-name="Path 1029"
                    transform="translate(63 133.6)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M55.1 134.72c-1.662-.854-3.232 3.625-1.712 4.278s3.593-3.308 1.712-4.278"
                    data-name="Path 1030"
                    transform="translate(61.301 147.924)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1411" transform="translate(79.776 329.771)">
                <g clipPath="url(#clip-path-40)" data-name="Group 1410">
                  <path
                    fill="url(#linear-gradient-12)"
                    d="M0 0l74.5.275.168 46.689-74.5-.275z"
                    data-name="Path 9772"
                    transform="rotate(-82.841 33.837 40.845)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1413" transform="translate(97.123 336.888)">
                <g clipPath="url(#clip-path-41)" data-name="Group 1412">
                  <path
                    fill="url(#linear-gradient-13)"
                    d="M0 0H26.821V61.491H0z"
                    data-name="Rectangle 1357"
                    transform="translate(-7.102)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1420">
                <g clipPath="url(#clip-path)" data-name="Group 1419">
                  <g
                    data-name="Group 1418"
                    opacity="0.5"
                    transform="translate(97.574 355.569)"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  >
                    <g data-name="Group 1417">
                      <g clipPath="url(#clip-path-43)" data-name="Group 1416">
                        <g data-name="Group 1415" transform="translate(.001)">
                          <g
                            clipPath="url(#clip-path-44)"
                            data-name="Group 1414"
                          >
                            <path
                              fill="url(#linear-gradient-14)"
                              d="M0 0H15.849V24.477H0z"
                              data-name="Rectangle 1358"
                              transform="translate(-.386)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g data-name="Group 1422" transform="translate(99.621 337.969)">
                <g clipPath="url(#clip-path-45)" data-name="Group 1421">
                  <path
                    fill="url(#linear-gradient-15)"
                    d="M0 0l46.179.17.091 25.409-46.179-.17z"
                    data-name="Path 9773"
                    transform="rotate(-82.841 21.793 24.886)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1424">
                <g clipPath="url(#clip-path)" data-name="Group 1423">
                  <path
                    fill="#fff"
                    d="M48.95 167.033c-2.989 0 1.556 12.843 3.113 12.541s-.623-12.541-3.113-12.541"
                    data-name="Path 1035"
                    transform="translate(55.703 183.55)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1426" transform="translate(95.91 43.833)">
                <g clipPath="url(#clip-path-47)" data-name="Group 1425">
                  <path
                    fill="url(#linear-gradient-16)"
                    d="M0 0H46.835V72.779H0z"
                    data-name="Rectangle 1363"
                    transform="translate(-4.114)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1428" transform="translate(102.15 43.833)">
                <g clipPath="url(#clip-path-48)" data-name="Group 1427">
                  <path
                    fill="url(#linear-gradient-17)"
                    d="M0 0H20.027V45.286H0z"
                    data-name="Rectangle 1364"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1430" transform="translate(250.512 43.833)">
                <g clipPath="url(#clip-path-49)" data-name="Group 1429">
                  <path
                    fill="url(#linear-gradient-18)"
                    d="M0 0H46.837V72.779H0z"
                    data-name="Rectangle 1365"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1432" transform="translate(267.186 43.827)">
                <g clipPath="url(#clip-path-50)" data-name="Group 1431">
                  <path
                    fill="url(#linear-gradient-19)"
                    d="M0 0H20.258V39.566H0z"
                    data-name="Rectangle 1366"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1434" transform="translate(271.427 48.585)">
                <g clipPath="url(#clip-path-51)" data-name="Group 1433">
                  <path
                    fill="url(#linear-gradient-20)"
                    d="M0 0H39.84V71.511H0z"
                    data-name="Rectangle 1367"
                    transform="translate(-3.114)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1436" transform="translate(85.677 48.585)">
                <g clipPath="url(#clip-path-52)" data-name="Group 1435">
                  <path
                    fill="url(#linear-gradient-21)"
                    d="M0 0H39.84V71.511H0z"
                    data-name="Rectangle 1368"
                    transform="translate(-4.687)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1438" transform="translate(91.053 34)">
                <g clipPath="url(#clip-path-53)" data-name="Group 1437">
                  <path
                    fill="url(#linear-gradient-22)"
                    d="M0 0l219.377.81.85 236.88L.85 236.88z"
                    data-name="Path 9774"
                    transform="rotate(-82.841 94.555 112.065)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1440">
                <g clipPath="url(#clip-path)" data-name="Group 1439">
                  <path
                    fill="#dfe3fb"
                    d="M52.132 126.824c6.753 27.976 131.381 31.022 161.991 5.669s-13.469-115.42-80.994-111.487C71.368 24.6 41.071 81 52.132 126.824"
                    data-name="Path 1043"
                    transform="translate(57.95 22.946)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1442" transform="translate(107.193 40.431)">
                <g clipPath="url(#clip-path-55)" data-name="Group 1441">
                  <path
                    fill="url(#linear-gradient-23)"
                    d="M0 0H203.009V136.963H0z"
                    data-name="Rectangle 1371"
                    transform="translate(-8.818)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1449">
                <g clipPath="url(#clip-path)" data-name="Group 1448">
                  <g
                    data-name="Group 1447"
                    opacity="0.3"
                    transform="translate(91.053 108.33)"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  >
                    <g data-name="Group 1446">
                      <g clipPath="url(#clip-path-57)" data-name="Group 1445">
                        <g data-name="Group 1444" transform="translate(.001)">
                          <g
                            clipPath="url(#clip-path-58)"
                            data-name="Group 1443"
                          >
                            <path
                              fill="url(#linear-gradient-24)"
                              d="M0 0H213.633V120.615H0z"
                              data-name="Rectangle 1372"
                              transform="translate(-3.596)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g data-name="Group 1451" transform="translate(118.694 189.616)">
                <g clipPath="url(#clip-path-59)" data-name="Group 1450">
                  <path
                    fill="url(#linear-gradient-25)"
                    d="M0 0H150.537V28.515H0z"
                    data-name="Rectangle 1375"
                    transform="translate(-.498)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1458">
                <g clipPath="url(#clip-path)" data-name="Group 1457">
                  <g
                    data-name="Group 1456"
                    opacity="0.5"
                    transform="translate(118.694 189.616)"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  >
                    <g data-name="Group 1455">
                      <g clipPath="url(#clip-path-61)" data-name="Group 1454">
                        <g data-name="Group 1453" transform="translate(-.001)">
                          <g
                            clipPath="url(#clip-path-62)"
                            data-name="Group 1452"
                          >
                            <path
                              fill="url(#linear-gradient-26)"
                              d="M0 0H147.757V22.372H0z"
                              data-name="Rectangle 1376"
                              transform="translate(-.498 -1.746)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g data-name="Group 1460" transform="translate(133.983 189.616)">
                <g clipPath="url(#clip-path-63)" data-name="Group 1459">
                  <path
                    fill="url(#linear-gradient-27)"
                    d="M0 0l30.274.112.431 120.188-30.274-.111z"
                    data-name="Path 9775"
                    transform="rotate(-82.841 7.893 9.014)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1462" transform="translate(134.231 200.966)">
                <g clipPath="url(#clip-path-64)" data-name="Group 1461">
                  <path
                    fill="url(#linear-gradient-28)"
                    d="M0 0l175.23.647.512 142.764-175.23-.647z"
                    data-name="Path 9776"
                    transform="rotate(-82.841 78.505 92.665)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1464" transform="translate(229.657 215.992)">
                <g clipPath="url(#clip-path-65)" data-name="Group 1463">
                  <path
                    fill="url(#linear-gradient-29)"
                    d="M0 0H19.612V45.082H0z"
                    data-name="Rectangle 1381"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1466" transform="translate(231.393 155.945)">
                <g clipPath="url(#clip-path-66)" data-name="Group 1465">
                  <path
                    fill="url(#linear-gradient-30)"
                    d="M0 0l105.575.39.392 109.171-105.575-.39z"
                    data-name="Path 9777"
                    transform="rotate(-82.841 44.943 55.247)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1473">
                <g clipPath="url(#clip-path)" data-name="Group 1472">
                  <g
                    data-name="Group 1471"
                    opacity="0.5"
                    transform="translate(92.359 163.741)"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  >
                    <g data-name="Group 1470">
                      <g clipPath="url(#clip-path-68)" data-name="Group 1469">
                        <g
                          data-name="Group 1468"
                          transform="translate(.001 -.001)"
                        >
                          <g
                            clipPath="url(#clip-path-69)"
                            data-name="Group 1467"
                          >
                            <path
                              fill="url(#linear-gradient-31)"
                              d="M0 0H50.76V30.77H0z"
                              data-name="Rectangle 1383"
                              transform="translate(0 -1.831)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g data-name="Group 1475" transform="translate(304.269 155.519)">
                <g clipPath="url(#clip-path-70)" data-name="Group 1474">
                  <path
                    fill="url(#linear-gradient-32)"
                    d="M0 0H22.766V38.462H0z"
                    data-name="Rectangle 1386"
                    transform="translate(-1.551)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1477" transform="translate(216.297 73.665)">
                <g clipPath="url(#clip-path-71)" data-name="Group 1476">
                  <path
                    fill="url(#linear-gradient-33)"
                    d="M0 0H40.35V35.131H0z"
                    data-name="Rectangle 1387"
                    transform="translate(-1.261)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1479" transform="translate(134.23 73.665)">
                <g clipPath="url(#clip-path-72)" data-name="Group 1478">
                  <path
                    fill="url(#linear-gradient-34)"
                    d="M0 0H40.35V35.131H0z"
                    data-name="Rectangle 1388"
                    transform="translate(-1.79)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1481">
                <g clipPath="url(#clip-path)" data-name="Group 1480">
                  <path
                    fill="#2f95cd"
                    d="M75.083 55.621s8.5 8.524 32.614 8.524 36.485-9.835 36.485-9.835S133.556 68.3 107.7 68.3 75.083 55.621 75.083 55.621"
                    data-name="Path 1056"
                    transform="translate(87.215 59.679)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1507">
                <g clipPath="url(#clip-path)" data-name="Group 1506">
                  <g
                    data-name="Group 1490"
                    opacity="0.5"
                    transform="translate(129.803 43.812)"
                    style={{ mixBlendMode: "screen", isolation: "isolate" }}
                  >
                    <g data-name="Group 1489">
                      <g clipPath="url(#clip-path-75)" data-name="Group 1488">
                        <g
                          data-name="Group 1487"
                          transform="translate(0 -.002)"
                        >
                          <g
                            clipPath="url(#clip-path-76)"
                            data-name="Group 1486"
                          >
                            <path
                              fill="url(#linear-gradient-35)"
                              d="M0 0H140.332V67.874H0z"
                              data-name="Rectangle 1392"
                              transform="translate(-1.472 -.972)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 1495"
                    opacity="0.5"
                    transform="translate(149.003 148.815)"
                    style={{ mixBlendMode: "screen", isolation: "isolate" }}
                  >
                    <g data-name="Group 1494">
                      <g clipPath="url(#clip-path-77)" data-name="Group 1493">
                        <g
                          data-name="Group 1492"
                          transform="translate(-.001 .001)"
                        >
                          <g
                            clipPath="url(#clip-path-78)"
                            data-name="Group 1491"
                          >
                            <path
                              fill="url(#linear-gradient-36)"
                              d="M0 0H99.949V20.51H0z"
                              data-name="Rectangle 1394"
                              transform="translate(-1.644 -1.632)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 1500"
                    opacity="0.3"
                    transform="translate(288.601 110.37)"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  >
                    <g data-name="Group 1499">
                      <g clipPath="url(#clip-path-79)" data-name="Group 1498">
                        <g data-name="Group 1497">
                          <g
                            clipPath="url(#clip-path-80)"
                            data-name="Group 1496"
                          >
                            <path
                              fill="url(#linear-gradient-37)"
                              d="M0 0H1.014V2.976H0z"
                              data-name="Rectangle 1396"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 1505"
                    opacity="0.3"
                    transform="translate(134.231 233.72)"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  >
                    <g data-name="Group 1504">
                      <g clipPath="url(#clip-path-81)" data-name="Group 1503">
                        <g data-name="Group 1502">
                          <g
                            clipPath="url(#clip-path-82)"
                            data-name="Group 1501"
                          >
                            <path
                              fill="url(#linear-gradient-38)"
                              d="M0 0H127.87V128.765H0z"
                              data-name="Rectangle 1398"
                              transform="translate(-4.24)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g data-name="Group 1509" transform="translate(138.338 258.453)">
                <g clipPath="url(#clip-path-83)" data-name="Group 1508">
                  <path
                    fill="url(#linear-gradient-39)"
                    d="M0 0H112.387V101.414H0z"
                    data-name="Rectangle 1401"
                    transform="translate(-3.862)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1511" transform="translate(138.63 261.074)">
                <g clipPath="url(#clip-path-84)" data-name="Group 1510">
                  <path
                    fill="url(#radial-gradient-2)"
                    d="M0 0H112.385V101.414H0z"
                    data-name="Rectangle 1402"
                    transform="translate(-3.86)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1528">
                <g clipPath="url(#clip-path)" data-name="Group 1527">
                  <g
                    data-name="Group 1516"
                    opacity="0.5"
                    transform="translate(151.321 200.966)"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  >
                    <g data-name="Group 1515">
                      <g clipPath="url(#clip-path-86)" data-name="Group 1514">
                        <g data-name="Group 1513">
                          <g
                            clipPath="url(#clip-path-87)"
                            data-name="Group 1512"
                          >
                            <path
                              fill="url(#linear-gradient-40)"
                              d="M0 0H82.786V32.919H0z"
                              data-name="Rectangle 1403"
                              transform="translate(-2.706)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <path
                    fill="#fff"
                    d="M101.736 112.183c-1.7.548-1.781 13.863 0 16.008s4.639 1.482 5.592-1.65-3.02-15.181-5.592-14.358"
                    data-name="Path 1066"
                    transform="translate(116.659 123.232)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M103.185 121.491c-1.418.327-1.582 3.631-.927 4.785s3.121.907 3.521-.5-1.165-4.62-2.594-4.29"
                    data-name="Path 1067"
                    transform="translate(118.353 133.479)"
                  ></path>
                  <g
                    data-name="Group 1521"
                    opacity="0.72"
                    transform="translate(157.512 339.266)"
                    style={{ mixBlendMode: "screen", isolation: "isolate" }}
                  >
                    <g data-name="Group 1520">
                      <g clipPath="url(#clip-path-88)" data-name="Group 1519">
                        <g
                          data-name="Group 1518"
                          transform="translate(0 -.001)"
                        >
                          <g
                            clipPath="url(#clip-path-89)"
                            data-name="Group 1517"
                          >
                            <path
                              fill="url(#linear-gradient-41)"
                              d="M0 0H74.806V20.567H0z"
                              data-name="Rectangle 1405"
                              transform="translate(0 -2.893)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <path
                    fill="#fff"
                    d="M100.2 19.058c-2.989.676 22.078 13.55 23.092 12.02S108.463 17.19 100.2 19.058"
                    data-name="Path 1069"
                    transform="translate(116.099 20.732)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M123.117 24.749c-2.5 1.31-2.3 3.925 2.873 3.27s7.987-4.59 7.425-5.354-7.494.615-10.3 2.084"
                    data-name="Path 1070"
                    transform="translate(141.2 24.665)"
                  ></path>
                  <g
                    data-name="Group 1526"
                    opacity="0.3"
                    transform="translate(231.393 158.351)"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  >
                    <g data-name="Group 1525">
                      <g clipPath="url(#clip-path-90)" data-name="Group 1524">
                        <g data-name="Group 1523" transform="translate(.001)">
                          <g
                            clipPath="url(#clip-path-91)"
                            data-name="Group 1522"
                          >
                            <path
                              fill="url(#linear-gradient-42)"
                              d="M0 0H97.863V92.015H0z"
                              data-name="Rectangle 1407"
                              transform="translate(-3.451)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g data-name="Group 1530" transform="translate(305.779 110.378)">
                <g clipPath="url(#clip-path-92)" data-name="Group 1529">
                  <path
                    fill="url(#linear-gradient-43)"
                    d="M0 0l80.683.3.2 55.468-80.683-.3z"
                    data-name="Path 9780"
                    transform="rotate(-82.841 37.278 42.824)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1532" transform="translate(306.291 110.372)">
                <g clipPath="url(#clip-path-93)" data-name="Group 1531">
                  <path
                    fill="url(#linear-gradient-44)"
                    d="M0 0H29.207V49.439H0z"
                    data-name="Rectangle 1411"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1539">
                <g clipPath="url(#clip-path)" data-name="Group 1538">
                  <g
                    data-name="Group 1537"
                    opacity="0.5"
                    transform="translate(313.066 122.155)"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  >
                    <g data-name="Group 1536">
                      <g clipPath="url(#clip-path-95)" data-name="Group 1535">
                        <g data-name="Group 1534">
                          <g
                            clipPath="url(#clip-path-96)"
                            data-name="Group 1533"
                          >
                            <path
                              fill="url(#linear-gradient-45)"
                              d="M0 0H12.418V28.639H0z"
                              data-name="Rectangle 1412"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g data-name="Group 1541" transform="translate(302.984 115.051)">
                <g clipPath="url(#clip-path-97)" data-name="Group 1540">
                  <path
                    fill="url(#linear-gradient-46)"
                    d="M0 0l43.375.16.078 21.64-43.375-.157z"
                    data-name="Path 9781"
                    transform="rotate(-82.82 20.437 23.739)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1543" transform="translate(319.734 137.093)">
                <g clipPath="url(#clip-path-98)" data-name="Group 1542">
                  <path
                    fill="url(#linear-gradient-47)"
                    d="M0 0H20.933V27.405H0z"
                    data-name="Rectangle 1416"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1545">
                <g clipPath="url(#clip-path)" data-name="Group 1544">
                  <path
                    fill="#fff"
                    d="M143.768 59.517c-1.36-.155-.9 11.466.787 10.977s1.126-10.759-.787-10.977"
                    data-name="Path 1077"
                    transform="translate(166.073 65.401)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1559">
                <g clipPath="url(#clip-path)" data-name="Group 1558">
                  <g
                    data-name="Group 1552"
                    opacity="0.2"
                    transform="translate(110.411 94.861)"
                    style={{ mixBlendMode: "screen", isolation: "isolate" }}
                  >
                    <g data-name="Group 1551">
                      <g clipPath="url(#clip-path-101)" data-name="Group 1550">
                        <g
                          data-name="Group 1549"
                          transform="translate(0 -.001)"
                        >
                          <g
                            clipPath="url(#clip-path-102)"
                            data-name="Group 1548"
                          >
                            <path
                              fill="url(#linear-gradient-48)"
                              d="M0 0l55.1.112.037 18.764-55.1-.112z"
                              data-name="Path 9782"
                              transform="rotate(-86.078 25.992 28.976)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 1557"
                    opacity="0.72"
                    transform="translate(150.112 265.197)"
                    style={{ mixBlendMode: "screen", isolation: "isolate" }}
                  >
                    <g data-name="Group 1556">
                      <g clipPath="url(#clip-path-103)" data-name="Group 1555">
                        <g
                          data-name="Group 1554"
                          transform="translate(-.001 -.001)"
                        >
                          <g
                            clipPath="url(#clip-path-104)"
                            data-name="Group 1553"
                          >
                            <path
                              fill="url(#linear-gradient-49)"
                              d="M0 0H96.015V45.791H0z"
                              data-name="Rectangle 1421"
                              transform="translate(-1.26 -2.37)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g data-name="Group 1561" transform="translate(326.55 .002)">
                <g clipPath="url(#clip-path-105)" data-name="Group 1560">
                  <path
                    fill="url(#linear-gradient-50)"
                    d="M0 0l96.271.2.178 87.041-96.271-.2z"
                    data-name="Path 9783"
                    transform="rotate(-85.93 45.188 48.93)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 1573">
                <g clipPath="url(#clip-path)" data-name="Group 1572">
                  <path
                    fill="#fff"
                    d="M167.674 11.733q-.227 4.335-.385 8.6t-.225 8.551c.376.029.757.044 1.139.044h1.139a49.75 49.75 0 003.001-.088q.045-.963.045-1.893v-1.92q0-3.331-.069-6.637t-.225-6.658h5.549q-.405 5.28-.62 10.48t-.214 10.48q0 2.585.067 5.146t.182 5.167h-5.145q.114-2.6.205-5.191t.179-5.167q-1.287-.066-2.559-.12c-.85-.038-1.7-.057-2.538-.057h-.182v7.535q0 1.489.045 3H161.9q.11-3.151.145-6.229t.035-6.188q0-9.417-.406-18.856z"
                    data-name="Path 1082"
                    transform="translate(187.797 12.893)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M179.637 42.942c-.6-.027-1.2-.048-1.8-.063s-1.2-.023-1.8-.023q-1.488 0-2.979.055t-2.977.164l.045-4.71c.346.046.692.078 1.038.1s.69.048 1.038.078q.136-3.4.259-6.756t.123-6.735q0-2.257-.067-4.456t-.136-4.435c-.391 0-.789.006-1.2.021s-.819.038-1.241.065l.045-4.576c1.159.069 2.315.128 3.474.164s2.324.055 3.5.055h1.265q.632 0 1.286-.044l-.136 4.355c-.346 0-.687 0-1.027-.019s-.679-.021-1.027-.021q-.315 3.942-.45 7.829t-.136 7.829q0 3.441.136 6.855c.48 0 .973-.006 1.476-.023s1.012-.036 1.524-.067z"
                    data-name="Path 1083"
                    transform="translate(197.396 12.825)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M180.618 41.5a2.313 2.313 0 01-.216.986 2.714 2.714 0 01-.562.8 2.582 2.582 0 01-.837.535 2.671 2.671 0 01-1.025.2 2.529 2.529 0 01-1.005-.2 2.736 2.736 0 01-.821-.535 2.41 2.41 0 01-.767-1.786 2.357 2.357 0 01.2-.974 2.606 2.606 0 01.566-.8 2.657 2.657 0 01.821-.548 2.566 2.566 0 011.005-.2 2.71 2.71 0 011.025.2 2.512 2.512 0 01.837.548 2.92 2.92 0 01.562.8 2.237 2.237 0 01.216.974m.4-29.915q-.632 6.067-.992 12.243t-.425 12.156l-3 .086q-.159-6.02-.417-12.22t-.6-12.22z"
                    data-name="Path 1084"
                    transform="translate(203.723 12.733)"
                  ></path>
                  <g
                    data-name="Group 1566"
                    opacity="0.72"
                    transform="translate(275.742 55.402)"
                    style={{ mixBlendMode: "screen", isolation: "isolate" }}
                  >
                    <g data-name="Group 1565">
                      <g clipPath="url(#clip-path-107)" data-name="Group 1564">
                        <g data-name="Group 1563" transform="translate(-.001)">
                          <g
                            clipPath="url(#clip-path-108)"
                            data-name="Group 1562"
                          >
                            <path
                              fill="url(#linear-gradient-51)"
                              d="M0 0H20.563V23.887H0z"
                              data-name="Rectangle 1425"
                              transform="translate(-1.402 -1.266)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 1571"
                    opacity="0.72"
                    transform="translate(91.935 51.246)"
                    style={{ mixBlendMode: "screen", isolation: "isolate" }}
                  >
                    <g data-name="Group 1570">
                      <g clipPath="url(#clip-path-109)" data-name="Group 1569">
                        <g data-name="Group 1568" transform="translate(-.001)">
                          <g
                            clipPath="url(#clip-path-110)"
                            data-name="Group 1567"
                          >
                            <path
                              fill="url(#linear-gradient-52)"
                              d="M0 0H22.764V25.76H0z"
                              data-name="Rectangle 1427"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 1654" transform="translate(379 303)">
          <g data-name="Group 1686">
            <g filter="url(#Rectangle_3)" transform="translate(-399.5 -325.5)">
              <rect
                width="135"
                height="159"
                fill="#fff"
                data-name="Rectangle 3"
                rx="15"
                transform="translate(399.5 325.5)"
              ></rect>
            </g>
            <path
              fill="#1d1d1b"
              d="M93.461 0L62.173 10.4l10.3 6.081L50.255 39.6 31.733 16.483 0 48.216l10.654 3.192 21.08-21.1 18.521 21.1 28.1-28.1 6.986 6.992z"
              data-name="Path 6"
              transform="translate(21.27 79)"
            ></path>
            <text
            style={{direction: 'ltr'}}
              fill="#011032"
              data-name="نمو حقيقي"
              fontFamily="Tajawal-Medium, Tajawal"
              fontSize="25"
              fontWeight="500"
              transform="translate(13 15)"
            >
              <tspan x="-21.513" y="16"></tspan>
              <tspan x='60' y="16">نمو</tspan>
              <tspan x="10" y="44">
                حقيقي
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BannerImg;
