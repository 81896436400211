import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";
// import { Button } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { clearData } from "../../../actions/user";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export const Navigation = ({ open, close, lng, user, dispatch, t }) => (
  <>
    <motion.ul
      variants={variants}
      initial={open ? "open" : "closed"}
      animate={open ? "open" : "closed"}
      className="n-ul"
      style={{ display: open ? "block" : "none" }}
      onClick={() => close(!open)}
    >
      {itemIds.map((i, j) => (
        <MenuItem open={open} i={i} key={j} />
      ))}
      {/* {
        user &&
      <Link
        className={
          lng === "arab"
            ? "newDesignBTN fix fs-5 hover-filled-slide-down button-to-display"
            : "lngBTN fix fs-5 hover-filled-slide-down poppinsdesc button-to-display"
        }
        to={!user ? "auth/login" : "#"}
        onClick={() => (!user ? null : dispatch(clearData()))}
      >
        <Button
          className="btn common-btn fs-5 "
          style={{ margin: "auto", display: "flex" }}
        >
          {t("Logout")}
        </Button>
      </Link>
    } */}
    </motion.ul>
  </>
);

const itemIds = [
  {
    text: "Home",
    route: "/",
  },
  // {
  //   text: "About Us",
  //   route: "/about",
  // },
  {
    text: "Pricing",
    route: "/pricing",
  },
  {
    text: "Contact Us",
    route: "/contact",
  },
  {
    text: "Business",
    route: "/business",
  },
  {
    text: "Blog",
    route: "/blog",
  },
];
