/* eslint-disable */
import { t } from "i18next";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { clearData } from "../../actions/user";
import PopUpModal from "../popUps/PopUpModal";
import { useTranslation } from "react-i18next";
import dash from "../../components/Assets/Icons/monitor.png";
import sign from "../../components/Assets/Icons/user.png";
import styles from "./Header.module.scss";
import arabic from "../../components/Assets/Icons/saudi-arabi.png";
import english from "../../components/Assets/Icons/united-kingdom.png";
import down from "../../components/Assets/Icons/down.png";
import up from "../../components/Assets/Icons/up.png";
import { ContactUsModal } from "../popUps/ContactUsModal";
import Sidebar from "./Sidebar";
import "./Navbar/styles.css";
import "./sidebar.css";
import { Example } from "./Navbar/Example";
import MobileAppBanner from "../../views/Banners/MobileApp";
import { useParams } from 'react-router-dom';

const Header = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const user = useSelector((state) => state.userAuth);
  const [loginModal, setLoginModal] = useState(false);
  const [drop, setDrop] = useState(false);
  const [showModal, setShowModal] = useState(false)

  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
      setLastScrollY(window.scrollY);
    
  };

  useEffect(() => {
    // if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      // };
    }
  }, [lastScrollY]);

  console.log(lastScrollY);

  const handleLanguage = (str) => {
    if (str === "eng") {
      localStorage.setItem("language", JSON.stringify({ lang: "en" }));
      window.location.reload();
    } else {
      localStorage.setItem("language", JSON.stringify({ lang: "arab" }));
      window.location.reload();
    }
  };

  return (
    <>
      <MobileAppBanner />
      <div className={`active ${show ? "navbar navbar-show" : "navbar navbar-hide"} ${"header"}`} style={{marginRight:"200px"}}>
        <Example />

        <div className="leftSideHeader">
          <div
            className="logo_header_wrapper d-flex align-items-center"
            style={{
              paddingLeft: "10px",
              width: "auto",
              cursor: "pointer",
            }}
          >
            <img style={{ zIndex: "200", color: "#fff", padding: "0px" }} className="logo" src={"/assets/newDesign/newLogo.png"} onClick={() => navigate("/")} />
          </div>

          <ul style={{ padding: "0px" }} className="navbar-wrap d-none d-lg-flex">
            <li>
              <Link to="/">{t("Home")}</Link>
            </li>
            <li>
              <Link to="/pricing">{t("Pricing")}</Link>
            </li>
            <li>
              <Link style={{ textAlign: lng === "arab" ? "right" : "left" }} to={"/contact"}>
                {t("Contact Us")}
              </Link>
            </li>
            <li>
              <Link style={{ textAlign: lng === "arab" ? "right" : "left" }} to={"/business"}>
                {t("Business")}
              </Link>
            </li>
            <li>
              <Link style={{ textAlign: lng === "arab" ? "right" : "left" }} to={"/blog"}>
                {t("Blog")}
              </Link>
            </li>
          </ul>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="btns_wrapper">
            <Link
              className={lng === "arab" ? "newDesignBTN fix fs-5 hover-filled-slide-down" : "lngBTN fix fs-5 hover-filled-slide-down poppinsdesc"}
              to={!user ? "auth/login" : "#"}
              onClick={() => (!user ? null : dispatch(clearData()))}
              // style={{ display: !user ? "none" : "block" }}
            >
              <span className="log-in-out-btn">{!user ? t("Login") : t("Logout")}</span>
            </Link>
            <button
              className={
                !user
                  ? lng === "arab"
                    ? "btn auth-btn fix s glow-on-hover "
                    : "poppinsdesc btn auth-btn fix   glow-on-hover "
                  : lng === "arab"
                  ? "btn auth-btn fix  glow-on-hover"
                  : "poppinsdesc btn auth-btn fix   glow-on-hover"
              }
              onClick={() => (!user ? setLoginModal(true) : window.open(process.env.REACT_APP_DASHBOARD_URL + "?t=" + localStorage.getItem("sess"), "_blank"))}
            >
              <span className="dash-btn">{t(!user ? "Join Now" : "Dashboard")}</span>
              {!user ? <img src={dash} width={15} /> : <img src={sign} className="login-button-icon" />}
            </button>

            <div className={styles.language}>
              <div
                style={{
                  position: "absolute",
                  border: "1px solid #4F5689",
                  borderRadius: "20px",
                  background: "black",
                }}
              >
                <div className={styles.dropdown} onClick={() => handleLanguage(lng === "arab" ? "eng" : "arab")}>
                  <img src={lng === "arab" ? english : arabic} width={30} />
                  <h5 style={{ fontFamily: lng === "arab" ? "Poppins" : "Tajawal" }} className={styles.araby + " " + (lng === "arab" ? "force_english_font" : "force_arabic_font")}>
                    {lng === "arab" ? "ENG" : "عربي"}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <PopUpModal show={loginModal} login={true} onHide={() => setLoginModal(false)} />
          <ContactUsModal onHide={() => setShowModal(false)} show={showModal} />
        </div>
      </div>
    </>
  );
};

export default Header;
