/* eslint-disable */
import { useSelect } from '@mui/base';
import i18next from 'i18next';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import checkErr from '../actions/api_error_func.js';
import { API_URL } from "../constants/index.js"
import RecaptchaPopUp from '../views/RecaptchaPopUp.js';
import { toastAlert } from './alert/toastAlert.js';
import PopUpModal from './popUps/PopUpModal.js';
import UpgradeModal from './UpgradeModal/UpgradeModal.jsx';
import Marquee from "react-double-marquee";
import AddBG from "../components/Assets/Img/imageSectionVideos/AddBG.gif"
import Enhance from "../components/Assets/Img/imageSectionVideos/Enhance.gif"
import NOBG from "../components/Assets/Img/imageSectionVideos/NOBG.gif"
import PhotoGen from "../components/Assets/Img/imageSectionVideos/PhotoGen.gif"
import ScribbleVideo from "../components/Assets/Img/imageSectionVideos/ScribbleVideo.gif"
import { Swiper, SwiperSlide } from 'swiper/react';
import AddBGH from "../components/Assets/Img/imageSectionVideos/AddBG.png"
import AddBGHA from "../components/Assets/Img/imageSectionVideos/AddBGAR.png"
import EnhanceH from "../components/Assets/Img/imageSectionVideos/Enhance.png"
import NOBGH from "../components/Assets/Img/imageSectionVideos/NOBG.png"
import PhotoGenH from "../components/Assets/Img/imageSectionVideos/PhotoGen.png"
import ScribbleVideoH from "../components/Assets/Img/imageSectionVideos/ScribbleVideo.png"
import PhotoGenAR from "../components/Assets/Img/imageSectionVideos/PhotoGenAR.gif"
import AddBGAR from "../components/Assets/Img/imageSectionVideos/AddBGAR.gif"
import PhotoGenHA from "../components/Assets/Img/imageSectionVideos/PhotoGenAR.png"
import { RightArrowSvg, LeftArrowSvg } from '../views/Blog/svg.js';
import 'swiper/css';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import 'swiper/css/navigation';

//Section 8
const TextToImage = () => {
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const { t } = useTranslation();
    const [imageText, setImageText] = useState('');
    const [imageArray, setImageArray] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [upgradeModalSwitch, setUpgradeModalSwitch] = useState(false);
    const user = useSelector((state) => state.userAuth);
    const [captchaResponse, setCaptchaResponse] = useState(0)
    const [recaptchaPopUp, setRecaptchaPopUp] = useState(false)
    const features = [
        {
        name: 'Generate Image',
        arabic_name: "إنشاء صور",
        link: 'create',
        video: PhotoGen,
        videoHA: PhotoGenAR,
        videoH: PhotoGenH,
        videoAr: PhotoGenAR,
        videoHA: PhotoGenHA,

        },
        {
        name: 'Image Enhance',
        arabic_name: "تحسين الصورة",
        link: 'enhance',
        video: Enhance,
        videoH: EnhanceH,
    
        },
        {
        name: 'Scribble',
        arabic_name: "شخابيط",
        link: 'scribble',
        video: ScribbleVideo,
        videoH: ScribbleVideoH,
        },
        {
        name: 'Background Remover',
        arabic_name: "مسح خلفية الصور",
        link: 'remover',
        video: NOBG,
        videoH: NOBGH,
        },
        {
        name: 'Replace Background',
        arabic_name: "تغيير خلفية الصور",
        link: 'painting',
        video: AddBG,
        videoH: AddBGH,
        videoHA: AddBGHA,
        videoAr: AddBGAR,

        },
      ]
      const [view, setView] = useState(3);
  const [view2, setView2] = useState(3);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    if (window.innerWidth > 3400) {
      setView(2.5);
    } else if (window.innerWidth > 2530 && window.innerWidth <= 3400) {
      setView(2.5);
    } else if (window.innerWidth > 1860 && window.innerWidth <= 2530) {
      setView(2.5);
    } else if (window.innerWidth > 1400 && window.innerWidth <= 1860) {
      setView(2.5);
    } else if (window.innerWidth > 1111 && window.innerWidth <= 1400) {
      setView(2.5);
    } else if (window.innerWidth > 900 && window.innerWidth <= 1111) {
      setView(2);
    } else if (window.innerWidth > 700 && window.innerWidth <= 900) {
      setView(1.5);
    } else if (window.innerWidth > 550 && window.innerWidth <= 700) {
      setView(1);
    } else if (window.innerWidth > 400 && window.innerWidth <= 550) {
      setView(1);
    } else if (window.innerWidth > 280 && window.innerWidth <= 400) {
      setView(1);
    } else if (window.innerWidth > 210 && window.innerWidth <= 280) {
      setView(1);
    } else {
      setView(1);
    }
  }, [width]);
    const generateImg = () => {
        if (user?._id) {
            if (user?.image_count_left === 0) {
                setUpgradeModalSwitch(true)
                setImageArray(imageArray)
                setLoading(false)
                return toastAlert('No more image generation quota left', undefined, "warning");
            }
          }
          if (!imageText) {
            return toastAlert('Please enter a valid text', undefined, "warning");
          }
        if (!localStorage.getItem('sess')) {
            // if (localStorage.getItem('imageUse')) {
                return setShowModal(true)
            // }
        }
        setRecaptchaPopUp(true)
        

    }
    const pureGen = () => {
        var elem = document.getElementById("image-loader-bar");
        var width = 1;
        var id = setInterval(frame, 200);
        function frame() {
            if (width >= 100) {
                clearInterval(id);
            } else {
                width++;
                elem.style.width = width + '%';
            }
        }
        setLoading(true)
        fetch(API_URL('sinkin-dream-shaper'), {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit

            headers: localStorage.getItem("sess") ? {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("sess")
            } : { 'Content-Type': 'application/json' },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({ prompt: imageText, image_size: '768x768', num_images: '4', height: 704, 
            width: 704 }) // body data type must match "Content-Type" header
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data)
                if (data.urls) {
                    setImageArray(data.urls)
                    setLoading(false)
                    var elem = document.getElementById("image-loader-bar");
                    elem.style.width = "0%"
                    if (!localStorage.getItem('sess')) {
                        localStorage.setItem("imageUse", true)
                    }

                } else {
                    toastAlert(data.message || "Something went wrong, please try again later", undefined, "warning");                    setImageArray(imageArray)
                    setLoading(false)
                }
            }).catch(error => {
                checkErr(error, setLoading, toastAlert)
            })
    }
    useEffect(() => {
        if (captchaResponse) {
          pureGen()
        }
    }, [captchaResponse])
    return (
        <div id='imageai' style={{direction: lng === 'arab'? 'rtl': 'ltr', height: 'auto'}}>
            <div style={{paddingBottom: '40px'}} className="imageai-div">
                <div className="container row justify-content-center">
                    <h1 style={{ color: '#fff', marginTop: 60, textAlign: 'center', fontSize: '2.5rem' }} className='we-turn-your'>{t('Create stunning visuals in seconds')}</h1>
                    <h6 style={{ color: '#fff', marginTop: 20, marginBottom: 40, textAlign: 'center' }}>{t('The ultimate ecosystem of apps, plugins & resources for all creators, powered by artificial intelligence.')}</h6>
                    <div className="row justify-content-center" style={{ direction: lng !== 'arab' ? 'ltr' : 'rtl', flexDirection: 'row' }}>
                        <div id='image-loader' className="progress" style={{ display: loading ? '' : 'none' }}>
                            <div id='image-loader-bar' className="progress-bar" style={{ width: '0%' }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div style={{position: 'relative', width: '100%', maxWidth: '1200px'}}>
                        <Swiper
                            style={{direction: 'ltr'}}
                            modules={[Navigation,Autoplay]}
                             autoplay={{
                             delay: 2500,
                              disableOnInteraction: false,
                               }}
                            navigation={{
                                prevEl: '.prev',
                                nextEl: '.next',
                              }}
                            spaceBetween={10}
                            slidesPerView={view}
                            loop={true}
                        >
                            {features?.map((item, i) => 
                                <SwiperSlide key={i}>
                                    <VideoCard 
                                        setShowModa={setShowModal}
                                        title={lng === 'arab'? item?.arabic_name:item?.name}
                                        index={i}
                                        videoAr={item?.videoAr}
                                        video={item?.video} 
                                        thumb={lng === 'arab'? (item?.videoHA || item?.videoH):item?.videoH}  
                                        goTo={item?.link}
                                    />
                                </SwiperSlide>
                                
                            )}
                            
    
                        </Swiper>
                        <svg className='prev' width={width <= 900 ?"30":"40"} height={width <= 900 ?"30.5":"41"}  viewBox="0 0 67 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="33.5" cy="33.6796" rx="33.5" ry="33.6796" fill="#F5F5F5" />
                <ellipse cx="33.5" cy="33.6796" rx="26.6421" ry="26.5" transform="rotate(-90 33.5 33.6796)" fill="currentColor" />
                <path d="M24.0833 33.8447L44.625 33.8447" stroke="#F5F5F5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M32 42.8444C32 38.9872 24 33.8444 24 33.8444C24 33.8444 32 28.7015 32 24.8444" stroke="#F5F5F5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            
            <svg className='next' width={width <= 900 ?"30":"40"} height={width <= 900 ?"30.5":"41"} viewBox="0 0 67 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="33.5" cy="33.835" rx="33.5" ry="33.6796" fill="#F5F5F5" />
                <g filter="url(#filter0_i_165_317)">
                    <ellipse cx="33.5" cy="33.835" rx="26.6421" ry="26.5" transform="rotate(-90 33.5 33.835)" fill="currentColor" />
                </g>
                <path d="M43.9167 34.0001L23.375 34.0001" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M36 42.9998C36 39.1426 44 33.9998 44 33.9998C44 33.9998 36 28.8569 36 24.9998" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <defs>
                    <filter id="filter0_i_165_317" x="7" y="7.19287" width="60" height="53.2843" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="7" />
                        <feGaussianBlur stdDeviation="10" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0" />
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_165_317" />
                    </filter>
                </defs>
            </svg>
                        </div>
                        {/* <div className="all_images_wrapper row">
                            {features?.map((item, i) => 
                                <VideoCard 
                                title={lng === 'arab'? item?.arabic_name:item?.name}
                                index={i}
                                video={item?.video} 
                                thumb={item?.videoH} 
                                goTo={item?.link}
                                />
                            )}
                        </div> */}
                    </div>
                </div>
            </div>
            

    
            <PopUpModal
                popUpFor={'images'}
                show={showModal}
                onHide={() => setShowModal(false)}
            />
            <UpgradeModal
            forImg = {true}
        showModal={upgradeModalSwitch}
        setShowModal={setUpgradeModalSwitch}
      />
        <RecaptchaPopUp setLoading={setLoading} backdrop='static' show={recaptchaPopUp} onHide={() => setRecaptchaPopUp(false)} captcharesponse={captchaResponse} submitfunction={setCaptchaResponse}/>
        </div>
    )
}

export default TextToImage

const VideoCard = ({title, video, index, goTo, thumb, videoAr, setShowModa}) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const cardRef = useRef();
    const textRef = useRef();
    const marqRef = useRef();
    const [displayMarq, setDisplayMarq] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [imageLoading, setImageLoading] = useState(false);
    const [hovering, setHovering] = useState(false);

    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);
      useEffect(() => {
        if (cardRef.current && textRef.current) {
          if ((cardRef.current.offsetWidth - textRef.current.offsetWidth) <= 15) {
            setDisplayMarq(true)
          }
        }
        if (cardRef.current && marqRef.current) {
          if ((cardRef.current.offsetWidth - marqRef.current.offsetWidth) > 15) {
            setDisplayMarq(false)
          }
        }
      }, [cardRef.current?.offsetWidth, title, textRef.current, width, marqRef.current, location.pathname])
    return (
            <div key={index} style={{display: 'flex', alignItems:'center', justifyContent: 'center'}}>
                <Link 
                  style={{
                    overflow: 'hidden', 
                    padding: hovering? '0px':'0px 0px 5px 0px', 
                    position:'relative', 
                    outline: 'unset', 
                    height: '280px', 
                    // width: '290px',
                  }} 
                  ref={cardRef} 
                  to={`#`} 
                  onClick={() => setShowModa(true)} 
                  className='category_card customVideo'
                  
                >
                    {imageLoading && (
                        <div className='cat_img' style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Spinner animation="grow" />
                        </div>
                    )}   
                    {/* <GifPlayer gif={video}/> */}
                    <img 
                      onMouseOver={() => setHovering(true)} 
                      onMouseOut={() => setHovering(false)} 
                      style={{
                        width: '100%', 
                        height: hovering? '100%':'100%',
                        flexGrow: '1', 
                        transition: 'transform 0.4s ease-out, height 0.4s ease-out', 
                        position: 'absolute', 
                        bottom: hovering? '41px':'41px', 
                        left: '0px', 
                        right: '0px', 
                        top:'0px', 
                      }}  
                      src={
                        width <= 900?
                        thumb:
                        !hovering? thumb: lng === 'arab'? (videoAr||video):video
                      } 
                      alt={title}
                    />
                        <div style={{opacity: hovering? "1":'0', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', position:'absolute', bottom: '5px', borderRadius: hovering? '15px':null}} className={hovering? "videoBtnEffect textEffect":"textEffect"}>
                        {!displayMarq?
                        <span>
                            <label style={{whiteSpace: 'nowrap'}} ref={textRef}>{title}</label>
                        </span> :
                        <div className={lng === 'arab' ? 'cssmarquee_arab' : 'cssmarquee'} style={{ direction: 'ltr' }}>
                            <Marquee 
                              speed={0.025}
                              delay={0}
                              childMargin={15} 
                              direction={lng === 'arab' ? 'right' : 'left'} 
                              className='label_of_title'
                            >
                                <label ref={marqRef}>{title}</label>
                            </Marquee>
                        </div>
                        }
                        </div>
                </Link>
            </div>  
    )
}