import React, { Fragment, useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import "../../App.scss";
import styles from "../B2B/style.module.scss";
import perfectStyle from "./Perfect.module.scss";
import arrow from "../Assets/Icons/Next.png";
import women from "../Assets/Img/women.png";
import mobile from "../Assets/Img/mobile.png";
import rise from "../Assets/Icons/Rise.png";
import roll from "../Assets/Icons/Roll.png";
import output from "../Assets/Icons/output.png";
import { ArrowSvg, LatestBlogSvg } from "../../views/Blog/svg";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Navigation, Pagination, Scrollbar, A11y, autoplay } from "swiper";
import "swiper/css/navigation";
import 'swiper/css';
import { Swiper, SwiperSlide } from "swiper/react";
import PopUpModal from "../popUps/PopUpModal";
import { getBlog } from "../../actions/blog";
import { Spinner } from "react-bootstrap";

const Index = () => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const navigate = useNavigate();
  const user = useSelector((state) => state.userAuth);
  const [loginModal, setLoginModal] = useState(false);
  const dispatch = useDispatch();
  const blog = useSelector((state) => state.blog.blog);
  const blogLoading = useSelector((state) => state.blog.loading);

  const [view, setView] = useState(3);
  const [view2, setView2] = useState(3);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    if (window.innerWidth > 3400 && window.innerWidth <= 4000) {
      setView(4);
    } else if (window.innerWidth > 2530 && window.innerWidth <= 3400) {
      setView(4);
    } else if (window.innerWidth > 1860 && window.innerWidth <= 2530) {
      setView(4);
    } else if (window.innerWidth > 1400 && window.innerWidth <= 1860) {
      setView(2.5);
    } else if (window.innerWidth > 1111 && window.innerWidth <= 1400) {
      setView(2.5);
    } else if (window.innerWidth > 900 && window.innerWidth <= 1111) {
      setView(2);
    } else if (window.innerWidth > 700 && window.innerWidth <= 900) {
      setView(1.5);
    } else if (window.innerWidth > 550 && window.innerWidth <= 700) {
      setView(1);
    } else if (window.innerWidth > 400 && window.innerWidth <= 550) {
      setView(1);
    } else if (window.innerWidth > 280 && window.innerWidth <= 400) {
      setView(1);
    } else if (window.innerWidth > 210 && window.innerWidth <= 280) {
      setView(1);
    } else {
      setView(1);
    }
  }, [width]);

  useEffect(() => {
    dispatch(getBlog())
  }, [])

  let data = [
    {
      id: "1",
      image: "/assets/images/blog/slider1.png",
      nameEn: "What is AI-generated content and how does it work?",
      nameAr:
        "ما هو المحتوى الذي يتم إنشاؤه بواسطة الذكاء الاصطناعي وكيف يعمل؟",
      descriptionAr:
        "المحتوى الذي يتم إنشاؤه بواسطة الذكاء الاصطناعي هو المحتوى الذي يتم إنشاؤه باستخدام خوارزميات التعلم الآلي. تحلل هذه الخوارزميات كميات كبيرة من البيانات لتحديد الأنماط وتوليد محتوى يكون ذو صلة وجذابية للجمهور المستهدف. يمكن أن يأخذ المحتوى الذي يتم إنشاؤه بواسطة الذكاء الاصطناعي العديد من الأشكال، بما في ذلك المقالات ومنشورات وسائل التواصل الاجتماعي ووصف المنتجات وغيرها.",
      descriptionEn:
        "AI-generated content is content that is created using machine learning algorithms. These algorithms analyze large amounts of data to identify patterns and generate content that is relevant and engaging to the target audience. AI-generated content can take many forms, including articles, social media posts, product descriptions, and more.",
    },
    {
      id: "2",
      image: "/assets/images/blog/slider2.png",
      nameEn: "How AI is revolutionizing the Arabic language industry",
      nameAr: "طريقة ثورية للذكاء الاصطناعي في صناعة اللغة العربية",
      descriptionEn:
        "Artificial intelligence (AI) has been transforming various industries, and the Arabic language industry is no exception. From machine translation to speech recognition, AI is changing the way we interact with the Arabic language. In this blog post, we'll explore how AI is revolutionizing the Arabic language industry, its benefits, challenges, and the future of AI in the industry.",
      descriptionAr:
        "تحول الذكاء الاصطناعي (AI) العديد من الصناعات، وصناعة اللغة العربية ليست استثناءً. من الترجمة الآلية إلى التعرف على الكلام، يغير AI الطريقة التي نتفاعل بها مع اللغة العربية. في هذه المقالة، سنستكشف كيف يثور الذكاء الاصطناعي في صناعة اللغة العربية، فوائدها، تحدياتها، ومستقبل AI في الصناعة.",
    },
    {
      id: "3",
      image: "/assets/images/blog/slider3.png",
      nameEn: "The Ethics of AI: Balancing Innovation with Responsibility",
      nameAr: "أخلاقيات الذكاء الاصطناعي: الموازنة بين الابتكار والمسؤولية",
      descriptionEn:
        "In this blog post, we will be exploring the ethics of AI and the importance of balancing innovation with responsibility. We will cover everything from the basics to the advanced concepts, and provide you with a comprehensive guide to the ethics of AI.",
      descriptionAr:
        "في هذا المقال، سنستكشف أخلاقيات الذكاء الاصطناعي وأهمية توازن الابتكار مع المسؤولية. سنغطي كل شيء من الأساسيات إلى المفاهيم المتقدمة، ونقدم لك دليلًا شاملاً لأخلاقيات الذكاء الاصطناعي.",
    },
  ];

  return (
    <Box sx={{ direction: lng === "arab" ? "rtl" : "ltr" }}>
      <Box sx={{ paddingTop: "15px" }}>
        <Box className={styles.container}>
          <Grid container sx={{ maxWidth: "1200px", margin: "auto" }}>
            <Grid className={styles.grid} item md={6} sm={12} xs={12}>
              {/* <h4>Araby.ai</h4> */}
              {/* <hr />
              <h4>{t("Perfect Solution For your Business")}</h4> */}
              <h1 style={{ fontWeight: "500" }} className="email-header h1eh">
                {t("Everything you need to")}
                <br />
                {t("scale your team")}
              </h1>
              <Box className={styles.b_container}>
                <Box>
                  <h4>{t("I’m a small Business")}</h4>
                  <h6>
                    {t(
                      "Araby.Ai is tailored for all business sizes from small to large"
                    )}
                  </h6>
                  <Link to={"/business"} >{t("Get started")}</Link>
                  <img src={arrow} alt="" />
                </Box>
                <Box>
                  <h4>{t("We are a Corporate")}</h4>
                  <h6>
                    {t(
                      "Seamlessly integrate Araby.Ai at any level of your business."
                    )}
                  </h6>
                  <Link to={"/business"} href="#contact">{t("Get started")}</Link>
                  <img src={arrow} alt="" />
                </Box>
              </Box>
            </Grid>
            <Grid alignItems="center" item md={6} sm={12} xs={12}>
              <Box className={styles.right}>
                <Box className={styles.columns}>
                  <img
                    src={women}
                    className={lng === "arab" ? styles.imgA : styles.imgE}
                    alt=""
                  />
                  <Box>
                    <h1 style={{ direction: "ltr" }}>100 M</h1>
                    <h6>
                      {t("Fact checked")} <br /> {t("Words")}
                    </h6>
                  </Box>
                </Box>
                <Box className={styles.columnsR}>
                  <Box className={styles.grow}>
                    <h1>85%</h1>
                    <h6>
                      {t("Grow Your Business")} <br /> {t("And Performance")}
                    </h6>
                  </Box>
                  <Box className={styles.mobile}>
                    <img
                      src={mobile}
                      className={
                        lng === "arab" ? styles.mobileA : styles.mobileE
                      }
                      alt=""
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {blog?.length === 0 ?
      null:
      <div
        className={perfectStyle.blog}
        style={{
          paddingLeft: lng === "arab" ? "unset" : "10%",
          paddingRight: lng === "arab" ? "10%" : "unset",
          width: "100%",
          padding: "0px",
        }}
      >
        <div
          style={{
            margin: "0px",
            padding: "60px 10px 60px 10px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "unset",
          }}
          className={perfectStyle.banner}
        >
          <div style={{ maxWidth: "1200px", width: "100%" }}>
            <p className="email-header h1eh">{t("Latest Blog")}</p>
            <LatestBlogSvg />
          </div>
          <div style={{ maxWidth: "1200px", width: "100%" }}>
            <div className="row">
              {blogLoading ?
                <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "60px",
                }}
              >
                <Spinner />
              </div> :
              blog?.length === 0 ?
                null:
                <Fragment>
                        {blog?.length === 0 ? (
                          <p style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>{t("No Data Here")}</p>
                        ) : (
                          <Fragment>
                            {blog?.slice(0, 3)?.map((item, i) => (
                          <div
                            key={i}
                            className="p-3 col-lg-4 col-md-6 col-sm-12 blog_dash"
                          >
                            <Card sx={{ borderRadius: "16px", overflow: "hidden"}}>
                              <CardMedia
                                sx={{ height: "200px" }}
                                image={item.cover_image}
                                title={lng === "arab" ? item?.cover_alt_ar : item?.cover_alt_en}
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate(`/blog-details/${item?._id}`)}
                              />
                              <CardContent
                                sx={{
                                  bg: "white",
                                  paddingLeft: "20px",
                                  paddingTop: "20px",
                                  paddingBottom: "28px",
                                  paddingRight: "32px",
                                }}
                              >
                                <Typography
                                  gutterBottom
                                  variant="h4"
                                  sx={{
                                    margin: 0,
                                    color: "#000000",
                                    lineHeight: "28px",
                                    fontSize: "16px",
                                    fontWeight: 700,
                                    display: "-webkit-box",
                                    overflow: "hidden",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: 1,
                                    fontFamily: lng === "arab" ? "Tajawal" : "Poppins"
                                  }}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => navigate(`/blog-details/${item?._id}`)}
                                >
                                  {lng === "arab" ? item?.title_ar : item?.title_en}
                                </Typography>
                                <Typography
                                  sx={{
                                    display: "-webkit-box",
                                    overflow: "hidden",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: 2,
                                    color: "#000000",
                                    lineHeight: "180%",
                                    fontSize: "15px",
                                    fontWeight: 400,
                                    fontFamily: lng === "arab" ? "Tajawal" : "Poppins"
                                  }}
                                >
                                  {lng === "arab"
                                    ? item?.description_ar
                                    : item?.description_en}
                                </Typography>
                              </CardContent>
                              <CardActions
                                sx={{
                                  bg: "#F7F7F7",
                                  px: "20px",
                                  pt: "10px",
                                  pb: "33px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  flexDirection: lng === "arab" ? "row-reverse" : "row",
                                  direction: lng === "arab" ? "ltr" : "rtl",
                                }}
                              >
                                <Link
                                  style={{
                                    color: "#1877F2",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    lineHeight: "32px",
                                    fontFamily: lng === "arab" ? "Tajawal" : "Poppins"
                                  }}
                                  to={`/blog-details/${item?._id}`}
                                >
                                  {t("Read more")}
                                </Link>
                                <svg
                                  style={{
                                    cursor: "pointer",
                                    transform: lng === "arab" ? "rotate(180deg)" : null,
                                  }}
                                  onClick={() => navigate(`/blog-details/${item?._id}`)}
                                  width="16"
                                  height="16"
                                  viewBox="0 0 21 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clip-path="url(#clip0_165_278)">
                                    <path
                                      d="M10.5 20.9999C7.6954 20.9999 5.05854 19.9077 3.07537 17.9245C1.0922 15.9413 0 13.3045 0 10.4999C0 7.69527 1.0922 5.05842 3.07537 3.07525C5.05854 1.09208 7.6954 -0.00012207 10.5 -0.00012207C13.3046 -0.00012207 15.9415 1.09208 17.9246 3.07525C19.9078 5.05842 21 7.69527 21 10.4999C21 13.3045 19.9078 15.9413 17.9246 17.9245C15.9415 19.9077 13.3046 20.9999 10.5 20.9999ZM10.5 1.6405C5.61497 1.6405 1.64062 5.61485 1.64062 10.4999C1.64062 15.3849 5.61497 19.3593 10.5 19.3593C15.385 19.3593 19.3594 15.3849 19.3594 10.4999C19.3594 5.61485 15.385 1.6405 10.5 1.6405ZM14.7773 10.4999L9.10547 4.82803L7.94534 5.98816L12.4571 10.4999L7.94534 15.0116L9.10547 16.1717L14.7773 10.4999Z"
                                      fill="#1877F2"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_165_278">
                                      <rect width="21" height="21" fill="white" />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </CardActions>
                            </Card>
                          </div>
                        ))}
                        <div className="blogs_mobil" style={{ position: 'relative', width: '100%', maxWidth: '1100px' }}>
                          <Swiper
                            style={{ direction: "ltr" }}
                            modules={[Navigation]}
                            autoplay={{
                              delay: 500,
                            }}
                            navigation={{
                              prevEl: ".prev",
                              nextEl: ".next",
                            }}
                            spaceBetween={10}
                            slidesPerView={view}
                            loop={true}
                          >
                            {blog?.slice(0, 3)?.map((item, i) => (
                              <SwiperSlide>
                                <div key={i}>
                                  <Card
                                    sx={{
                                      borderRadius: "16px",
                                      overflow: "hidden",
                                      margin: "50px",
                                    }}
                                  >
                                    <CardMedia
                                      sx={{ height: "180px" }}
                                      image={item.cover_image}
                                      title={lng === "arab" ? item?.cover_alt_ar : item?.cover_alt_en}
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        navigate(`/blog-details/${item?._id}`)
                                      }
                                    />
                                    <CardContent
                                      sx={{
                                        bg: "white",
                                        paddingLeft: "20px",
                                        paddingTop: "20px",
                                        paddingBottom: "28px",
                                        paddingRight: "32px",
                                      }}
                                    >
                                      <Typography
                                        gutterBottom
                                        variant="h4"
                                        sx={{
                                          margin: 0,
                                          color: "#000000",
                                          lineHeight: "28px",
                                          fontSize: "16px",
                                          fontWeight: 700,
                                          display: "-webkit-box",
                                          overflow: "hidden",
                                          WebkitBoxOrient: "vertical",
                                          WebkitLineClamp: 1,
                                        }}
                                        style={{ cursor: "pointer", textAlign: lng === 'arab' ? "right" : "left" }}
                                        onClick={() =>
                                          navigate(`/blog-details/${item?._id}`)
                                        }
                                      >
                                        {lng === "arab" ? item?.title_ar : item?.title_en}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          display: "-webkit-box",
                                          overflow: "hidden",
                                          WebkitBoxOrient: "vertical",
                                          WebkitLineClamp: 2,
                                          color: "#000000",
                                          lineHeight: "180%",
                                          fontSize: "15px",
                                          fontWeight: 400,
                                        }}
                                        style={{ textAlign: lng === 'arab' ? "right" : "left" }}
                                      >
                                        {lng === "arab"
                                          ? item?.description_ar
                                          : item?.description_en}
                                      </Typography>
                                    </CardContent>
                                    <CardActions
                                      sx={{
                                        bg: "#F7F7F7",
                                        px: "20px",
                                        pt: "10px",
                                        pb: "16px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        flexDirection:
                                          lng === "arab" ? "row-reverse" : "row",
                                        direction: lng === "arab" ? "ltr" : "rtl",
                                      }}
                                    >
                                      <Link
                                        style={{
                                          color: "#1877F2",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          lineHeight: "32px",
                                        }}
                                        to={`/blog-details/${item?._id}`}
                                      >
                                        {t("Read more")}
                                      </Link>
                                      <svg
                                        style={{
                                          cursor: "pointer",
                                          transform:
                                            lng === "arab" ? "rotate(180deg)" : null,
                                        }}
                                        onClick={() =>
                                          navigate(`/blog-details/${item?._id}`)
                                        }
                                        width="16"
                                        height="16"
                                        viewBox="0 0 21 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g clip-path="url(#clip0_165_278)">
                                          <path
                                            d="M10.5 20.9999C7.6954 20.9999 5.05854 19.9077 3.07537 17.9245C1.0922 15.9413 0 13.3045 0 10.4999C0 7.69527 1.0922 5.05842 3.07537 3.07525C5.05854 1.09208 7.6954 -0.00012207 10.5 -0.00012207C13.3046 -0.00012207 15.9415 1.09208 17.9246 3.07525C19.9078 5.05842 21 7.69527 21 10.4999C21 13.3045 19.9078 15.9413 17.9246 17.9245C15.9415 19.9077 13.3046 20.9999 10.5 20.9999ZM10.5 1.6405C5.61497 1.6405 1.64062 5.61485 1.64062 10.4999C1.64062 15.3849 5.61497 19.3593 10.5 19.3593C15.385 19.3593 19.3594 15.3849 19.3594 10.4999C19.3594 5.61485 15.385 1.6405 10.5 1.6405ZM14.7773 10.4999L9.10547 4.82803L7.94534 5.98816L12.4571 10.4999L7.94534 15.0116L9.10547 16.1717L14.7773 10.4999Z"
                                            fill="#1877F2"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_165_278">
                                            <rect width="21" height="21" fill="white" />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </CardActions>
                                  </Card>
                                </div>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                          <svg className='prev' width={width <= 900 ? "30" : "40"} height={width <= 900 ? "30.5" : "41"} viewBox="0 0 67 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <ellipse cx="33.5" cy="33.6796" rx="33.5" ry="33.6796" fill="#F5F5F5" />
                            <ellipse cx="33.5" cy="33.6796" rx="26.6421" ry="26.5" transform="rotate(-90 33.5 33.6796)" fill="currentColor" />
                            <path d="M24.0833 33.8447L44.625 33.8447" stroke="#F5F5F5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M32 42.8444C32 38.9872 24 33.8444 24 33.8444C24 33.8444 32 28.7015 32 24.8444" stroke="#F5F5F5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
          
                          <svg className='next' width={width <= 900 ? "30" : "40"} height={width <= 900 ? "30.5" : "41"} viewBox="0 0 67 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <ellipse cx="33.5" cy="33.835" rx="33.5" ry="33.6796" fill="#F5F5F5" />
                            <g filter="url(#filter0_i_165_317)">
                              <ellipse cx="33.5" cy="33.835" rx="26.6421" ry="26.5" transform="rotate(-90 33.5 33.835)" fill="currentColor" />
                            </g>
                            <path d="M43.9167 34.0001L23.375 34.0001" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M36 42.9998C36 39.1426 44 33.9998 44 33.9998C44 33.9998 36 28.8569 36 24.9998" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <defs>
                              <filter id="filter0_i_165_317" x="7" y="7.19287" width="60" height="53.2843" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dx="7" />
                                <feGaussianBlur stdDeviation="10" />
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0" />
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_165_317" />
                              </filter>
                            </defs>
                          </svg>
                        </div>
                            </Fragment>
                        )}
                </Fragment>
              }
            </div>
          </div>
        </div>
      </div>}
      <Box
        style={{ boxShadow: "unset", width: "100%", maxWidth: "1200px" }}
        className={styles.scaleContainer}
        id='scaleContainer'
      >
        <h1 className="email-header h1eh">
          {t("Accelerate Your business and personal KPIs with")} <br />{" "}
          {t("artificial intelligence")}
        </h1>
        <h6 className="h6introcontent customH6">
          {t(
            "Grow bigger with araby.ai. streamline your work and increase your quality without sacrificing time or effort."
          )}
        </h6>
        <div className={styles.boxes}>
          <div>
            <h1>
              <img src={rise} alt="" />
              80%
            </h1>
            <h6>{t("Time reduced spend on 1st draft")}</h6>
          </div>
          <div>
            <h1>
              <img src={output} alt="" />
              10X
            </h1>
            <h6>{t("Output of High-quality content")}</h6>
          </div>
          <div>
            <h1>
              <img src={roll} alt="" />
              400%
            </h1>
            <h6>{t("Roi using Araby ai")}</h6>
          </div>
        </div>
        <button
          style={{ marginTop: "30px" }}
          className={
            lng === "arab"
              ? "btn common-btn fs-5 fw-bold"
              : "btn common-btn fs-5"
          }
          onClick={() =>
            !user
              ? setLoginModal(true)
              : window.open(
                process.env.REACT_APP_DASHBOARD_URL +
                "?t=" +
                localStorage.getItem("sess"),
                "_blank"
              )
          }
        >
          {t("Start Now")}
        </button>
      </Box>
      <PopUpModal
        show={loginModal}
        login={true}
        onHide={() => setLoginModal(false)}
      />
    </Box>
  );
};

export default Index;
