import * as React from "react";
import { useRef } from "react";
import { motion, sync, useCycle } from "framer-motion";
import { useDimensions } from "./use-dimensions";
import { MenuToggle } from "./MenuToggle";
import { Navigation } from "./Navigation";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(30px at 40px 40px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

export const Example = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const user = useSelector((state) => state.userAuth);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <motion.nav
      className="n-nav"
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
    >
      <Navigation
        open={isOpen}
        close={toggleOpen}
        lng={lng}
        t={t}
        user={user}
        dispatch={dispatch}
      />
      <MenuToggle toggle={() => toggleOpen()} />
    </motion.nav>
  );
};
