import "./Pricing.css";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Marquee from "react-fast-marquee";
import Marquee from "react-easy-marquee";
import { marqueeData, accordionData,marqueeData2 } from "./Data";
import { useTranslation } from "react-i18next";
import Plans from "./Plans";
import { useEffect, useState } from 'react';
import PopUpModal from "../popUps/PopUpModal";
import { useSelector } from "react-redux";

const Pricing = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [loginModal, setLoginModal] = useState(false);
  const user = useSelector((state) => state.userAuth);

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  return (
    <>
    <Container
      style={{ direction: lng === "arab" ? "rtl" : "ltr", marginTop: "50px" }}
    >
      <Plans />
      <div className="pricing">
        <h1>{t("Frequently Asked Questions")}</h1>
        <section className="pricing-accordion-wrapper">
          {accordionData.map((items, index) => {
            return (
              <Accordion
                disableGutters
                key={index}
                sx={{
                  "&:before": {
                    display: "none",
                  },

                }}
                className="pricing-accordion"
              >
                <AccordionSummary
                  className="pricing-accordion-summary"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h1 style={{padding:"8px"}} color="black">{t(items.question)}</h1>
                </AccordionSummary>
                <AccordionDetails className="pricing-accordion-details">
                  <Typography color="#7B797D">{t(items.answer)}</Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </section>
        <section className="coding-languages">
          <h1>{t("What can Araby.Ai write for you?")}</h1>
          <h4>
           {t("Our AI has been trained on high-performing copy from the")}
            <br /> {t("top brands. It knows what converts and how to write content")}
            <br /> {t("that resonates with your audience.")}
          </h4>
          <section className="pricing-slider">
            <Marquee style={{ marginTop: "15px",marginBottom:"15px" }} duration={120000}>
              {marqueeData.map((items, index) => {
                return (
                    <div
                      key={index}
                      className="marquee-item-cards"
                      style={{ opacity: "1" }}
                    >
                      <div>
                        <img src={items.image} alt="java" />
                      </div>
                      <h4>{items.text}</h4>
                    </div>
                );
              })}
            </Marquee>
            <Marquee
              duration={150000}
              reverse={true}
              style={{ marginTop: "15px",marginBottom:"15px" }}             >
              {marqueeData2.map((items, index) => {
                return (
                  <div
                    key={index}
                    className="marquee-item-cards"
                    style={{ opacity: "0.8" }}
                  >
                    <div>
                      <img src={items.image} alt="java" />
                    </div>
                    <h4>{items.text}</h4>
                  </div>
                );
              })}
            </Marquee>
            <Marquee duration={140000} style={{ marginTop: "15px",marginBottom:"15px" }}>
              {marqueeData.map((items, index) => {
                return (
                  <div
                    key={index}
                    className="marquee-item-cards"
                    style={{ opacity: "0.6" }}
                  >
                    <div>
                      <img src={items.image} alt="java" />
                    </div>
                    <h4>{items.text}</h4>
                  </div>
                );
              })}
            </Marquee>
            <Marquee
              duration={130000}
              reverse={true}
              style={{ marginTop: "15px",marginBottom:"15px" }}
            >
              {marqueeData2.map((items, index) => {
                return (
                  <div
                    key={index}
                    className="marquee-item-cards"
                    style={{ opacity: "0.4" }}
                  >
                    <div>
                      <img src={items.image} alt="java" />
                    </div>
                    <h4>{items.text}</h4>
                  </div>
                );
              })}
            </Marquee>
          </section>
          <h3>{t("Over 11 Coding Language")}</h3>
          <h6>{t("Generate High Quality Code in Seconds")}</h6>
          <button
            onClick={() => !user? setLoginModal(true) : window.open(process.env.REACT_APP_DASHBOARD_URL+"?t=" +localStorage.getItem("sess"),"_blank")}
          >{t("Get Started For Free")}</button>
        </section>
        {/* <section className="reviews">
          <h1>Let's hear what they say about ArabyAi</h1>
          <div className="reviews-card-wrapper">
            {reviewsData.map((items, index) => {
              return (
                <>
                  <div key={index} className="reviews-cards">
                    <div className="reviews-card-head">
                      <div className="reviews-card-hero">
                        <Avatar
                          sx={{
                            marginRight: "10px",
                            width: "56px",
                            height: "56px",
                          }}
                          alt={items.name}

                          // src="/static/images/avatar/1.jpg"
                        />
                        <span>
                          <h5>{items.name}</h5>
                          <h6>{items.Profession}</h6>
                        </span>
                      </div>
                      <div className="review-star">
                        <h5>5</h5>
                        <StarRoundedIcon sx={{ color: "#EFCE4A", ml: "2px" }} />
                      </div>
                    </div>
                    <div className="reviews-card-content">
                      <h5>{items.highlight}</h5>
                      <h6>{items.text} </h6>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </section>  */}
        <PopUpModal
          show={loginModal}
          login={true}
          onHide={() => setLoginModal(false)}
        />
      </div>
    </Container>
    </>
  );
};

export default Pricing;
