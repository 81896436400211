import React from "react";
import { FC } from "react";
import styles from "./animated_hand_wave.module.scss";
const AnimatedHandWave: FC = () => {
  return (
    <span className={styles["wave-emoji"]} role="img" aria-label="waving hand">
      👋
    </span>
  );
};
export default AnimatedHandWave;
