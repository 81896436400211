class Assets {
  static readonly baseURL: string = window.location.origin;
  ///social
  static readonly GOOGLE_ICON: string = `${this.baseURL}/assets/newDesign/googleIcon.svg`;
  static readonly FACEBOOK_ICON: string = `${this.baseURL}/assets/newDesign/facebook.svg`;
  static readonly APPLE_ICON: string = `${this.baseURL}/assets/newDesign/apple.svg`;

  static readonly ARAAB_AI_LOGO: string = `${this.baseURL}/assets/newDesign/newLogo.png`;
  static readonly LOGIN_SIDE_BACKGROUND: string = `${this.baseURL}/assets/newDesign/login_side_background.png`;
  
  static readonly SUBMIT_ARROW: string = `${this.baseURL}/assets/newDesign/submitArrow.svg`;
  static readonly EDIT_ICON: string = `${this.baseURL}/assets/newDesign/editIcon.svg`;
}

export default Assets;
