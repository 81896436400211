/* eslint-disable */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./footer.scss";
import { isMobile, isAndroid, isIOS } from "react-device-detect";

import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faTiktok,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import Icon from "./backgroundWave";
import { Link, useNavigate } from "react-router-dom";
import { ContactUsModal } from "./popUps/ContactUsModal";
import mastercard from "../components/Assets/Icons/mastercard.png";
import visa from "../components/Assets/Icons/visa.png";
import verified from "../components/Assets/Icons/verifiedvisaicon.png";
import mastersecure from "../components/Assets/Icons/MasterCardSecureCode.png";
import newFooter from "../components/Assets/Img/newFooter.png";
import AppleIcon from '@mui/icons-material/Apple';
import playS from "../components/Assets/Img/playstore.png";
// import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;

  const scrollToContactUs = () => {
    const element = document.getElementById("contact-us");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <footer
      style={{
        position: "relative",
        direction: lng === "arab" ? "rtl" : "ltr",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        overflow: "hidden",
      }}
    >
      <img
        src={newFooter}
        alt="footer_background"
        style={{
          objectFit: "cover",
          position: "absolute",
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          zIndex: "10",
          height: "100%",
          width: "100%",
        }}
      />
      <div
        style={{
          background: "unset",
          zIndex: "11",
          position: "relative",
          paddingBottom: "65px",
        }}
        className="footer-top-wrap m-auto"
      >
        <div className="container">
          <div style={{ justifyContent: "center", rowGap: "30px" }} className="row">
            <div
              style={{
                textAlign: lng === "arab" ? "right" : "left",
                rowGap: "20px",
              }}
              className="row col-lg-10 col-md-12"
            >
              <div className="col-lg-3 col-md-4 col-sm-4 footerElement">
                <div className="footer-widget">
                  <h4 className="fw-title">{t("Company")}</h4>
                  <ul className="fw-links">
                    <li>
                      <Link to="/pricing">{t("Pricing")}</Link>
                    </li>
                    <li>
                      <Link to="/terms-and-conditions">{t("Terms and Conditions")}</Link>
                    </li>
                    <li>
                      <Link to="/privacy-and-policy">{t("Privacy Policy")}</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-4 footerElement">
                <div className="footer-widget">
                  <h4 className="fw-title">{t("Help")}</h4>
                  <ul className="fw-links">
                    <li>
                      <button style={{ textAlign: lng === "arab" ? "right" : "left" }} onClick={() => navigate("/contact")}>
                        {t("Contact Us")}
                      </button>
                    </li>
                    {/* HIDE STATIC ELEMENTS: Partners */}
                    <li>
                      <button style={{ textAlign: lng === "arab" ? "right" : "left" }} onClick={() => setShowModal(true)}>
                        {t("Share Feedback")}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-4 footerElement">
                <div className="footer-widget">
                  <h4 className="fw-title">{t("Support")}</h4>
                  <ul className="fw-links">
                    {/* HIDE STATIC ELEMENTS: Help Center */}
                    <li>
                      <button style={{ textAlign: lng === "arab" ? "right" : "left" }} onClick={() => setShowModal(true)}>
                        {t("Report a Bug")}
                      </button>
                    </li>
                    <li>
                      <button style={{ textAlign: lng === "arab" ? "right" : "left" }} onClick={() => setShowModal(true)}>
                        {t("Report an Outage")}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-4 footerElement">
                <div className="footer-widget">
                  <h4 className="fw-title">{t("Follow Us")}</h4>
                  <div className="footer-social">
                    <ul
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                      className="fw-links"
                    >
                      <li>
                        <a href="https://www.instagram.com/araby.ai/" target="_blank" rel="noopener noreferrer">
                          <div className="icon">
                            <FontAwesomeIcon icon={faInstagram} />
                          </div>{" "}
                          @Araby AI
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/ai_araby" target="_blank">
                          <div className="icon">
                            <FontAwesomeIcon icon={faTwitter} />
                          </div>{" "}
                          @ArabyAI
                        </a>
                      </li>
                      <li>
                        <a href="https://www.tiktok.com/@araby.ai" target="_blank">
                          <div className="icon">
                            <FontAwesomeIcon icon={faTiktok} />
                          </div>{" "}
                          @ArabyAI
                        </a>
                      </li>

                      {/* <li ><a href="/#"><div className="icon"><FontAwesomeIcon icon={faFacebookF} /></div> @ArabyAI</a></li> */}
                      <li>
                        <a href="https://www.linkedin.com/company/araby-ai/" target="_blank" rel="noopener noreferrer">
                          <div className="icon">
                            <FontAwesomeIcon icon={faLinkedin} />
                          </div>{" "}
                          @Araby AI
                        </a>
                      </li>
                      {/* <li ><a href="/#"><div className="icon"><FontAwesomeIcon icon={faYoutube} /></div> @Araby AI</a></li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-10 col-lg-10 col-md-8 col-sm-6">
                <div className="card_detials">
                  <p>{t("We accept payments online using Visa and MasterCard credit/debit card in SAR")}</p>
                </div>
                <div className="cards">
                  <div style={{ display: "flex", gap: "20px" }}>
                    <img src={mastercard} alt="visa" width="60px" />
                    <img src={visa} alt="visa" width="60px" />
                    <img src={verified} alt="visa" width="60px" />
                    <img src={mastersecure} alt="visa" width="60px" />
                  </div>
                  {/* {isAndroid || isIOS ? ( */}
                    <div className="mobile_Links">
                      {/* {isIOS ? ( */}
                        <a href="https://apps.apple.com/in/app/araby-ai/id6447341656" target="_blank">
                          <div className="mobile_links_container">
                            <AppleIcon color="#000" fontSize="large" />
                            <span>
                              <p>{t("DOWNLOAD ON THE")}</p>
                              <h6>APP STORE</h6>
                            </span>
                          </div>
                        </a>
                      {/* ) : isAndroid ? ( */}
                        <a href="https://play.google.com/store/apps/details?id=com.araby.ai" target="_blank" >
                          <div className="mobile_links_container">
                            <img src={playS} />
                            <span>
                              <p>{t("GET IT ON")}</p>
                              <h6>GOOGLE PLAY</h6>
                            </span>
                          </div>
                        </a>
                      {/* ) : null} */}
                    </div>
                  {/* ) : null} */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row main_cards col-lg-10 col-md-12">
        <div className=" offset-md-1 col-lg-10 col-md-10 col-sm-6">
        <div className="card_detials">
              <p>{t("We accept payments online using Visa and MasterCard credit/debit card in SAR")}</p>
            </div>
            <div className="cards">
              <img src={mastercard} alt="visa" width='70px'  />
              <img src={visa} alt="visa" width='70px' />
              <img src={verified} alt="visa"width='70px' />
              <img src={mastersecure} alt="visa"width='70px'  />
            </div>
        </div>
          </div> */}
      </div>
      {/* <div style={{background: 'unset'}} className="copyright-wrap">
        <center className="container ">
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', flexDirection: 'column' }}>
            <div style={{ direction: 'ltr', display: "flex", alignItems: "center", justifyContent: 'center', gap: '5px' }}>
              <img
                src={"/assets/newDesign/newLogo.png"}
                style={{ color: "#fff" }}
                height="42px"
                width="38px"
              />
              <p style={{ color: "#fff", padding: '0px', margin: '0px', marginTop: '4px' }}>
                Araby AI
              </p>
            </div>
            <div className="copyright-text">
              <p>© {new Date().getFullYear()} {t("Araby AI All rights reserved.")}</p>
            </div>
          </div>
        </center>
      </div> */}
      <ContactUsModal onHide={() => setShowModal(false)} show={showModal} />
    </footer>
  );
};

export default Footer;
