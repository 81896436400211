import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next';
const BrowserModal = (props) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  return (    
    <Modal
      {...props}
      size="lg"
      centered
      className='auth_modal'
    >
      <Modal.Body className='forceArabicFont row' style={{ direction: lng === 'arab'? 'rtl': 'ltr', display: 'flex', padding: '5px' }}>
        <div style={{display: 'flex', flexDirection: 'column', alignContent: 'center',  justifyContent: 'center', gap: '10px'}} className='modal_img_wrapper widthMedia form-group col-md-5 col-sm-12 col-lg-12'>
          <img style={{alignSelf: 'center'}} src={"../assets/newDesign/Oops1.svg"} alt="Oops" height="100px" width="100px" />
          <label className='image_title'>{t("Incompatible Browser Detected!")}</label>
          <div>{t("Dear user, please be aware that ")}<b>Google Sign In </b>{t(" is not available for the browser you are currently using. Follow the steps shown below to open this page in your system browser (Chrome or Safari)")}</div>
          <img style={{ alignSelf: 'center', width: '100%'}}   src={"../assets/browser_guide2.jpg"} alt='browser change guide' />
          <button onClick={() => {
            // props.setModalGuideOpenBrowser(true);
            props.onHide();
            }} className='btn common-btn'>{t("GOT IT")}</button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default BrowserModal