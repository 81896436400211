import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Icon from '../backgroundWave'
import styles from "../../styles/Home.module.css";
import Privacy from '../privacyPolicy/Privacy';
import Terms from '../termsandCond/Terms';

const StaticContentPage = ({isPrivacy}) => {
    useEffect(() => {
        window.scrollTo(10, 0);
      }, []);
  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '40px 0px 40px 0px'}}>
        <Link style={{position: 'absolute', top: '20px', zIndex: '1000', left: '30px', minHeight: '36px', backgroundColor: '#fff', borderRadius: '10px', minWidth:'60px', display: 'flex', alignItems: 'center', justifyContent: 'center'}} to='/'>Back</Link>
        <div className={styles.privacyWrapper}>
            <Icon />
            <div style={{position: "absolute", bottom: "0px", left: "0px", right: "0px", zIndex: "111"}}>
                <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                  <defs>
                    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"/>
                  </defs>
                  <g className="parallax">
                    <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"/>
                    <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"/>
                    <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"/>
                    <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                  </g>
                </svg>
            </div>
        </div>
        <div style={{whiteSpace: 'pre-wrap', display: 'flex', justifyContent: 'center'}} className='white_paper'>
            {isPrivacy?
            <Privacy/>:
            <Terms/>
            }
        </div>
    </div>
  )
}

export default StaticContentPage