import styles from "./blog.module.scss";
import Carousel from "react-material-ui-carousel";
import { Button, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { ArrowSvg, LatestBlogSvg, LeftArrowSvg, RightArrowSvg } from "./svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { emailNewsLetter, getBlog } from "../../actions/blog";
import { toastAlert } from "../../components/alert/toastAlert";
import LoadingEffect from "../../components/LoadingEffect/LoadingEffect";
import RecaptchaPopUp from "../RecaptchaPopUp";
import { Spinner } from "react-bootstrap";

const Blog = () => {
  const dispatch = useDispatch();
  const blog = useSelector((state) => state.blog.blog);
  const blogLoading = useSelector((state) => state.blog.loading);
  const isSmall = useMediaQuery("(max-width:728px)");
  const [items, setItems] = useState([]);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  let lng = JSON.parse(localStorage.getItem("language"))?.lang;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getBlog());
  }, []);

  useEffect(() => {
    if (!blogLoading) {
      const itemsPerSlide = isSmall ? 1 : 3;
      const sliderItems = (isSmall ? blog.length >= 1 : blog.length > 3)
        ? itemsPerSlide
        : blog.length;
      const arr = [];
      for (let i = 0; i < blog.length; i += sliderItems) {
        if (i % sliderItems === 0) {
          arr.push(
            <div key={i} className={styles.carouselItem}>
              {blog.slice(i, i + sliderItems).map((da, index) => {
                return (
                  <Card
                    key={index}
                    sx={{
                      width: { xs: "100%", lg: "33.3%" },
                      borderRadius: { xs: "16px", lg: "40px" },
                      overflow: "hidden",
                      margin: { xs: "20px", lg: "unset" },
                      height: { xs: "368px", lg: "unset" },
                      fontFamily: lng === "arab" ? "Tajawal" : "Poppins",
                    }}
                  >
                    <CardMedia
                      sx={{
                        height: { xs: "180px", lg: "284px" },
                        cursor: "pointer",
                      }}
                      image={da?.cover_image}
                      title={
                        lng === "arab" ? da?.cover_alt_ar : da?.cover_alt_en
                      }
                      onClick={() => navigate(`/blog-details/${da?._id}`)}
                    />
                    <CardContent
                      sx={{
                        bg: "white",
                        paddingLeft: "20px",
                        paddingTop: { xs: "8px", lg: "20px" },
                        paddingBottom: { xs: "16px", lg: "28px" },
                        paddingRight: "32px",
                      }}
                    >
                      <Typography
                        gutterBottom
                        sx={{
                          cursor: "pointer",
                          color: "#0C111F",
                          lineHeight: "28px",
                          fontSize: { xs: "16px", lg: "24px" },
                          fontWeight: 700,
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          fontFamily: lng === "arab" ? "Tajawal" : "Poppins",
                        }}
                        onClick={() => navigate(`/blog-details/${da?._id}`)}
                      >
                        {lng === "arab" ? da?.title_ar : da?.title_en}
                      </Typography>
                      <Typography
                        sx={{
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          color: "rgba(12, 17, 31, 0.6)",
                          lineHeight: "180%",
                          fontSize: { xs: "15px", lg: "18px" },
                          fontWeight: 400,
                          fontFamily: lng === "arab" ? "Tajawal" : "Poppins",
                        }}
                      >
                        {lng === "arab"
                          ? da?.description_ar
                          : da?.description_en}
                      </Typography>
                    </CardContent>
                    <CardActions
                      sx={{
                        bg: "#F7F7F7",
                        px: "20px",
                        pt: "10px",
                        pb: "33px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: lng === "arab" ? "row-reverse" : "row",
                        direction: lng === "arab" ? "ltr" : "rtl",
                      }}
                    >
                      <Link
                        style={{
                          color: "#1877F2",
                          fontWeight: 500,
                          fontSize: "18px",
                          lineHeight: "32px",
                        }}
                        to={`/blog-details/${da?._id}`}
                      >
                        {t("Read more")}
                      </Link>
                      <ArrowSvg
                        style={{
                          cursor: "pointer",
                          transform: lng === "arab" ? "rotate(180deg)" : null,
                        }}
                        onClick={() => navigate(`/blog-details/${da?._id}`)}
                      />
                    </CardActions>
                  </Card>
                );
              })}
            </div>
          );
        }
      }
      setItems(arr);
    }
  }, [isSmall, blogLoading]);
  const [captchaResponse, setCaptchaResponse] = useState(0);
  const [recaptchaPopUp, setRecaptchaPopUp] = useState(false);
  const sendTicket = async () => {
    setLoading(true);
    if (!email) {
      setLoading(false);
      toastAlert(t("Please enter your email"), undefined, "warning");
      return;
    }
    setRecaptchaPopUp(true);
  };
  const handleSubmit = () => {
    dispatch(emailNewsLetter(email, setLoading, setEmail));
  };

  useEffect(() => {
    if (captchaResponse) {
      handleSubmit();
    }
  }, [captchaResponse]);
  return (
    <div className={styles.blog} dir={lng === "arab" ? "rtl" : "ltr"}>
      <div className={styles.banner}>
        <div>
          {lng === "arab" ? (
            <p>
              انتج محتوى عالي الجودة في
              <span className={styles.minutes}>دقائق</span>
            </p>
          ) : (
            <p>
              {"Generate High-Quality Posts in "}
              <s>{"Days"}</s>
              <span className={styles.minutes}>{" Minutes"}</span>
            </p>
          )}
          {/* <p>{t("Say goodbye to endless hours of writing and hello to more free time with Araby.ai! Our Al writer takes the burden of writing off your shoulders, so you can focus on the things you love. With high-quality, engaging content at your fingertips, your business will thrive. Ready to experience the difference? Try Araby.ai today!")}</p> */}
          <p>
            {t(
              "Discover the Latest Insights and Trends in AI with Our Expertly Curated Blog Content"
            )}
          </p>
          {/* TODO:
                    Uncomment Later */}
          {/* <div>
                        <button>{t("Start building")}</button>
                        <button>{t("Read Blog")}</button>
                    </div> */}
        </div>
        {blog?.length && 
        <div className="container">
          <div>
            <div>
              <p>{t("Latest Blog")}</p>
              <LatestBlogSvg />
            </div>
            {blog?.length > 3 && (
            <div>
              {!isSmall && (
                <Fragment>
                  <button
                    onClick={() => {
                      const get = document.getElementById("prev-btn");
                      get.click();
                    }}
                  >
                    {lng === "arab" ? <RightArrowSvg /> : <LeftArrowSvg />}
                  </button>

                  <button
                    onClick={() => {
                      const get = document.getElementById("next-btn");
                      get.click();
                    }}
                  >
                    {lng === "arab" ? <LeftArrowSvg /> : <RightArrowSvg />}
                  </button>
                </Fragment>
              )}
            </div>
            )}
          </div>
          {blog?.length > 0 && (
          <div
            style={{
              padding: isSmall && "3px",
              display: isSmall && "flex",
              justifyContent: isSmall && "center",
              alignItems: isSmall && "center",
            }}
          >
            {!blogLoading && (
              <>
                {isSmall && (
                  <button
                    style={{
                      border: "unset",
                      width: "30px",
                      height: "30.5px",
                      padding: "unset",
                      borderRadius: "50%",
                    }}
                    onClick={() => {
                      const get = document.getElementById("prev-btn");
                      get.click();
                    }}
                  >
                    {lng === "arab" ? (
                      <RightArrowSvg width={30} height={30.5} />
                    ) : (
                      <LeftArrowSvg width={30} height={30.5} />
                    )}
                  </button>
                )}
                <Carousel
                  sx={{
                    width: "100%",
                    "& .css-ktslay-MuiButtonBase-root-MuiIconButton-root": {
                      display: "none",
                    },
                  }}
                  indicators={false}
                  autoPlay={false}
                  NextIcon={<button id="next-btn" />}
                  PrevIcon={<button id="prev-btn" />}
                >
                  {items}
                </Carousel>
                {isSmall && (
                  <button
                    style={{
                      border: "unset",
                      width: "30px",
                      height: "30.5px",
                      padding: "unset",
                      borderRadius: "50%",
                    }}
                    onClick={() => {
                      const get = document.getElementById("next-btn");
                      get.click();
                    }}
                  >
                    {lng === "arab" ? (
                      <LeftArrowSvg width={30} height={30.5} />
                    ) : (
                      <RightArrowSvg width={30} height={30.5} />
                    )}
                  </button>
                )}
              </>
            )}
          </div>
          )}
        </div>}
      </div>
      {blogLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "60px",
          }}
        >
          <Spinner />
        </div>
      ) : (
        blog?.length === 0? 
          null:
          <div className={styles.featured}>
          <div>
            <p>{t("Featured News")}</p>
            <LatestBlogSvg />
          </div>

          <div>
            <img
              onClick={() =>
                navigate(`/blog-details/${blog?.length < 4 ? blog[0]?._id : blog[4]?._id}`)
              }
              src={blog?.length < 4 ? blog[0]?.cover_image : blog[blog?.length - 4]?.cover_image}
              alt={
                lng === "arab"
                  ? blog?.length < 4 ? blog[0]?.cover_alt_ar : blog[blog?.length - 4]?.cover_alt_ar
                  : blog?.length < 4 ? blog[0]?.cover_alt_en : blog[blog?.length - 4]?.cover_alt_en
              }
              style={{ cursor: "pointer" }}
            />
            <div>
              <p
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(`/blog-details/${blog?.length < 4 ? blog[0]?._id : blog[blog?.length - 4]?._id}`)
                }
              >
                {lng === "arab"
                  ? blog?.length < 4 ? blog[0]?.title_ar : blog[blog?.length - 4]?.title_ar
                  : blog?.length < 4 ? blog[0]?.title_en : blog[blog?.length - 4]?.title_en}
              </p>
              <div>
                <p>
                  {lng === "arab"
                    ? blog?.length < 4 ? blog[0]?.description_ar : blog[blog?.length - 4]?.description_ar
                    : blog?.length < 4 ? blog[0]?.description_en : blog[blog?.length - 4]?.description_en}
                </p>
                <Link
                  style={{ color: "#1877F2" }}
                  to={`/blog-details/${blog?.length < 4 ? blog[0]?._id : blog[blog?.length - 4]?._id}`}
                >
                  {t("Read more")}
                </Link>
              </div>
              {/* TODO:
                        Uncomment Later */}
              {/* <div>
                            <img src={"/assets/images/blog/avatar.png"} alt={"avatar"} />
                            <div>
                                <p>Kayle Gg</p>
                                <p>April 20-2023</p>
                            </div>
                        </div> */}
            </div>
          </div>

          <div>
            <div className="row">
              {blog?.slice(blog?.length - 3, blog?.length).map((item, i) => (
                <div key={i} className="p-3 col-lg-4 col-md-6 col-sm-12">
                  <div className={styles.card}>
                    <img
                      src={item?.cover_image}
                      alt={
                        lng === "arab" ? item?.cover_alt_ar : item.cover_alt_en
                      }
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/blog-details/${item?._id}`)}
                    />
                    <h1
                      onClick={() => navigate(`/blog-details/${item?._id}`)}
                      className={styles.topic}
                      style={{ cursor: "pointer" }}
                    >
                      {lng === "arab" ? item?.title_ar : item?.title_en}
                    </h1>
                    <p>{lng === "arab" ? item?.title_ar : item?.title_en}</p>
                    <div>
                      <p>
                        {lng === "arab"
                          ? item?.description_ar
                          : item?.description_en}
                      </p>
                      <Link
                        style={{ color: "#1877F2" }}
                        to={`/blog-details/${item?._id}`}
                      >
                        {t("Read more")}
                      </Link>
                    </div>
                    {/* TODO:
                                    Uncomment later */}
                    {/* <div>
                                        <img src={item?.avatar} alt={"avatar"} />
                                        <div>
                                            <p>{item?.author}</p>
                                            <p>{item?.date}</p>
                                        </div>
                                    </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className={styles.newsLetter}>
        <p>{t("Join our Newsletter and never miss out again")}</p>
        <p>
          {t(
            "Unlock the Future of AI: Immerse yourself in the most innovative and groundbreaking developments by subscribing to our AI newsletter. Gain access to expert analysis, thought-provoking insights, and exclusive content on artificial intelligence. Connect with a vibrant community of AI pioneers, and stay ahead with invitations to webinars, events, and special promotions. Elevate your AI expertise – subscribe now and transform your digital journey!"
          )}
        </p>
        <div style={{ backgroundColor: "#FFFFFF" }}>
          <div
            style={{
              paddingLeft: "15px",
              paddingRight: "15px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <svg
              width="41"
              height="28"
              viewBox="0 0 41 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M36.5839 0.144409H4.35738C2.4664 0.144409 0.920898 1.68311 0.920898 3.58089V24.1998C0.920898 26.0982 2.46739 27.6363 4.35738 27.6363H36.5839C38.4749 27.6363 40.0204 26.0975 40.0204 24.1998V3.58089C40.0204 1.68265 38.4741 0.144409 36.5839 0.144409ZM36.0562 2.4354C34.9452 3.55011 21.8945 16.6433 21.3583 17.1813C20.9105 17.6304 20.031 17.6307 19.583 17.1813L4.88507 2.4354H36.0562ZM3.21189 23.7786V4.00205L13.0681 13.8903L3.21189 23.7786ZM4.88507 25.3453L14.6855 15.513L17.9604 18.7986C19.3025 20.145 21.6394 20.1445 22.981 18.7986L26.2559 15.513L36.0562 25.3453H4.88507ZM37.7294 23.7786L27.8732 13.8903L37.7294 4.00205V23.7786Z"
                fill="url(#paint0_linear_202_65)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_202_65"
                  x1="38.7385"
                  y1="-2.09901"
                  x2="13.0994"
                  y2="24.181"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#0057FF" />
                  <stop offset="1" stopColor="#00D1FF" />
                </linearGradient>
              </defs>
            </svg>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t("Your Email Address")}
              style={{
                marginLeft: lng === "arab" ? "unset" : "10px",
                marginRight: lng === "arab" ? "10px" : "unset",
              }}
            />
          </div>
          <button
            style={{ backgroundColor: loading ? "gray" : "#FFFFFF" }}
            onClick={() => sendTicket()}
          >
            {loading ? <LoadingEffect /> : t("Subscribe Now")}
          </button>
        </div>
      </div>
      <RecaptchaPopUp
        setLoading={setLoading}
        backdrop="static"
        show={recaptchaPopUp}
        onHide={() => setRecaptchaPopUp(false)}
        captcharesponse={captchaResponse}
        submitfunction={setCaptchaResponse}
      />
    </div>
  );
};

export default Blog;
