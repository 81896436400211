import React from 'react'
import { Modal } from 'react-bootstrap'
import './UpgradeModal.css';
// import OopsSvg from '../../assets/img/Oops1.svg';
import { useTranslation } from 'react-i18next';

const UpgradeModal = (props) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;

    return (
        <Modal
            className="modal modal-primary upgrade-modal"
            centered
            show={props.showModal}
            onHide={() => props.setShowModal(false)}
        >

            <button
                onClick={() => props.setShowModal(false)}
                style={{
                    position: 'absolute',
                    height: 30,
                    width: 30,
                    right: 7,
                    top: 5,
                    border: 'none',
                    background: 'none',
                    color: 'grey'
                }}>
                <p style={{ fontSize: 25 }}>x</p>

            </button>
            <Modal.Header className="justify-content-center">
                {/* <div className="modal-profile"> */}
                {/* <i className="nc-icon nc-bulb-63"></i> */}
                <img src={"assets/newDesign/Oops1.svg"} alt="Oops" height="202px" width="202px" />
                {/* </div> */}
            </Modal.Header>
            <Modal.Body className="text-center" style={{ display: 'flex', flexDirection: 'column', fontFamily: lng === 'arab'? "'Tajawal":"'Poppins" }}>
                <div>
                    <h1 style={{ fontWeight: 800, color: '#388af3' }}>{t("Oops!")}</h1>
                    <h3 style={{ fontWeight: 600, fontSize: 25 }}>{props.forImg? t("You Ran Out of Images.."):t("You Ran Out of Words..")}</h3>
                    <h5 style={{ fontSize: 18 }}>{props.forImg? t("Get more images by"):t("Get more words by")}
                        < br />
                        {t("upgrading to a higher plan")}</h5>
                </div>
                <a href="https://dashboard.araby.ai/plan" className="btn common-btn-modal">
                    {t("Upgrade Plan")}
                </a>
            </Modal.Body>
        </Modal >
    );
}

export default UpgradeModal;