import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { API_URL } from "../../constants";
import QuillNoSSRWrapper from "react-quill"
import PopUpModal from "../popUps/PopUpModal";
import checkErr from "../../actions/api_error_func";
import { toastAlert } from "../alert/toastAlert";
import LoadingEffect from "../LoadingEffect/LoadingEffect";
import UpgradeModal from "../UpgradeModal/UpgradeModal";
import { useSelector } from "react-redux";
import RecaptchaPopUp from "../../views/RecaptchaPopUp";
// import ArrowImg from "../../assets/arrow.png" 
// import ThinkingImg from "../../assets/thinking.svg"
//import the component
const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
}
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
]

export default function Email() {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const user = useSelector((state) => state.userAuth);
  const [usertext, setUsertext] = useState('')
  const [loading, setLoading] = useState(false)
  const [generatedText, setGeneratedText] = useState('')
  const [emailFrom, setEmailfrom] = useState('')
  const [emailTo, setEmailTo] = useState('')
  const [currentFormats, setCurrentFormats] = useState(formats);
  const [showModal, setShowModal] = useState(false);
  const [upgradeModalSwitch, setUpgradeModalSwitch] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState(0)
  const [recaptchaPopUp, setRecaptchaPopUp] = useState(false)




  const generateText = () => {
    if (user?._id) {
      if (user?.text_count_left === 0) {
        setUpgradeModalSwitch(true)
        setGeneratedText(generatedText)
        setLoading(false)
        return toastAlert('No more text generation quota left', undefined, "warning");
      }
    }
    if (!usertext) {
      return toastAlert('Please enter a subject', undefined, "warning");
    }
    if (!localStorage.getItem('sess')) {
      if (localStorage.getItem('emailUse')) {
        return setShowModal(true)
      }
    }
    setRecaptchaPopUp(true)
}

  const [width, setWidth] = useState(window.innerWidth);

  const isMobile = width <= 768;
  const isTablet = width <= 850;
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    setCurrentFormats(isMobile || isTablet ? [
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'video',
    ] : formats)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const pureGen = () => {
    setLoading(true)
    setGeneratedText('')
    fetch(API_URL('generate-text-landing'), {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers:
        localStorage.getItem("sess") ? {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem("sess")
        } :
          { 'Content-Type': 'application/json' },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body:
        JSON.stringify({
          useCase: "EmailLandingPage",
          tone: 'Formal',
          language: null,
          inputs: [{
            "keyPoints": usertext,
            "sender": emailFrom,
            "recipient": emailTo
          }]
        }) // body data type must match "Content-Type" header
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          setGeneratedText(data)
          setLoading(false)
          if (!localStorage.getItem('sess')) {
            localStorage.setItem("emailUse", true)
          }
        }
        else {
          toastAlert(data.message || "Something went wrong, please try again later", undefined, "warning");
          setGeneratedText(generatedText)
          setLoading(false)
        }
      }).catch(error => {
        checkErr(error, setLoading, toastAlert)
      })
  }
  useEffect(() => {
    if (captchaResponse) {
      pureGen()
    }
  }, [captchaResponse])

  return (
    <div id="email" className="container email-section">
      <div className="emaildiv">
        <div className="email-editor">
          <form style={{ direction: lng === "arab" ? 'rtl' : 'ltr' }} className="email-form" onSubmit={(e) => { e.preventDefault(); generateText() }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', flexDirection: lng === "arab" ? "row-reverse" : "" }}>
              <div style={{ opacity: '0.5' }} className="email-input">
                <label style={{ color: '#000' }}>{t("From")}: </label>
                <input placeholder={t("Sender Name")} type="text" value={emailTo} onChange={(e) => setEmailTo(e.target.value)} style={{ color: '#000' }} />
              </div>
              <div style={{ opacity: '0.5' }} className="email-input">
                <label style={{ color: '#000' }}>{t("To")}: </label>
                <input placeholder={t("Your Name")} type="text" value={emailFrom} onChange={(e) => setEmailfrom(e.target.value)} style={{ color: '#000' }} />
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ alignItems: 'center' }} className="email-input subject-arrow">
                <label>{t("Subject")}: </label>
                <input style={{ borderBottom: 'unset', width: '100%' }} className="real_email_input" value={usertext} onChange={(e) => setUsertext(e.target.value)} name="subject" type="text" placeholder={t("Enter your topic here...")} />
                <img
                  style={lng !== 'arab' ?
                    {
                      left: '-206px',
                      right: 'auto',
                      // width: 198px;
                      transform: 'rotate(216deg)'
                    } : null}
                  className="subject-arrow-img" src={"assets/arrow.png"} alt="" />
              </div>
            </div>

            <button
              style={{ padding: isMobile || isTablet ? '15px 40px !important' : '15px 60px !important', background: loading ? 'gray' : 'linear-gradient(45deg, rgba(17, 113, 216, 1) 0%, rgba(60, 200, 235, 1) 100%)', pointerEvents: loading && 'none' }} className="btn common-btn" type="submit" >{
                loading ?
                  <LoadingEffect />
                  :
                  t("Write")}
            </button>
          </form>
          <div style={{ direction: 'ltr' }} className={generatedText === "" ? "email-editor-hidden" : "email-editor-visible"}>
            <QuillNoSSRWrapper modules={modules} formats={currentFormats} theme="snow" />
            <div style={{ whiteSpace: 'pre-wrap', padding: '8px 8px 20px 8px', direction: generatedText?.language === 'Arabic' ? 'rtl' : 'ltr' }}>
              {generatedText?.text}
            </div>
          </div>
        </div>
      </div>
      <article style={{ direction: lng === "arab" ? 'rtl' : 'ltr' }} className="email-article">
        <h1 className="email-header h1eh">{t('Confused about writing an email ?')}</h1>
        <section className="email-sec">
          <p className={lng === 'arab' ? "h6introcontent" : "h6introcontent poppinsdesc"}>{t('With Araby AI, writing an email just became easier. Simply enter the topic you want to write about and leave all the hassle to us.')}</p>
        </section>
        <img className="thinking-img" src={"assets/thinking.svg"} alt="" />
      </article>
      <PopUpModal
        popUpFor={'email'}
        show={showModal}
        onHide={() => setShowModal(false)}
      />
      <UpgradeModal
        showModal={upgradeModalSwitch}
        setShowModal={setUpgradeModalSwitch}
      />
      <RecaptchaPopUp setLoading={setLoading} backdrop='static' show={recaptchaPopUp} onHide={() => setRecaptchaPopUp(false)} captcharesponse={captchaResponse} submitfunction={setCaptchaResponse} />
    </div>
  )
}