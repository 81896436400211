import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import checkErr from "../../actions/api_error_func";
import { toastAlert } from "../alert/toastAlert";
import reCAPTCHA from "react-google-recaptcha"
import Reaptcha from 'reaptcha';
import RecaptchaPopUp from "../../views/RecaptchaPopUp";
import Footer from '../Footer';

export default function ContactUs() {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const captchaRef = useRef(null)

  const [submitting, setSubmitting] = useState(false);
  const [capatchaInput, setCapatchInput] = useState('');
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [captchaResponse, setCaptchaResponse] = useState(0)
  const [recaptchaPopUp, setRecaptchaPopUp] = useState(false)
  const sendTicket = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setRecaptchaPopUp(true)
  }
  const pureFunc = async (e) => {
    await fetch(`https://gateway.api.production.araby.ai/ticket/create`, {
      method: "POST",
      headers: {
        Authorization: localStorage.getItem("sess"),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formValues)
    })
    .then((res) => res.json())
      .then(async (data) => {
        if (data.success) {
          Swal.fire({
            title: t('Sent!'),
            text: t('Your ticket has been successfully sent to us'),
            icon: 'success',
            confirmButtonText: 'Close'
          })
          setFormValues({
            name: "",
            email: "",
            message: "",
          })
        }
        setSubmitting(false);
      }).catch(err => {
      checkErr(err, setSubmitting, toastAlert)
      setSubmitting(false);
    })
  }
  useEffect(() => {
    if (captchaResponse) {
      pureFunc()
    }
  }, [captchaResponse])
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (

    <div id="contact-us" className="contact-us-section contact-modal" style={{ direction: lng === 'arab' ? 'rtl' : 'ltr',paddingTop:"100px" }}>
      <div className="row center cu-center">
        <h1 className="cuh1-contactus">
          {t("Contact Us")}
        </h1>
        <h1 className="cuh1-contactus-caption">
          {t("How we can help you?")}
        </h1>
        <h6>
          {t("Have a question, Or just want to say hello?, fill the form below")}
        </h6>
      </div>
      <form onSubmit={sendTicket} className="cu-divborder">
        <div className="cu-divcontents">
          <div className="row cu-row">
            <div className="col-sm-6 col-12">
              <h6>{t("Name")}</h6>
              <input className="cu-input"
              placeholder={t("Full Name")}
              disabled={submitting}
                value={formValues.name}
                required
                onChange={(e) => {
                  setFormValues({ ...formValues, name: e.target.value })
                }} />
            </div>
            <div className="col-sm-6 col-12">
              <h6>{t("Email")}</h6>
              <input className="cu-input"
              disabled={submitting}
                required
                placeholder={t("Email Address")}
                type={'email'}
                value={formValues.email}
                onChange={(e) => {
                  setFormValues({ ...formValues, email: e.target.value })
                }}
              />
            </div>
          </div>
          <div className="row cu-row">
            <h6>{t("Your Message")}</h6>
            <textarea className="cu-input" value={formValues.message}
              required
              placeholder={t("Message")}
              disabled={submitting}
              onChange={(e) => {
                setFormValues({ ...formValues, message: e.target.value })
              }} />
          </div>
          <div className="row cu-row cu-row-btn">
            <button disabled={submitting} className="btn common-btn cu-btn" >{submitting? t('Loading'):t('Send')}</button>
          </div>
        </div>
      </form>
      <RecaptchaPopUp setLoading={setSubmitting} backdrop='static' show={recaptchaPopUp} onHide={() => setRecaptchaPopUp(false)} captcharesponse={captchaResponse} submitfunction={setCaptchaResponse}/>

      {/* fix the css */}

    </div>
  );
}