import * as React from "react";
import { motion } from "framer-motion";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

// const colors = ["#FF008C", "#D309E1", "#9C1AFF", "#7700FF", "#4400FF"];

export const MenuItem = ({ i }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  // const style = { border: `2px solid ${colors[i]}` };

  return (
    <>
      <motion.li
        className="n-li"
        variants={variants}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
      >
        {/* <div className="n-icon-placeholder" style={style} /> */}
        <div className="n-text-placeholder">
          <h3 onClick={() => navigate(i.route)}>{t(i.text)}</h3>
        </div>
      </motion.li>
    </>
  );
};
