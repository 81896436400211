import { LOADING_BLOG, FETCH_BLOG, LOADED_BLOG, LOADED_SINGLE_BLOG, FETCH_SINGLE_BLOG, LOADING_SINGLE_BLOG } from "../constants/index";
import * as api from "../api";
import checkErr from "./api_error_func";
import { toastAlert } from "../components/alert/toastAlert";
import { t } from "i18next";


export const getBlog = () => async (dispatch) => {
    dispatch({ type: LOADED_BLOG });
    try {
        var result = await api.getBlog();
        dispatch({ type: FETCH_BLOG, payload: result?.data?.blogs || [] });
    } catch (error) {
        dispatch({ type: LOADING_BLOG });
        checkErr(error, false, toastAlert, false, dispatch, false)
    }
}

export const getBlogDetails = (id) => async (dispatch) => {
    dispatch({ type: LOADED_SINGLE_BLOG });
    try {
        var result = await api.getBlogById(id);
        dispatch({ type: FETCH_SINGLE_BLOG, payload: result?.data?.blog || {} });
    } catch (error) {
        dispatch({ type: LOADING_SINGLE_BLOG });
        checkErr(error, false, toastAlert, false, dispatch, false)
    }
}

export const emailNewsLetter = (email, setLoading, setEmail) => async (dispatch) => {
    try {
        var result = await api.newsLetter(email);
        setEmail("");
        toastAlert(t(result?.data?.message), undefined, "success");
        setLoading(false);

    } catch (error) {
        checkErr(error, setLoading, toastAlert)
    }
}