// Next.js API route support: https://nextjs.org/docs/api-routes/introduction
import axios from "axios";
const base_url = process.env.REACT_APP_BASE_URL;
const base_auth_url = process.env.REACT_APP_AUTH_BASE_URL;
const blog_base_url = process.env.REACT_APP_BLOG_URL;
console.log(blog_base_url)
export default function handler(req, res) {
  res.status(200).json({ name: "John Doe" });
}

export const requestOtp = (phone_number, country_code) =>
  axios.post(`${base_auth_url}/auth/sendOTP`, { phone_number, country_code });

export const verifyOtp = (phone_number, country_code, otp) =>
  axios.post(`${base_auth_url}/auth/verifyOTP`, { phone_number, country_code, otp });

export const verifyFireBaseOtp = (token) =>
  axios.post(`${base_auth_url}/firebaseAuth`, { token });

export const socialLogin = (token, name, type) =>
  axios.get(
    `${base_auth_url}/${type === "FACEBOOK" ? "facebook" : type === "APPLE" ? "apple" : "google"
    }-with-token?id_token=${token}&name=${name}`
  );

export const newSocialLogin = (token) => {
  var data = { google_id: token }
  var config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${base_auth_url}/socialLogin`,
    headers: {},
    data: data
  };
  return axios(config);
};

export const getStatisticsAPI = () => axios.get(`${base_url}/landing_data`);

export const register = (email, password) => {
  const formData = new URLSearchParams();
  formData.append("email", email);
  formData.append("password", password);

  return axios.post(`${base_auth_url}/register`, formData);
};

export const login = (email, password) => {
  const formData = new URLSearchParams();
  formData.append("email", email);
  formData.append("password", password);

  return axios.post(`${base_auth_url}/login`, formData);
};

export const forgotPassord = (email) => {
  const formData = new URLSearchParams();
  formData.append("email", email);
  return axios.post(`${base_auth_url}/resetPassword/otp`, formData);
};
export const verifyOTPForPasswordReset = (email, otp) => {
  const formData = new URLSearchParams();
  formData.append("email", email);
  formData.append("otp", otp);

  return axios.post(`${base_auth_url}/otp/verify`, formData);
};
export const resetPassword = (email, password, otp) => {
  const formData = new URLSearchParams();
  formData.append("email", email);
  formData.append("password", password);
  formData.append("otp", otp);
  return axios.post(`${base_auth_url}/reset_password`, formData);
};

export const getUserData = (token) =>
  axios.get(`${base_auth_url}/autoLogin`, {
    headers: {
      authorization: token,
    },
  });
export const aiAPI = async (userData, endpoint) => {
  return await axios({
    method: "post",
    url: `${base_url}${endpoint}`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    data: JSON.stringify(userData),
  });
};

export const userSubscription = async () => {
  return await axios.get(`${process.env.REACT_APP_BASE_URL}/stripe/subscribe/get-latest`, {
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem("sess")
    }
  });
};

// Blog Api's
export const getBlog = () => {
  return axios.get(`${blog_base_url}blog`)
}

export const getBlogById = (id) => {
  return axios.get(`${blog_base_url}blog/${id}`)
}

export const newsLetter = (email) => {
  const data = {
    email: email
  }
  return axios.post(`${blog_base_url}blog/news_letter`, data)
}