import a from "../../components/Assets/aiImages/1-min.png"
import b from "../../components/Assets/aiImages/2-min.png"
import c from "../../components/Assets/aiImages/3-min.png"
import d from "../../components/Assets/aiImages/4-min.png"
import e from "../../components/Assets/aiImages/5-min.png"
import f from "../../components/Assets/aiImages/6-min.png"
import g from "../../components/Assets/aiImages/7-min.png"
import h from "../../components/Assets/aiImages/8-min.png"
import i from "../../components/Assets/aiImages/9-min.png"
import j from "../../components/Assets/aiImages/10-min.png"
import jj from "../../components/Assets/aiImages/11-min.png"
import k from "../../components/Assets/aiImages/12-min.png"
import l from "../../components/Assets/aiImages/13-min.png"
import m from "../../components/Assets/aiImages/14-min.png"
import n from "../../components/Assets/aiImages/15-min.png"
import o from "../../components/Assets/aiImages/16-min.png"
import p from "../../components/Assets/aiImages/17-min.png"
import q from "../../components/Assets/aiImages/18-min.png"
import r from "../../components/Assets/aiImages/19-min.png"
import s from "../../components/Assets/aiImages/20-min.png"
import t from "../../components/Assets/aiImages/21-min.png"
import u from "../../components/Assets/aiImages/22-min.png"
import v from "../../components/Assets/aiImages/23-min.png"
import w from "../../components/Assets/aiImages/24-min.png"
import x from "../../components/Assets/aiImages/25-min.png"
import y from "../../components/Assets/aiImages/26-min.png"
import z from "../../components/Assets/aiImages/27-min.png"
import aa from "../../components/Assets/aiImages/28-min.png"
import ab from "../../components/Assets/aiImages/29-min.png"
import ac from "../../components/Assets/aiImages/30-min.png"
import ad from "../../components/Assets/aiImages/31-min.png"
import ae from "../../components/Assets/aiImages/32-min.png"
import af from "../../components/Assets/aiImages/33-min.png"

export const marqueeData = [
  {
    image: a,
    text: "araby.ai",
  },
  {
    image: b,
    text: "araby.ai",
  },
  {
    image: c,
    text: "araby.ai",
  },
  {
    image: d,
    text: "araby.ai",
  },
  {
    image: e,
    text: "araby.ai",
  },
  {
    image: f,
    text: "araby.ai",
  },
  {
    image: g,
    text: "araby.ai",
  },
  {
    image: h,
    text: "araby.ai",
  },
  {
    image: i,
    text: "araby.ai",
  },
  {
    image: jj,
    text: "araby.ai",
  },
  {
    image: j,
    text: "araby.ai",
  },
 
  
];


export const marqueeData1 = [
  {
    image: k,
    text: "araby.ai",
  },
  {
    image: l,
    text: "araby.ai",
  },
  {
    image: m,
    text: "araby.ai",
  },
  {
    image: n,
    text: "araby.ai",
  },
  {
    image: o,
    text: "araby.ai",
  },
  {
    image: p,
    text: "araby.ai",
  },
  {
    image: q,
    text: "araby.ai",
  },
  {
    image: r,
    text: "araby.ai",
  },
  {
    image: s,
    text: "araby.ai",
  },
  {
    image: t,
    text: "araby.ai",
  },
  {
    image: af,
    text: "araby.ai",
  },
]
export const marqueeData2=[
  {
    image: u,
    text: "araby.ai",
  },
  {
    image: v,
    text: "araby.ai",
  },
  {
    image: w,
    text: "araby.ai",
  },
  {
    image: x,
    text: "araby.ai",
  },
  {
    image: y,
    text: "araby.ai",
  },
  {
    image: z,
    text: "araby.ai",
  },
  {
    image: aa,
    text: "araby.ai",
  },
  {
    image: ab,
    text: "araby.ai",
  },
  {
    image: ac,
    text: "araby.ai",
  },
  {
    image: ad,
    text: "araby.ai",
  },
  {
    image: ae,
    text: "araby.ai",
  },
 

]