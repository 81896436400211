import React,{useEffect,useState} from 'react'
import "bootstrap/dist/css/bootstrap.css";
import "../styles/globals.css";
import "../styles/responsive.css";
import "../styles/emails.css";
import "../styles/blog.css";
import "../styles/tweets.css";
import "../styles/contact-us.css";
import "../styles/tajawal.css";
import "../styles/what-is-it.css";
import "../App.scss";
import styles from "../styles/Home.module.css";
import "react-quill/dist/quill.snow.css";
import Icon from "../components/backgroundWave";
import IntroContent from "../components/intro/IntroContent";
import Email from "../components/email/Email";
import Tweet from "../components/tweet/Tweet";
import Blog from "../components/blog/Blog";
import OurWork from "../components/OurWork";
import TextToImage from "../components/TextToImage";
import Footer from "../components/Footer";
import Statistics from "../components/statistics/Statistics";
import CodeFaster from '../components/CodeFaster/CodeFaster';
import LandingPage from './landingPage/LandingPage';
import PerfectSolution from "../components/PerfectSolution/Index";
import MobileBanner from '../components/MobileBanner/MobileBanner';


const Home = () => {
 
  return (
    <div className="App">
      <LandingPage />
      {/* <div style={{ position: "relative", bottom: 0, left: 0, right: 0 }}>
        <Statistics />
      </div> */}
      <Email />
      <TextToImage />
      <MobileBanner/>
      {/* <div className="creative_writing_section row">
        <Tweet />
        <div className="or_effect col-lg-1 col-md-1 col-sm-12">
          <div className="line_effect " />
          <label>{t("or")}</label>
          <div className="line_effect" />
        </div>
        <Blog />
      </div> */}
      {/* coding section */}
      {/* <CodeFaster /> */}
      {/* <WhatIsIt /> */}
      {/* <OurWork /> */}
      <PerfectSolution />
      {/* <ContactUs /> */}
      {/* <Footer /> */}
    </div>
  );
}

export default Home