import React, {useEffect, useRef} from 'react'

const TypingEffect = () => {
    const canvasRef = useRef();
    let lng = JSON.parse(localStorage.getItem("language")??' ')?.lang;
  useEffect(() => {
    var canvas = canvasRef.current;
    if (canvas) {
    var context = canvas.getContext('2d');
    var W = window.innerWidth;
    var H = window.innerHeight;
    canvas.width = W;
    canvas.height = H;

    var fontSize = 18;
    var columns = Math.floor(W / fontSize);
    var drops = [];
    for(var i=0; i<columns; i++){
        drops.push(0);
    }
    var str = lng === 'arab'? 
        "عربي AI، لم يعد الأمر خيالًا، عربي AI، لم يعد خيالًا":
        "Araby Ai, Creating Magic, Araby Ai, Creating Magic";
    function draw(){
        context.fillStyle = "rgba(0,0,0,0.05)";
        context.fillRect(0, 0, W, H);
        context.fontSize = "700 " + fontSize + "px";
        context.fillStyle = "#fff";
        for(var i=0; i<columns; i++){
            var index = Math.floor(Math.random()*str.length);
            var x = i * fontSize;
            var y = drops[i] * fontSize;
            context.fillText(str[index], x, y);
            if(y >= canvas.height && Math.random() > 0.99){
                drops[i] = 0;
            }
            drops[i]++;
        }
    }
    draw();
    setInterval(draw, 35);
  }
  })
  return (
        <canvas ref={canvasRef} id="canvas" style={{background: '#111'}}></canvas>
  )
}

export default TypingEffect