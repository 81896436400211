import java from "../Assets/Icons/java.png";
import python from "../Assets/Icons/python.png";
import php from "../Assets/Icons/php.png";
import html from "../Assets/Icons/html.png";
import css from "../Assets/Icons/css-3.png"
import ruby from "../Assets/Icons/ruby.png"
import csharp from "../Assets/Icons/c-sharp.png"
import cplus from "../Assets/Icons/c-.png"
import cc from "../Assets/Icons/cc.png"
import js from "../Assets/Icons/js.png"
import dart from "../Assets/Icons/dart.png"


export const reviewsData = [
  {
    name: "Joseph Kania",
    Profession: "Web Developer",
    highlight: "Highly Recommended",
    text: "I'm absolutely amazed by ArabyAi! It's a great tool for developers and coders that makes writing codes so much easier and faster. I used it to write my first code and it was so user-friendly that I was able to finish quickly. Highly recommended! ",
  },
  {
    name: "Jenny Andrei",
    Profession: "Freelancer",
    highlight: "Incredibly user-friendly and easy to use",
    text: "Not only is it incredibly user-friendly and easy to use, but it also offers a wide range of features that really help me to write better and faster. I can easily access different language dictionaries, thesaurus, and other resources, which make my writing much more articulate and precise. Most importantly, the website is constantly updated to ensure that I'm always up to date with the latest trends and vocabulary. ",
  },
  {
    name: "Dorothy Smith",
    Profession: "Writer",
    highlight: "Simple and Intuitive ",
    text: "I recently discovered ArabyAi, and I'm so impressed with the simple and intuitive way it allows to write anything. As a busy professional, I appreciate the ease of use and the organized design. I highly recommend ArabyAi to anyone who needs a reliable and efficient way to write anything.",
  },
];
export const marqueeData = [
  {
    image: java,
    text: "Java",
  },
  {
    image: python,
    text: "Python",
  },
  {
    image: cc,
    text: "C",
  },
  {
    image: css,
    text: "CSS",
  },
  {
    image: php,
    text: "PHP",
  },
  {
    image: html,
    text: "HTML",
  },
];
export const marqueeData2 = [
  {
    image: js,
    text: "Javascript",
  },
  {
    image: csharp,
    text: "c#",
  },
  {
    image: cplus,
    text: "C++",
  },
  {
    image: dart,
    text: "Dart",
  },
  {
    image: ruby,
    text: "Ruby",
  },
 
];

export const accordionData = [
  {
    question:
      "Does the word limit shown in a monthly or annual paid plan renew monthly or are these words one-time?",
    answer:
      "Yes. In both monthly and annual plans, the given number of words are renewed at the start of your next billing cycle.",
  },
  {
    question:
      "I have exhausted my words before the start of the next billing month. How can I get more?",
    answer:
      "If your words are exhausted during the month, you can upgrade to the next higher plan and only the pro-rated difference will be charged, based on the remaining days in the billing cycle. If you need fewer words next month, you can again downgrade your plan and be charged accordingly.",
  },
  {
    question: "Is there any pay-as-you-go plan?",
    answer:
      "No. Currently, there is no pay-as-you-go plan and only monthly and annual plans are available.",
  },
  {
    question:
      "If I subscribe to a monthly plan, will I be charged automatically next month?",
    answer:
      "Yes, monthly subscription plans charge you automatically every month until you cancel or pause them.",
  },
  {
    question: "What are the payment methods supported by Araby.Ai?",
    answer:
      "Araby.Ai currently accepts debit/credit cards as payment methods. In countries where our users are more concentrated, we plan to gradually add additional payment gateways. Users will be charged in USD for these additional payment methods.",
  },
];
