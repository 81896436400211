import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import NotFound from "./views/404_not_found/notfoundscreen";
import { LoginScreen, LoginForm } from "./views/loginscreen/loginscreen";
import RegisterSuccess from "./views/loginscreen/components/registersuccess/registersuccess";
import ForgotPassword from "./views/loginscreen/components/forgotpassword/forgotpassword";
import { useDispatch, useSelector } from "react-redux";
import LoadingPopUp from "./components/popUps/LoadingPopUp";
import { STOP_LOADING } from "./constants";
import axios from "axios";
import { getUserData, getStatistics } from "./actions/user";
import AOS from "aos";
import "aos/dist/aos.css";
import { TRANSLATIONS_ARAB } from "./translations/arab/translation";
import { initReactI18next } from "react-i18next";
import $ from "jquery";
import i18n from "i18next";
import Logout from "./views/Logout";
// import Privacy from "./components/privacyPolicy/Privacy";
// import Terms from "./components/termsandCond/Terms";
import StaticContentPage from "./components/staticContentPage/StaticContentPage";
import Pricing from "./components/Pricing/Pricing";
import Home from "./views/home";
import Blog from "./views/Blog";
import BlogDetails from "./views/BlogDetails";
import Header from "./components/Header/Header";
// import ComingSoon from "./components/Header/Navbar/comingsoon";
// import ContactUsPage from "./views/ContactPage/ContactUsPage";
import ContactUs from "./components/ContactUs/ContactUs";
import Footer from "./components/Footer";
// import { getUserSubscription } from "./actions/user_subscription";
import B2B from "./components/B2B/B2B";
import MobileAppBanner from "./views/Banners/MobileApp";
const resources = {
  arab: {
    translation: TRANSLATIONS_ARAB,
  },
};
let languageFromLocal = JSON.parse(localStorage.getItem("language"));
i18n.use(initReactI18next).init({
  resources,
  lng: languageFromLocal ? languageFromLocal.lang : "arab",
});
const MainRoutes = () => {
  const path = window.location.pathname;
  let allTextTypes = [
    "label",
    "a",
    "button",
    "div",
    "li",
    "p",
    "text",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "span",
    "input",
    "text",
  ];

  const [changeOfLanguage, setChangeOfLanguage] = useState(0);
  let languageFromLocal = JSON.parse(localStorage.getItem("language"));
  // if (!languageFromLocal) {
  //   setChangeOfLanguage(changeOfLanguage + 1);
  //   localStorage.setItem("language", JSON.stringify({ lang: "arab" }));
  // }
  useEffect(() => {
    languageFromLocal = JSON.parse(localStorage.getItem("language"));
  }, [changeOfLanguage]);
  useEffect(() => {
    if (languageFromLocal) {
      i18n.changeLanguage(languageFromLocal.lang);
      if (!languageFromLocal.lang ) {
        $("body").addClass("arabic_direction");
      } else if (languageFromLocal?.lang === "arab") {
        $("body").addClass("arabic_direction");
      } else {
        $("body").addClass("english_direction");
      }
    }
  }, [changeOfLanguage]);
  useEffect(() => {
    AOS.init();
  }, []);
  useEffect(() => {
    for (let i = 0; i < allTextTypes.length; i++) {
      let allElements = document.getElementsByTagName(allTextTypes[i]);
      for (let j = 0; j < allElements.length; j++) {
        if (!languageFromLocal?.lang ) {
          allElements[j].style.fontFamily = "Tajawal";
        } else if (languageFromLocal?.lang === "arab") {
          allElements[j].style.fontFamily = "Tajawal";
        } else {
          allElements[j].style.fontFamily = "Poppins";
        }
      } 
    }
  });
  const location = useLocation();
  const dispatch = useDispatch();
  const bigLoading = useSelector((state) => state.loading);
  const user = useSelector((state) => state.userAuth);
  // const statisticsLoading = useSelector((state) => state.statistics.loading);
  const [loadingSwitch, setLoadingSwitch] = useState(bigLoading);
  // const [innerCounter, setInnerCounter] = useState();
  let token = localStorage.getItem("sess");
  // useEffect(() => {
  //   // setInterval(async () => {
  //   //   if (!statisticsLoading) {
  //   //     dispatch(getStatistics('effect'));
  //   //   }
  //   // }, 60000);
  // }, []);
  useEffect(() => {
    dispatch(getStatistics(false));
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
      axios.defaults.headers.common["lang"] = JSON.parse(localStorage.getItem("language"))?.lang === 'arab'? 'ar':'en';
      dispatch(getUserData(token));
      // if (user?.plan !== 'Trial') {
      // dispatch(getUserSubscription());
      // }
    } else {
      axios.defaults.headers.common["lang"] = JSON.parse(localStorage.getItem("language"))?.lang === 'arab'? 'ar':'en';
      dispatch({ type: STOP_LOADING });
    }
  }, [location]);
  useEffect(() => {
    setLoadingSwitch(bigLoading);
  }, [bigLoading]);
  return (
    <>
    
      {path === "/privacy-and-policy" ||
        path === "/terms-and-conditions" ||
        path === "/comingSoon" ||
        path === "/auth/signup" ||
        path === "/auth/login" ||
        path === "/auth/forgotpassword" ||
        path === "/auth/registersuccess" ? null : (
        <Header />
      )}
      {loadingSwitch ? <LoadingPopUp /> : null}
      <Routes location={location.pathname}>
        {/* <Route path="/" element={<NotFound />} /> */}

        <Route path="/" element={<Home />} />
        {!localStorage.getItem("sess") && !user ? (
          <Route
            path="auth"
            element={<LoginScreen location={location.pathname} />}
          >
            <Route path="login" element={<LoginForm isLoginForm={true} />} />
            <Route path="signup" element={<LoginForm isLoginForm={false} />} />
            <Route path="registersuccess" element={<RegisterSuccess />} />
            <Route path="forgotpassword" element={<ForgotPassword />} />
          </Route>
        ) : localStorage.getItem("sess") ? (
          <Route path="logingOut" element={<Logout />} />
        ) : null}
        <Route path="/logingOut" element={<Logout />} />
        {/* <Route path="/comingSoon" element={<ComingSoon/>}/> */}
        <Route
          path="/privacy-and-policy"
          element={<StaticContentPage isPrivacy={true} />}
        />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/terms-and-conditions" element={<StaticContentPage />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="/business" element={<B2B />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-details/:id" element={<BlogDetails />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
      {path === "/privacy-and-policy" ||
        path === "/terms-and-conditions" ||
        path === "/comingSoon" ||
        path === "/auth/signup" ||
        path === "/auth/login" ||
        path === "/auth/forgotpassword" ||
        path === "/auth/registersuccess" ? null : (
        <Footer />
      )}
    </>
  );
};

export default MainRoutes;
