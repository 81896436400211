import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import lightCheck from "../Assets/Img/badge-check.png";
import blackCheck from "../Assets/Img/badge-checkblack.png";
import "../Pricing/Pricing.css"
import { Box, Grid, Slider, TextField } from "@mui/material";

const PlansComponent = ({name, desc, price, list, btnText, myPlanId, thisPlanId, buttonText, onPressed, isYearly, isPro, padge, useSubs, unitAMount,ismobile,isaPOPUP, isBusiness, ranges, setSelectedNumber, setTotalPrice }) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const user = useSelector((state) => state.user);
    const [customPrice, setCustomPrice] = useState(5)
    const [customMarks, setCustomMarks] = useState([])
    useEffect(() => {
      let arr = ranges?.map(item => {return {value: item?.up_to, label: item?.up_to }})?.filter(item => item?.value)
      arr?.unshift({value: 1, label: 1})
      setCustomMarks(arr)
    }, [ranges])
    const detectPrice = (numberOfUSers) => {
      let elem = 1;
      return ranges?.filter(item => numberOfUSers <= item?.up_to)?.slice(0)?.shift()?.unit_amount / 100
    }
  return (
    <div className="col-xl-3 col-lg-6 col-sm-10" style={{ minWidth: "300px", maxWidth: "400px" }}>
      <div
        onClick={(t(btnText) === name && myPlanId === thisPlanId) || isBusiness || user?.enterprise ? null : onPressed}
        style={{ cursor: "pointer", boxShadow: isPro ? " 0px 0px 21px -3px rgba(114, 57, 235, 0.43)" : null }}
        className="newPlanCard plans_card "
      >
        <div className="upper_info">
          <div className="pop_wrapper">
            <div style={{ background: isPro ? "linear-gradient(90deg, #D852F0 0%, #1A77F3 100%)" : null, color: isPro ? "white" : null }} className="plan_name">
              <h6 style={{ color: isPro ? "white" : "grey", margin: 0, fontWeight: "800", fontSize: "1.5rem", letterSpacing: "-1px" }}>{name}</h6>
            </div>
            {padge ? (
              <div style={{ backgroundColor: "transparent", padding: "3px", borderRadius: "3px" }} className="plan_name">
                <label style={{ fontSize: "11px", color: "#D852F0" }}>{t("Popular")}</label>
              </div>
            ) : null}
          </div>
        </div>
        <hr style={{ backgroundColor: "#fff", margin: isaPOPUP ? "5px 0" : null }} />
        <div className="priceWrapper">
          <h1 className="price">
            {
              isBusiness && ranges?.length?
              '$'+(detectPrice(customPrice)): 
              "$"+((isYearly ? (Math.round((price / 12) * 100) / 100).toFixed(2) : price) || 0)
            }
          </h1>
          {isBusiness && ranges?.length?
          <label>{t('Per User')}</label>: (
            <>
              <label>{t(`Per ${isYearly ? "Month" : "Month"}`)}</label>
              <hr style={{ margin: isaPOPUP ? "5px 0" : null }} />
              <p style={{ textAlign: lng === "arab" ? "right" : "left", fontSize: ismobile ? "1.2rem" : "0.8em", marginBottom: "0" }}>{desc}.</p>
            </>
          )}
        </div>
        {isBusiness && ranges?.length?
        <div style={{width: '100%', padding: '10px'}}>
        <Slider
        value={customPrice}
        name="company_size"
        aria-label="company_size"
        step={1}
        marks={customMarks}
        min={5}
        valueLabelDisplay={customPrice > 5? "on":null}
        max={customMarks?.length? Math.max(...customMarks?.map(item => item?.value)) : 5}
        onChange={(e) => {setCustomPrice(e.target.value); setSelectedNumber(e.target.value)}}
      /></div>:null
      }
        
        <hr style={{ margin: isaPOPUP ? "5px 0" : null }} />
        <ul className="plan_details">
          {list?.map((item, i) => (
            <li key={i}>
              <img src={isPro ? lightCheck : blackCheck} style={{ height: "15px", width: "15px" }} alt="check_mark" />
              {item === "ArabyGPT" ? (
                <h6 className="araby-gpt-plan-text">Araby GPT</h6>
              ) : (
                <span
                  style={{
                    fontWeight:
                      item === "45,000 Words" ||
                      item === "50 Images" ||
                      item === "150,000 Words" ||
                      item === "200 Images" ||
                      item === "800 Images" ||
                      item === "450 Images" ||
                      item === "275000 Words" ||
                      item === "75000 Words"
                        ? "700"
                        : "",
                    textAlign: lng === "arab" ? "right" : "left",
                    direction: lng === "arab" ? "rtl" : "ltr",
                  }}
                >
                  {item}
                </span>
              )}
            </li>
          ))}
        </ul>
        {t(btnText) === name && myPlanId === thisPlanId ? (
          <button  disabled className={"subs_button"}>{buttonText ?? t("Current Plan")}</button>):
          <button  onClick={() => {onPressed()}} className={isPro ? "sub_pro_button" : "subs_button"}>{buttonText ?? t("Change Plan")}</button>
        }
      </div>
    </div>
  );
}

export default PlansComponent