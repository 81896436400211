import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { useTranslation } from "react-i18next";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Col, Row } from "react-bootstrap";
import PlansComponent from "../plansComponent/PlansComponent";
import { arabicPlans, englishPlans } from "../Assets/data/plansList";
import { useDispatch, useSelector } from "react-redux";
import TextButton from "../textbutton/textbutton";
import { useNavigate } from "react-router-dom";
import "./Pricing.css";
import { baseUrl } from "../../api/baseUrl";
import { getUserSubscription } from "../../actions/user_subscription";
import PopUpModal from "../popUps/PopUpModal";

const Plans = ({isaPOPUP, isMobile}) => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [plans, setPlans] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [plansLoadin, setPlansLoading] = useState(false);
  const [loading, setLoading] = useState(categoriesLoading || plansLoadin);
  const [plansCategories, setPlansCaregories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isYearly, setYearly] = useState(false);
  const user = useSelector((state) => state.user);
  const userSubscription = useSelector((state) => state.userSubscription);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [planName, setPlanName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showSubModal, setShowSubModal] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState(5)
  const [totalPrice, setTotalPrice] = useState(0)
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (user?.plan !== "Trial" && localStorage.getItem("sess")) {
      dispatch(getUserSubscription());
    }
  }, [user]);
  const createCheckout = async (id) => {
    setSelectedPlan(id);
    let response = await fetch(`${baseUrl}/stripe/checkout/create`, {
      method: "POST",
      headers: {
        Authorization: localStorage.getItem("sess"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        plan_id: id,
        success_url:
          `${process.env.REACT_APP_WEBSITE_URL}plan` + "?success=true",
        cancel_url:
          `${process.env.REACT_APP_WEBSITE_URL}plan` + "?canceled=true",
      }),
    })
      .then((res) => res.json())
      .then(async (data) => {
        if (data.success) {
          window.open(data.url, "_self");
          setLoading(false);
          setSelectedPlan(id);
          localStorage.setItem("planId", id);
          // setShowCheckout(true);
        }
      })
      .catch((error) => {
        // checkErr(error, setLoading, toastAlert);
      });
  };
  const getAllPlans = async (selectedCategory) => {
    setPlansLoading(true);
    // let response = await fetch("http://192.168.100.157:5000/plan/user/getAll", {
    let response = await fetch(
      `${baseUrl}/plan/user/getAll?active=true&category_id=${selectedCategory}`,
      {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("sess"),
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then(async (data) => {
        if (data.success) {
          setPlans(
            data.products.sort(
              (a, b) =>
                (a.monthly?.unit_amount || 0) - (b.monthly?.unit_amount || 0)
            )
          );
          setPlansLoading(false);
        }
      })
      .catch((error) => {
        // checkErr(error, setPlansLoading, toastAlert);
      });
  };
  useEffect(() => {
    setLoading(categoriesLoading || plansLoadin);
  }, [categoriesLoading, plansLoadin]);

  useEffect(() => {
    if (selectedCategory) {
      getAllPlans(selectedCategory);
    }
  }, [selectedCategory]);

  useEffect(() => {
    setCategoriesLoading();
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/plans/category/get?is_active=true`,
      headers: {
        Authorization: localStorage.getItem("sess"),
      },
    };
    axios(config)
      .then(function (response) {
        setPlansCaregories(response?.data?.categories?.reverse());
        setSelectedCategory(response?.data?.categories?.slice(0)?.shift()?._id);
        setCategoriesLoading(false);
      })
      .catch(function (error) {
        setSelectedCategory("");
        setCategoriesLoading(false);
      });
  }, []);

  const login = () => {
    if(localStorage.getItem("sess") === null){
      return setShowModal(true)
    }else{
      console.log("elese here");
      window.open( process.env.REACT_APP_DASHBOARD_URL_PLAN +"?t=" + localStorage.getItem("sess"),"_blank")
    }
  }


  return (
    <div style={{ position: "relative",fontFamily:lng === "arab" ?"Tajawal":"Poppins" }} className="plan-type" >
    
    {categoriesLoading ? (
      <div style={{ minHeight: "50px" }} />
    ) : (
      <>
      <div >
        <div>
          {width > 600 ? 
            (<h1 style={{textAlign:"center", fontWeight: "600", color: "black", margin: 0}}>
              {t("Pricing")}
            </h1>) 
            : null}
            <h4 style={{fontSize:"16px", textAlign:"center", fontWeight: 300, color: "black", margin: 0}}>
              {t("Tailored plans to fit businesses of all sizes")}
            </h4>
        </div>
        {categoriesLoading ? (
          <div style={{ minHeight: "50px" }} />
          ) : (
          <div className="toggle_button_plans">
            {plansCategories?.map((item, i) => 
              <button
                onClick={() => {setSelectedCategory(item?._id); setYearly(false)}}
                disabled={categoriesLoading || loading}
                className={
                  selectedCategory === item?._id ? "toggle_button_plans_1" : "toggle_button_plans_2"
                }
                style={{ padding:isaPOPUP && !isMobile ? "0" : null,}}
              >
                {lng === 'arab'? item?.name_ar:item?.name_en}
              </button>
            )}
          </div>
        )}
        <div className="save-10-head" >
          <h4 onClick={() => setYearly(false)} style={{ cursor: "pointer",alignItems:"center",display:"flex"}}>
            {t("Monthly")}
          </h4>
          <Switch
            checked={!isYearly}
            onChange={(e) => setYearly(!isYearly)}
            style={{
              margin:"35px 10px 35px 10px",
              direction: "ltr",
              transform: "rotate(180deg)",
              bottom: lng === 'arab'? "6px":'4px',
            }}
          />
          <h4 onClick={() => setYearly(true)} style={{ cursor: "pointer",alignItems:"center",display:"flex"}}>
            {t("Annual")}
            <span className="save10" >{t("Save 10%")} </span>
          </h4>
        </div>
      </div>
    </>
    )}
    <div style={{backgroundColor: "transparent", idth: "100%", margin: "0 auto", display: "flex", flexDirection: "column", justifyContent: "center", gap: "20px"}}>
      {plansLoadin ? (
        <SkeletonTheme baseColor="#d3d3d3" highlightColor="#f3f6f4">
          <div style={{ rowGap: "10px", display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center"}} className="justify-content-center">
             <Skeleton
              width={270}
              height={320}
              borderRadius={"5%"}
              style={{
                margin: "0 10px 0 10px",
                aspectRatio: "278 / 401",
              }}
            />
            <div style={{ width: "1em" }} />
            <Skeleton
              width={270}
              height={320}
              borderRadius={"5%"}
              style={{
                margin: "0 10px 0 10px",
                aspectRatio: "278 / 401",
              }}
            />
            <div style={{ width: "1em" }} />
            <Skeleton
              width={270}
              height={320}
              borderRadius={"5%"}
              style={{
                margin: "0 10px 0 10px",
                aspectRatio: "278 / 401",
              }}
            />
          </div>
        </SkeletonTheme>
        ) : (
        <div style={{ display: "flex",gap: "40px",justifyContent: "center",margin: "auto",flexWrap: "wrap",width: "100%",}}>
         {plans.map((plan, i) => (
         <PlansComponent ismobile={ width <= 900}
              key={i}
              name={t(plan.name)}
              isYearly={isYearly}
              desc={
                lng === "arab"
                  ? plan?.product?.desc_ar
                  : plan?.product?.desc_en
              }
              price={
                isYearly
                  ? plan.yearly && plan.yearly.unit_amount / 100
                  : plan.monthly && plan.monthly.unit_amount / 100
              }
              list={
                lng === "arab"
                  ? // arabicPlans[plan?.name] : englishPlans[plan?.name]
                    plan?.features?.map((item) => item?.feat_ar)
                  : plan?.features?.map((item) => item?.feat_en)
              }
              padge={i === 1}
              isPro={i === plans?.length - 1}
              btnText={userSubscription?.product?.name ?? user?.plan}
              onPressed={login}
              myPlanId={
                isYearly
                  ? plan.yearly && plan.yearly.id
                  : plan.monthly && plan.monthly.id
              }
              useSubs={userSubscription?.amount}
              unitAMount={
                isYearly
                  ? plan?.yearly?.unit_amount
                  : plan?.monthly?.unit_amount
              }
              thisPlanId={
                isYearly
                  ? plan.yearly && plan.yearly.id
                  : plan.monthly && plan.monthly.id
              }
                setSelectedNumber={setSelectedNumber}
                setTotalPrice={setTotalPrice}
                isBusiness={selectedCategory === plansCategories?.slice(0)?.reverse().shift()?._id}
                ranges={plan?.monthly?.tiers}
                planName={plan.name}
            />
          ))}
        </div>
      )}

      <Col style={{ justifyContent: "center", padding: "10px" }}>
        <p
          style={{
            fontSize: "18px",
            color: "black",
            textAlign: "center",
            margin: "0px",
          }}
        >
          {t("Need More?")}
        </p>

        <div className="d-flex justify-content-center">
          <Link to="/contactus">
            <TextButton
              onPressed={() => {}}
              fontWeight={600}
              text={t("Contact Us")}
              backgroundColor={`transparent linear-gradient(to right,rgba(216, 81, 239, 1),rgba(146, 64, 202, 1),rgba(24, 119, 242, 1),rgba(24, 119, 242, 1),rgba(146, 64, 202, 1),rgba(216, 81, 239, 1)) no-repeat 0% 0% `}
              hoverBackgroundColor={`transparent linear-gradient(to right,rgba(216, 81, 239, 1),rgba(146, 64, 202, 1),rgba(24, 119, 242, 1),rgba(24, 119, 242, 1),rgba(146, 64, 202, 1),rgba(216, 81, 239, 1)) no-repeat  100% 0%`}
              textColor={"white"}
              padding={"0.5em 2em"}
              borderRadius={"8px"}
            />
          </Link>
        </div>
      </Col>

      {/* 
      <button
        className="d-flex"
        style={{
          margin: "0 auto",
          background:
            "linear-gradient(to right,rgba(216, 81, 239, 1), rgba(146,64,202,1),rgba(24, 119, 242, 1))",
        }}
      >
        <p
          style={{
            padding: "0.25em 1em",
            margin: 0,
            color: "white",
            fontFamily: "Poppins",
            fontWeight: 500,
            B,
          }}
        >
          Contact Us
        </p>
      </button> */}
    </div>
    <PopUpModal
        popUpFor={'email'}
        show={showModal}
        onHide={() => setShowModal(false)}
      />
  </div>
  );
};

export default Plans;
