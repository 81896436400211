import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toastAlert } from "../alert/toastAlert";
import styles from "./style.module.scss";
import { Box, Grid, Slider, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import rtlPlugin from 'stylis-plugin-rtl';
import arrow from "../Assets/Icons/Next.png";
import women from "../Assets/Img/women.png";
import mobile from "../Assets/Img/mobile.png";
import b2b from "../Assets/Img/b2b.png";
import b2bm from "../Assets/Img/b2bm.png";
import b2ba from "../Assets/Img/arabicdesktopview.png";
import b2bma from "../Assets/Img/arabicmobileview.png";
import rise from "../Assets/Icons/Rise.png";
import roll from "../Assets/Icons/Roll.png";
import output from "../Assets/Icons/output.png";
import int from "../Assets/Icons/Instagram.png";
import adword from "../Assets/Icons/Adwords.png";
import shopi from "../Assets/Icons/Shopify.png";
import word from "../Assets/Icons/Docs.png";
import face from "../Assets/Icons/Facebook.png";
import py from "../Assets/Icons/Pythonl.png";
import mega from "../Assets/Icons/Megaphone.png";
import quality from "../Assets/Icons/Qualityassurance.png";
import plan from "../Assets/Icons/Plan.png";
import Swal from "sweetalert2";
import RecaptchaPopUp from "../../views/RecaptchaPopUp";
import checkErr from "../../actions/api_error_func";
import B2Bgpt from "./B2Bgpt";
import PopUpModal from "../popUps/PopUpModal";
import { useSelector } from "react-redux";
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme({
  direction: 'rtl', // Both here and <body dir="rtl">
  typography: {
    fontFamily: ["tajawal"],
  }
});
const themeL = createTheme({
  direction: 'ltr', // Both here and <body dir="rtl">
  typography: {
    fontFamily: ["Poppins"],
  }
});

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});
const cacheLtr = createCache({
  key: 'muiltr',
  stylisPlugins: [prefixer],
});

const icons = [int, adword, shopi, word, face, py];
const business = [
  {
    image: mega,
    heading: "Better Marketing",
    subHeading:
      "Our marketing platform is designed to help businesses reach their goals faster and more efficiently. With Better Marketing,",
  },
  {
    image: quality,
    heading: "Better Planning",
    subHeading:
      "Our business planning software allows you to create strategies for growth and success!",
  },
  {
    image: plan,
    heading: "Better Quality",
    subHeading:
      "Are you tired of subpar business solutions? Our solution provides the highest quality of business services in the market.",
  },
];
const marksE = [
  {
    value: 2,
    label: "small",
  },
  {
    value: 25,
    label: "Medium",
  },
  {
    value: 50,
    label: "Large",
  },
];
const marksA = [
  {
    value: 2,
    label: "صغيرة",
  },
  {
    value: 25,
    label: "متوسطة",
  },
  {
    value: 50,
    label: "كبيرة",
  },
];

const B2B = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [submitting, setSubmitting] = useState(false);
  const [recaptchaPopUp, setRecaptchaPopUp] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState(0);
  const [loginModal, setLoginModal] = useState(false);
  const user = useSelector((state) => state.userAuth);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company_name: "",
    company_size: "2",
    message: "",
  });
  const handleChange = (e) => {
    const { name: key, value } = e.target;
    setFormData({ ...formData, [key]: value });
  };

  const sendTicket = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setRecaptchaPopUp(true);
  };
  const pureFunc = async (e) => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/ticket/create`, {
      method: "POST",
      headers: {
        Authorization: localStorage.getItem("sess"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then(async (data) => {
        if (data.success) {
          Swal.fire({
            title: t("Sent!"),
            text: t("Your ticket has been successfully sent to us"),
            icon: "success",
            confirmButtonText: "Close",
          });
          setFormData({
            name: "",
            email: "",
            company_name: "",
            company_size: "",
            message: "",
          });
        }
        setSubmitting(false);
      })
      .catch((err) => {
        checkErr(err, setSubmitting, toastAlert);
        setSubmitting(false);
      });
  };
  useEffect(() => {
    if (captchaResponse) {
      pureFunc();
    }
  }, [captchaResponse]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(formData, "the data");
  return (
    <Box sx={{ direction: lng === "arab" ? "rtl" : "ltr" }}>
      <Box sx={{ background: "#030314" }}>
        <Box className={styles.banner}>
          <div className={styles.ball1}></div>
          <div className={styles.ball2}></div>
          <div className={styles.bannerBg}>
            <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <h1 style={{ textAlign: lng === "arab" ? "right" : "left" }}>
              {t("The Leading Generative AI")}
              <br /> {t("In the Middle East")}
            </h1>
            <div style={{display: 'flex', zIndex: '99'}}>
            <button
              style={{width: 'auto', transform: 'scale(0.8)'}} 
              className={lng === 'arab'? "btn common-btn fs-5 fw-bold":"btn common-btn fs-5"}
              onClick={() => !user? setLoginModal(true) : window.open(process.env.REACT_APP_DASHBOARD_URL+"?t=" +localStorage.getItem("sess"),"_blank")}
            >
              {t("Start Now")}
            </button>
            </div>
            
            </div>
            
            {lng === "arab" ? (
              <img src={b2ba} alt="" />
            ) : (
              <img src={b2b} alt="" />
            )}
            {lng === "arab" ? (
              <img src={b2bma} alt="" />
            ) : (
              <img src={b2bm} alt="" />
            )}
            <div className={styles.imagesContainer}>
              <h2>
                {t("Easily bring AI in your workflow to improve")} <br />
                {t("& create content, wherever you are.")}
              </h2>
              <div className={styles.icons}>
                {icons.map((items) => {
                  return (
                    <div>
                      <img src={items} alt="" width={20} height={20} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Box>
        <Box className={styles.container}>
          <Grid container sx={{ maxWidth: "1200px", margin: "auto" }}>
            <Grid className={styles.grid} item md={6} sm={12} xs={12}>
              <h4>Araby.ai</h4>
              <hr />
              <h4>{t("Perfect Solution For your Business")}</h4>
              <h1>
                {t("Everything you need to")}
                <br />
                {t("scale your team")}
              </h1>
              <Box className={styles.b_container}>
                <Box>
                  <h2>{t("I’m a small Business")}</h2>
                  <h6>
                    {t(
                      "Araby.Ai is tailored for all business sizes from small to large"
                    )}
                  </h6>
                  <a href="#contact">{t("Get started")}</a>
                  <img src={arrow} alt="" />
                </Box>
                <Box>
                  <h2>{t("We are a Corporate")}</h2>
                  <h6>
                    {t(
                      "Seamlessly integrate Araby.Ai at any level of your business."
                    )}
                  </h6>
                  <a href="#contact">{t("Get started")}</a>
                  <img src={arrow} alt="" />
                </Box>
              </Box>
            </Grid>
            <Grid alignItems="center" item md={6} sm={12} xs={12}>
              <Box className={styles.right}>
                <Box className={styles.columns}>
                  <img
                    src={women}
                    className={lng === "arab" ? styles.imgA : styles.imgE}
                    alt=""
                  />
                  <Box>
                    <h1 style={{ direction: "ltr" }}>100 M</h1>
                    <h6>
                      {t("Fact checked")} <br /> {t("Words")}
                    </h6>
                  </Box>
                </Box>
                <Box className={styles.columnsR}>
                  <Box className={styles.grow}>
                    <h1>85%</h1>
                    <h6>
                      {t("Grow Your Business")} <br /> {t("And Performance")}
                    </h6>
                  </Box>
                  <Box className={styles.mobile}>
                    <img
                      src={mobile}
                      className={
                        lng === "arab" ? styles.mobileA : styles.mobileE
                      }
                      alt=""
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={styles.scaleContainer}>
        <h1>
          {t("Accelerate Your business KPIs with")} <br />{" "}
          {t("artificial intelligence")}
        </h1>
        <h6>
          {t(
            "Grow bigger with araby.ai. streamline your work and increase your quality without sacrificing time or effort."
          )}
        </h6>
        <div className={styles.boxes}>
          <div>
            <h1>
              <img src={rise} alt="" />
              80%
            </h1>
            <h6>{t("Time reduced spend on 1st draft")}</h6>
          </div>
          <div>
            <h1>
              <img src={output} alt="" />
              10X
            </h1>
            <h6>{t("Output of High-quality content")}</h6>
          </div>
          <div>
            <h1>
              <img src={roll} alt="" />
              400%
            </h1>
            <h6>{t("Roi using Araby ai")}</h6>
          </div>
        </div>
      </Box>
      <Box className={styles.forBusinees}>
        <h1>
          <span>Araby.Ai</span> {t("for your Business")}
        </h1>
        <h4 style={{ margin: "40px auto" }}>
          {t(
            "The right solution for any business. improve your workflows and start enhancing you business instantly with araby.ai. contact us now to get started"
          )}
        </h4>
        <button
          style={{ width: "unset", fontSize: "1rem", fontWeight: "bold" }}
          className="btn common-btn cu-btn"
        >
          <a href="#contact"> {t("Contact Us")}</a>
        </button>
      </Box>
      <B2Bgpt />
      <Box className={styles.forBusinees}>
        <h1>
          <span>Araby.Ai</span> {t("for your companies")}{" "}
        </h1>
        <div>
          {business.map((items, index) => {
            return (
              <div key={index}>
                <img src={items.image} alt="" />
                <h4>{t(items.heading)}</h4>
                <p>{t(items.subHeading)}</p>
              </div>
            );
          })}
        </div>
      </Box>
      
      <CacheProvider value={lng === 'arab'? cacheRtl:cacheLtr}>

      <ThemeProvider theme={lng === "arab" ? theme : themeL}>
      <Box id="contact" className={styles.contactUs} >
        <div className={styles.collaborate}>
          <div>
            <h1>
              {t("Lets")} <span>{t("collaborate")}</span> {t("& Start")}{" "}
              <span>{t("Now")}</span>
            </h1>
            <h6>
              {t(
                "Ready to take your team's productivity to the next level? Contact us today and get started with our exclusive team packages. Experience the power of ArabyAi's premium features and unlock a whole new level of efficiency and innovation for your work. Don't miss out on this opportunity to elevate your team's performance - reach out to us now"
              )}
            </h6>
          </div>
        </div>
        <form
          onSubmit={sendTicket}
          className={styles.group}
          onChange={handleChange}
        >
          <h2>{t("Contact Us")}</h2>
          <TextField
            name="name"
            required
            value={formData.name}
            disabled={submitting}
            variant="outlined"
            id="outlined-basic"
            label={t("Name")}
            size="small"
            InputProps={{ sx: { borderRadius: 3 } }}
            fullWidth
          />
          <TextField
            name="email"
            type="email"
            value={formData.email}
            required
            disabled={submitting}
            variant="outlined"
            id="outlined-basic"
            label={t("Email")}
            size="small"
            InputProps={{ sx: { borderRadius: 3 } }}
            sx={{ mt: 2 }}
            fullWidth
          />
          <TextField
            value={formData.company_name}
            name="company_name"
            required
            type="text"
            disabled={submitting}
            variant="outlined"
            id="outlined-basic"
            label={t("Company name")}
            size="small"
            sx={{ mt: 2 }}
            InputProps={{ sx: { borderRadius: 3 } }}
            fullWidth
          />
          <label style={{ alignSelf: "flex-start" }}>
            {t("Team size")} : {formData.company_size}
            {formData.company_size === 50 ? "+" : ""} {t("users")}
          </label>
          <Slider
            value={formData.company_size}
            name="company_size"
            aria-label="company_size"
            step={2}
            marks={lng === "arab" ? marksA : marksE}
            min={2}
            max={50}
            onChange={handleChange}
          />
          <TextField
            name="message"
            value={formData.message}
            disabled={submitting}
            variant="outlined"
            id="outlined-basic"
            label={t("message")}
            InputProps={{ sx: { borderRadius: 3 } }}
            sx={{ mt: 2 }}
            multiline
            fullWidth
          />
          <button
            style={{
              marginTop: "20px",
              width: "unset",
              fontSize: "1rem",
              fontWeight: "bold",
            }}
            className="btn common-btn cu-btn"
          >
            {t("Get In Touch")}
          </button>
        </form>
      </Box>
      </ThemeProvider>

      </CacheProvider>
      <RecaptchaPopUp
        setLoading={setSubmitting}
        backdrop="static"
        show={recaptchaPopUp}
        onHide={() => setRecaptchaPopUp(false)}
        captcharesponse={captchaResponse}
        submitfunction={setCaptchaResponse}
      />
      <PopUpModal
        show={loginModal}
        login={true}
        onHide={() => setLoginModal(false)}
      />
    </Box>
  );
};

export default B2B;
