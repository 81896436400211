import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import Reaptcha from 'reaptcha';
import validateCaptchaToken from '../api/captchaCheck';
import { toastAlert } from '../components/alert/toastAlert';

const RecaptchaPopUp = (props) => {
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const { t } = useTranslation();
    const [capatchaInput, setCapatchInput] = useState('');
    const captchaRefSec = useRef(null)
    useEffect(() => {
        if (capatchaInput) {
            captchaRefSec?.current?.execute()
        }
    }, [capatchaInput])
    const verify = () =>{
        captchaRefSec?.current?.getResponse()?.then(res => {
            setCapatchInput(res)
            validateCaptchaToken(res)
            .then(async (innserRes) => {
                if (innserRes?.success) {
                    props.submitfunction(props.captcharesponse + 1)
                    props.onHide();
                } else {
                    if (props.setCustomResponse) {
                        props.setCustomResponse({ status: props.FutureStatus.initialized });
                    }
                    if (props.setLoading) {
                        props.setLoading(false)
                    }
                    
                    toastAlert("Too much actions, Please try again later", undefined, 'warning');
                    props.onHide();
                }
            })
            .catch((e) => {
                if (props.setCustomResponse) {
                    props.setCustomResponse({ status: props.FutureStatus.initialized });
                }
                if (props.setLoading) {
                    props.setLoading(false)
                }
                toastAlert("Something went wrong, please try again later", undefined, 'warning')
                props.onHide();
            })
        })
    }
  return (
    <Modal
      {...props}
      size="md"
      centered
      className='captchaModal'
    >
      <Modal.Body className='forceArabicFont' style={{ display: 'flex', padding: '5px', alignItems: 'center', justifyContent: 'center', minHeight: '200px' }}>
            <Reaptcha 
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={captchaRefSec}
                theme='dark'
                className='captchClass'
                size='invisible'
                onLoad={() => setCapatchInput('ready')}
                onVerify={verify}
                onExpire={() => setCapatchInput('')}
          />
      </Modal.Body>
    </Modal>
  )
}

export default RecaptchaPopUp