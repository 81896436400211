import * as api from "../api";
import { toastAlert } from "../components/alert/toastAlert";
import { LOADING, USER_SUBSCRIPTION, STOP_LOADING } from "../constants";
import checkErr from "./api_error_func";

export const getUserSubscription = () => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
        var result = await api.userSubscription();
        dispatch({ type: USER_SUBSCRIPTION, subscription: result?.data?.subItem || [] });
        dispatch({ type: STOP_LOADING });
    } catch (error) {
        dispatch({ type: STOP_LOADING });
        checkErr(error, false, toastAlert, false, dispatch, false)
    }
};

export const setUserSubscription = (userSubscription) => (dispatch) => {
    dispatch({ type: USER_SUBSCRIPTION, subscription: userSubscription });
};
