import React, { useState, useEffect, useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from 'react-i18next';
import { requestOtp, socialLogin, verifyOtp, verifyFireBaseOtp, register, newSocialLogin } from '../../api';
import OtpInput from 'react-otp-input';
import { Spinner } from 'react-bootstrap';
import { LoginSocialGoogle, LoginSocialFacebook, LoginSocialApple } from 'reactjs-social-login'
import { useDispatch } from 'react-redux';
import { authentication } from '../../firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toastAlert } from '../alert/toastAlert';
import { createSearchParams, Link } from 'react-router-dom';
import Assets from "../../utils/assets";
import checkErr from '../../actions/api_error_func';
import TextButton from '../textbutton/textbutton';
import RegisterSuccess from '../../views/loginscreen/components/registersuccess/registersuccess';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery'
import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import Reaptcha from 'reaptcha';
import RecaptchaPopUp from '../../views/RecaptchaPopUp';
import { ClientJS } from 'clientjs';
import BrowserModal from './BrowserModal';

const PopUpModal = (props) => {
  
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  
  const { t } = useTranslation();
  const [popUpFor, setPopUpFor] = useState('real');
  useEffect(() => {
    setPopUpFor(props.popUpFor || 'real')
  }, [props.for])
  return (
    <Modal
      {...props}
      size="lg"
      centered
      className='auth_modal'
    >
      <Modal.Header style={{border: 'unset', borderTopLeftRadius: '30px', borderTopRightRadius: '30px', paddingBottom: '0px', paddingRight: '20px'}} closeButton>

      </Modal.Header>
      <Modal.Body className='forceArabicFont row' style={{ direction: lng === 'arab'? 'rtl': 'ltr', display: 'flex', padding: '16px' }}>
        {
          popUpFor === 'real' ?
            <RealSignUpAssets /> :
            popUpFor === 'email' ?
              <RealEmailAssets /> :
              popUpFor === 'social' ?
                <RealSocialAssets /> :
                popUpFor === 'images' ?
                  <RealImagesAssets /> :
                  popUpFor === 'articles' ?
                    <RealArticlesAssets /> :
                    <RealSignUpAssets />
        }
        <div className='formWrapper widthMedia form-group  col-md-6 col-sm-12 col-lg-6'>
          <SignUpForm type={popUpFor} exit={props.onHide} />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default PopUpModal

export const SignUpForm = ({type, exit, seLoginWithMobile, page, loginWithMobile}) => {
  const captchaRef = useRef();
  const navigate = useNavigate();
  const [innserOTP, setInnerOTP] = useState('');
  const [isValidBrowser, setIsValidBrowser] = useState(true);
  const [modalOpenBrowser, setModalOpenBrowser] = useState(false);

  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  useEffect(() => {
    const client = new ClientJS();
    const browser = client.getBrowser();
    setIsValidBrowser(browser !== "Instagram")
    
  }, [])
  function generateOTP() {
    var digits = '0123456789';
    let OTP = '';
    for (let i = 0; i < 4; i++ ) {
        OTP += digits[Math.floor(Math.random() * 10)];
    }
    return OTP;
}

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
      'size': 'invisible',
      'callback': (response) => {
        // onSignInSubmit();
      }
    }, authentication);
  }
  const dispatch = useDispatch();
  const googleRef = useRef(null);
  const facebookRef = useRef(null);
  const { t } = useTranslation();
  const [phoneData, setPhoneData] = useState({
    phone_number: '',
    otp: ''
  })
  const [emailReg, setEmailReg] = useState({
    email: '',
    password: '',
    password_confirm: ''
  })
  const [phoneErrors, setPhoneErrors] = useState('');
  const [fireBaseInUser, setFireBaseInUse] = useState(false)
  const [fireBaseInUserCount, setFireBaseInUseCount] = useState(0)

  const [loading, setLoading] = useState(false)
  const [view, setView] = useState(0)
  const [captchaResponse, setCaptchaResponse] = useState(0)
  const [recaptchaPopUp, setRecaptchaPopUp] = useState(false)

  const submitEmail = (e) => {
    e.preventDefault();
    if (!emailReg.email) {
      return toastAlert("Please enter a valid email", null, "warning");
    } else {
      setView(2);
    }
  }
  const validatePassword = (password) => {
    const re = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.{8,21})/);
    return re.test(password);
  };
  
  const submitNewAccount = async (e) => {
    e.preventDefault();
    setLoading(true)
    if (!emailReg.password) {
      setLoading(false)
      return toastAlert("Please enter a valid password", null, "warning");
    } else if (!validatePassword(emailReg.password)) {
      setLoading(false)
      return;
    } else if (!emailReg.password_confirm) {
      setLoading(false)
      return toastAlert("Please enter password confirmation", null, "warning");
    }  else if (emailReg.password !== emailReg.password_confirm) {
      setLoading(false)
      return toastAlert("The two passwords don't match", null, "warning");
    } else {
      setRecaptchaPopUp(true)
    }
  }
  const pureRegister = async () => {
    try {
      const result = await register(emailReg.email, emailReg.password);
      setView(1);
      setLoading(false);
    } catch (error) {
      setView(0);
      setLoading(false);
      setEmailReg({...emailReg, 
        password: '',
        password_confirm: ''
      })
      toastAlert(error?.response?.data?.message, null, "warning");
    }
  }
  useEffect(() => {
    if (captchaResponse) {
      pureRegister()
    }
  }, [captchaResponse])
  const signInUserSocial = async (access_token, name, type) => {
    try {
      let res = await socialLogin(access_token, name, type);
      dispatch({type: "AUTH", data: res.data.token});
      // setLoading(false)

    } catch (error) {
      checkErr(error, setLoading, toastAlert);
    }
  }
  useEffect(() => {
    if (fireBaseInUserCount >= 2) {
      setFireBaseInUse(false);
    }
  }, [fireBaseInUserCount])
  const requestOTP = () => {
    setLoading(true)
    if (!phoneData.phone_number) {
      setLoading(false)
      return toastAlert("Please enter your phone number'", undefined, "warning");
    } else if (!isValidPhoneNumber(phoneData.phone_number)) {
      setLoading(false)
      return toastAlert("Please enter a valid phone number", undefined, "warning");
    }
      let oldAppVerifier = window.recaptchaVerifier;
      if (oldAppVerifier) {
        oldAppVerifier.clear();
      captchaRef.current.innerHTML = `<div id="sign-in-button"></div>`
    }
    setLoading(true);
    generateRecaptcha();
    let appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(authentication, phoneData.phone_number, appVerifier)
    .then(confirmationResult => {
      setView(3)
      window.confirmationResult = confirmationResult;
      toastAlert("Sent", undefined, "success");
      setLoading(false);
    })
    .catch(error => {
      toastAlert(
        error.message == 'Firebase: Error (auth/too-many-requests).'?
        "too many requests, Please try again later":
        "Something went wrong, please try again", undefined, "warning");
      setLoading(false);
    })
  }

  const verifyOTP = () => {
    setLoading(true)
    if (!phoneData.otp) {
      setLoading(false)
      return toastAlert("Please enter otp", undefined, "warning");
    } else if (phoneData.otp.length < 4) {
      setLoading(false)
      return toastAlert("Please enter a valid otp", undefined, "warning");
    }
      let confirmationResult = window.confirmationResult;
      confirmationResult.confirm(phoneData.otp).then(result => {
        const user = result.user;
        verifyFireBaseOtp(user.accessToken).then(res => {
          setLoading(false)
          dispatch({type: "AUTH", data: res.data.token});
          setView(0);
          setLoading(false);
          setPhoneErrors('');
          setPhoneData({phone_number: '', otp: ''})
          if(exit) {
            exit();
          }
        })
        .catch(e => {
          // setLoading(false)
          return checkErr(e, setLoading, toastAlert)
          // return toastAlert("Please enter a valid otp", undefined, "warning");
        })
      })
        .catch(error => {
          return checkErr(error, setLoading, toastAlert)
        })
  }
  const handleResend = () => {
      let oldAppVerifier = window.recaptchaVerifier;
      if (oldAppVerifier) {
        oldAppVerifier.clear();
        captchaRef.current.innerHTML = `<div id="sign-in-button"></div>`
      }
      setLoading(true);
      generateRecaptcha();
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(authentication, phoneData.phone_number, appVerifier)
      .then(confirmationResult => {
        window.confirmationResult = confirmationResult;
        toastAlert("Sent", undefined, "success");
        setLoading(false);
      })
      .catch(error => {
        toastAlert(
          error.message == 'Firebase: Error (auth/too-many-requests).'?
          "too many requests, Please try again later":
          "Something went wrong, please try again", undefined, "warning");
        setFireBaseInUse(false);
        setLoading(false);
      })
  }
  let params = {
    view: 'mob'
  }
  const authKeyReqHandler = () => {
    setLoading(true)
    if (!phoneData.phone_number) {
      setLoading(false)
      return toastAlert("Please enter your phone number", undefined, "warning");
    } else if (!isValidPhoneNumber(phoneData.phone_number)) {
      setLoading(false)
      return toastAlert("Please enter a valid phone number", undefined, "warning");
    }
    requestOtp(parsePhoneNumber(phoneData.phone_number)?.nationalNumber, parsePhoneNumber(phoneData.phone_number)?.countryCallingCode )
    .then(res => {
      setView(3)
      setLoading(false);
      toastAlert("Sent", undefined, "success");
    })
    .catch(e => {
      checkErr(e, setLoading, toastAlert)
    })
    // let otp = generateOTP();
    // let message = `Hello From Araby.ai, Your OTP is ${otp}`;
    // setInnerOTP(otp);
    // var settings = {
    //   "async": true,
    //   "crossDomain": true,
    //   "url": `https://api.authkey.io/request?authkey=0652f53b7c4f968d&mobile=${parsePhoneNumber(phoneData.phone_number)?.nationalNumber}&country_code=${parsePhoneNumber(phoneData.phone_number)?.countryCallingCode}&sms=${message}&sender=Araby.ai`,
    //   "method": "GET"
    // }
    // $.ajax(settings).done(function (response) {
    //   if (response?.LogID) {
    //     toastAlert(response?.Message, undefined, "success");
    //     setView(3)
    //     setLoading(false);
    //   } else {
    //     checkErr()
    //     toastAlert("Something went wrong, Please try another method", undefined, "warning");
    //     setLoading(false);
    //   }
    // });
  }
  const authKeyVerifyHandler = () => {
    setLoading(true)
    if (!phoneData.otp) {
      setLoading(false)
      return toastAlert("Please enter otp", undefined, "warning");
    } else if (phoneData.otp.length < 4) {
      setLoading(false)
      return toastAlert("Please enter a valid otp", undefined, "warning");
    }
    verifyOtp(parsePhoneNumber(phoneData.phone_number)?.nationalNumber, parsePhoneNumber(phoneData.phone_number)?.countryCallingCode, phoneData.otp)
    .then(res => {
      setLoading(false)
      dispatch({ type: "AUTH", data: res.data.token });
      setView(0);
      setLoading(false);
      setPhoneErrors('');
      setPhoneData({ phone_number: '', otp: '' })
      if(exit) {
        exit();
      }
    })
    .catch(e => {
      setPhoneData({...phoneData, otp: ''})
      return checkErr(e, setLoading, toastAlert)
    })
    // if (phoneData.otp == innserOTP) {
    //   verifyFireBaseOtp(phoneData.phone_number).then(res => {
    //     setLoading(false)
    //     dispatch({type: "AUTH", data: res.data.token});
    //     setView(0);
    //     setLoading(false);
    //     setPhoneErrors('');
    //     setPhoneData({phone_number: '', otp: ''})
    //     if(exit) {
    //       exit();
    //     }
    //   })
    //   .catch(e => {
    //     return checkErr(e, setLoading, toastAlert)
    //   })
    // } else {
    //   setLoading(false);
    //   return toastAlert("OTP has been Expired", undefined, "warning");
    // }
  }

  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
        signInUserSocial(tokenResponse.access_token, '', "GOOGLE")
    },
    onNonOAuthError: (err) => {
      setLoading(false)
    },
    onError: (err) => {
      setLoading(false)
    }
  });

  useGoogleOneTapLogin({
    onSuccess: async (credentialResponse) => {
      setLoading(true)
      try {
        let res = await newSocialLogin(credentialResponse.credential);
        dispatch({type: "AUTH", data: res.data.token});
      } catch (error) {
        checkErr(error, false, toastAlert);
        setLoading(false)
      }
    },
    onError: () => {
    },
    cancel_on_tap_outside: false
  });
  
    return (
        <div  style={{gap: '7px', justifyContent:'center', alignItems:'center', margin: type === "mobile" && "0 auto"}} className={type === 'mobile'? 'formGlobal row':'row'}>
          {loading?
          <Spinner/>:
          view === 0?
          <>
            {type != "mobile" && (
            <div className='form_header col-md-12 col-sm-12 col-lg-10'>
              <h5 style={{ textAlign: 'center' }}>{t("Create Account..")}</h5>
              {
                type === 'real' ?
                  <p style={{ textAlign: 'center', color: '#000' }}>
                    {t("Get Access to Social Media, Emails, Articles, Advertisements, Images, and Much More")}
                  </p> :
                  type === 'email' ?
                    <p style={{ textAlign: 'center', color: '#000' }}>
                      {t("And Write Professional Emails, Cold Emails, Promotional Email.")}
                    </p> :
                    type === 'social' ?
                      <p style={{ textAlign: 'center', color: '#000' }}>
                        {t("Write") + ' '}
                        <label style={{ color: '#E1306C' }}>{t("Instagram caption")}</label>
                        {t(', ') + ' '}
                        <label style={{ color: '#00ACEE' }}>{t("Twitter posts")}</label>
                        {t(', ') + ' '}
                        <label style={{ color: '#3B5998' }}>{t("Facebook Ads")}</label>
                        {t(', ') + ' '}
                        <label style={{ color: '#0072B1' }}>{t("LinkedIn Posts")}</label>
                      </p> :
                      type === 'images' ?
                        <p style={{ textAlign: 'center', color: '#000' }}>
                          {t("Generate Content for your articles and blogs automatically in seconds")}
                        </p> :
                        type === 'articles' ?
                          <p style={{ textAlign: 'center', color: '#000' }}>
                            {t("Write Blogs, Stories, Articles and all other types of documents in seconds")}
                          </p> :
                          <p style={{ textAlign: 'center', color: '#000' }}>
                            {t("Get Access to Social Media, Emails, Articles, Advertisements, Images, and Much More")}
                          </p>
              }
            </div>
            )}
            <div className={type === "mobile"? "sign_up_tools col-md-12 col-sm-12 col-lg-12" : "sign_up_tools col-md-12 col-sm-12 col-lg-10"}>
              {type === "mobile"? 
              <>
              
              <label className="text-label">{t("Phone Number")}</label>
              <div style={{direction: 'rtl', display: 'flex', alignItems: 'center', border: '1px solid #ced4da', borderRadius: '0.375rem', padding: '3px' }}>
                <button onClick={() => requestOTP()} className='pop_up_submitBTN'>
                  <img src={Assets?.SUBMIT_ARROW} style={{ height: '15px', width: '15px' }} alt='submit' />
                </button>
                <PhoneInput
                  id="phone"
                  country="sa"
                  // className="form-control"
                  style={{ display: "flex", direction: 'ltr' }}
                  placeholder={t('Enter phone number')}
                  value={phoneData.phone_number}
                  onChange={(phone) =>
                    setPhoneData({
                      ...phoneData,
                      phone_number: '+'+phone,
                    })
                  }
                />
              </div>
              </>:
              <form onSubmit={submitEmail} className='row pop_sign_up_form'>
                <div className='form-group'>
                  <input style={{marginBottom: type === 'mobile'? '9px':null}} value={emailReg.email} onChange={(e) => setEmailReg({...emailReg, email: e.target.value})} className='form-control' type='email' placeholder={t("Enter your email")} required/>
                </div>
                <div className='form-group'>
                <TextButton
                  backgroundColor={"transparent linear-gradient(to right, #d851ef, #9240CA, #1877f2, #1877f2,#9240CA,#d851ef) no-repeat 0% 0%"}
                  hoverBackgroundColor={"transparent linear-gradient(to right, #d851ef,#9240CA, #1877f2,#1877f2,#9240CA,#d851ef) no-repeat 100% 0%"}
                  onPressed={undefined}
                  isSubmitButton={true}
                  text={t("Continue")}
                  fontWeight="500"
                  fontSize="0.9em"
                  textColor={"white"}
                  style={{ width: "100%", height: "35px" }}
                  borderRadius={"6px"}
                  shadow={"0px 2px 2px rgba(0,0,0,0.05)"}
                  border={"0"}
                  hoverScale={0.95}
                  disableButton={false}
                  padding={"0.2em 0"}
                  // disable={isLoading}
                />
                </div>
              </form>}
              <button 
                onClick={() => {
                  if (type !== "mobile" || !type) {
                    // navigate("/auth/signup", { state: {view: 'mob'} });
                    navigate({
                      pathname: '/auth/signup',
                      search: `?${createSearchParams(params)}`,
                    })
                    // navigate('/auth/signup?view=mob');
                  } else {
                    if (type === 'mobile' && page) {
                      navigate('/auth/login');
                    } else {
                      navigate('/auth/signup');
                    }
                    seLoginWithMobile(loginWithMobile? false:'mobile')
                  }
                }} 
                style={{color: '#1171D8', marginTop: '10px', marginBottom:'5px', cursor:'pointer', backgroundColor: 'transparent', border: 'none'}}>
                {type === 'mobile' && page && t("Login with email?")}
                {type === 'mobile' && !page && t("Sign up with email?")}
              </button>
            </div>
            <div  className={type === "mobile"? "widthMedia form-group col-md-12 col-sm-12 col-lg-12" : 'widthMedia form-group col-md-7 col-sm-10 col-lg-8'}>
              <svg style={{ direction: 'ltr' }} xmlns="http://www.w3.org/2000/svg" width="100%" height="23" viewBox="0 0 430 23">
                <g id="Group_1679" data-name="Group 1679" transform="translate(-310.5 -303.5)">
                  <line id="Line_21" data-name="Line 21" x1="0" x2="120" transform="translate(310.5 315)" fill="none" stroke="#707070" stroke-width="1" />
                  <line id="Line_26" data-name="Line 26" x1="0" x2="120" transform="translate(620.5 315)" fill="none" stroke="#707070" stroke-width="1" />
                  <text id="Or_Continue_With" data-name="Or Continue With" transform="translate(531 320.5)" fill="#020202" font-size="16" font-family="Tajawal" font-weight="600" opacity="0.34"><tspan x="-50.504" y="0">{t("Or Continue With")}</tspan></text>
                </g>
              </svg>
            </div>
            <div style={{ justifyContent: 'center' }} className={type === "mobile"? 'row col-md-12 col-sm-12 col-lg-12' : 'row col-md-12 col-sm-12 col-lg-10'}>
              <div className={type === 'mobile'? 'modal_img_wrapper widthMedia form-group  col-md-12 col-sm-12 col-lg-12':'modal_img_wrapper widthMedia form-group  col-md-6 col-sm-12 col-lg-6'}>

                {isValidBrowser?<LoginSocialFacebook
                  ref={facebookRef}
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                  auth_type='reauthenticate'
                  redirect_uri={process.env.REACT_APP_SOCIAL_REDIRECT_URL}
                  onLoginStart={() => {
                    setLoading(true);
                  }}
                  
                  // onLogoutFailure={onLogoutFailure}
                  // onLoginStart={onLoginStart}
                  // onLogoutSuccess={onLogoutSuccess}
                  onResolve={({ provider, data }) => {
                    //TODO check if its 
                    signInUserSocial(data.accessToken, data.name, "FACEBOOK")
                  }}
                  onReject={(err) => {
                    setLoading(false);
                  }}
                >
                  {/* <button className='facebook btn auth-btn'>
                    <label>Facebook</label>
                    <img src={Assets?.FACEBOOK_ICON} alt='google_icon' />
                  </button> */}
                  <TextButton
                    backgroundColor={"white"}
                    text="Faceboook"
                    fontSize="0.75em"
                    onPressed={undefined}
                    style={{ width: "100%", height: "35px" }}
                    borderRadius={"6px"}
                    shadow={"0px 2px 4px rgba(0,0,0,0.1)"}
                    hoverShadow={"0px 2px 4px rgba(0,0,0,0.1)"}
                    hoverScale={0.95}
                    disableButton={false}
                    padding={"0.2em 0"}
                    leadIcon={<img src={Assets.FACEBOOK_ICON} width="20px" />}
                    // disable={isLoading}
                  />
                </LoginSocialFacebook>:<TextButton
                    backgroundColor={"white"}
                    text="Faceboook"
                    fontSize="0.75em"
                    onPressed={() => {setModalOpenBrowser(true)}}
                    style={{ width: "100%", height: "35px" }}
                    borderRadius={"6px"}
                    shadow={"0px 2px 4px rgba(0,0,0,0.1)"}
                    hoverShadow={"0px 2px 4px rgba(0,0,0,0.1)"}
                    hoverScale={0.95}
                    disableButton={false}
                    padding={"0.2em 0"}
                    leadIcon={<img src={Assets.FACEBOOK_ICON} width="20px" />}
                    // disable={isLoading}
                  />}
              </div>
              <div className={type === 'mobile'? 'modal_img_wrapper widthMedia form-group  col-md-12 col-sm-12 col-lg-12':'modal_img_wrapper widthMedia form-group  col-md-6 col-sm-12 col-lg-6'}>
                {/* <button className='google_btn btn auth-btn'>
                        <label>Google</label>
                        <img src={GoogleeButton} alt='google_icon'/>
                    </button> */}
                {/* <LoginSocialGoogle
                  ref={googleRef}
                  client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  isSignedIn={true}
                  redirect_uri={process.env.REACT_APP_SOCIAL_REDIRECT_URL}
                  scope='https://www.googleapis.com/auth/userinfo.email'
                  onLoginStart={(err) => {

                  }}
                  onResolve={({ provider, data }) => {
                    signInUserSocial(data.access_token, data.name, "GOOGLE")
                  }}
                  onReject={(err) => {
                  }}
                > */}
                  {isValidBrowser?<TextButton
                      backgroundColor={"white"}
                      text="Google"
                      fontSize="0.75em"
                      onPressed={() => {setLoading(true); loginWithGoogle()}}
                      style={{ width: "100%", height: "35px" }}
                      borderRadius={"6px"}
                      shadow={"0px 2px 4px rgba(0,0,0,0.1)"}
                      hoverShadow={"0px 2px 4px rgba(0,0,0,0.1)"}
                      hoverScale={0.95}
                      disableButton={false}
                      padding={"0.2em 0"}
                      leadIcon={<img src={Assets.GOOGLE_ICON} width="20px" />}
                      // disable={isLoading}
                    />:
                    <TextButton
                      backgroundColor={"white"}
                      text="Google"
                      fontSize="0.75em"
                      onPressed={() => {setModalOpenBrowser(true)}}
                      style={{ width: "100%", height: "35px" }}
                      borderRadius={"6px"}
                      shadow={"0px 2px 4px rgba(0,0,0,0.1)"}
                      hoverShadow={"0px 2px 4px rgba(0,0,0,0.1)"}
                      hoverScale={0.95}
                      disableButton={false}
                      padding={"0.2em 0"}
                      leadIcon={<img src={Assets.GOOGLE_ICON} width="20px" />}
                      // disable={isLoading}
                    />}
                    
                   
                  
                  
                  
                {/* </LoginSocialGoogle> */}
              </div>

              <div className='modal_img_wrapper widthMedia form-group  col-md-6 col-sm-12 col-lg-12'>

                <LoginSocialApple
                  client_id={process.env.REACT_APP_APPLE_ID || ''}
                  scope={'name email'}
                  redirect_uri={process.env.REACT_APP_SOCIAL_REDIRECT_URL || 'https://araby.ai'}
                  // onLoginStart={onLoginStart}
                  onLoginStart={() => {
                    setLoading(true);
                  }}
                  onResolve={({ provider, data }) => {
                    signInUserSocial(data.authorization.code, data?.name, "APPLE")

                    // setProvider(provider);
                    // setProfile(data);
                  }}
                  onReject={(err) => {
                    setLoading(false);
                  }}
                >
                  {/* <button className='apple btn auth-btn'>
                    <label>Apple</label>
                    <img src={Assets?.APPLE_ICON} alt='google_icon' />
                  </button> */}
                  <TextButton
                    backgroundColor={"black"}
                    hoverBackgroundColor={"black"}
                    text="Apple"
                    textColor="white"
                    fontSize="0.75em"
                    onPressed={undefined}
                    style={{ width: "100%", height: "35px" }}
                    borderRadius={"6px"}
                    className="appleBtn"
                    shadow={"0px 2px 4px rgba(0,0,0,0.1)"}
                    hoverShadow={"0px 2px 4px rgba(0,0,0,0.1)"}
                    hoverScale={0.95}
                    disableButton={false}
                    padding={"0.2em 0"}
                    leadIcon={<img src={Assets.APPLE_ICON} width="20px" />}
                    // disable={isLoading}
                  />
                </LoginSocialApple>
              </div>
            </div>
          </> :
          view === 1?
          <RegisterSuccess  fromPOPUP/> :
          view === 2?
          <form onSubmit={submitNewAccount} className='row pop_sign_up_form'>
            
          <div className='form-group'>
            <label>{t("Password")}</label>
            <input value={emailReg.password} onChange={(e) => setEmailReg({...emailReg, password: e.target.value})} className='form-control' type='password' placeholder={t("Enter your password")}/>
          </div>
          <div className='form-group'>
            <label>{t("Confirm Password")}</label>
            <input value={emailReg.password_confirm} onChange={(e) => setEmailReg({...emailReg, password_confirm: e.target.value})} className='form-control' type='password' placeholder={t("Confirm your password")}/>
          </div>
          <div className='form-group'>
          <TextButton
            backgroundColor={"transparent linear-gradient(to right, #d851ef, #9240CA, #1877f2, #1877f2,#9240CA,#d851ef) no-repeat 0% 0%"}
            hoverBackgroundColor={"transparent linear-gradient(to right, #d851ef,#9240CA, #1877f2,#1877f2,#9240CA,#d851ef) no-repeat 100% 0%"}
            onPressed={undefined}
            isSubmitButton={true}
            text={t("Register")}
            fontWeight="500"
            fontSize="0.9em"
            textColor={"white"}
            style={{ width: "100%", height: "35px" }}
            borderRadius={"6px"}
            shadow={"0px 2px 2px rgba(0,0,0,0.05)"}
            border={"0"}
            hoverScale={0.95}
            disableButton={false}
            padding={"0.2em 0"}
            // disable={isLoading}
          />
          
          <div style={{height: emailReg.password?.length? '128px':'20px'}} className='password_options'>
            {emailReg.password?.length? 
            <>
            <label 
              style={{
                color: emailReg.password?.length? 
                emailReg.password?.length > 8 && emailReg.password?.length <= 20?
                'green': 
                "red":
                null
              }}>
                - {t("Password length must be 8-20 characters long")}
            </label>
            <label
            style={{
              color: emailReg.password?.length? 
              new RegExp(/(?=.*[A-Z])/).test(emailReg.password)?
              'green': 
              "red":
              null
            }}>
              - {t("At least 1 uppercase letter")}
            </label>
            <label 
            style={{
              color: emailReg.password?.length? 
              new RegExp(/(?=.*[a-z])/).test(emailReg.password)?
              'green': 
              "red":
              null
            }}>
              - {t("At least 1 lowercase letter")}
            </label>
            <label
            style={{
              color: emailReg.password?.length? 
              new RegExp(/(?=.*\d)/).test(emailReg.password)?
              'green': 
              "red":
              null
            }}>
              - {t("At least 1 numeric character")}
            </label>

            </>:null}
          </div >
          </div>
          
        </form>:
        <div className='otpForm'>
        <h2>{t("Enter OTP")}</h2>
        <div className='otp_number_info'>
          <label>{t("Sent to: ")}</label>
          <label style={{ direction: 'ltr' }}>{phoneData.phone_number}</label>
          <button onClick={() => setView(0)}>
            {/* <img src={"assets/newDesign/editIcon.svg"} alt='edit' /> */}
            <img src={Assets?.EDIT_ICON} alt='edit' />
          </button>
        </div>
        <div style={{ direction: 'ltr' }} className='otpInput'>
          <OtpInput
            value={phoneData.otp}
            onChange={(otp) => setPhoneData({ ...phoneData, otp: otp })}
            inputStyle={fireBaseInUser? {width: '40px', height: '40px'}:{width: '40px', height: '40px'}}
            numInputs={6}
            separator={<span style={{ width: '10px' }}></span>}
          />
        </div>
        <div onClick={handleResend} style={{ color: '#1171D8', marginTop: '5px', marginBottom: '5px', cursor: 'pointer' }}>{t("Re-Send OTP")}</div>
        <button onClick={() => verifyOTP()} className="btn common-btn">{t('Verify')}</button>
      </div>

      }
            <div ref={captchaRef}>
              <div  id="sign-in-button"></div>
            </div>
            <RecaptchaPopUp setLoading={setLoading} backdrop='static' show={recaptchaPopUp} onHide={() => setRecaptchaPopUp(false)} captcharesponse={captchaResponse} submitfunction={setCaptchaResponse}/>
            <BrowserModal
        show={modalOpenBrowser}
        onHide={() => setModalOpenBrowser(false)}
      />
    </div>
  )
}

const RealEmailAssets = () => {
  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px' }} className='modal_img_wrapper widthMedia form-group col-md-6 col-sm-12 col-lg-6'>
      <label className='image_title'>{t("Join Now And Receive A Special Gift From Us")}</label>
      <img className='image_itself' src={"assets/newDesign/emailImg.svg"} alt='modal_pop-up' />
    </div>
  )
}

const RealSignUpAssets = () => {
  const { t } = useTranslation();
  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px'}} className='modal_img_wrapper widthMedia form-group col-md-5 col-sm-12 col-lg-6'>
      <label className='image_title pop_login_signin'>{t("Join Now And Receive A Special Gift From Us")}</label>
      <img className='image_itself' src={"assets/newDesign/realSignImg.svg"} alt='modal_pop-up' />
    </div>
  )
}

const RealSocialAssets = () => {
  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px' }} className='modal_img_wrapper widthMedia form-group col-md-6 col-sm-12 col-lg-6'>
      <label className='image_title'>{t("Social Media Engagement")}</label>
      <img className='image_itself' src={"assets/newDesign/socialImg.svg"} alt='modal_pop-up' />
    </div>
  )
}

const RealImagesAssets = () => {
  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px' }} className='modal_img_wrapper widthMedia form-group col-md-6 col-sm-12 col-lg-6'>
      <label className='image_title'>{t("Your Imaginations is The Only Limit")}</label>
      <img className='image_itself' src={"assets/newDesign/imagesImg.svg"} alt='modal_pop-up' />
    </div>
  )
}

const RealArticlesAssets = () => {
  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px' }} className='modal_img_wrapper widthMedia form-group col-md-6 col-sm-12 col-lg-6'>
      <label className='image_title'>{t("Write Articles Daily With High Quality")}</label>
      <img className='image_itself' src={"assets/newDesign/articlesImg.svg"} alt='modal_pop-up' />
    </div>
  )
}