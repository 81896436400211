import { FC, useState } from "react";
import styles from "./verifyotp.module.scss";
import Assets from "../../../../utils/assets";
import TextButton from "../../../../components/textbutton/textbutton";
import Spinner from "react-bootstrap/esm/Spinner";
import OtpInput from "react-otp-input";
import Form from "react-bootstrap/esm/Form";
import Fade from "../../../../components/transitions/fadetransition";
import { CustomResponse } from "../../../../utils/typedef";
import FutureStatus from "../../../../utils/futurestatus";
import { sleep } from "../../../../utils/common_functions";
import { verifyOTPForPasswordReset } from "../../../../api";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type SetOTP = (selectedOTP: string) => void;

interface Props {
  email: string;
  onSuccess: SetOTP;
}
const VerifyOTP: FC<Props> = (props) => {
  const { t } = useTranslation();
  const otpLength = 4;
  const [customResponse, setCustomResponse] = useState<
    CustomResponse<any, string>
  >({ status: FutureStatus.initialized });

  const isLoading = customResponse.status === FutureStatus.loading;
  const [otp, setOTP] = useState<string>("");
  return (
    <div className={styles["wrapper"]}>
      <Link to="/">
        <img
          src={Assets.ARAAB_AI_LOGO}
          alt={"Araab AI Logo"}
          className={styles.logo}
        />
      </Link>
      {/* <img
        src={Assets.ARAAB_AI_LOGO}
        alt={"Araab AI Logo"}
        className={styles.logo}
      /> */}
      <h3 className={styles.title}>{t("Verification")}</h3>
      <p className={styles.description}>
        {t("We've send you a 4-digit code. Please enter it to confirm your password reset.")}
      </p>

      <Form
        className={styles.form}
        onSubmit={async (e) => {
          e.preventDefault();

          let hasError: boolean = otp.length < otpLength;

          if (hasError) return;

          setCustomResponse({ status: FutureStatus.loading });
          await sleep(500);

          try {
            const result = await verifyOTPForPasswordReset(props.email, otp);
            setCustomResponse({
              status: FutureStatus.success,
              data: result.data,
            });
            props.onSuccess(otp);
          } catch (e) {
            let errorMessage =
              "Oops! Something went wrong. Please try again later.";
            if (e instanceof AxiosError && e.response?.data?.message != null) {
              errorMessage = e.response!.data!.message;
            }
            setCustomResponse({
              status: FutureStatus.failure,
              error: errorMessage,
            });
          }
        }}
      >
        <div style={{direction: 'ltr'}} className={styles["otp-input-wrapper"]}>
          <OtpInput
            className={styles["otp-input"]}
            value={otp}
            onChange={(val: string) => {
              setOTP(val);
            }}
            // value={"123456"}
            numInputs={otpLength}

            // onChange={(otp) => setPhoneData({ ...phoneData, otp: otp })}
            // numInputs={fireBaseInUser ? 6 : 4}
            // separator={<span style={{ width: "10px" }}></span>}
          />
        </div>
        <TextButton
          backgroundColor={
            " transparent linear-gradient(to right, #d851ef, #9240CA, #1877f2, #1877f2,#9240CA,#d851ef) no-repeat 0% 0%"
          }
          hoverBackgroundColor={
            " transparent linear-gradient(to right, #d851ef,#9240CA, #1877f2,#1877f2,#9240CA,#d851ef) no-repeat 100% 0%"
          }
          onPressed={undefined}
          isSubmitButton={true}
          text={t("Verify OTP")}
          className="appleBtn"
          fontWeight="500"
          fontSize="1.0em"
          textColor={"white"}
          style={{ width: "100%", height: "40px" }}
          borderRadius={"6px"}
          shadow={"0px 2px 2px rgba(0,0,0,0.05)"}
          border={"0"}
          hoverScale={0.95}
          disableButton={false}
          padding={"0.2em 0"}
          disable={otp.length < otpLength || isLoading}
        >
          {isLoading ? (
            <Spinner style={{ width: "25px", height: "25px" }} />
          ) : null}
        </TextButton>
      </Form>
      <Fade duration={400} isVisible={customResponse.error != null}>
        {" "}
        <p className={styles["respone-error-message"] + " mb-3"}>
          {customResponse.error}
        </p>
      </Fade>
    </div>
  );
};

export default VerifyOTP;
