import React, {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';

const Statistics = () => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const statisticsLoading = useSelector((state) => state.statistics.loading);
    const statisticsData = useSelector(state => state.statistics);
    const [imageGenCounter, setImageGenCounter] = useState({
        current:statisticsData?.imgStatistics,
        old: statisticsData?.oldImg
    });
    const [textGenCounter, setTextGenCounter] = useState({
        current:statisticsData?.textStatistics,
        old: statisticsData?.oldText
    });
    useEffect(() => {
        setTextGenCounter({
            current:statisticsData?.textStatistics,
            old: statisticsData?.oldText
        })
        setImageGenCounter({
            current:statisticsData?.imgStatistics,
            old: statisticsData?.oldImg
        })
    }, [statisticsData])
    
  return (
    <div className='statistics_page' style={{direction: lng === "arab"? 'rtl': 'ltr',margin:"auto",position:"relative", }}>
        <div className='count_wrapper row col-lg-8 col-md-12 col-12'>
            <div className='counter_Component col-lg-5 col-md-5 col-sm-12'>
                <label style={{display: 'flex', alignItems:'center', justifyContent:'center', minHeight: '60px'}}>
                    {statisticsLoading?
                    <Spinner/>:
                    <div style={{textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <CountUp 
                        separator=',' 
                        style={{background: 'linear-gradient(158deg,#3a95f6 20%,#7a14ff 100%)', '-webkit-text-fill-color': 'transparent', '-webkit-background-clip': 'text', fontWeight: '900'}}
                        className='payment_numbers user_data_numbers h1blog'
                        start={textGenCounter.old}
                        end={textGenCounter.current}
                        />
                        <label>{t('Text Generated')}</label>
                    </div>
                    }
                </label>
            </div>
            <div style={{padding: '0px', height: '100px', borderRadius: '15px', backgroundColor: 'unset', width: '2px', background: 'linear-gradient(158deg,#3a95f6 20%,#7a14ff 100%)'}} className="line_effect col-lg-1 col-md-1 col-sm-12"/>
            <div className='counter_Component col-lg-5 col-md-5 col-sm-12'>
                <label style={{display: 'flex', alignItems:'center', justifyContent:'center', minHeight: '60px'}}>
                {statisticsLoading?
                    <Spinner/>:
                    <div style={{textAlign: 'center',display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <CountUp 
                        separator=',' 
                        style={{background: 'linear-gradient(158deg,#3a95f6 20%,#7a14ff 100%)', '-webkit-text-fill-color': 'transparent', '-webkit-background-clip': 'text', fontWeight: '900'}}
                        className='payment_numbers user_data_numbers h1blog' 
                        start={imageGenCounter.old}
                        end={imageGenCounter.current}
                        />
                        <label>{t('Images Generated')}</label>
                    </div>
                    
                }
                </label>
            </div> 
        </div>
    </div>
  )
}

export default Statistics