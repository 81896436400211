export const LatestBlogSvg = (props) => (
    <svg width="259" height="18" viewBox="0 0 259 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M257 16C188.081 5.4353 40.5946 -9.35532 2 16" stroke="#1877F2" strokeWidth="4" strokeLinecap="round" />
    </svg>
)

export const LeftArrowSvg = (props) => (
    <svg width="40" height="41" viewBox="0 0 67 68" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <ellipse cx="33.5" cy="33.6796" rx="33.5" ry="33.6796" fill="#F5F5F5" />
        <ellipse cx="33.5" cy="33.6796" rx="26.6421" ry="26.5" transform="rotate(-90 33.5 33.6796)" fill="currentColor" />
        <path d="M24.0833 33.8447L44.625 33.8447" stroke="#F5F5F5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M32 42.8444C32 38.9872 24 33.8444 24 33.8444C24 33.8444 32 28.7015 32 24.8444" stroke="#F5F5F5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

)

export const RightArrowSvg = (props) => (
    <svg width="40" height="41" viewBox="0 0 67 68" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <ellipse cx="33.5" cy="33.835" rx="33.5" ry="33.6796" fill="#F5F5F5" />
        <g filter="url(#filter0_i_165_317)">
            <ellipse cx="33.5" cy="33.835" rx="26.6421" ry="26.5" transform="rotate(-90 33.5 33.835)" fill="currentColor" />
        </g>
        <path d="M43.9167 34.0001L23.375 34.0001" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M36 42.9998C36 39.1426 44 33.9998 44 33.9998C44 33.9998 36 28.8569 36 24.9998" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <defs>
            <filter id="filter0_i_165_317" x="7" y="7.19287" width="60" height="53.2843" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dx="7" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0" />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_165_317" />
            </filter>
        </defs>
    </svg>

)

export const ArrowSvg = (props) => (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_165_278)">
            <path d="M10.5 20.9999C7.6954 20.9999 5.05854 19.9077 3.07537 17.9245C1.0922 15.9413 0 13.3045 0 10.4999C0 7.69527 1.0922 5.05842 3.07537 3.07525C5.05854 1.09208 7.6954 -0.00012207 10.5 -0.00012207C13.3046 -0.00012207 15.9415 1.09208 17.9246 3.07525C19.9078 5.05842 21 7.69527 21 10.4999C21 13.3045 19.9078 15.9413 17.9246 17.9245C15.9415 19.9077 13.3046 20.9999 10.5 20.9999ZM10.5 1.6405C5.61497 1.6405 1.64062 5.61485 1.64062 10.4999C1.64062 15.3849 5.61497 19.3593 10.5 19.3593C15.385 19.3593 19.3594 15.3849 19.3594 10.4999C19.3594 5.61485 15.385 1.6405 10.5 1.6405ZM14.7773 10.4999L9.10547 4.82803L7.94534 5.98816L12.4571 10.4999L7.94534 15.0116L9.10547 16.1717L14.7773 10.4999Z" fill="#1877F2" />
        </g>
        <defs>
            <clipPath id="clip0_165_278">
                <rect width="21" height="21" fill="white" />
            </clipPath>
        </defs>
    </svg>

)