import React from "react";
import style from "./gpt.module.scss";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import user from "../Assets/Icons/userg.png";
import logo from "../Assets/Icons/aai.png";
import send from "../Assets/Icons/send2.png";

const B2Bgpt = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;

  return (
    <div className={lng === "arab" ? style.allforoneA : style.allforoneE}>
      <div
        // className={style.ball1}
        style={{
          right: lng === "arab" ? "-100px" : "",
          left: lng === "arab" ? "" : "-150px",
        }}
      ></div>
      <div className={style.ball2}></div>
      <div className={style.texts}>
        <h1 style={{ fontWeight: "bold" }}>
          {t("Our Awesome")}
          <br /> <span>ArabyGPT</span>
        </h1>
        <h4 style={{ fontWeight: lng === "arab" ? "" : 400, fontSize: '14px' }}>
          {t(
            "Are you searching for a powerful and innovative solution to improve your business operations? Look no further than ArabyGPT! As a highly advanced language model, ArabyGPT can quickly and accurately understand and respond to your inquiries, providing personalized recommendations, and even generate engaging content. Additionally, ArabyGPT can be seamlessly integrated with a wide range of business tools and platforms, making it easy to incorporate into your existing workflows. Say goodbye to clunky, outdated service processes and hello to the future of business with ArabyGPT!"
          )}
        </h4>
      </div>
      <Box className={lng === "arab" ? style.b2bgptA : style.b2bgptE}>
        <h1>{t("Welcome to ArabyGPT")}</h1>
        <p>{t("Your personalised Ai - powered Araby ai")}</p>
        <div className={style.gptTop}>
          <div className={style.services}>
            <h3>{t("Services")}</h3>
            <h3>{t("Brand voice")}</h3>
            <h3>{t("Solutions")}</h3>
          </div>
          <div
            className={style.quetions}
            style={{ flexDirection: lng === "arab" ? "row-reverse" : "" }}
          >
            <h5 style={{ textAlign: lng === "arab" ? "right" : "left" }}>
              {t(
                "Explain quantum computing in simple terms with multiple options"
              )}
            </h5>
            <span>
              <img src={user} alt="" />
            </span>
          </div>
          <div className={style.answer}>
            <span>
              <img src={logo} alt="" />
            </span>
            <ul>
              {t(
                "Sure, here are a few options for explaining quantum computing in simple terms:"
              )}
              <li>
                {t(
                  "Quantum computing is a type of computing that uses quantum bits (qubits) instead of traditional bits to perform calculations. Qubits are able to represent multiple states at the same time, which allows for much faster processing of complex problems."
                )}
              </li>
              <li>
                {t(
                  "Think of quantum computing like a giant maze. Traditional computers can only move in one direction at a time, but quantum"
                )}
              </li>
            </ul>
          </div>
          <div className={style.input}>
            <div>
              <input placeholder={t("Type here...")} disabled />
              <img src={send} alt="" />
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default B2Bgpt;
