import React from "react";
import { FC } from "react";
import styles from "./textbutton.module.scss";
import { VoidCallBack } from "../../utils/typedef";

interface Props {
  text: string;
  textColor?: string;
  fontWeight?: string;
  fontSize?: string;
  hoverTextColor?: string;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  border?: string;
  hoverBorder?: String;
  borderRadius?: string;
  padding?: string;
  shadow?: string;
  hoverShadow?: string;
  className?: string;
  onPressed?: VoidCallBack;
  disableButton: boolean;
  hoverScale: number;
  leadIcon?: React.ReactNode;
  style?: React.CSSProperties;
  disable?: boolean;
  children?: React.ReactNode;
  isSubmitButton?: boolean;
}

const TextButton: FC<Props> = (props: Props) => {
  const isDisabled = props.disable ?? false;
  const defaultStyle = {
    "--opacity": isDisabled ? 0.5 : 1.0,
    "--font-weight": props.fontWeight,
    "--font-size": props.fontSize ?? "18px",
    "--text-color": props.textColor ?? "black",
    "--background-color": props.backgroundColor,
    "--border": props.border,
    "--border-radius": props.borderRadius ?? "0",
    "--padding": props.padding ?? "1.5em 5.2em",
    "--shadow": props.shadow ?? "0",
  } as React.CSSProperties;

  const hoverStyle = {
    "--hover-text-color": props.hoverTextColor ?? props.textColor,
    "--hover-background-color":
      props.hoverBackgroundColor ?? props.backgroundColor,
    "--hover-border": props.hoverBorder ?? props.border,
    "--hover-shadow": props.hoverShadow ?? props.shadow,
    "--hover-scale": props.hoverScale ?? "1",
  } as React.CSSProperties;

  return (
    <button
      className={`${styles.wrapper} ${props.className ? props.className : ""} ${
        isDisabled ? "" : styles.hover
      }`}
      type={props.isSubmitButton === true ? "submit" : "button"}
      onClick={props.disable ? undefined : props.onPressed}
      style={
        {
          ...defaultStyle,
          "--text-color": props.textColor ?? "black",
          ...(!isDisabled ? hoverStyle : { cursor: "default" }),

          ...(props.style ?? {}),
        } as React.CSSProperties
      }
    >
      <div className={styles.content}>
        {props.children != null ? (
          props.children
        ) : (
          <>
            {props.leadIcon}
            {props.leadIcon != null ? <div style={{ width: "0.5em" }} /> : null}
            <p>{props.text}</p>
          </>
        )}
      </div>
    </button>
  );
};

export default TextButton;
