// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"
const firebaseConfig = {
  apiKey: "AIzaSyCKdbygkzLF3BQjxlbIDrj-2N-Glwru-Og",
  authDomain: "arabyai-ecbd3.firebaseapp.com",
  projectId: "arabyai-ecbd3",
  storageBucket: "arabyai-ecbd3.appspot.com",
  messagingSenderId: "592940640319",
  appId: "1:592940640319:web:ce84b57c51b9fd8ff8b08a",
  measurementId: "G-7HKM75CKN5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const authentication = getAuth(app);