
import React, { lazy, Suspense, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals.js";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers/index.js";
import { BrowserRouter, MemoryRouter } from "react-router-dom";
import LoadingPopUp from "./components/popUps/LoadingPopUp.js";
import MainRoutes from "./routes.js";
import { HelmetProvider } from 'react-helmet-async';
import UpgradeModal from "./components/UpgradeModal/UpgradeModal";
import { Modal } from "react-bootstrap";
import AppleIcon from "@mui/icons-material/Apple";
import playS from "./components/Assets/Img/playstore.png";
import googleIcon from "./components/Assets/Img/googleplay.svg";
import appleIcon from "./components/Assets/Img/appstore.svg";

const InnerMainRoutes = lazy(() => import('./routes.js'));
const MainRoutesLazy = () => {
  return (
    <Suspense fallback={<LoadingPopUp />}>
      <InnerMainRoutes />
    </Suspense>
  );
}
const store = createStore(reducers, compose(applyMiddleware(thunk)));
function App() {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [upgradeModalSwitch, setUpgradeModalSwitch] = useState(false)
  useEffect(() => {
    if (!lng) {
      setUpgradeModalSwitch(true)
    } else {
      setUpgradeModalSwitch(false)
    }
  })
  return (
    <HelmetProvider>
      <Provider store={store}>
        <BrowserRouter>
          <MainRoutesLazy />
        </BrowserRouter>
      </Provider>
      <LngModal
        showModal={upgradeModalSwitch}
        setShowModal={setUpgradeModalSwitch}
      />
    </HelmetProvider>
  );
}

export default App;

const LngModal = (props) => {
  const handleLanguage = (str) => {
    if (str === "eng") {
      localStorage.setItem("language", JSON.stringify({ lang: "en" }));
      window.location.reload();
    } else {
      localStorage.setItem("language", JSON.stringify({ lang: "arab" }));
      window.location.reload();
    }
  };
  return (
      <Modal
          className="modal modal-primary lng-modal"
          centered
          size="lg"
          backdrop='static'
          show={props.showModal}
          onHide={() => props.setShowModal(false)}
      >
          {/* <Modal.Header className="justify-content-center">
              <img src={"assets/newDesign/Oops1.svg"} alt="Oops" height="202px" width="202px" />
          </Modal.Header> */}
          <Modal.Body className="text-center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
              {/* <img className="bottom-image" src={"assets/bottomImage.png"} alt="Oops" /> */}
              {/* <img className="top-image" src={"assets/topImage.png"} alt="Oops" /> */}
              <div style={{width: '100%', flexGrow: '1', display: 'flex', alignItems:'center', justifyContent: 'center', flexDirection: 'column', gap: '20px'}}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '20px'}}>
                  <h1 style={{ fontWeight: 800, color: '#fff', zIndex: '1' }}>الرجاء اختيار لغتك</h1>
                  <h1 style={{ fontWeight: 800, color: '#fff', zIndex: '1' }}>Please select your language</h1>
                </div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px', padding: '10px', flexWrap: 'wrap'}}>
                  <button onClick={() => handleLanguage('eng')} className="lang_btn">English</button>
                  <button onClick={() => handleLanguage('arab')} className="lang_btn">عربي</button>
                </div>
              </div>
              <div style={{width: '100%',zIndex: '1'}}> 
                <label style={{ fontWeight: "500", zIndex: '1', color:'#fff', textAlign: 'center' }}>
                    Download The Mobile App
                </label>
                <div className="mobileLinksWrapper" style={{width: '100%', display: 'flex', textAlign: "center", margin: "auto", justifyContent: "center", gap: '10px' }}>
                  <a style={{display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px'}} href="https://play.google.com/store/apps/details?id=com.araby.ai" target="_blank" rel="noreferrer">
                    <img style={{height: '50px'}} src={googleIcon} alt='apple'/>
                  </a>
                  <a style={{display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px'}} href="https://apps.apple.com/in/app/araby-ai/id6447341656" target="_blank" rel="noreferrer">
                    <img style={{height: '50px'}} src={appleIcon} alt='apple'/>
                  </a>
                </div>
              </div>
              {/* <a href="https://dashboard.araby.ai/plan" className="btn common-btn-modal">
                  {("Upgrade Plan") }
              </a> */}
          </Modal.Body>
      </Modal >
  );
  }
