import { FC, useEffect, useState } from "react";
import styles from "./not_found.module.scss";
import { useTranslation } from 'react-i18next';

const NotFound: FC = () => {
  const { t } = useTranslation();
  const [counter, setCounter] = useState(5);
  let timeOut = 5;
  let interval;
  useEffect(() => {
        interval = setInterval(() => {
            if (timeOut > 0) {
              setCounter(timeOut--);
            }
            if (timeOut === 0) {
                window.location.replace('/')
            }
        }, 1000);
  }, [timeOut]);
  return (
    <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}} className={styles.wrapper}>
      <h2>{t("This page is not Found")}</h2>
      <p>{t("You will be redirected after ")}<span>{counter}</span></p>
    </div>
  );
};
export default NotFound;
