import { FC } from "react";
import styles from "./registersuccess.module.scss";
import { CheckCircleOutlined } from "@mui/icons-material";
import TextButton from "../../../../components/textbutton/textbutton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

interface Props {
  fromPOPUP: boolean;
}
const RegisterSuccess: FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
//   const resendVerf = () => {
//     ResendVerificationEmail
// Endpoint: /resend_verification_email
// Method: POST
// Body:{
// email: String | required
//   }
  return (
    <div className={styles.wrapper}>
      <div className={styles["title-section"]}>
        <CheckCircleOutlined className={styles.checkicon} />
        <h3 className={styles.title}>{t("Thank You")}</h3>
      </div>
      <p className={styles.message}>
        {t("Please check your inbox for confirmation email. Click the link in the email to confirm your email address.")}
      </p>
      <TextButton
        backgroundColor={
          " transparent linear-gradient(to right, #d851ef, #9240CA, #1877f2, #1877f2,#9240CA,#d851ef) no-repeat 0% 0%"
        }
        hoverBackgroundColor={
          " transparent linear-gradient(to right, #d851ef,#9240CA, #1877f2,#1877f2,#9240CA,#d851ef) no-repeat 100% 0%"
        }
        onPressed={(e) => {
          e.preventDefault();
          navigate("/auth/login", { replace: true });
        }}
        text={t("Go back to login")}
        fontWeight="500"
        className="appleBtn"
        fontSize="1.2em"
        textColor={"white"}
        style={{ width: "90%" }}
        borderRadius={"6px"}
        shadow={"0px 2px 2px rgba(0,0,0,0.05)"}
        border={"0"}
        hoverScale={0.95}
        disableButton={false}
        padding={"0.6em 0"}
      />
    </div>
  );
};
export default RegisterSuccess;
