/* eslint-disable */
import i18next from 'i18next';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PopUpModal from './popUps/PopUpModal';

const OurWork = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const user = useSelector((state) => state.userAuth);

  return (
    <div style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }}>
      <div className="container-our-work" >

        <div style={{ padding: '40px' }}>
          {/* <p>{t('Our Work')}</p> */}
          <h1 className="h1blog">{t('Who is Araby AI for?')}</h1>
          <h6>{t("If you try any of our services, you'll discover a platform that writers can't live without.")}</h6>
          <div className="row our-work-justify" style={{ direction: 'ltr' }}>
            <button
              className="btn large-icon-btn col-sm-3"
              style={{ margin: '20px 20px 0 0' }}
            >
              <img className="icon" src={"assets/newDesign/first.svg"} />
              <h4>{t('Content Marketing Teams')}</h4>
              <h6>{t("kickstart your content now with simple steps and improve your brand's voice and tone.")}</h6>
            </button>

            <button
              className="btn large-icon-btn col-sm-3 "
              style={{ margin: '20px 20px 0 0' }}
            >
              <img className="icon" src={"assets/newDesign/second.svg"} />
              <h4>{t('Copywriters')}</h4>
              <h6>{t('Never face writers block again. Generate creative content that aligns with your ideas.')}</h6>
            </button>
            <button
              className="btn large-icon-btn col-sm-3"
              style={{ margin: '20px 20px 0 0' }}
            >
              <img className="icon" src={"assets/newDesign/third.svg"} />
              <h4>{t('Entrepreneurs')}</h4>
              <h6>{t("Build your brand's voice, get your thoughts out to the world and educate your customers without the need for a dedicated copywriting staff.")}</h6>
            </button>
          </div>
          <div style={{ margin: '60px 40px 30px 40px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <button onClick={() => 
              !user
              ? setShowModal(true)
              : window.open(
                process.env.REACT_APP_DASHBOARD_URL+"?t=" +localStorage.getItem("sess"),"_blank"
                )
                } className="btn common-btn">{t('Start Now')}</button>
          </div>
        </div>
      </div>
      <PopUpModal
        popUpFor={'real'}
        show={showModal}
        onHide={() => setShowModal(false)}
      />
    </div>
  );

}

export default OurWork;