import { FC, useState } from "react";
import styles from "./new_password.module.scss";
import Assets from "../../../../utils/assets";
import TextButton from "../../../../components/textbutton/textbutton";
import Spinner from "react-bootstrap/esm/Spinner";
import Form from "react-bootstrap/esm/Form";
import Fade from "../../../../components/transitions/fadetransition";
import { CustomResponse } from "../../../../utils/typedef";
import FutureStatus from "../../../../utils/futurestatus";
import * as yup from "yup";
import parseYupError from "../../../../utils/yupErrorParser";
import { sleep } from "../../../../utils/common_functions";
import { resetPassword } from "../../../../api";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const schema = yup.object().shape({
  new_password: yup
    .string()
    .required("Please enter your new password")
    .matches(/(?=.{8,21})/, {message: 'Password length must be 8-20 characters long'})
    .matches(/(?=.*[A-Z])/, {message: 'At least 1 uppercase letter'})
    .matches(/(?=.*[a-z])/, {message: 'At least 1 lowercase letter'})
    .matches(/(?=.*\d)/, {message: "At least 1 numeric character"}),
  confirm_new_password: yup
    .string()
    .required("Required")
    .oneOf([yup.ref("new_password")], "Passwords must match"),
});

interface Props {
  email: string;
  otp: string;
  onSuccess: VoidFunction;
}

const NewPassword: FC<Props> = (props) => {
  const { t } = useTranslation();
  const [formErrors, setFormErrors] = useState<any>({});
  const [customResponse, setCustomResponse] = useState<
    CustomResponse<any, string>
  >({ status: FutureStatus.initialized });

  const isLoading = customResponse.status === FutureStatus.loading;
  return (
    <div className={styles["wrapper"]}>
      <Link to='/'>
      <img
        src={Assets.ARAAB_AI_LOGO}
        alt={"Araab AI Logo"}
        className={styles.logo}
      />
      </Link>
      
      <h3 className={styles.title}>{t("Password Reset")}</h3>
      <p className={styles.description}>{t("Please enter your new password")}</p>

      <Form
        className={styles.form}
        onSubmit={async (e) => {
          e.preventDefault();
          const target = e.target as typeof e.target & {
            new_password: { value: string };
            confirm_new_password: { value: string };
          };

          const data = {
            new_password: target["new_password"].value,
            confirm_new_password: target["confirm_new_password"]?.value,
          };

          let hasError: boolean = false;

          try {
            schema.validateSync(data, { abortEarly: false, strict: true });
            setFormErrors({});
            // await sleep(2000);
          } catch (e: any) {
            if (e instanceof yup.ValidationError) {
              setFormErrors(parseYupError(e));
            } else {
              setFormErrors({
                unknown: [
                  "Oops! Something went wrong. Please try again later.",
                ],
              });
            }
            hasError = true;
          }

          if (hasError) return;

          setCustomResponse({ status: FutureStatus.loading });
          await sleep(500);

          try {
            const result = await resetPassword(
              props.email,
              data.new_password,
              props.otp
            );
            setCustomResponse({
              status: FutureStatus.success,
              data: result.data,
            });
            props.onSuccess();
          } catch (e) {
            let errorMessage =
              "Oops! Something went wrong. Please try again later.";
            if (e instanceof AxiosError && e.response?.data?.message != null) {
              errorMessage = e.response!.data!.message;
            }
            setCustomResponse({
              status: FutureStatus.failure,
              error: errorMessage,
            });
          }
        }}
      >
        <Form.Group
          className="mb-2"
          controlId="new_password"
          style={{ margin: 0 }}
        >
          <Form.Control
            type="password"
            placeholder="كلمة المرور الجديدة"
            className={
              formErrors["new_password"] != null
                ? styles["textfield-error"]
                : styles.textfield
            }
          />
          <div style={{paddingTop: '10px'}}>
            <Fade duration={400} isVisible={formErrors["new_password"] != null}>
              {" "}
              {formErrors["new_password"] != null ?
                formErrors["new_password"]?.map((item: string, i: Number) => 
                <p className={styles["error-message"]}>
                  {t(item)}
                </p>
                ):null}
            </Fade>
          </div>
        </Form.Group>
        <Form.Group controlId="confirm_new_password" className="mb-3">
          <Form.Control
            type="password"
            placeholder="تأكيد كلمة المرور الجديدة"
            className={
              formErrors["confirm_new_password"] != null
                ? styles["textfield-error"]
                : styles.textfield
            }
          />
          <div>
            <Fade
              duration={400}
              isVisible={formErrors["confirm_new_password"] != null}
            >
              {" "}
              <p className={styles["error-message"]}>
                {formErrors["confirm_new_password"] != null
                  ? t(formErrors["confirm_new_password"][0])
                  : null}
              </p>
            </Fade>
          </div>
        </Form.Group>
        <TextButton
          backgroundColor={
            " transparent linear-gradient(to right, #d851ef, #9240CA, #1877f2, #1877f2,#9240CA,#d851ef) no-repeat 0% 0%"
          }
          hoverBackgroundColor={
            " transparent linear-gradient(to right, #d851ef,#9240CA, #1877f2,#1877f2,#9240CA,#d851ef) no-repeat 100% 0%"
          }
          onPressed={undefined}
          isSubmitButton={true}
          text={t("Submit")}
          fontWeight="500"
          fontSize="1.0em"
          className="appleBtn"
          textColor={"white"}
          style={{ width: "100%", height: "40px" }}
          borderRadius={"6px"}
          shadow={"0px 2px 2px rgba(0,0,0,0.05)"}
          border={"0"}
          hoverScale={0.95}
          disableButton={false}
          padding={"0.2em 0"}
          disable={isLoading}
        >
          {isLoading ? (
            <Spinner style={{ width: "25px", height: "25px" }} />
          ) : null}
        </TextButton>
      </Form>
      <Fade duration={400} isVisible={customResponse.error != null}>
        {" "}
        <p className={styles["respone-error-message"] + " mb-3"}>
          {t(customResponse.error ?? '')}
        </p>
      </Fade>
    </div>
  );
};

export default NewPassword;
