import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { clearData } from '../actions/user';
import styles from "./404_not_found/not_found.module.scss";
import { useTranslation } from 'react-i18next';
const Logout = () => {
    let dispatch = useDispatch();
    const { t } = useTranslation();
  const [counter, setCounter] = useState(5);
  let timeOut = 5;
  let interval;
  useEffect(() => {
    dispatch(clearData())
  }, []);
  return (
    <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}} className={styles.wrapper}>
      <h2>{t("Thank your, We hope that we will come back again soon")}</h2>
      <p>{t("You will be redirected after ")}<span>{counter}</span></p>
    </div>
  )
}

export default Logout