import React, { Fragment, useEffect } from "react";
import styles from "./blogDetails.module.scss";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { getBlogDetails } from "../../actions/blog";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

const Index = () => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const dispatch = useDispatch();
  const blogDetail = useSelector((state) => state.blogDetail.blogDetail);
  const blogDetailLoading = useSelector((state) => state.blogDetail.loading);
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      dispatch(getBlogDetails(id));
    }
  }, [id]);
  return (
    <Fragment>
      {!blogDetailLoading && (
        <Helmet>
          <meta
            name="description"
            content={
              lng === "arab"
                ? blogDetail?.meta_description_ar
                : blogDetail?.meta_description_en
            }
          />
        </Helmet>
      )}
      <div className={styles.details} dir={lng === "arab" ? "rtl" : "ltr"}>
        <div className={styles.banner}>
          <div>
            <p>
              {lng === "arab" ? blogDetail?.title_ar : blogDetail?.title_en}
            </p>
          </div>
        </div>
        {!blogDetailLoading && (
          <div className={styles.blogDetailImage}>
            <img
              src={blogDetail?.banner_image}
              alt={
                lng === "arab"
                  ? blogDetail?.banner_alt_ar
                  : blogDetail?.banner_alt_en
              }
            />
          </div>
        )}
        <div className={styles.blogContent}>
          {blogDetailLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "60px",
              }}
            >
              <Spinner />
            </div>
          ) : lng === "arab" ? (
            <div
              className={styles.blogDetailContent}
              dangerouslySetInnerHTML={{ __html: blogDetail?.content_ar }}
            />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: blogDetail?.content_en }} />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Index;
