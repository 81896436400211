import { LOAD_USER } from "../constants";

export default function checkErr(
  error,
  setLoadingModalShow,
  toastAlert,
  option,
  dispatch,
  failedOption,
  loghim
) {
  if (error?.response?.status === 400) {
    if (setLoadingModalShow) {
      setLoadingModalShow(false);
    }
    if (toastAlert) {
      // toastAlert("Bad Request", undefined, "warning");
      toastAlert(error?.response?.data?.message, undefined, "warning");
    }
    if (option) {
      option();
    }
  } else if (error?.response?.status === 401) {
    if (setLoadingModalShow) {
      setLoadingModalShow(false);
    }
    if (toastAlert) {
      toastAlert(
        "Please login again to continue",
        undefined,
        "warning",
        "login"
      );
      if (dispatch) {
        localStorage.removeItem('sess');
        dispatch({ type: LOAD_USER, payload: null });
        window.location.reload();
      }
    }
  } else {
    if (toastAlert) {
      toastAlert(
        "Something went wrong, please try again later",
        undefined,
        "warning"
      );
      if (dispatch && loghim) {
        localStorage.removeItem('sess');
        dispatch({ type: LOAD_USER, payload: null });
        window.location.reload();
      }
    }
    if (failedOption) {
      failedOption();
    }
    if (setLoadingModalShow) {
      setLoadingModalShow(false);
    }
  }
}
