import { LOADING, LOAD_USER, STOP_LOADING, LOADING_STATISTICS, FETCH_STATISTICS, LOADED_STATISTICS } from "../constants/index";
import * as api from "../api";
import checkErr from "./api_error_func";
import { toastAlert } from "../components/alert/toastAlert";
import { googleLogout } from '@react-oauth/google';

export const getUserData = (token) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const { data } = await api.getUserData(token);
    dispatch({ type: STOP_LOADING });
    dispatch({ type: LOAD_USER, payload: data.User });
  } catch (error) {
    dispatch({ type: STOP_LOADING });
    dispatch({ type: LOAD_USER, payload: null });
    checkErr(error, false, toastAlert, false, dispatch, false, 'loghim')
  }
};
export const getStatistics = (effect) => async (dispatch) => {
  if (!effect) {
    dispatch({ type: LOADING_STATISTICS });
  }
  try {
    const { data } = await api.getStatisticsAPI();
    dispatch({ type: FETCH_STATISTICS, payload: data?.data });
  } catch (error) {
    if (!effect) {
      dispatch({ type: LOADED_STATISTICS });
    }
    checkErr(error, false, false, false, dispatch, false, 'loghim')
  }
};

export const clearData = () => async (dispatch) => {
  try {
    localStorage.removeItem("sess");
    googleLogout();
    dispatch({ type: LOAD_USER, payload: null });
    window.location.replace('/');
  } catch (error) {
    checkErr(error, false, toastAlert);
  }
};
