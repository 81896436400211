import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next';

const BrowserGuideModal = (props) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  return (
    <Modal
      {...props}
      size="lg"
      centered
      className='auth_modal'
    >
      <Modal.Body className='forceArabicFont row' style={{ direction: lng === 'arab'? 'rtl': 'ltr', display: 'flex', padding: '5px' }}>
      <div style={{display: 'flex', flexDirection: 'column', alignContent: 'center',  justifyContent: 'center', gap: '10px', padding: '20px'}} className='modal_img_wrapper widthMedia form-group col-md-5 col-sm-12 col-lg-12'>
      <label className='image_title'>{t("Resolve issue")}</label>

    <div>Open this page in the system browser (Chrome or Safari). You can do this from the top menu of this application</div>
    <img style={{ alignSelf: 'center'}}  className='image_itself' src={"../assets/browser_guide2.jpg"} alt='browser change guide' />

    <button className='btn common-btn' onClick={props.onHide}>GOT IT</button>
    </div>
      </Modal.Body>
    </Modal>
  )
}

export default BrowserGuideModal