import { Fragment, useEffect, useRef, useState } from "react";
import { FC } from "react";
import styles from "./loginscreen.module.scss";
import transistionstyles from "../../route.module.scss";
import * as yup from "yup";
import { Form, Spinner } from "react-bootstrap";
import Assets from "../../utils/assets";
import AnimatedHandWave from "./components/animated_hand_wave/animated_hand_wave";
import FutureStatus from "../../utils/futurestatus";
import parseYupError from "../../utils/yupErrorParser";
import Fade from "../../components/transitions/fadetransition";
import TextButton from "../../components/textbutton/textbutton";
import { Link, useNavigate, useOutlet } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { login, newSocialLogin, register, socialLogin } from "../../api";
import { sleep } from "../../utils/common_functions";
import axios, { AxiosError } from "axios";
import { CustomResponse } from "../../utils/typedef";
import { useDispatch } from "react-redux";
import TypingEffect from "./typingEffect";
import { useTranslation } from "react-i18next";
import {
  LoginSocialApple,
  LoginSocialFacebook,
} from "reactjs-social-login";
import { toastAlert } from "../../components/alert/toastAlert";
import checkErr from "../../actions/api_error_func";
import  { SignUpForm } from "../../components/popUps/PopUpModal";
import { ArrowForward } from "@mui/icons-material";
import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import { baseUrl } from "../../api/baseUrl";
import RecaptchaPopUp from "../RecaptchaPopUp";
import { ClientJS } from 'clientjs';
import BrowserModal from "../../components/popUps/BrowserModal";
import BrowserGuideModal from "../../components/popUps/BrowserGuideModal";

const handleLanguage = (str: string) => {
  if (str === "eng") {
    localStorage.setItem("language", JSON.stringify({ lang: "en" }));
    window.location.reload();
  } else {
    localStorage.setItem("language", JSON.stringify({ lang: "arab" }));
    window.location.reload();
  }
};
const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required(("Please enter your email"))
    .email("Please enter a valid email"),
  password: yup.string().required("Please enter your password"),
});
const registerSchema = yup.object().shape({
  email: yup
    .string()
    .required("Please enter your email")
    .email("Please enter a valid email"),
  password: yup
    .string()
    .required("Please enter your password")
    // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,21})/, {message: ['Password length must be 8-20 characters long', 'At least 1 uppercase letter']})
    .matches(/(?=.{8,21})/, {message: 'Password length must be 8-20 characters long'})
    .matches(/(?=.*[A-Z])/, {message: 'At least 1 uppercase letter'})
    .matches(/(?=.*[a-z])/, {message: 'At least 1 lowercase letter'})
    .matches(/(?=.*\d)/, {message: "At least 1 numeric character"}),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match"),
});
interface Props {
  isLoginForm: boolean;
}
interface MainProps {
  location: string;
}
export const LoginScreen: FC<MainProps> = (props: MainProps) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language") ?? " ")?.lang;

  const loginRef = useRef<HTMLDivElement>(null);
  const registerRef = useRef<HTMLDivElement>(null);
  const registersuccessRef = useRef<HTMLDivElement>(null);
  const forgotpasswordRef = useRef<HTMLDivElement>(null);


  // const isLogin = props.location.includes("login");

  let ref = loginRef;

  if (props.location.includes("login")) {
    ref = registerRef;
  } else if (props.location.includes("registersuccess")) {
    ref = registersuccessRef;
  } else if (props.location.includes("forgotpassword")) {
    ref = forgotpasswordRef;
  }
  const currentOutlet = useOutlet();
  // onResolve={async ({ provider, data }) => {

    //     try {
    //       let res = await socialLogin(data!.access_token, data?.name, "GOOGLE");
    //       dispatch({ type: "AUTH", data: res.data.token });
    //     } catch (err) {
    //       checkErr(err, false, toastAlert)
    //     }
    //     finally {
    //       setCustomResponse({ status: FutureStatus.initialized });
    //     } 
    //   }}
    //   onReject={(err) => {
    //     setCustomResponse({ status: FutureStatus.initialized });
    //   }}
  // return <Outlet key={location.pathname} />;
  
  return (
    <div
      style={{ direction: lng === "arab" ? "rtl" : "ltr" }}
      className={styles.wrapper}
    >
      <div className={styles.left}>
        <SwitchTransition>
          <CSSTransition
            key={props.location}
            nodeRef={ref}
            timeout={1000}
            unmountOnExit
            classNames={{
              // appear: "my-appear",
              // appearActive: "my-active-appear",
              // appearDone: "my-done-appear",
              enter: transistionstyles["fade-enter-left"],
              enterActive: transistionstyles["fade-enter-active-left"],
              // enterDone: "my-done-enter",
              exit: transistionstyles["fade-exit-left"],
              exitActive: transistionstyles["fade-exit-active-left"],
              // exitDone: "my-done-exit",
            }}
          >
            {(state) => {
              return (
                <div ref={ref} className={styles["left-content"]}>
                  {currentOutlet}
                </div>
              );
            }}
          </CSSTransition>
        </SwitchTransition>
      </div>

      <div className={styles.right}>
        <TypingEffect />
        {/* <img src={Assets.LOGIN_SIDE_BACKGROUND} alt={"Login side background"} /> */}
        <div className={styles.overlay} />
        <div className={styles["side-text"]}>
          <h4>{t("Creating Magic")}</h4>
        </div>
      </div>
    </div>
  );
};

export const LoginForm: FC<Props> = (props: Props) => {
  const [isValidBrowser, setIsValidBrowser] = useState(true);
  const [isAndroid, setIsAndroid] = useState(false);
  const [modalOpenBrowser, setModalOpenBrowser] = useState(false);
  const [modalGuideOpenBrowser, setModalGuideOpenBrowser] = useState(false);

  useEffect(() => {
    const client = new ClientJS();
    const browser = client.getBrowser();
    setIsValidBrowser(browser !== "Instagram")
    if(client.getOS() !== "iOS")
      setIsAndroid(true)
  }, [])
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language") ?? " ")?.lang;
  const search = window?.location?.search;
  const viewURL = new URLSearchParams(search).get("view");
  const [loginWithMobile, seLoginWithMobile] = useState(viewURL ? true : false);
  useEffect(() => {
    seLoginWithMobile(viewURL ? true : false);
  }, [viewURL]);
  const [formErrors, setFormErrors] = useState<any>({});
  const [customResponse, setCustomResponse] = useState<
    CustomResponse<any, string>
  >({ status: FutureStatus.initialized });

  const isLoading = customResponse.status === FutureStatus.loading;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signInUserSocial = async (
    access_token: string,
    name: string,
    type: string
  ) => {
    try {
      let res = await socialLogin(access_token, name, type);
      dispatch({type: "AUTH", data: res.data.token});
    } catch (error) {
      checkErr(error, false, toastAlert);
      setCustomResponse({ status: FutureStatus.initialized });
    }
  }
  const [captchaResponse, setCaptchaResponse] = useState(0)
  const [recaptchaPopUp, setRecaptchaPopUp] = useState(false)
  const [innerData, setInnerData] = useState({})

  const submitFunc = async (data: any) =>{
          try {
            setResendButton(false)
            if (props.isLoginForm) {
              const result = await login(data.email, data.password);
              setCustomResponse({status: FutureStatus.success, data: result.data});
              dispatch({ type: "AUTH", data: result.data.token });
            } else {
              const result = await register(data.email, data.password);
              setCustomResponse({status: FutureStatus.success, data: result.data});
              navigate("/auth/registersuccess");
            }
          } catch (e) {
            checkErr(e, false, toastAlert);
            let errorMessage = "Oops! Something went wrong. Please try again later.";
            if (e instanceof AxiosError && e.response?.data?.message != null) {
              if (e.response?.data?.error_code) {
                resendVerificationEmail(data?.email, 'first')
                setResendButton(true)
                setEmailTo(data?.email)
                errorMessage = "Please verify your email";
              } else {
                errorMessage = e.response!.data!.message;
              }
            }
            setCustomResponse({status: FutureStatus.failure, error: errorMessage});
          }    
    }
  useEffect(() => {
    if (captchaResponse) {
      submitFunc(innerData);
    }
  }, [captchaResponse])
  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        let res = await socialLogin(tokenResponse.access_token, '', "GOOGLE");
        dispatch({ type: "AUTH", data: res.data.token });
      } catch (err) {
        setCustomResponse({ status: FutureStatus.initialized });
        checkErr(err, false, toastAlert)
      }
    },
    onNonOAuthError: (err) => {
      setCustomResponse({ status: FutureStatus.initialized });
    },
    onError: (err) => {
      setCustomResponse({ status: FutureStatus.initialized });
    }
  });
  useGoogleOneTapLogin({
    onSuccess: async (credentialResponse) => {
      setCustomResponse({ status: FutureStatus.loading });
      try {
        let res = await newSocialLogin(credentialResponse.credential ?? '');
        dispatch({type: "AUTH", data: res.data.token});
      } catch (error) {
        checkErr(error, false, toastAlert);
        setCustomResponse({ status: FutureStatus.initialized });
      }
    },
    onError: () => {
      //
    },
    cancel_on_tap_outside: false
  });
  
  const [resendButton, setResendButton] = useState(false)  
  const [emailTo, setEmailTo] = useState('')  

  const resendVerificationEmail = (email: string, status: string) => {
    var data = {email: email};
    var config = {
      method: 'post',
    maxBodyLength: Infinity,
      url: `${baseUrl}/resend_verification_email`,
      headers: { },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      if (!status) {
        toastAlert('Sent!', undefined, false)
      }
    })
    .catch(function (error) {
      if (!status) {
        toastAlert('Not able to send, please try again later', undefined, 'warning')
      }
    });
  }
  return (
    <>
      <Link to="/">
        <img
          src={Assets.ARAAB_AI_LOGO}
          alt={"Araab AI Logo"}
          className={styles.logo}
        />
      </Link>
      <h3 style={{ color: "black" }}>
        {t("Welcome")} <AnimatedHandWave />
      </h3>
      <p>
        {" "}
        {`${
          props.isLoginForm
            ? t("Please Sign in to continue")
            : t("Let's get you started")
        }`}
      </p>
      <div style={{ height: "1.5em" }} />
      {loginWithMobile ? (
        <SignUpForm
          type={"mobile"}
          page={props.isLoginForm}
          exit={""}
          loginWithMobile={loginWithMobile}
          seLoginWithMobile={seLoginWithMobile}
        />
      ) : (
        <Form
          className={styles.form}
          onSubmit={async (e) => {
            e.preventDefault();
            const target = e.target as typeof e.target & {
              email: { value: string };
              password: { value: string };
              confirm_password: { value?: string };
            };
            const schema = props.isLoginForm ? loginSchema : registerSchema;

            const data = {
              email: target.email.value,
              password: target.password.value,
              confirm_password: target["confirm_password"]?.value,
            };

            let hasError: boolean = false;

            try {
              schema.validateSync(data, { abortEarly: false, strict: true });
              setFormErrors({});
              // await sleep(2000);
            } catch (e: any) {
              if (e instanceof yup.ValidationError) {
                setFormErrors(parseYupError(e));
              } else {
                setFormErrors({
                  unknown: [
                    "Oops! Something went wrong. Please try again later.",
                  ],
                });
              }
              hasError = true;
            }

            if (hasError) return;

            setCustomResponse({ status: FutureStatus.loading });
            await sleep(500);
            setInnerData(data)
            setRecaptchaPopUp(true)
            //open pop-up
          }}
        >
          {" "}
          <Link
            className="lngBTN fix fs-5 "
            to={"#"}
            onClick={() => handleLanguage(lng === "arab" ? "eng" : "arab")}
            style={{
              position: "absolute",
              top: " 10px",
              left: "20px",
              right: lng === "arab" ? "20px" : "unset",
            }}
          >
            <span
              style={{
                fontFamily: lng === "arab" ? "'Poppins'" : "'Tajawal'",
                color: "black",
              }}
            >
              {lng === "arab" ? "EN" : "ع"}
            </span>
          </Link>
          <Form.Group className="mb-3" controlId="email">
            <Form.Control
              placeholder={
                lng !== "arab" ? "Enter Your Email" : "أدخل البريد الإلكتروني"
              }
              className={
                formErrors.email != null
                  ? styles["textfield-error"]
                  : styles.textfield
              }
            />

            <Fade duration={400} isVisible={formErrors.email != null}>
              {" "}
              <p className={styles["error-message"]}>
                {formErrors.email != null ? t(formErrors.email[0]) : null}
              </p>
            </Fade>
          </Form.Group>
          <Form.Group
            className={props.isLoginForm ? "" : "mb-3"}
            controlId="password"
            style={{ margin: 0 }}
          >
            <Form.Control
              type="password"
              placeholder={lng !== "arab" ? "Password" : "كلمة المرور"}
              className={
                formErrors.password != null
                  ? styles["textfield-error"]
                  : styles.textfield
              }
            />
            <div style={{paddingTop: '10px'}}>
              <Fade duration={400} isVisible={formErrors.password != null}>
                {" "}
                {formErrors.password != null ?
                formErrors.password?.map((item: string, i: Number) => 
                <p  className={styles["error-message"]}>
                  {t(item ?? '')}
                </p>
                ):null}
                
              </Fade>
            </div>
          </Form.Group>
          {props.isLoginForm ? null : (
          <Form.Group controlId="confirm_password">
              <Form.Control
                type="password"
                placeholder={
                  lng !== "arab" ? "Confirm Password" : "تأكيد كلمة المرور"
                }
                className={
                  formErrors["confirm_password"] != null
                    ? styles["textfield-error"]
                    : styles.textfield
                }
              />
              <div style={{paddingTop: '10px'}}>
                <Fade
                  duration={400}
                  isVisible={formErrors["confirm_password"] != null}
                >
                  
                  {" "}
                  <p className={styles["error-message"]}>
                    {formErrors["confirm_password"] != null
                      ? t(formErrors["confirm_password"][0])
                      : null}
                  </p>
                </Fade>
              </div>
            </Form.Group>
          )}
          <div className="mb-3">
            <Link
              to={`/auth/${props.isLoginForm ? "forgotpassword" : "login"}`}
              replace={true}
              className={styles["forgot-password"]}
            >
              {props.isLoginForm
                ? t("Forgot password?")
                : t("Already have an account?")}
            </Link>
            
          </div>
          <Fade duration={400} isVisible={customResponse.error != null}>
            {" "}
            <p style={{whiteSpace: 'pre-wrap'}} className={styles["respone-error-message"] + " mb-3"}>
              {t(customResponse!.error || "")}
              
              {resendButton? <><br/><Link style={{color: '#1877f2'}} onClick={() => resendVerificationEmail(emailTo, '')} to='#'>{t(" Send Again?")}</Link></>:null}
            </p>
          </Fade>
          <TextButton
            backgroundColor={
              " transparent linear-gradient(to right, #d851ef, #9240CA, #1877f2, #1877f2,#9240CA,#d851ef) no-repeat 0% 0%"
            }
            hoverBackgroundColor={
              " transparent linear-gradient(to right, #d851ef,#9240CA, #1877f2,#1877f2,#9240CA,#d851ef) no-repeat 100% 0%"
            }
            onPressed={undefined}
            isSubmitButton={true}
            text={props.isLoginForm ? t("Login") : t("Register")}
            fontWeight="500"
            fontSize="0.9em"
            className="appleBtn"
            textColor={"white"}
            style={{ width: "100%", height: "35px" }}
            borderRadius={"6px"}
            shadow={"0px 2px 2px rgba(0,0,0,0.05)"}
            border={"0"}
            hoverScale={0.95}
            disableButton={false}
            padding={"0.2em 0"}
            disable={isLoading}
          >
            {isLoading ? (
              <Spinner style={{ width: "25px", height: "25px" }} />
            ) : null}
          </TextButton>
          <div style={{ height: "0.8em" }} />
          {props.isLoginForm ? (
            <>
              <TextButton
                backgroundColor={"white"}
                hoverBackgroundColor={"white"}
                text={t("Create Account")}
                fontWeight="500"
                fontSize="0.9em"
                textColor={"#1877f2"}
                style={{ width: "100%", height: "35px" }}
                borderRadius={"6px"}
                shadow={"0px 2px 4px rgba(0,0,0,0.1)"}
                hoverShadow={"0"}
                border={"1px solid rgba(60, 200, 235, 1)"}
                hoverScale={0.95}
                disableButton={false}
                padding={"0.2em 0"}
                onPressed={(e) => {
                  e.preventDefault();
                  navigate("/auth/signup", { replace: true });
                }}
                disable={isLoading}
              />
              <div style={{ height: "1.25em" }} />
            </>
          ) : null}
          <div className={styles["continue-with-wrapper"]}>
            <div className={styles.separator} />
            <p>{t("Or Continue With")}</p>
            <div className={styles.separator} />
          </div>
          {isLoading?
           <TextButton
             backgroundColor={"white"}
             text="Faceboook"
             fontSize="0.75em"
             onPressed={undefined}
             style={{ width: "100%", height: "35px" }}
             borderRadius={"6px"}
             shadow={"0px 2px 4px rgba(0,0,0,0.1)"}
             hoverShadow={"0px 2px 4px rgba(0,0,0,0.1)"}
             hoverScale={0.95}
             disableButton={isLoading ?? FutureStatus.loading}
             padding={"0.2em 0"}
             leadIcon={<img src={Assets.FACEBOOK_ICON} width="20px" alt="Facebook Icon" />}
             disable={isLoading ?? FutureStatus.loading}
           />:
          isValidBrowser?<LoginSocialFacebook
          appId={process.env.REACT_APP_FACEBOOK_APP_ID ?? ""}
          auth_type="reauthenticate"
          redirect_uri={process.env.REACT_APP_SOCIAL_REDIRECT_URL}
          // onLogoutFailure={onLogoutFailure}
          // onLoginStart={onLoginStart             }
          // onLogoutSuccess={onLogoutSuccess}
          onLoginStart={() => {
            setCustomResponse({ status: FutureStatus.loading });
          }}
          onResolve={async ({ provider, data }) => {
            try {
              let res = await socialLogin(
                data!.accessToken,
                data!.name,
                "FACEBOOK"
              );
              dispatch({ type: "AUTH", data: res.data.token });
            } catch (err) {
              checkErr(err, false, toastAlert)
            }
          }}
          onReject={(err) => {
            setCustomResponse({ status: FutureStatus.initialized });

            // props.setLoadingModalShow(false)
            // toastAlert(t('Error While Trying to Login With Facebook'), undefined, "warning")
          }}
        >
          <TextButton
            backgroundColor={"white"}
            text="Faceboook"
            fontSize="0.75em"
            onPressed={undefined}
            style={{ width: "100%", height: "35px" }}
            borderRadius={"6px"}
            shadow={"0px 2px 4px rgba(0,0,0,0.1)"}
            hoverShadow={"0px 2px 4px rgba(0,0,0,0.1)"}
            hoverScale={0.95}
            disableButton={isLoading ?? FutureStatus.loading}
            padding={"0.2em 0"}
            leadIcon={<img src={Assets.FACEBOOK_ICON} width="20px" alt="Facebook Icon" />}
            disable={isLoading ?? FutureStatus.loading}
          />
        </LoginSocialFacebook>:<TextButton
            backgroundColor={"white"}
            text="Faceboook"
            fontSize="0.75em"
            onPressed={() => {setModalOpenBrowser(true)}}
            style={{ width: "100%", height: "35px" }}
            borderRadius={"6px"}
            shadow={"0px 2px 4px rgba(0,0,0,0.1)"}
            hoverShadow={"0px 2px 4px rgba(0,0,0,0.1)"}
            hoverScale={0.95}
            disableButton={isLoading ?? FutureStatus.loading}
            padding={"0.2em 0"}
            leadIcon={<img src={Assets.FACEBOOK_ICON} width="20px" alt="Facebook Icon" />}
            disable={isLoading ?? FutureStatus.loading}
          />}

          <div style={{ height: "0.5em" }} />
          {isLoading?
          <TextButton
            backgroundColor={"white"}
            text="Google"
            fontSize="0.75em"
            onPressed={undefined}
            style={{ width: "100%", height: "35px" }}
            borderRadius={"6px"}
            shadow={"0px 2px 4px rgba(0,0,0,0.1)"}
            hoverShadow={"0px 2px 4px rgba(0,0,0,0.1)"}
            hoverScale={0.95}
            disableButton={isLoading ?? FutureStatus.loading}
            padding={"0.2em 0"}
            leadIcon={<img src={Assets.GOOGLE_ICON} width="20px" alt="Google Icon" />}
            disable={isLoading ?? FutureStatus.loading}
          />:
          
          // <LoginSocialGoogle
          //   client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""}
          //   redirect_uri={process.env.REACT_APP_SOCIAL_REDIRECT_URL}
          //   scope="https://www.googleapis.com/auth/userinfo.email"
          //   isOnlyGetToken
          //   onLoginStart={() => {
          //     setCustomResponse({ status: FutureStatus.loading });
          //   }}
          //   onResolve={async ({ provider, data }) => {
          //     try {
          //       let res = await socialLogin(data!.access_token, data?.name, "GOOGLE");
          //       dispatch({ type: "AUTH", data: res.data.token });
          //     } catch (err) {
          //       checkErr(err, false, toastAlert)
          //     }
          //     finally {
          //       setCustomResponse({ status: FutureStatus.initialized });
          //     } 
          //   }}
          //   onReject={(err) => {
          //     setCustomResponse({ status: FutureStatus.initialized });
          //   }}
          // >
          isValidBrowser?<TextButton
            backgroundColor={"white"}
            text="Google"
            fontSize="0.75em"
            onPressed={() => {setCustomResponse({ status: FutureStatus.loading }); loginWithGoogle()}}
            style={{ width: "100%", height: "35px" }}
            borderRadius={"6px"}
            shadow={"0px 2px 4px rgba(0,0,0,0.1)"}
            hoverShadow={"0px 2px 4px rgba(0,0,0,0.1)"}
            hoverScale={0.95}
            disableButton={isLoading ?? FutureStatus.loading}
            padding={"0.2em 0"}
            leadIcon={<img src={Assets.GOOGLE_ICON} width="20px" alt="Google Icon" />}
            disable={isLoading ?? FutureStatus.loading}
          />:<TextButton
          backgroundColor={"white"}
          text="Google"
          fontSize="0.75em"
          onPressed={() => {setModalOpenBrowser(true)}}
          style={{ width: "100%", height: "35px" }}
          borderRadius={"6px"}
          shadow={"0px 2px 4px rgba(0,0,0,0.1)"}
          hoverShadow={"0px 2px 4px rgba(0,0,0,0.1)"}
          hoverScale={0.95}
          disableButton={isLoading ?? FutureStatus.loading}
          padding={"0.2em 0"}
          leadIcon={<img src={Assets.GOOGLE_ICON} width="20px" alt="Google Icon" />}
          disable={isLoading ?? FutureStatus.loading}
        />
          // </LoginSocialGoogle>
          }

          <div style={{ height: "0.5em" }} />
          {isLoading?
          <TextButton
            backgroundColor={"black"}
            hoverBackgroundColor={"black"}
            className='appleBtn'
            text="Apple"
            textColor="#fff"
            fontSize="0.75em"
            onPressed={undefined}
            style={{ width: "100%", height: "35px" }}
            borderRadius={"6px"}
            shadow={"0px 2px 4px rgba(0,0,0,0.1)"}
            hoverShadow={"0px 2px 4px rgba(0,0,0,0.1)"}
            hoverScale={0.95}
            disableButton={isLoading ?? FutureStatus.loading}
            padding={"0.2em 0"}
            leadIcon={<img src={Assets.APPLE_ICON} width="20px" alt="Apple Icon" />}
            disable={isLoading ?? FutureStatus.loading}
          />:
          <LoginSocialApple
            client_id={process.env.REACT_APP_APPLE_ID || ""}
            scope={"name email"}
            redirect_uri={
              process.env.REACT_APP_SOCIAL_REDIRECT_URL || "https://araby.ai"
            }
            // onLoginStart={onLoginStart}

            onLoginStart={() => {
              setCustomResponse({ status: FutureStatus.loading });
            }}
            onResolve={({ provider, data }) => {
              signInUserSocial(data?.authorization.code, data?.name, "APPLE")
            }}
            onReject={(err) => {
              setCustomResponse({ status: FutureStatus.initialized });
            }}
          >
            <TextButton
              backgroundColor={"black"}
              hoverBackgroundColor={"black"}
              className="appleBtn"
              text="Apple"
              textColor="#fff"
              fontSize="0.75em"
              onPressed={undefined}
              style={{ width: "100%", height: "35px" }}
              borderRadius={"6px"}
              shadow={"0px 2px 4px rgba(0,0,0,0.1)"}
              hoverShadow={"0px 2px 4px rgba(0,0,0,0.1)"}
              hoverScale={0.95}
              disableButton={isLoading ?? FutureStatus.loading}
              padding={"0.2em 0"}
              leadIcon={<img src={Assets.APPLE_ICON} width="20px" alt="Apple Icon" />}
              disable={isLoading ?? FutureStatus.loading}
            />
          </LoginSocialApple>}
          {!loginWithMobile && props.isLoginForm &&
          <div style={{marginTop: '20px'}} className={styles["backlink-wrapper"]}>
            <Link to="#" onClick={() => seLoginWithMobile(true)}>
              <ArrowForward /> 
               {t("Login using Mobile")}
            </Link>
          </div>}
        </Form>
      )}
              

      <RecaptchaPopUp FutureStatus={FutureStatus} setCustomResponse={setCustomResponse} backdrop='static' show={recaptchaPopUp} onHide={() => setRecaptchaPopUp(false)} captcharesponse={captchaResponse} submitfunction={setCaptchaResponse}/>
      <BrowserModal
        show={modalOpenBrowser}
        onHide={() => setModalOpenBrowser(false)}
        setModalGuideOpenBrowser={setModalGuideOpenBrowser}
      />
      <BrowserGuideModal
        show={modalGuideOpenBrowser}
        onHide={() => setModalGuideOpenBrowser(false)}
      />
    </>
  );
};
