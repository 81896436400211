import {  useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styles from "./Styles.module.scss";
import Typed from "react-typed";
import Marquee from "react-easy-marquee";
import { marqueeData,marqueeData1,marqueeData2 } from "./Data";
import writeGif from "../../components/Assets/Gifs/Writing-ar.gif"
import writeGifE from "../../components/Assets/Gifs/Writing-en.gif"
import photoGifE from "../../components/Assets/Gifs/Images-en.gif"
import photoGif from "../../components/Assets/Gifs/Images-ar.gif"
import ArabyGif from "../../components/Assets/Gifs/gpt.gif"
import Statistics from '../../components/statistics/Statistics';
import bannerBC from '../../components/Assets/Img/Banner.webp'
import PopUpModal from "../../components/popUps/PopUpModal";

const LandingPage = () => {
  const [showModal, setShowModal] = useState(false);
  const user = useSelector((state) => state.userAuth);
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const bcRef = useRef()
  useEffect(() => {
    var bgImg = new Image();
    bgImg.onload = function(){
      if (bcRef.current) {
        let elem = document.getElementById('bc_image')
        elem.style.backgroundImage = 'url(' + bgImg.src + ')';
      }
    };
    bgImg.src = bannerBC;
  }, [bcRef.current])
 
  return (
    <div className={styles.container + ' ' + 'bannerSectionWrapper'}>
      <div ref={bcRef} id="bc_image" className={styles.background}>
        <div
          style={{ maxWidth: "1180px", margin: "auto", position: "relative" }}
        >
          <Marquee className={styles.marquee_container} duration={250000}>
            {marqueeData2.map((items, index) => {
              return (
                <div
                  key={index}
                  className={styles.marquee_items}
                  style={{ opacity: "1" }}
                >
                  <div>
                    <img
                      src={items.image}
                      alt=""
                      width={200}
                      style={{ transform: "rotate( 270deg" }}
                    />
                  </div>
                </div>
              );
            })}
          </Marquee>
          <Marquee
            className={styles.marquee_container}
            duration={200000}
            reverse={true}
          >
            {marqueeData1.map((items, index) => {
              return (
                <div
                  key={index}
                  className={styles.marquee_items}
                  style={{ opacity: "1" }}
                >
                  <div>
                    <img
                      src={items.image}
                      alt=""
                      width={200}
                      style={{ transform: "rotate( 270deg" }}
                    />
                  </div>
                </div>
              );
            })}
          </Marquee>
          <Marquee className={styles.marquee_container} duration={350000}>
            {marqueeData.map((items, index) => {
              return (
                <div
                  key={index}
                  className={styles.marquee_items}
                  style={{ opacity: "1" }}
                >
                  <div>
                    <img
                      src={items.image}
                      alt=""
                      width={200}
                      style={{ transform: "rotate( 270deg" }}
                    />
                  </div>
                </div>
              );
            })}
          </Marquee>

          <div className={styles.floating}>
            <div className={styles.float}>
              {lng === "arab" ? (
                <img src={writeGif} alt="" />
              ) : (
                <img src={writeGifE} alt="" />
              )}
            </div>
            <div className={styles.float}>
              <img src={ArabyGif} alt="" />
            </div>
            <div className={styles.float}>
              {lng === "arab" ? (
                <img src={photoGif} alt="" />
              ) : (
                <img src={photoGifE} alt="" />
              )}
            </div>
          </div>
          <div className={styles.heading_container}>
            <h5 style={{ direction: lng === "arab" ? "rtl" : "ltr" }}>
              {t("Welcome to Araby.AI")}
            </h5>
            <h1>{t("Unlock The Power Of AI")}</h1>
            <div
              style={{
                paddingTop: "30px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                direction: lng === "arab" ? "rtl" : "ltr",
              }}
            >
              <Typed
                strings={[
                  t("Artificial Intelligence"),
                  t("Araby GPT"),
                  t("Content Writing"),
                  t("Photo Generating"),
                  t("Coding"),
                ]}
                typeSpeed={50}
                backSpeed={40}
                loop
                className={styles.typewriter}
              />
            </div>
            <h4>
              {t(
                "Our technology provides various types of artificial intelligence for use in several fields, and all of this is in Arabic"
              )}
            </h4>
            <div className={styles.button}>
              <button
                onClick={() =>
                  !user
                    ? setShowModal(true)
                    : window.open(
                        process.env.REACT_APP_DASHBOARD_URL +
                          "?t=" +
                          localStorage.getItem("sess"),
                        "_blank"
                      )
                }
                className={
                  lng === "arab"
                    ? "btn common-btn fs-5 fw-bold"
                    : "btn common-btn fs-5"
                }
              >
                {t("Start Now")}
              </button>
            </div>
          </div>
        </div>
        <PopUpModal
          popUpFor={"real"}
          show={showModal}
          onHide={() => setShowModal(false)}
        />
      </div>
      <Statistics />
    </div>
  );
};

export default LandingPage;
