import { FC, useRef, useState } from "react";
import styles from "./forgotpassword.module.scss";
import { Form, Spinner } from "react-bootstrap";
import Fade from "../../../../components/transitions/fadetransition";
import { Link, useNavigate } from "react-router-dom";
import Assets from "../../../../utils/assets";
import TextButton from "../../../../components/textbutton/textbutton";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import * as yup from "yup";
import parseYupError from "../../../../utils/yupErrorParser";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import transistionstyles from "../../../../route.module.scss";
import VerifyOTP from "../verifyotp/verifyotp";
import { useTranslation } from "react-i18next";
import { CustomResponse } from "../../../../utils/typedef";
import FutureStatus from "../../../../utils/futurestatus";
import { sleep } from "../../../../utils/common_functions";
import { forgotPassord } from "../../../../api";
import checkErr from "../../../../actions/api_error_func";
import { AxiosError } from "axios";
import { toastAlert } from "../../../../components/alert/toastAlert";
import NewPassword from "../newpassword/new_password";

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Please enter your email")
    .email("Please enter a valid email"),
});
type SetEmail = (selectedEmail: string) => void;
enum ForgotPasswordFlow {
  ENTER_EMAIL = "enteremail",
  VERIFY_OTP = "verifytop",
  NEW_PASSWORD = "newpassword",
  SUCCESS = "success",
}
type SetCurrentFlow = (
  page: ForgotPasswordFlow,
  isAnimateRight: boolean
) => void;

interface Props {
  onSuccess: SetEmail;
}

const ForgotPassword: FC = () => {
  const [currentFlow, setCurrentFlow] = useState<ForgotPasswordFlow>(
    ForgotPasswordFlow.ENTER_EMAIL
  );

  const enteremailRef = useRef<HTMLDivElement>(null);
  const verifyOtpRef = useRef<HTMLDivElement>(null);
  const newPasswordRef = useRef<HTMLDivElement>(null);
  const successRef = useRef<HTMLDivElement>(null);
  const [isAnimateRight, setIsAnimateRight] = useState<boolean>(true);
  const [selectedEmail, setSelectedEmail] = useState<string>();
  const [selectedOTP, setSelectedOTP] = useState<string>();

  // const isLogin = props.location.includes("login");
  const navigate = useNavigate();

  let ref: React.RefObject<HTMLDivElement>;
  let currentFlowComponent: JSX.Element;

  const setNewCurrentFlow: SetCurrentFlow = (page, isAnimateRight) => {
    setIsAnimateRight(isAnimateRight);
    setCurrentFlow(page);
  };

  switch (currentFlow) {
    case ForgotPasswordFlow.ENTER_EMAIL:
      ref = enteremailRef;
      currentFlowComponent = (
        <EnterEmail
          onSuccess={(email) => {
            setSelectedEmail(email);
            setNewCurrentFlow(ForgotPasswordFlow.VERIFY_OTP, false);
          }}
        />
      );
      break;

    case ForgotPasswordFlow.VERIFY_OTP:
      ref = verifyOtpRef;
      currentFlowComponent = (
        <VerifyOTP
          email={selectedEmail ?? ""}
          onSuccess={(otp) => {
            setSelectedOTP(otp);
            setNewCurrentFlow(ForgotPasswordFlow.NEW_PASSWORD, false);
          }}
        />
      );

      break;
    case ForgotPasswordFlow.NEW_PASSWORD:
      ref = newPasswordRef;
      currentFlowComponent = (
        <NewPassword
          email={selectedEmail ?? ""}
          otp={selectedOTP ?? ""}
          onSuccess={() => {
            toastAlert("Password reset successfully", null, "success");
            navigate("/auth/login", { replace: true });
          }}
        />
      );
      break;
    case ForgotPasswordFlow.SUCCESS:
      ref = successRef;
      // currentFlowComponent  = <VerifyOTP/>

      break;
  }

  const animateDirectionClassName = isAnimateRight ? "right" : "left";

  return (
    <SwitchTransition>
      <CSSTransition
        key={currentFlow}
        nodeRef={ref}
        timeout={1000}
        unmountOnExit
        classNames={{
          // appear: "my-appear",
          // appearActive: "my-active-appear",
          // appearDone: "my-done-appear",
          enter: transistionstyles[`fade-enter-${animateDirectionClassName}`],
          enterActive:
            transistionstyles[`fade-enter-active-${animateDirectionClassName}`],
          // enterDone: "my-done-enter",
          exit: transistionstyles[`fade-exit-${animateDirectionClassName}`],
          exitActive:
            transistionstyles[`fade-exit-active-${animateDirectionClassName}`],
          // exitDone: "my-done-exit",
        }}
      >
        {(state) => {
          return (
            <div ref={ref} style={{ width: "100%" }}>
              {currentFlowComponent}
            </div>
          );
        }}
      </CSSTransition>
    </SwitchTransition>
  );
};

const EnterEmail: FC<Props> = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language")??' ')?.lang;

  const [formErrors, setFormErrors] = useState<any>({});

  const [customResponse, setCustomResponse] = useState<
    CustomResponse<any, string>
  >({ status: FutureStatus.initialized });

  const isLoading = customResponse.status === FutureStatus.loading;
  return (
    <div className={styles.wrapper}>
      <img
        src={Assets.ARAAB_AI_LOGO}
        alt={"Araab AI Logo"}
        className={styles.logo}
      />
      <p className={styles.text}>
        {t(
          "Enter your email address associated with your account and we'll send you a link to reset your password"
        )}
      </p>
      <Form
        className={styles.form}
        onSubmit={async (e) => {
          e.preventDefault();
          const target = e.target as typeof e.target & {
            email: { value: string };
            password: { value: string };
            confirm_password: { value?: string };
          };

          const data = {
            email: target.email.value,
          };

          let hasError: boolean = false;

          try {
            schema.validateSync(data, { abortEarly: false, strict: true });
            setFormErrors({});

            // await sleep(2000);
          } catch (e: any) {
            if (e instanceof yup.ValidationError) {
              setFormErrors(parseYupError(e));
            } else {
              setFormErrors({
                unknown: [
                  "Oops! Something went wrong. Please try again later.",
                ],
              });
            }
            hasError = true;
          }

          if (hasError) return;

          setCustomResponse({ status: FutureStatus.loading });
          await sleep(500);

          try {
            const result = await forgotPassord(data.email);
            setCustomResponse({
              status: FutureStatus.success,
              data: result.data,
            });

            props.onSuccess(data.email);
          } catch (e) {
            let errorMessage =
              "Oops! Something went wrong. Please try again later.";
            if (e instanceof AxiosError && e.response?.data?.message != null) {
              errorMessage = e.response!.data!.message;
            }
            setCustomResponse({
              status: FutureStatus.failure,
              error: errorMessage,
            });
          }
        }}
      >
        <Form.Group className="mb-3" controlId="email">
          <Form.Control
            // type="email"
            placeholder={lng !== 'arab'? "Enter Your Email":"أدخل البريد الإلكتروني"}
            className={
              formErrors.email != null
                ? styles["textfield-error"]
                : styles.textfield
            }
          />

          <Fade duration={400} isVisible={formErrors.email != null}>
            {" "}
            <p className={styles["error-message"]}>
              {formErrors.email != null ? t(formErrors.email[0]) : null}
            </p>
          </Fade>
        </Form.Group>
        <TextButton
          backgroundColor={
            " transparent linear-gradient(to right, #d851ef, #9240CA, #1877f2, #1877f2,#9240CA,#d851ef) no-repeat 0% 0%"
          }
          hoverBackgroundColor={
            " transparent linear-gradient(to right, #d851ef,#9240CA, #1877f2,#1877f2,#9240CA,#d851ef) no-repeat 100% 0%"
          }
          onPressed={undefined}
          isSubmitButton={true}
          text={t("Send OTP")}
          fontWeight="500"
          className="appleBtn"
          fontSize="1.0em"
          textColor={"white"}
          style={{ width: "100%", height: "40px" }}
          borderRadius={"6px"}
          shadow={"0px 2px 2px rgba(0,0,0,0.05)"}
          border={"0"}
          hoverScale={0.95}
          disableButton={false}
          padding={"0.2em 0"}
          disable={isLoading}
        >
          {isLoading ? (
            <Spinner style={{ width: "25px", height: "25px" }} />
          ) : null}
        </TextButton>
      </Form>

      <Fade duration={400} isVisible={customResponse.error != null}>
        {" "}
        <p className={styles["respone-error-message"] + " mb-3"}>
          {t(customResponse.error ?? '')}
        </p>
      </Fade>

      <div className={styles["backlink-wrapper"]}>
        <Link to="/auth/login">
          <ArrowForward /> {t("Back to sign in")}
        </Link>
      </div>
    </div>
  );
};
export default ForgotPassword;
